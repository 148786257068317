import BinIcon from 'assets/icons/cart-trash-bin.svg';
import PayIcon from 'assets/icons/pay-icon.svg';
import Button from 'components/Button';
import useIntl from 'hooks/useIntl';
import useItemIcon from 'hooks/useItemIcon';
import { FunctionalComponent } from 'preact';
import { TotalPriceTitle, TotalPriceValue } from 'screens/Cart/components';
import styled from 'styled-components';
import { colorsSpec } from 'styles/defaultTheme';
import { CartItem } from 'types/cache/Cart';
import { CartTypes } from 'types/shared/Cart';
import { formatPrice } from 'utils/price/priceOperations';
import TravelPlanItemDetails from '../TravelPlanItemDetails';

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  border-radius: 3px;
  width: 95%;
  margin: 18px;
`;

const DetailsHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
`;

const DetailsHeaderTitle = styled.h4`
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  font-size: 18px;
  line-height: 25px;
`;

const DetailsHeaderSubitle = styled.h5`
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  font-size: 14px;
  line-height: 22px;
`;

const RemoveButton = styled(Button)`
  padding: 2px 18px;
  border-radius: 25px;
  min-width: 110px;
  height: 32px;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }

  img {
    width: 15px;
    height: 15px;
  }

  @media (max-width: 576px) {
    margin-left: 150px;
  }
`;

const PaidButton = styled(RemoveButton)`
  background: ${({ theme }) => theme.colors[colorsSpec.accentLight]};
  cursor: default;

  &:hover {
    background-color: ${({ theme }) => theme.colors[colorsSpec.accentLight]};
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  margin-bottom: 20px;
`;

const StyledImage = styled.img`
  filter: invert(53%) sepia(0%) saturate(6%) hue-rotate(210deg)
    brightness(95%) contrast(90%);
`;

interface IProps {
  travelPlanName: string;
  item: CartItem;
  travelPlanId: string;
  handleItemRemove: (
    travelPlanId: string,
    itemId: number,
    itemType: CartTypes
  ) => void;
}

const TravelPlanItem: FunctionalComponent<IProps> = ({
  item,
  travelPlanId,
  handleItemRemove,
}) => {
  const { t } = useIntl('app.TravelPlan');
  const { icon } = useItemIcon(item.type, item.item);

  return (
    <DetailsWrapper>
      <DetailsHeaderContainer>
        <StyledImage src={icon} />
        <div>
          <DetailsHeaderTitle>{item.item.title}</DetailsHeaderTitle>
          <DetailsHeaderSubitle>{item.item.subtitle}</DetailsHeaderSubitle>
        </div>

        {
          item.paid
            ? <PaidButton
                title={t('paid')}
                icon={PayIcon}
              />
            : <RemoveButton
                secondary
                title={t('remove')}
                icon={BinIcon}
                onClick={() => handleItemRemove(travelPlanId, item.id, item.type)}
              />
        }
      </DetailsHeaderContainer>
      {TravelPlanItemDetails(item.id, item.item)[item.type]}
      <PriceContainer>
        <PriceWrapper>
          <TotalPriceTitle>{t('totalPrice')}</TotalPriceTitle>
          <TotalPriceValue>{formatPrice(item.item.price)}</TotalPriceValue>
        </PriceWrapper>
      </PriceContainer>
    </DetailsWrapper>
  );
};

export default TravelPlanItem;
