import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { FunctionalComponent } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  max-width: 40px;
  max-height: 40px;
  position: relative;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 210px;
  justify-content: space-between;
`;

type Props = {
  isOpen: boolean;
  tooltipPoweredBy: string;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
};

const LoadLeadTravelerTooltip: FunctionalComponent<Props> = ({
  isOpen,
  tooltipPoweredBy,
  onCancel,
  onConfirm,
  title,
}) => {
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      tooltipRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isOpen]);

  useOnClickOutside(tooltipRef, () => isOpen && onCancel());

  return (
    <TooltipContainer ref={tooltipRef}>
      {isOpen && (
        <Tooltip
          style={{
            position: 'relative',
            zIndex: '2',
            minWidth: '343px',
            top: '100px',
            left: '60px',
          }}
          onDismiss={() => onCancel()}
          title={title}
          text={
            <ButtonsContainer>
              <Button title="Yes" secondary onClick={() => onConfirm()} />
              <Button title="No" secondary onClick={() => onCancel()} />
            </ButtonsContainer>
          }
          footerText={tooltipPoweredBy}
        />
      )}
    </TooltipContainer>
  );
};

export default LoadLeadTravelerTooltip;
