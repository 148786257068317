import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { GET_CITIES } from 'apollo/myDesti/queries';
import EmptyState from 'components/EmptyState';
import InfoDisclaimer from 'components/InfoDisclaimer';
import Loading from 'components/Loading';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import SelectComponent, { Option } from 'components/Select';
import Tabs, { mapActiveTab } from 'components/Tabs';
import Tile from 'components/Tile';
import { PAGE_LIMIT } from 'config/network';
import { Paths } from 'constants/paths';
import useIntl from 'hooks/useIntl';
import { Fragment } from 'preact';
import { useRef, useState } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import { locationAndPeriodVar } from 'screens/LocationAndPeriod/cache';
import { InfiniteScrollWithRef } from 'shared/components/InfiniteScrollWithRef';
import { SelectContainer } from 'shared/components/SelectContainer';
import { GET_INTEGRATION_AVAILABLE_ACCOMMODATIONS } from '../../../apollo/myDesti/mutations';
import { stayDetailsVar } from '../../Details/Stay/cache';
import {
  Container,
  ControlsContainer,
  EndMessage,
  InfiniteScrollLoader,
  ItemImage,
  LeftColumn,
  RightColumn,
  Row,
  SortContainer,
  SortLabel,
  TileContainer,
} from '../components';
import { MARKERS_MOCK } from '../config';
import { AccommodationSort, stayListVar } from './cache';
import { useStayList } from './useStayList';

const defaultAmenities = [
  { image: 'assets/icons/temperature.svg', label: 'Heating' },
  { image: 'assets/icons/computer.svg', label: 'Computer' },
  { image: 'assets/icons/fork.svg', label: 'Food' },
  {
    image: 'assets/icons/pet-bottle.svg',
    label: 'Pets friendly',
  },
  { image: 'assets/icons/wifi.svg', label: 'WiFi' },
  { image: 'assets/icons/elevator.svg', label: 'Elevator' },
];

const StayList = ({ tabs = [], location }) => {
  const { t } = useIntl('app.StayList');
  const { t: controls } = useIntl('app.Controls');
  const { activeTab } = location.state;

  const history = useHistory();
  const isThisPageVisited = IsPageVisited('StayList');
  const emptyStateConfig = { title: t('noItems') };
  const containerEl = useRef(null);
  const [resultStays, setResultStays] = useState([]);

  const [page, setPage] = useState(0);
  const { cityIds, regionIds, cityName, travelPeriod } = locationAndPeriodVar();
  const { orderBy, city } = useReactiveVar(stayListVar);
  const { setCity, setOrderBy, setRatings } = useStayList(stayListVar);
  const { people, rooms } = useReactiveVar(stayDetailsVar);

  const { data: citiesData } = useQuery(GET_CITIES);

  const [
    getAvailableAccommodations,
    { called: calledAvailAcc, loading: loadingAvailAcc },
  ] = useMutation(GET_INTEGRATION_AVAILABLE_ACCOMMODATIONS, {
    variables: {
      city: cityName,
      arrivalDate: travelPeriod[0],
      departureDate: travelPeriod[1],
      numOfAdults: people,
      childrenAges: [],
      numOfRooms: rooms,
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setResultStays(data?.getAvailableAccommodations?.accommodations);
    },
  });

  const sortByOptions = [
    {
      value: controls('mostPopular'),
      label: controls('mostPopular'),
      key: AccommodationSort.popular,
    },
    {
      value: controls('priceAscending'),
      label: controls('priceAscending'),
      key: AccommodationSort.priceAsc,
    },
    {
      value: controls('priceDescending'),
      label: controls('priceDescending'),
      key: AccommodationSort.priceDesc,
    },
    {
      value: controls('ratingAscending'),
      label: controls('ratingAscending'),
      key: AccommodationSort.ratingAsc,
    },
    {
      value: controls('ratingDescending'),
      label: controls('ratingDescending'),
      key: AccommodationSort.ratingDesc,
    },
  ];

  const cities = [
    { value: controls('selectAll'), label: controls('selectAll') },
    ...citiesData.city
      .filter(c => cityIds.includes(c.id) || regionIds.includes(c.region_id))
      .map(city => ({
        value: city.name,
        key: city.id.toString(),
        label: city.name,
      }))
      .sort((a, b) => a.value.localeCompare(b.value)),
  ];

  const onCityChange = selectedCity => {
    if (selectedCity === city) {
      return;
    }

    resetScroll(selectedCity);
    setCity(selectedCity);
    setPage(0);
  };

  const onOrderByChange = (value, key) => {
    if (orderBy.key === key) {
      return;
    }

    resetScroll(key);
    setPage(0);
    setOrderBy({ value, key });
  };

  const onNextPage = () => {
    const nextPage = page + 1;
    setPage(nextPage);
  };

  // useEffect(() => {
  //   fetchMore({
  //     variables: {
  //       offset: page * PAGE_LIMIT,
  //       limit: PAGE_LIMIT,
  //       city: city === cities[0].value ? null : city,
  //       orderBy: formatOrderBy(
  //         sortByOptions.find(option => option.key === orderBy.key)
  //       ),
  //     },
  //   });
  // }, [city, orderBy, page]);

  const resetScroll = option => {
    if (option === city || option === orderBy.key) {
      return;
    }

    containerEl.current.base.scrollTop = 0;
  };

  const hasMore = () => {
    const nextPage = page + 1;

    if (resultStays.length < PAGE_LIMIT) {
      return false;
    }

    return resultStays.length > nextPage * PAGE_LIMIT;
  };

  // if (!calledAcByPk && !loadingAcByPk) {
  //   getAccommodationsByPk();
  // }

  if (!calledAvailAcc && !loadingAvailAcc) {
    getAvailableAccommodations();
  }

  return (
    <Fragment>
      <ModalHeader title={t('title')} />
      {tabs.length && <Tabs config={mapActiveTab(tabs, activeTab)} />}

      <ControlsContainer>
        <Row>
          <LeftColumn>
            <SelectComponent
              input={{
                onChange: ({ value }: Option) => onCityChange(value),
                value: city || controls('selectAll'),
              }}
              options={cities}
              styleType="type_3"
            />
          </LeftColumn>
          <RightColumn>
            <SortContainer>
              <SortLabel>{controls('sortBy')}</SortLabel>
              <SelectContainer>
                <SelectComponent
                  input={{
                    onChange: ({ value, key }: Option) =>
                      onOrderByChange(value, key),
                    value: orderBy.value || controls('mostPopular'),
                  }}
                  options={sortByOptions}
                  styleType="type_3"
                />
              </SelectContainer>
            </SortContainer>
          </RightColumn>
        </Row>
      </ControlsContainer>

      {loadingAvailAcc ? (
        <Loading />
      ) : (
        <div>
          {resultStays?.length ? (
            <Container>
              <InfiniteScrollWithRef
                ref={containerEl}
                height={425}
                dataLength={resultStays.length}
                next={() => onNextPage()}
                hasMore={hasMore()}
                loader={<InfiniteScrollLoader />}
                endMessage={
                  <EndMessage>
                    <h4>{t('endMessage')}</h4>
                  </EndMessage>
                }
              >
                {resultStays.map(
                  ({
                    integration,
                    stayEstablishmentId,
                    image = `assets/images/list-item-${stayEstablishmentId}.png`,
                    name,
                    description,
                    amenities = defaultAmenities,
                  }) => (
                    <Row
                      onClick={() =>
                        history.push({
                          pathname: `/stay/${integration}/${stayEstablishmentId}`,
                          state: {
                            activeTab: 'app.Controls.stayTab',
                          },
                        })
                      }
                    >
                      <LeftColumn>
                        <ItemImage
                          src={image}
                          style={{ objectFit: 'cover', width: '100%' }}
                        />
                      </LeftColumn>
                      <RightColumn>
                        <TileContainer>
                          <Tile
                            title={name}
                            description={description}
                            amenities={amenities}
                          />
                        </TileContainer>
                      </RightColumn>
                    </Row>
                  )
                )}
              </InfiniteScrollWithRef>
              <InfoDisclaimer
                text={controls('priceDisclaimer')}
                icon="assets/icons/info-bubble.svg"
              />
            </Container>
          ) : (
            <EmptyState config={emptyStateConfig} />
          )}
        </div>
      )}

      <ModalFooter
        mapIconProps={{
          onClick: () =>
            history.push({
              pathname: '/map',
              state: {
                markers: MARKERS_MOCK,
                activeTab: 'app.Controls.stayTab',
              },
            }),
        }}
        buttonTitle={t('buttonTitle')}
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        onButtonClick={() => history.push(Paths.Arrangements)}
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default StayList;
