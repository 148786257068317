import BinIcon from 'assets/icons/trash-bin.svg';
import BackgroundImage from 'assets/images/hotel-cover-small.png';
import useIntl from 'hooks/useIntl';
import { Fragment, FunctionalComponent } from 'preact';
import {
  CartItem,
  CartItemImage,
  CartItemInfo,
  LargeCard,
  RemoveButton,
  Row,
  Title,
  Value,
} from 'screens/Cart/components';
import { formatPrice } from 'utils/price/priceOperations';

type Props = {
  item: any;
  onRemove: (item: any) => void;
  isSmall?: boolean;
  isOpen?: boolean;
};

const StayCartItem: FunctionalComponent<Props> = ({
  item,
  onRemove,
  isSmall = false,
  isOpen = false,
}) => {
  const { t } = useIntl('app.Cart');
  return (
    <CartItem isSmall={isSmall} isOpen={isOpen}>
      <CartItemImage isSmall={isSmall} isOpen={isOpen}>
        <img src={BackgroundImage} />
        <p>{item.title}</p>

        <span>{formatPrice(item.price)}</span>
      </CartItemImage>

      {!isSmall && (
        <Fragment>
          <RemoveButton
            title={t('removeButton')}
            icon={BinIcon}
            isOpen={isOpen}
            onClick={() => onRemove(item)}
          />
          <CartItemInfo>
            <Row>
              <LargeCard>
                <Value>
                  {item.people} {t('stayPeople')}
                </Value>
                <Value>
                  {item.children} {t('stayChildren')}
                </Value>
              </LargeCard>
              <LargeCard>
                <Title>{t('stayCity')}</Title>
                <Value>{item.city}</Value>
              </LargeCard>
              <LargeCard>
                <Title>{t('stayType')}</Title>
                <Value>{t('stayClass')}</Value>
              </LargeCard>
              <LargeCard>
                <Title>{t('stayPrice')}</Title>
                <Value>{formatPrice(item.price)}</Value>
              </LargeCard>
            </Row>
          </CartItemInfo>
        </Fragment>
      )}
    </CartItem>
  );
};

export default StayCartItem;
