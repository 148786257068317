import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import dateDiffInDMH from 'utils/time/dateDiffInDMH';
import secondsToDMH from 'utils/time/secondsToDMH';
import TransportPill from '../TransportPill';

dayjs.extend(utc);

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors[colorsSpec.primaryLight]};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  height: 100%;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const MainTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.93px;
  line-height: 19px;
  text-transform: uppercase;
`;

const DetailRow = styled(HeaderRow)`
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  border-radius: 17px;
  background-color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  margin-top: 10px;
  padding: 10px 10px 10px 5px;
`;

const TravelTimes = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30%;
  max-width: 30%;
`;

const TravelText = styled.p`
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
`;

const TravelTextBig = styled.p`
  font-size: 21px;
  font-weight: bold;
  line-height: 28px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
`;

const TravelMisc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  min-width: 30%;
`;

const TravelMiscText = styled.div`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  padding-right: 5px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
`;

const TravelMiscArrow = styled.div`
  top: -2px;
  padding-left: 5px;
  position: relative;
  opacity: 0.9;
  transform: scale(2, 1);
`;

const TravelMiscBuyButton = styled.button`
  border: none;
  border-radius: 2px;
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.colors[colorsSpec.disabled]
      : theme.colors[colorsSpec.accentLight]};
  color: #fff;
  height: 30px;
  width: 180px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.8px;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  z-index: 100;
`;

const DetailTimeStation = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 37%;
  min-width: 37%;
  hyphens: auto;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  ${({ right }) =>
    right
      ? `
      justify-content: end;
      text-align: right;
  `
      : ``}
`;

const DetailTransportInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DetailTransportText = styled.p`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
`;

const TransportMore = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondary]};
  font-size: 10px;
  padding: 10px 12px 12px;
`;

const SoldOverlay = styled.div`
  position: fixed;
  width: calc(100% - 10px);
  height: 100%;
  padding: 0;
  margin: -7px 0 0 -10px;
  filter: grayscale(100%);
  backdrop-filter: grayscale(1);
  z-index: 999;
  top: 0;
  left: 0;
`;

const TransportPillContainer = styled.div`
  display: flex;
`;

type Props = {
  isPreview?: boolean;
  title: string;
  description?: string;
  rating: number;
  price: number;
  reviewCount: number;
  type: string;
  departureCode: string;
  departureTime: string;
  arivalCode: string;
  arivalTime: string;
  transportItem: any;
  transportType: number;
  duration?: number;
  disabled?: boolean;
  onBuyClick: (e: any) => void;
};

const TransportTile: FunctionalComponent<Props> = ({
  isPreview,
  title = '',
  rating = 0,
  reviewCount = 0,
  type = '',
  departureCode = '',
  departureTime = '',
  arivalCode = '',
  arivalTime = '',
  transportItem = {},
  transportType = null,
  duration = null,
  disabled = false,
  onBuyClick = () => null,
}) => {
  const { t } = useIntl('app.IndividualTransport');
  let segmentIndex = 0;
  let itineraryIndex = 0;
  const hasTitle = title && title.length > 0;

  const [expanded, setExpanded] = useState(false);
  const [minimized, setMinimized] = useState(hasTitle);

  const renderTransportPills = () => {
    if (minimized) return;

    if (transportType === 0) {
      return (
        <TransportPillContainer>
          {transportItem.itineraries.map(itinerary =>
            itinerary.segments.map(segment =>
              segmentIndex++ <= 1 ? (
                <TransportPill
                  type={segment.travelMethodCode.value}
                  info={`${segment.segmentProducerCode.title} ${
                    segment.productCode.value || ''
                  } ${segment.transportId}`}
                />
              ) : segmentIndex ===
                transportItem.itineraries.reduce(
                  (acc, element) => acc + element.segments.length,
                  0
                ) ? (
                <TransportMore>+{segmentIndex - 2}</TransportMore>
              ) : null
            )
          )}
        </TransportPillContainer>
      );
    } else if (transportType === 1) {
      return (
        <TransportPillContainer>
          {transportItem.itineraries.map(itinerary =>
            itineraryIndex++ <= 1 ? (
              <TransportPill
                type="Air"
                info={`${itinerary.airline} ${itinerary.flightNumber}`}
              />
            ) : itineraryIndex === transportItem.itineraries.length ? (
              <TransportMore>+{itineraryIndex - 2}</TransportMore>
            ) : null
          )}
        </TransportPillContainer>
      );
    }
  };

  const renderDetails = () => {
    if (!expanded || minimized) return;
    if (transportType === 0) {
      return transportItem.itineraries.map(itinerary =>
        itinerary.segments?.map(segment => (
          <DetailRow>
            <DetailTimeStation>
              <p>
                <strong>
                  {dayjs(segment.departureDateTime).format('HH:mm')}
                </strong>
                &nbsp;{segment.departureLocation.name}
              </p>
            </DetailTimeStation>
            <DetailTransportInfo>
              <TransportPill
                type={segment.travelMethodCode.value}
                info={`${segment.segmentProducerCode.title} ${
                  segment.productCode.value || ''
                } ${segment.transportId}`}
              />
              <DetailTransportText>
                {t(segment.travelMethodCode.title.toLowerCase())} {t('with')}{' '}
                {segment.segmentProducerCode.title}&nbsp;
                {segment.productCode.title}
              </DetailTransportText>
            </DetailTransportInfo>
            <DetailTimeStation right>
              <p>
                <strong>
                  {dayjs(segment.arrivalDateTime).format('HH:mm')}
                </strong>
                &nbsp;{segment.arrivalLocation.name}
              </p>
            </DetailTimeStation>
          </DetailRow>
        ))
      );
    } else if (transportType === 1) {
      return transportItem.itineraries.map(itinerary => (
        <DetailRow>
          <DetailTimeStation>
            <p>
              <strong>
                {dayjs.utc(itinerary.departureTime).format('HH:mm')}
              </strong>
              &nbsp;{itinerary.departureLocation.airportName}
              <br />
              {dayjs.utc(itinerary.departureTime).format('DD.MM.YYYY')}
            </p>
          </DetailTimeStation>
          <DetailTransportInfo>
            <TransportPill
              type="Air"
              info={`${itinerary.airline} ${itinerary.flightNumber}`}
            />
            <DetailTransportText>
              {t(itinerary.travelClass)}
            </DetailTransportText>
          </DetailTransportInfo>
          <DetailTimeStation right>
            <p>
              <strong>
                {dayjs.utc(itinerary.arrivalTime).format('HH:mm')}
              </strong>
              &nbsp;{itinerary.arrivalLocation.airportName}
              <br />
              {dayjs.utc(itinerary.arrivalTime).format('DD.MM.YYYY')}
            </p>
          </DetailTimeStation>
        </DetailRow>
      ));
    }
  };

  return (
    <Container
      onClick={(e: PointerEvent) => {
        e.stopPropagation();
        hasTitle ? setMinimized(!minimized) : setExpanded(!expanded);
      }}
    >
      {transportItem.fromPrice ? null : <SoldOverlay />}
      <HeaderRow>
        <TravelTimes>
          {hasTitle ? (
            <TravelText>
              <MainTitle>
                {title}
                {!minimized ? dayjs(departureTime).format(' DD.MM.YYYY.') : ''}
              </MainTitle>
            </TravelText>
          ) : null}
          {!minimized && (
            <>
              <TravelText>{`${departureCode}`}</TravelText>
              <TravelTextBig>
                {dayjs.utc(departureTime).format('HH:mm')}-
                {dayjs.utc(arivalTime).format('HH:mm')}
              </TravelTextBig>
              <TravelText>
                {`(${
                  duration
                    ? secondsToDMH(duration)
                    : dateDiffInDMH(departureTime, arivalTime)
                })`}
              </TravelText>
            </>
          )}
        </TravelTimes>
        {renderTransportPills()}
        <TravelMisc>
          <TravelMiscText
            style={{
              height: hasTitle ? (!minimized ? '70px' : 'auto') : 'auto',
            }}
          >
            <div>{t('travelDetails')}</div>
            {hasTitle ? (
              minimized ? (
                <TravelMiscArrow>&or;</TravelMiscArrow>
              ) : (
                <TravelMiscArrow>&and;</TravelMiscArrow>
              )
            ) : expanded ? (
              <TravelMiscArrow>&and;</TravelMiscArrow>
            ) : (
              <TravelMiscArrow>&or;</TravelMiscArrow>
            )}
          </TravelMiscText>
          {!minimized && (
            <TravelMiscBuyButton
              disabled={
                !transportItem.fromPrice?.amount || hasTitle || isPreview || disabled
              }
              onClick={onBuyClick}
            >
              {transportItem.fromPrice?.amount
                ? `${t('addFrom')} ${transportItem.fromPrice.amount} ${
                    transportItem.fromPrice?.currency
                  }`
                : t('soldOut')}
            </TravelMiscBuyButton>
          )}
        </TravelMisc>
      </HeaderRow>
      {renderDetails()}
    </Container>
  );
};

export default TransportTile;
