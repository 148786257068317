import { makeVar } from '@apollo/client';
import { TransportList } from 'types/cache/TransportList';
import { GenericConfig } from 'types/shared/GenericConfig';

// TODO: Update when we get all values on the backend
export const TransportationSuggestionSort: GenericConfig = {
  popular: 'arrival_city:asc',
};

const transportListInitial: TransportList = {
  orderBy: { key: TransportationSuggestionSort.popular, value: '' },
  type: null,
  transportItem: {},
  returnTransportItem: {}
};
export const transportListVar = makeVar<TransportList>(transportListInitial);
