import BackIcon from 'assets/icons/back-button-modal.svg';
import CloseIcon from 'assets/icons/x-button-modal.svg';
import Icon from 'components/Icon';
import { ModalContext } from 'components/Modal';
import { Paths } from 'constants/paths';
import { FunctionalComponent, VNode } from 'preact';
import { useContext } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const StyledModalHeader = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.secondaryDark};
  justify-content: space-between;
  display: flex;
  flex-shrink: 0;
  padding: 20px 25px;
`;

const Title = styled.h2`
  @media (max-width: 576px) {
    font-size: 30px !important;
    margin: auto 10px !important;
  }
`;

type Props = {
  title: string | VNode;
};

const ModalHeader: FunctionalComponent<Props> = ({ title = null }) => {
  const history = useHistory();
  const { onModalClose } = useContext(ModalContext);

  return (
    <StyledModalHeader>
      <Icon
        disabled={!history.index}
        icon={BackIcon}
        onClick={() => history.goBack()}
        id="footerBackButton"
      />
      <Title>{title}</Title>
      <Icon icon={CloseIcon} onClick={onModalClose} id="footerCloseButton" />
    </StyledModalHeader>
  );
};

export default ModalHeader;
