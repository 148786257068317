import useItemIcon from 'hooks/useItemIcon';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { colorsSpec } from 'styles/defaultTheme';
import { CartItemType } from 'types/cache/Cart';
import { CartTypes } from 'types/shared/Cart';

const PlanItemTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
  align-items: center;
  margin-left: 10px;

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  }
  h5 {
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  }

  ${({ isPaying }) =>
    isPaying &&
    `
margin-top: 20px;
`};
`;

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 0.7rem;
  filter: invert(53%) sepia(0%) saturate(6%) hue-rotate(210deg)
    brightness(95%) contrast(90%);
`;

interface IProps {
  type: CartTypes;
  item: CartItemType;
  isPaying: boolean;
}

const PlanItemTitle: FunctionalComponent<IProps> = ({
  type,
  item,
  isPaying,
}) => {
  const { icon } = useItemIcon(type, item);

  return (
    <PlanItemTitleContainer isPaying={isPaying}>
      <StyledImage src={icon} />
      <div>
        <h4>{item.title}</h4>
        <h5>{item.subtitle}</h5>
      </div>
    </PlanItemTitleContainer>
  );
};

export default PlanItemTitle;
