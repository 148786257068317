import {
  MutationHookOptions,
  QueryHookOptions,
  SubscriptionHookOptions,
  useMutation as useMutationOriginal,
  useQuery as useQueryOriginal,
  useSubscription as useSubscriptionOriginal,
} from '@apollo/client';
import { myPagesClient } from 'apollo/client';
import useToken from 'hooks/useToken';

export function useMyPagesMutation<A = any, B = any>(
  x: any,
  options?: MutationHookOptions<A, B>
) {
  const { token } = useToken();

  if (options) {
    const { variables } = options;

    if (token) {
      options.variables = { ...variables, id: token };
    }
  }

  return useMutationOriginal<A, B>(x, { ...options, client: myPagesClient });
}

export function useMyPagesQuery<A = any, B = any>(
  x: any,
  options?: QueryHookOptions<A, B>
) {
  const { token } = useToken();

  if (options) {
    const { variables } = options;

    if (token) {
      options.variables = { ...variables, id: token };
    }
  }

  return useQueryOriginal<A, B>(x, { ...options, client: myPagesClient });
}

export function useMyPagesSubscription<A = any, B = any>(
  x: any,
  options?: SubscriptionHookOptions<A, B>
) {
  return useSubscriptionOriginal<A, B>(x, {
    ...options,
    client: myPagesClient,
  });
}
