import { TransportTypesIconConfig } from 'hooks/useItemIcon';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { TransportTypes } from 'types/enums';

const ItemImage = styled.img.attrs(({ src }) => ({
  src,
}))`
  width: 30px;
  display: block;
  height: auto;
  position: relative;
  z-index: 1;
  filter: invert(65%) sepia(95%) saturate(0%) hue-rotate(152deg)
    brightness(131%) contrast(100%);
`;

type Props = {
  type: string;
};

const TransportIcon: FunctionalComponent<Props> = ({
  type = TransportTypes.Air,
}) => <ItemImage src={TransportTypesIconConfig[type]} />;

export default TransportIcon;
