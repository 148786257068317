import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import {
  InformationContainer,
  InformationItem,
  InformationList,
  InformationTitle,
  InformationValue,
} from 'screens/Cart/components';
import styled from 'styled-components';
import { ITransportCartItem } from 'types/cache/Cart';
import { distinctCollectionToString } from 'utils/string/distinctCollectionToString';
import { splitApplyJoin } from 'utils/string/splitApplyJoin';

const StyledInformationContainer = styled(InformationContainer)`
  margin-left: 20px;
`;

const StyledInformationList = styled(InformationList)`
  margin-right: 0px;
`;

interface IProps {
  item: ITransportCartItem;
}

const TransportTravelPlanItemDetails: FunctionalComponent<IProps> = ({
  item,
}) => {
  const { t } = useIntl('app.Cart');

  const { people, children, trip_type, operatorNames, transport_type, date } = item;

  const departureDate: string = dayjs(date).format('DD.MM.YYYY');
  const numberOfAdults: number = people ? people : 0;
  const numberOfChildren: number = children ? children : 0;

  return (
    <>
      <StyledInformationContainer>
        <StyledInformationList>
          <InformationItem>
            <InformationTitle>{t('transportDate')}</InformationTitle>
            <InformationValue>{departureDate}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('numberOfAdults')}</InformationTitle>
            <InformationValue>{`${numberOfAdults} ${t(
              'people'
            )}`}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('numberOfChildren')}</InformationTitle>
            <InformationValue>{`${numberOfChildren} ${t(
              'children'
            )}`}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('serviceProvider')}</InformationTitle>
            <InformationValue>{distinctCollectionToString(operatorNames, '/')}</InformationValue>
          </InformationItem>
        </StyledInformationList>
        <StyledInformationList>
          <InformationItem>
            <InformationTitle>{t('transportType')}</InformationTitle>
            <InformationValue>
              {splitApplyJoin(transport_type.toLowerCase(), t, ', ')}
            </InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('tripType')}</InformationTitle>
            <InformationValue>{t(trip_type)}</InformationValue>
          </InformationItem>
        </StyledInformationList>
      </StyledInformationContainer>
    </>
  );
};

export default TransportTravelPlanItemDetails;
