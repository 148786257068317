import useIntl from 'hooks/useIntl';
import { useMemo } from 'preact/hooks';

const useHotelDescription = code => {
  const { t } = useIntl('app.IndividualStay');
  const description = useMemo(() => {
    switch (code) {
      case 'GB008566': {
        return t('hotelDescription');
      }
      case 'SE001455': {
        return t('hotelDescription');
      }
    }
  }, [code]);

  return { description };
};

export default useHotelDescription;
