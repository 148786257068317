import { TRAVEL_ITINERARY_TRANSPORT_SUBSCRIPTION } from 'apollo/myPages/subscriptions';
import { useMyPagesSubscription } from 'apollo/myPages/useMyPages';
import { usePrevious } from 'hooks/usePrevious';
import { FunctionalComponent } from 'preact';
import { useEffect } from 'preact/hooks';
import { TravelItineraryTransport } from '../hooks/useFlightsItineraryStatus';

interface IProps {
  itemId: string;
  saveFlightPaymentStatusById: (
    itemId: string,
    data: TravelItineraryTransport
  ) => void;
}
const FlightSubscriptionStatusTracker: FunctionalComponent<IProps> = ({
  itemId,
  saveFlightPaymentStatusById,
}) => {
  const { data } = useMyPagesSubscription<TravelItineraryTransport>(
    TRAVEL_ITINERARY_TRANSPORT_SUBSCRIPTION,
    {
      variables: {
        itemId,
      },
      fetchPolicy: 'no-cache',
    }
  );

  const previousData = usePrevious(data);
  useEffect(() => {
    if (
      data &&
      (previousData?.travel_itinerary_transport[0]?.external_id !==
        data.travel_itinerary_transport[0]?.external_id ||
        previousData?.travel_itinerary_transport[0]?.transaction_id !==
          data.travel_itinerary_transport[0]?.transaction_id)
    ) {
      saveFlightPaymentStatusById(itemId, data);
    }
  }, [data, itemId, previousData, saveFlightPaymentStatusById]);

  return <></>;
};

export default FlightSubscriptionStatusTracker;
