import { useState } from 'react';

function useActiveStartDate(value, minDate) {
  const initialActiveStartDate =
    Array.isArray(value) && value[0] ? value[0] : minDate || new Date();

  const [activeStartDate, setActiveStartDate] = useState({
    month: initialActiveStartDate.getMonth(),
    year: initialActiveStartDate.getFullYear(),
  });

  return { activeStartDate, setActiveStartDate };
}

export default useActiveStartDate;
