import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_COUNTRIES } from 'apollo/myDesti/queries';
import { SIGNUP } from 'apollo/myPages/mutations';
import { GET_GENDER } from 'apollo/myPages/queries';
import { useMyPagesMutation, useMyPagesQuery } from 'apollo/myPages/useMyPages';
import CalendarInput from 'components/CalendarInput/';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import SelectComponent from 'components/Select';
import Tabs, { mapActiveTab } from 'components/Tabs';
import { TabProps } from 'components/Tabs/Tab';
import { DATE_FORMAT, MIN_DATE, TABS_HEIGHT } from 'config/inputs';
import { Paths } from 'constants/paths';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { Fragment, FunctionalComponent } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { Field, Form, FormSpy } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import useRegisterConstraints from 'shared/constraints/useRegisterConstraints';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import { ICountry } from 'types/cache/Country';
import validateForm from 'utils/form/validateForm';
import {
  Container,
  Description,
  InputColumnLeft,
  InputColumnRight,
  InputRow,
  RegisterButton,
  TabsContainer,
  TermsAndPolicy,
  Title,
} from '../components';
import {
  initialRegisterFormValues,
  maxDateOfBirth,
  OptionType,
  RegisterFormValuesType,
  registerFormVar,
} from './cache';
import { NOTIFICATION_TYPES } from 'types/shared/Notification';
import useNotification from 'screens/NotificationScreen/useNotification';
import { NotificationScreen } from 'screens/NotificationScreen';

const StyledLabelAnchor = styled.a`
  color: ${({ theme }) => theme.colors[colorsSpec.white]} !important;
  text-decoration: underline;
`;
type Props = {
  tabs: TabProps[];
};

const Register: FunctionalComponent<Props> = ({ tabs = [] }) => {
  const { t } = useIntl('app.Register');
  const { t: controls } = useIntl('app.Controls');
  const { constraints } = useRegisterConstraints();
  const history = useHistory();
  const isThisPageVisited = IsPageVisited('Register');
  const savedRegisterFormValues = useReactiveVar(registerFormVar);
  const [initialValues, setInitialValues] = useState({});
  const [tipTitle, setTipTitle] = useState(t('tooltipTitle'));
  const [tipText, setTipText] = useState(t('tooltipText'));
  const [showTip, setShowTip] = useState(!isThisPageVisited);
  const { notification, setNotification } = useNotification();

  const { data: { user_gender = [] } = {} } = useMyPagesQuery(GET_GENDER);
  const { data: { countries = [] } = {} } = useQuery<{ countries: ICountry[] }>(
    GET_COUNTRIES
  );

  useEffect(() => {
    setInitialValues(savedRegisterFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countryOptions = useMemo(() => {
    return countries.map(country => ({
      value: country.id.toString(),
      label: country.name,
    }));
  }, [countries]);

  const genderOptions = useMemo(() => {
    return user_gender?.map(gender => ({
      value: gender.value,
      label: t(gender.value),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_gender]);

  const [register, { loading }] = useMyPagesMutation(SIGNUP);

  const onSubmit = async ({
    email,
    firstName,
    lastName,
    password,
    confirmPassword,
    country,
    dateOfBirth,
    gender,
  }: RegisterFormValuesType) => {
    const response = await register({
      variables: {
        email,
        firstName,
        lastName,
        password,
        confirmPassword,
        countryId: countries.find(c => c.name === (country as OptionType).label)
          .id,
        dateOfBirth: dayjs(dateOfBirth).format('YYYY-MM-DD'),
        gender: (gender as OptionType).value,
      },
    })
      .then(() => {
        registerFormVar(initialRegisterFormValues);
        setInitialValues(initialRegisterFormValues);
        setNotification(NOTIFICATION_TYPES.USER_REGISTERED);

        setTimeout(() => {
          history.replace({
            pathname: 'login',
            state: { activeTab: t('login'), email },
          });
        }, 5000);
      })
      .catch(error => ({ message: error.message }));

    if (response && response.message) {
      try {
        const errorObject = JSON.parse(response.message);
        const splitMessages = errorObject.message
          .replace('Error!', '')
          .split('\n');
        const responseObject = {};

        for (let i = 0; i < errorObject.invalidInputs.length; i++) {
          responseObject[toCamelCase(errorObject.invalidInputs[i])] =
            splitMessages[i];
        }
        return responseObject;
      } catch (e) {
        return { email: response.message };
      }
    }
  };

  const toCamelCase = text => {
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
    return text.substr(0, 1).toLowerCase() + text.substr(1);
  };

  const renderForm = ({
    form,
    handleSubmit,
    submitting,
    pristine,
    hasValidationErrors,
  }) => (
    <form
      onSubmit={event =>
        handleSubmit(event).then(response => {
          if (!response) {
            form.restart();
          }
        })
      }
    >
      <InputRow>
        <InputColumnLeft>
          <Field
            placeholder={controls('firstNamePlaceholder')}
            required
            component={Input}
            name="firstName"
            id="firstName"
            isErrorVisible={true}
          />
        </InputColumnLeft>

        <InputColumnRight>
          <Field
            placeholder={controls('lastNamePlaceholder')}
            required
            name="lastName"
            id="lastName"
            component={Input}
            isErrorVisible={true}
          />
        </InputColumnRight>
      </InputRow>

      <InputRow>
        <InputColumnLeft>
          <Field
            placeholder={controls('emailPlaceholder')}
            required
            component={Input}
            name="email"
            id="email"
            isErrorVisible={true}
          />
        </InputColumnLeft>

        <InputColumnRight>
          <Field id="country" name="country" required>
            {({ meta, input }) => (
              <SelectComponent
                placeholder={`${controls('countryPlaceholder')}`}
                id="country"
                name="country"
                meta={meta}
                input={input}
                options={countryOptions}
                isSearchable
                required
              />
            )}
          </Field>
        </InputColumnRight>
      </InputRow>

      <InputRow>
        <InputColumnLeft>
          <Field
            placeholder={`${controls('genderPlaceholder')}`}
            name="gender"
            id="gender"
            component={SelectComponent}
            options={genderOptions}
            required
          />
        </InputColumnLeft>

        <InputColumnRight>
          <Field
            name="dateOfBirth"
            id="dateOfBirth"
            dateFormat={DATE_FORMAT}
            component={CalendarInput}
            placeholderText={controls('dateOfBirthPlaceholder')}
            minDate={MIN_DATE}
            maxDate={maxDateOfBirth}
            required
            isErrorVisible={true}
          />
        </InputColumnRight>
      </InputRow>

      <InputRow>
        <Field
          placeholder={controls('createPassword')}
          name="password"
          id="password"
          type="password"
          component={Input}
          required
          isErrorVisible={true}
        />
      </InputRow>

      <InputRow>
        <Description>{t('passwordDescription')}</Description>
      </InputRow>

      <InputRow>
        <Field
          placeholder={controls('confirmPassword')}
          name="confirmPassword"
          id="confirmPassword"
          type="password"
          component={Input}
          required
          isErrorVisible={true}
        />
      </InputRow>

      <InputRow>
        <TermsAndPolicy>
          <Field
            id="terms"
            name="terms"
            component={Checkbox}
            type="checkbox"
            label={[
              t('accept'),
              ' ',
              <StyledLabelAnchor
                onClick={e => {
                  e.preventDefault();
                  history.push({
                    pathname: Paths.TermsOfUse,
                    state: {
                      from: Paths.Register,
                    },
                  });
                }}
              >
                {t('termsOfUse')}
              </StyledLabelAnchor>,
            ]}
          />
        </TermsAndPolicy>
      </InputRow>

      <InputRow>
        <RegisterButton
          title={t('registerBtn')}
          disabled={submitting || pristine || hasValidationErrors || loading}
        />
      </InputRow>
      <FormSpy
        onChange={props => {
          registerFormVar({
            ...props.values,
            password: '',
            confirmPassword: '',
          } as RegisterFormValuesType);
        }}
      />
    </form>
  );

  return (
    <Fragment>
      <ModalHeader title={t('title')} />

      <Container>
        <NotificationScreen type={notification} />
        <TabsContainer>
          {tabs.length && (
            <Tabs
              config={mapActiveTab(tabs, 'app.Login.register')}
              height={TABS_HEIGHT}
              isSecondary
            />
          )}
        </TabsContainer>

        <Title>{t('heading')}</Title>

        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={values => validateForm(values, constraints)}
          render={renderForm}
        />
      </Container>
      <ModalFooter
        showButton={false}
        mapIconProps={{ disabled: true }}
        chatIconProps={{ disabled: true }}
        tooltipTitle={tipTitle}
        tooltipText={tipText}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        doOpen={showTip}
        doOnDismiss={() => setShowTip(false)}
      />
    </Fragment>
  );
};

export default Register;
