import Button from 'components/Button';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import { MarkupText } from 'preact-i18n';
import { useState } from 'preact/hooks';
import styled from 'styled-components';
import { colorsSpec } from 'styles/defaultTheme';

const TravelPlanActionsWrapper = styled.div`
  border-top: 2px solid ${({ theme }) => theme.colors[colorsSpec.primary]};
  display: flex;
  width: 90%;
  height: auto;
  justify-content: space-between;
  padding: 10px 0px 20px 10px;
  margin: auto;
  flex-direction: column;
`;

const TravelPlanActionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  p {
    font-size: 18px;
    line-height: 25px;
    text-align: ${({ isBookingPlan }) => (isBookingPlan ? 'justify' : 'start')};
    inline-size: 70%;
    overflow-wrap: break-word;

    @media (max-width: 576px) {
      text-align: center;
    }
  }

  button {
    width: 175px;
    border-radius: 3px;
    padding: 10px 20px;

    span {
      font-style: normal;
      font-size: 20px;
      font-weight: bold;
      line-height: 27px;
      text-align: center;
    }
  }

  @media (max-width: 610px) {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  &:hover {
    /* color: ${({ theme }) => theme.colors[colorsSpec.secondary]}; */
  }
`;

const CancelButton = styled(StyledButton)`
  width: 175px;
  border-radius: 3px;
  padding: 10px 20px;
  color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  background: ${({ theme }) => theme.colors[colorsSpec.secondary]};
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.primary]};

  span {
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;
  }
`;

const CancelButtonWrapper = styled(TravelPlanActionItem)`
  justify-content: end;
`;

const TravelPlanNameInput = styled.input`
  width: 174px;
  height: 39px;
  background: ${({ theme }) => theme.colors[colorsSpec.secondary]};
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.primary]};
  color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  /* box-sizing: border-box; */
  border-radius: 4px;
  padding: 5px;

  ::placeholder {
    font-size: 14px;
    line-height: 19px;
    text-align: start;
  }
`;

interface IProps {
  isMarketplaceItems: boolean;
  travelPlanName: string;
  setTravelPlanName: (name: string) => void;
  saveTravelPlan: () => void;
  handleBookNow: () => void;
  isGuest: boolean;
}

const TravelPlanActions: FunctionalComponent<IProps> = ({
  travelPlanName,
  isMarketplaceItems,
  setTravelPlanName,
  saveTravelPlan,
  handleBookNow,
  isGuest
}) => {
  const { t } = useIntl('app.Cart');

  const [isSavingPlan, setIsSavingPlan] = useState<boolean>(false);
  const [isBookingPlan, setIsBookingPlan] = useState<boolean>(false);

  const showBookingPlan: boolean = isBookingPlan && !isSavingPlan;
  const showSavingPlan: boolean = isSavingPlan && !isBookingPlan;
  const isTravelPlanName: boolean = travelPlanName.length > 0;

  const changeTravelPlanName = (e: InputEvent): void => {
    e.preventDefault();

    if (e.target instanceof HTMLInputElement) {
      setTravelPlanName(e.target.value);
    }
  };

  const handleSavePlan = (): void => {
    saveTravelPlan();
    setTimeout(() => {
      setIsSavingPlan(false);
    }, 0);
  };

  const handleSavePlanButtonClick = () => {
    if (isGuest) {
      saveTravelPlan();
    } else {
      setIsSavingPlan(true);
    }
  }

  return (
    <TravelPlanActionsWrapper>
      {showBookingPlan ? (
        <>
          <TravelPlanActionItem isBookingPlan={isBookingPlan}>
            <p>
              <MarkupText id="app.Cart.continueBooking"></MarkupText>
            </p>
            <StyledButton
              secondary
              title={t('okButton')}
              onClick={handleBookNow}
              disabled={!isMarketplaceItems}
            />
          </TravelPlanActionItem>
          <TravelPlanActionItem>
            <p>{t('bookPlanText')}</p>
            <CancelButtonWrapper>
              <CancelButton
                secondary
                title={t('cancelButton')}
                onClick={() => setIsBookingPlan(false)}
              />
            </CancelButtonWrapper>
          </TravelPlanActionItem>
        </>
      ) : showSavingPlan ? (
        <>
          <TravelPlanActionItem isBookingPlan={isBookingPlan}>
            <p>{t('travelPlanName')}</p>
            <TravelPlanNameInput
              type="text"
              placeholder={t('travelPlanName')}
              value={travelPlanName}
              onChange={e => changeTravelPlanName(e)}
            />
          </TravelPlanActionItem>
          <TravelPlanActionItem isBookingPlan={isBookingPlan}>
            <p>{t('savePlanActionTitle')}</p>
            <StyledButton
              secondary
              title={t('savePlanActionButton')}
              disabled={!isTravelPlanName || !isMarketplaceItems}
              onClick={() => handleSavePlan()}
            />
          </TravelPlanActionItem>
          <CancelButtonWrapper>
            <CancelButton
              secondary
              title={t('cancelButton')}
              onClick={() => setIsSavingPlan(false)}
            />
          </CancelButtonWrapper>
        </>
      ) : (
        <>
          <TravelPlanActionItem isBookingPlan={isBookingPlan}>
            <p>{t('savePlanActionTitle')}</p>
            <StyledButton
              secondary
              title={t('savePlanActionButton')}
              onClick={handleSavePlanButtonClick}
              disabled={!isMarketplaceItems}
            />
          </TravelPlanActionItem>
          <TravelPlanActionItem isBookingPlan={isBookingPlan}>
            <p>{t('bookPlanActionTitle')}</p>
            <StyledButton
              secondary
              title={t('bookPlanActionButton')}
              onClick={() => setIsBookingPlan(true)}
              disabled={!isMarketplaceItems}
            />
          </TravelPlanActionItem>
        </>
      )}
    </TravelPlanActionsWrapper>
  );
};

export default TravelPlanActions;
