import CheckIcon from 'assets/icons/check-green.svg';
import { FunctionalComponent, VNode } from 'preact';
import styled from 'styled-components';
import { colorsSpec } from 'styles';

const InfoContainer = styled.label`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  height: 100%;
  width: fit-content;
`;

const CheckBox = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => theme.thicknesses.default} solid
    ${({ theme: { colors }, disabled }) =>
      disabled ? colors.accent : colors.accentLight};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.smaller};
  flex-shrink: 0;
  height: 20px;
  justify-content: space-around;
  line-height: ${({ theme }) => theme.fontSizes.smaller};
  margin-right: ${({ theme }) => theme.spacing.medium2};
  text-align: center;
  width: 20px;
`;

const Input = styled.input`
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
  z-index: -1;

  &:checked + span img {
    opacity: 1;
  }
`;

const Icon = styled.img`
  display: inline-block;
  opacity: 0;
`;

const Label = styled.label`
  cursor: pointer;
  color: ${({ theme }) => theme.colors[colorsSpec.accent]};

  a {
    color: ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  }
`;

type Props = {
  input?: any; // TODO FieldInputProps<boolean>;
  id?: string;
  disabled?: boolean;
  label?: string | any;
  LabelComponent?: VNode;
  name?: string;
};

const Checkbox: FunctionalComponent<Props> = ({
  id,
  label,
  LabelComponent,
  disabled = false,
  input = null,
}) => (
  <InfoContainer>
    <Input id={id} disabled={disabled} {...input} type="checkbox" />
    <CheckBox disabled={disabled}>
      <Icon src={CheckIcon} alt="check" />
    </CheckBox>
    <Label htmlFor={id}>{LabelComponent ? LabelComponent : label}</Label>
  </InfoContainer>
);

export default Checkbox;
