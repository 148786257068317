import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { Fragment, FunctionalComponent } from 'preact';
import { ITravelItineraryStay } from 'types/cache/TravelItinerary';
import { Text } from 'preact-i18n';
import {
  CartItem,
  CartItemInfo,
  CartItemTime,
  CartItemTimeTitle,
  CartItemTimeValue,
  LargeCard,
  MediumSmall,
  Row,
  SmallCard,
  Title,
  Value,
} from '../components';
import { formatPrice } from 'utils/price/priceOperations';

type Props = {
  item: ITravelItineraryStay;
  stayOfferCount: number;
  adults: number;
  children: number;
};

const StayItineraryItem: FunctionalComponent<Props> = ({
  item,
  stayOfferCount,
  adults,
  children
}) => {
  const { t } = useIntl('app.TravelItinerary');

  return (
    <Fragment>
      <CartItem>
        <Fragment>
          <CartItemTime>
            <CartItemTimeTitle>{t('checkIn')}</CartItemTimeTitle>
            <CartItemTimeValue>
              {dayjs.utc(item.check_in_date).format('DD.MM.YYYY')}
            </CartItemTimeValue>
            <CartItemTimeTitle>{t('checkOut')}</CartItemTimeTitle>
            <CartItemTimeValue>
              {dayjs.utc(item.check_out_date).format('DD.MM.YYYY')}
            </CartItemTimeValue>
          </CartItemTime>
          <CartItemInfo>
            <Row>
              <SmallCard>
                <Value>{adults || 0} {t('adults')}</Value>
                <Value>{children || 0} {t('children')}</Value>
              </SmallCard>
              <LargeCard>
                <Title>{t('stayOfferBought')}</Title>
                <Value>
                  <Text
                    id="app.TravelItinerary.roomCount"
                    plural={item.number_of_rooms || 0}
                    fields={{ rooms: item.number_of_rooms || 0 }}
                  />
                  {' / '}
                  <Text
                    id="app.TravelItinerary.stayOfferCount"
                    plural={stayOfferCount || 0}
                    fields={{ stayOffers: stayOfferCount || 0 }}
                  />
                </Value>
              </LargeCard>
            </Row>
            <Row>
              <MediumSmall>
                <Title>{t('stayName')}</Title>
                <Value>{item.description}</Value>
              </MediumSmall>
              <MediumSmall>
                <Title>{t('stayCity')}</Title>
                <Value>{item.city}</Value>
              </MediumSmall>
              <MediumSmall>
                <Title>{t('summaryPrice')}</Title>
                <Value>{formatPrice(item.price)}</Value>
              </MediumSmall>
            </Row>
          </CartItemInfo>
        </Fragment>
      </CartItem>
    </Fragment>
  );
}

export default StayItineraryItem;
