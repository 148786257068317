import { PASSWORD_REGEX } from 'config/inputs';
import useIntl from 'hooks/useIntl';

export default function () {
  const { t: messages } = useIntl('app.ValidationMessages');

  const constraints = {
    firstName: {
      presence: {
        message: messages('firstNameRequired'),
      },
    },
    lastName: {
      presence: {
        message: messages('lastNameRequired'),
      },
    },
    email: {
      presence: {
        message: messages('emailRequired'),
      },
      email: {
        message: messages('emailInvalid'),
      },
    },
    password: {
      presence: {
        message: messages('passwordRequired'),
      },
      format: {
        pattern: PASSWORD_REGEX,
        message: messages('passwordInvalid'),
      },
    },
    confirmPassword: {
      presence: {
        message: messages('confirmPasswordRequired'),
      },
      equality: {
        attribute: 'password',
        message: messages('passwordMustMatch'),
      },
    },
    dateOfBirth: {
      presence: {
        message: messages('dateOfBirthRequired'),
      },
    },
    country: {
      presence: {
        message: messages('countryRequired'),
      },
    },
    gender: {
      presence: {
        message: messages('genderRequired'),
      },
    },
    terms: {
      presence: true,
      inclusion: {
        within: [true],
      },
    },
  };

  return { constraints };
}
