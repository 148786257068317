import { FunctionalComponent } from 'preact';
import { FieldInputProps } from 'react-final-form';
import styled from 'styled-components';

const Circle = styled.span`
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadius.round};
  display: inline-block;
  height: 20px;
  width: 20px;
`;

const Input = styled.input`
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
  z-index: -1;

  &:hover + ${Circle} {
    background-color: ${({ theme }) => theme.colors.secondaryDark};
  }

  &:focus + ${Circle} {
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:checked + ${Circle} {
    background-color: ${({ theme }) => theme.colors.secondaryDark};
  }

  &:disabled + ${Circle} {
    background-color: ${({ theme }) => theme.colors.accent};
    border-color: ${({ theme }) => theme.colors.white};
  }
`;

const StyledRadioInput = styled.label`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  padding-bottom: 5px;
`;

type Props = {
  disabled: boolean;
  input?: any;//FieldInputProps<string>;
  value?: any;
};

const RadioInput: FunctionalComponent<Props> = ({
  disabled = false,
  input = {},
  children = null,
}) => (
  <StyledRadioInput disabled={disabled}>
    <Input disabled={disabled} {...input} type="radio" />
    <Circle disabled={disabled} />
    {children}
  </StyledRadioInput>
);

export default RadioInput;
