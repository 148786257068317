const parentWindow = window.parent;

export const onMsg = (msg: string | Object, trustedOrigin: string) => {
  parentWindow.postMessage(
    JSON.stringify({
      message: msg,
    }),
    trustedOrigin
  );
};
