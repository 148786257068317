import styled from 'styled-components';
import { colorsSpec } from 'styles';

export const PlanItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const PlanItemInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const PlanItemInfoTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
`;

export const PlanItemInfoValue = styled(PlanItemInfoTitle)`
  font-weight: normal;
`;
