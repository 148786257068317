import CheckIcon from 'assets/icons/check-yellow.svg';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';

const Check = styled.img`
  opacity: 0;
`;

const Container = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryLight};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: inline flex;
  height: 45px;
  justify-content: center;
  padding: ${({ theme: { spacing } }) => `${spacing.none} ${spacing.large1}`};
  width: fit-content;
`;

const CheckBox = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.secondaryDark};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.smaller};
  height: 22px;
  justify-content: center;
  line-height: 10px;
  margin-right: ${({ theme }) => theme.spacing.medium2};
  width: 22px;
`;

const Input = styled.input`
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
  z-index: -1;

  &:checked + ${Container} {
    background-color: ${({ theme }) => theme.colors.secondaryDark};
    color: ${({ theme }) => theme.colors.primary};
  }

  &:checked + ${Container} ${Check} {
    opacity: 1;
  }

  &:hover + ${Container} {
    background-color: ${({ theme }) => theme.colors.secondaryDark};
    color: ${({ theme }) => theme.colors.white};
  }

  &:focus + ${Container} {
    background-color: ${({ theme }) => theme.colors.secondaryDark};
    color: ${({ theme }) => theme.colors.white};
  }

  &:disabled + ${Container} {
    background-color: ${({ theme }) => theme.colors.accent};
    cursor: default;
  }

  &:disabled + ${Container} ${CheckBox} {
    background-color: ${({ theme }) => theme.colors.accent};
    border-color: ${({ theme }) => theme.colors.white};
  }
`;

const StyledTag = styled.label`
  font-size: 18px;
  width: fit-content;
`;

type Props = {
  className?: string;
  id: string;
  disabled?: boolean;
  input: any; // TODO: FieldInputProps<any>;
  title: string;
  name?: string;
};

const Tag: FunctionalComponent<Props> = ({
  disabled = false,
  input = {},
  title = '',
  className = '',
  id = null,
}) => (
  <StyledTag>
    <Input disabled={disabled} {...input} type="checkbox" />
    <Container id={id} className={className}>
      <CheckBox>
        <Check src={CheckIcon} alt="check" />
      </CheckBox>
      <p>{title}</p>
    </Container>
  </StyledTag>
);

export default Tag;
