import { makeVar } from '@apollo/client';
import { StayIndividual } from 'types/cache/StayIndividual';

// Initial values are inherited from stay details
const stayIndividual: StayIndividual = {
  people: undefined,
  children: undefined,
  rooms: undefined,
  childrenYears: [],
  travelPeriod: [],
  accommodationName: '',
  accommodationCode: {},
  productOwner: undefined,
  integration: '',
  productLocation: undefined,
  checkInTime: ''
};

export const individualStayVar = makeVar<StayIndividual>(stayIndividual);
