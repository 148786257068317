import { CartItem } from 'types/cache/Cart';
import { CartTypes } from 'types/shared/Cart';
import useBookAccommodation from './useBookAccommodation';
import useBookActivity from './useBookActivity';
import useBookTransportation from './useBookTransportation';

const useBookItem = (item: CartItem, user) => {
  const { createActivityBooking } = useBookActivity(item, user);
  const { createAccommodationBooking } = useBookAccommodation(item);
  const { createTransportationBooking } = useBookTransportation(item);

  const bookItem = async (
    formValues,
    productOwnerGlobalId,
    paymentIntentId
  ) => {
    if (item.type === CartTypes.ACTIVITY) {
      return await createActivityBooking(
        formValues,
        productOwnerGlobalId,
        paymentIntentId
      )
        .then(response => {
          return response;
        })
        .catch(err => {
          console.error(err);
        });
    } else if (item.type === CartTypes.STAY) {
      return await createAccommodationBooking(
        formValues,
        productOwnerGlobalId,
        paymentIntentId
      )
        .then(response => {
          return response;
        })
        .catch(err => {
          console.error(err);
        });
    } else if (item.type === CartTypes.TRANSPORT) {
      return await createTransportationBooking(
        formValues,
        productOwnerGlobalId,
        paymentIntentId
      );
    }
  };

  return { bookItem };
};

export default useBookItem;
