import { useReactiveVar } from '@apollo/client';
import CartIcon from 'assets/icons/cart_2.svg';
import CheckGreenIcon from 'assets/icons/check-green-white.svg';
import ExploreIcon from 'assets/icons/explore.svg';
import Button from 'components/Button';
import Notification, {
  NotificationH3,
  NotificationLine,
  NotificationRow,
  NotificationTitle,
} from 'components/Notification';
import StyledIcon from 'components/StyledIcon';
import { Paths } from 'constants/paths';
import useIntl from 'hooks/useIntl';
import { Fragment, FunctionalComponent } from 'preact';
import { useHistory } from 'react-router-dom';
import { cartVar } from 'screens/Cart/cache';
import styled from 'styled-components';
import {
  NotificationTypes,
  NOTIFICATION_TYPES,
} from 'types/shared/Notification';

const StyledIconComponent = styled(StyledIcon)`
  height: 50px;
  width: 50px;
  margin-top: 15px;
`;

const NotificationTitleLeft = styled(NotificationTitle)`
  text-align: left;
  font-size: 16px;
`;

const ExploreMoreButton = styled(Button)`
  min-width: 220px;
`;

export const ADD_TO_CART_TIMEOUT = 2000;

type Props = {
  type: NotificationTypes;
};

export const NotificationScreen: FunctionalComponent<Props> = ({ type }) => {
  const { t } = useIntl('app.Notifications');
  const history = useHistory();

  const { cartItems = [] } = useReactiveVar(cartVar);

  return (
    type && (
      <Notification>
        {type === NOTIFICATION_TYPES.PAYMENT_COMPLETED && (
          <Fragment>
            <NotificationH3>{t('paymentCompleted')}</NotificationH3>
            <img src={'/assets/icons/cart-green.svg'} />
          </Fragment>
        )}

        {type === NOTIFICATION_TYPES.EXPLORE_MORE && (
          <Fragment>
            <NotificationH3>{t('congrats')}</NotificationH3>
            <NotificationH3>{t('purchaseSuccess')}</NotificationH3>
            <NotificationLine />
            <NotificationRow>
              <NotificationTitleLeft>
                {t('travelPlanInvoices')}
              </NotificationTitleLeft>
              <ExploreMoreButton
                onClick={() =>
                  history.push({
                    pathname: Paths.TravelItinerary,
                    state: { activeTab: 'app.Profile.travelTab' },
                  })
                }
                icon={ExploreIcon}
                title={t('myPagesButton')}
              />
            </NotificationRow>
            <NotificationRow>
              <NotificationTitleLeft>
                {t('exploreInvite')}
              </NotificationTitleLeft>
              <ExploreMoreButton
                onClick={() => history.push(Paths.TravelLocationAndPeriod)}
                icon={ExploreIcon}
                title={t('exploreButton')}
              />
            </NotificationRow>
          </Fragment>
        )}

        {type === NOTIFICATION_TYPES.ADD_TO_CART && (
          <Fragment>
            <NotificationH3>{t('addedToCart')}</NotificationH3>
            <StyledIconComponent
              icon={CartIcon}
              round
              id="cartIcon"
              itemCount={cartItems.length}
            />
          </Fragment>
        )}
        {type === NOTIFICATION_TYPES.EMAIL_CHANGED && (
          <Fragment>
            <NotificationH3>{t('emailChanged')}</NotificationH3>
            <StyledIconComponent icon={CheckGreenIcon} round />
          </Fragment>
        )}
        {type === NOTIFICATION_TYPES.PASSWORD_CHANGED && (
          <Fragment>
            <NotificationH3>{t('passwordChanged')}</NotificationH3>
            <StyledIconComponent icon={CheckGreenIcon} round />
          </Fragment>
        )}
        {type === NOTIFICATION_TYPES.USER_REGISTERED && (
          <Fragment>
            <NotificationH3>{t('userRegistered')}</NotificationH3>
            <StyledIconComponent icon={CheckGreenIcon} round />
          </Fragment>
        )}
      </Notification>
    )
  );
};
