import StayIcon from 'assets/icons/item-accommodation.svg';
import ActivityIcon from 'assets/icons/item-activity.svg';
import { useMemo } from 'preact/hooks';
import { CartItemType, ITransportCartItem } from 'types/cache/Cart';
import { TransportTypes } from 'types/enums';
import { CartTypes } from 'types/shared/Cart';

export const TransportTypesIconConfig = {
  [TransportTypes.Air]: 'assets/icons/item-transport-air.svg',
  [TransportTypes.Train]: 'assets/icons/item-transport-train.svg',
  [TransportTypes.Bus]: 'assets/icons/item-transport-bus.svg',
  [TransportTypes.Boat]: 'assets/icons/item-transport-boat.svg',
  [TransportTypes.Taxi]: 'assets/icons/item-transport-taxi.svg',
};

const useItemIcon = (type: CartTypes, item: CartItemType) => {
  const icon = useMemo(() => {
    switch (type) {
      case CartTypes.ACTIVITY:
        return ActivityIcon;
      case CartTypes.STAY:
        return StayIcon;
      case CartTypes.TRANSPORT: {
        const transportItem = item as ITransportCartItem;
        const transportType = transportItem.transport_type.split(', ')[0];
        return TransportTypesIconConfig[transportType];
      }
    }
  }, [type, item]);

  return { icon };
};

export default useItemIcon;
