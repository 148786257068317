import { DATE_FORMAT } from 'config/inputs';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import { useCallback } from 'preact/hooks';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { InputRow } from 'screens/Account/Billing/CheckoutForm';
import { InputColumnLeft, InputColumnRight } from 'screens/Account/components';
import {
  ActivityBookingField,
  ActivityBookingFieldTypes,
} from 'screens/Individual/Activity/activityModel';
import {
  FieldTitle,
  InputContainer,
  SectionTitle,
  StyledCalendar,
  StyledCheckbox,
  StyledInput,
  StyledSelectComponent,
  TravelerSectionWrapper,
  TravelerTitle,
} from '../StyledComponents';
import { AdditionalBookingFieldsConfiguration } from './useAdditionalBookingFieldsConfiguration';

interface IProps {
  additionalBookingFields: AdditionalBookingFieldsConfiguration;
  hasAdditionalPerBookingFields: boolean;
  hasAdditionalPerParticipantFields: boolean;
}

const InputSwitchComponent = (
  field: ActivityBookingField,
  name: string = ''
) => {
  switch (field?.fieldType) {
    case ActivityBookingFieldTypes.String: {
      return (
        <InputContainer>
          <FieldTitle>{field?.label}</FieldTitle>
          <Field
            placeholder={field?.label}
            required={field?.required}
            validate={field?.validate}
            component={StyledInput}
            name={name}
            id={name}
          />
        </InputContainer>
      );
    }
    case ActivityBookingFieldTypes.List: {
      return (
        <InputContainer>
          <FieldTitle>{field?.label}</FieldTitle>
          <Field
            placeholder={field?.label}
            required={field?.required}
            validate={field?.validate}
            component={StyledSelectComponent}
            options={field.listOptionsParsed}
            name={name}
            id={name}
            isErrorVisible={false}
          />
        </InputContainer>
      );
    }
    case ActivityBookingFieldTypes.Boolean: {
      return (
        <InputContainer>
          <Field
            LabelComponent={field?.LabelComponent}
            required={field?.required}
            validate={field?.validate}
            component={StyledCheckbox}
            name={name}
            id={name}
          />
        </InputContainer>
      );
    }
    case ActivityBookingFieldTypes.Phone: {
      return (
        <InputContainer>
          <FieldTitle>{field?.label}</FieldTitle>
          <Field
            placeholder={field?.label}
            required={field?.required}
            validate={field?.validate}
            component={StyledInput}
            name={name}
            id={name}
          />
        </InputContainer>
      );
    }
    case ActivityBookingFieldTypes.Date: {
      return (
        <InputContainer>
          <FieldTitle>{field?.label}</FieldTitle>
          <Field
            dateFormat={DATE_FORMAT}
            component={StyledCalendar}
            placeholderText={field?.label}
            required={field?.required}
            validate={field?.validate}
            name={name}
            id={name}
            maxDate={field?.maxDate}
          />
        </InputContainer>
      );
    }
    default: {
      return <></>;
    }
  }
};

const ActivityBookingFields: FunctionalComponent<IProps> = ({
  additionalBookingFields,
  hasAdditionalPerBookingFields,
  hasAdditionalPerParticipantFields,
}) => {
  const { t } = useIntl('app.Marketplace');

  const ParticipantFields = useCallback(
    ({ fields: participants }) => {
      return participants.map((name, index) => (
        <TravelerSectionWrapper>
          <TravelerTitle>
            <p>{`${t('travelerTitle')} ${index + 1} `}</p>
          </TravelerTitle>
          {additionalBookingFields?.requiredPerParticipantFields.map(f => (
            <InputRow>
              <InputColumnLeft>
                <InputContainer>
                  {InputSwitchComponent(f[0], `${name}.${f[0]?.name}`)}
                </InputContainer>
              </InputColumnLeft>
              <InputColumnRight>
                <InputContainer>
                  {InputSwitchComponent(f[1], `${name}.${f[1]?.name}`)}
                </InputContainer>
              </InputColumnRight>
            </InputRow>
          ))}
        </TravelerSectionWrapper>
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [additionalBookingFields]
  );

  return (
    <>
      <SectionTitle>
        <li>{t('additionalBookingDetails')}</li>
      </SectionTitle>
      {hasAdditionalPerBookingFields &&
        additionalBookingFields?.requiredPerBookingFields.map(f => (
          <InputRow>
            <InputColumnLeft>
              {InputSwitchComponent(
                f[0],
                `requiredPerBookingFields.${f[0]?.name}`
              )}
            </InputColumnLeft>
            <InputColumnRight>
              {InputSwitchComponent(
                f[1],
                `requiredPerBookingFields.${f[1]?.name}`
              )}
            </InputColumnRight>
          </InputRow>
        ))}
      {hasAdditionalPerParticipantFields && (
        <FieldArray name="requiredPerParticipantFields">
          {props => ParticipantFields(props)}
        </FieldArray>
      )}
    </>
  );
};

export default ActivityBookingFields;
