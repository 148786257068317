import { GenericConfig } from 'types/shared/GenericConfig';

export const CLIENTS: GenericConfig = {
  MY_DESTI: 'MyTravelplan',
  MY_PAGES: 'MyPages',
  MY_BOOKINGS: 'MyBookings',
};

export const HTTP_STATUS_CODES: GenericConfig = {
  INTERNAL_ERROR: 500,
  CONFLICT: 409,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
};

export const HTTP_ERROR_MESSAGES: GenericConfig = {
  JWT_EXPIRED: 'Could not verify JWT: JWTExpired',
};

// Page limit for Infinite Scroll used in Activities/Transport/Stay lists
export const PAGE_LIMIT = 20;
