import { ErrorLabel } from 'components/Input/StyledInput';
import styled from 'styled-components';
import { colorsSpec, fonts } from 'styles';

const StyledSelect = styled.div`
  position: relative;
  border: none;

  ${({ theme, error }) =>
    error &&
    `
    background-color: ${theme.colors[colorsSpec.accentDark]};
    border-radius: 4px;
    
    ${ErrorLabel} {
      display: block;
    }
  `};
`;

const StyledLabel = styled.span`
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  font-family: ${fonts.default};
  display: inline-block;
`;

export { StyledSelect, StyledLabel };
