import { FunctionComponent } from 'preact';
import styled from 'styled-components';

const Title = styled.span`
  font-size: 20px;
  font-weight: 800;

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

const Icon = styled.img`
  display: inline-block;
  height: 25px;
  vertical-align: middle;
  margin-right: ${({ theme }) => theme.spacing.medium2};

  @media (max-width: 576px) {
    display: none;
  }
`;

const StyledButton = styled.button.attrs(
  ({ secondary, theme: { colors, spacing, borderRadius } }) => ({
    bgColor: secondary ? colors.secondary : colors.primary,
    hoverColor: secondary ? colors.secondaryDark : colors.primaryDark,
    activeColor: secondary ? colors.secondary : colors.primary,
    focusColor: secondary ? colors.secondaryDark : colors.primaryDark,
    borderRadius: secondary ? borderRadius.large : borderRadius.extraLarge,
    height: secondary ? '50px' : '40px',
    minWidth: secondary ? '65px' : '131px',
    padding: `0 ${spacing.large2}`,
  })
)`
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
  border: ${({ theme, secondary }) =>
    secondary ? `2px solid ${theme.colors.primary}` : 'none'};
  border-radius: ${({ borderRadius }) => borderRadius};
  color: ${({ theme, secondary }) =>
    secondary ? theme.colors.primary : theme.colors.white};
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  max-width: 100%;
  min-width: ${({ minWidth }) => minWidth};
  padding: 15px;
  width: fit-content;

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};

    ${({ secondary, theme }) =>
      secondary &&
      `
      color: ${theme.colors.white};
    `}
  }

  &:focus {
    background-color: ${({ focusColor }) => focusColor};
  }

  &:active {
    background-color: ${({ activeColor }) => activeColor};

    ${({ secondary, theme }) =>
      secondary &&
      `
      color: ${theme.colors.primary};
    `}
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.accent};
    cursor: default;
  }
`;

type Props = {
  disabled?: boolean;
  icon?: string;
  iconProps?: any; // TODO: Add interface
  secondary?: boolean;
  title?: string;
  titleProps?: any; // TODO: Add interface
  onClick?: () => void;
  attributes?: any; // TODO: Add interface
};

const Button: FunctionComponent<Props> = ({
  disabled = false,
  icon = null,
  iconProps = null,
  secondary = false,
  title = null,
  titleProps = null,
  onClick = () => 0,
  ...attributes
}) => (
  <StyledButton
    disabled={disabled}
    onClick={onClick}
    icon={icon}
    secondary={secondary}
    {...attributes}
  >
    {icon ? <Icon src={icon} alt="icon" {...iconProps} /> : null}
    <Title {...titleProps}>{title}</Title>
  </StyledButton>
);

export default Button;
