import { ReactiveVar } from '@apollo/client';
import config from 'config/config';
import {
  Passenger,
  PassengerCategories,
  Station,
  TransportDetails,
  TransportType,
} from 'types/cache/TransportDetails';
import { TranportTypeKey } from 'types/enums';

const scrubListItemsForUI = (data: any) =>
  data.transport_category.map((item: any, idx: number) => ({
    ...item,
    id: `main-item-${idx}`,
    selectorKey: item.value,
    subItems: item.transport_subcategories.map(
      (subItem: any, subIdx: number) => ({
        name: subItem ? subItem.name : '',
        selectorKey: subItem.id,
        id: `sub-item-${idx}-${subIdx}`,
      })
    ),
  }));

export function useTransportDetails(
  transportDetailsVar: ReactiveVar<TransportDetails>
) {
  const setNumValues = (data: any, key: string) => {
    const oldValue = transportDetailsVar();
    const updated = {
      ...oldValue,
      [key]: data,
    };
    transportDetailsVar(updated);
  };

  const setTransportCategories = (data: any) => {
    const oldValue = transportDetailsVar();
    const updated = {
      ...oldValue,
      categoriesList: scrubListItemsForUI(data),
    };
    transportDetailsVar(updated);
  };

  const setTransportList = (data: any) => {
    const oldValue = transportDetailsVar();

    const updated = {
      ...oldValue,
      transportList: data.transport_type
        .filter(
          transportType => config.enabledTransportTypes[transportType.key_name]
        )
        .map((item: any, idx: number) => ({
          id: item.key_name === TranportTypeKey.Airplane ? 1 : 0, // needs to be fixed: using an index as an id!!!,
          key: item.key_name,
          order: item.order,
        }))
        .sort((a, b) => a.order - b.order),
    };

    transportDetailsVar(updated);
  };

  const setSelectedTransportType = (transportType: TransportType) => {
    const oldValue = transportDetailsVar();
    const selectedTransportType = transportType;

    const updated = { ...oldValue, selectedTransportType };
    transportDetailsVar(updated);
  };

  const setLocationTo = (locationTo: Station) => {
    const oldValue = transportDetailsVar();
    const updated = { ...oldValue, locationTo };
    transportDetailsVar(updated);
  };

  const setLocationFrom = (locationFrom: Station) => {
    const oldValue = transportDetailsVar();
    const updated = { ...oldValue, locationFrom };
    transportDetailsVar(updated);
  };

  const setStationTo = (stationTo: Station) => {
    const oldValue = transportDetailsVar();
    const updated = { ...oldValue, stationTo };
    transportDetailsVar(updated);
  };

  const setStationFrom = (stationFrom: Station) => {
    const oldValue = transportDetailsVar();
    const updated = { ...oldValue, stationFrom };
    transportDetailsVar(updated);
  };

  const setRoundTrip = (roundTrip: boolean) => {
    const oldValue = transportDetailsVar();
    const updated = { ...oldValue, roundTrip };
    transportDetailsVar(updated);
  };

  const setOptions = (options: any) => {
    const oldValue = transportDetailsVar();
    const updated = { ...oldValue, options };
    transportDetailsVar(updated);
  };

  const setPassengers = (passengers: Passenger[]) => {
    const oldValue = transportDetailsVar();
    const updated = { ...oldValue, passengers };
    transportDetailsVar(updated);
  };

  const setTravelClass = (travelClass: string) => {
    const oldValue = transportDetailsVar();
    const updated = { ...oldValue, travelClass };
    transportDetailsVar(updated);
  };

  const toggleTravelClassMix = () => {
    const oldValue = transportDetailsVar();
    const travelClassMix = !oldValue.travelClassMix;
    const updated = { ...oldValue, travelClassMix };
    transportDetailsVar(updated);
  };

  const setPassengerCategories = (passengerCategories: PassengerCategories) => {
    const oldValue = transportDetailsVar();
    const updated = { ...oldValue, passengerCategories };
    transportDetailsVar(updated);
  };

  return {
    setNumValues,
    setTransportCategories,
    setTransportList,
    setSelectedTransportType,
    setLocationTo,
    setLocationFrom,
    setRoundTrip,
    setOptions,
    setPassengers,
    setPassengerCategories,
    setStationTo,
    setStationFrom,
    setTravelClass,
    toggleTravelClassMix,
  };
}
