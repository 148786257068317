import { FunctionalComponent } from 'preact';
import InfiniteScroll, { Props } from 'react-infinite-scroll-component';

type InfiniteScrollWithRefProps = Props & {
  ref?: any;
};

export const InfiniteScrollWithRef: FunctionalComponent<InfiniteScrollWithRefProps> = props => {
  const { ref, ...infiniteScrollProps } = props;
  return (
    <InfiniteScroll {...infiniteScrollProps}>{props.children}</InfiniteScroll>
  );
};
