export const colorsSpec = {
  primary: 'primary',
  primaryDark: 'primaryDark',
  primaryLight: 'primaryLight',

  secondary: 'secondary',
  secondaryDark: 'secondaryDark',
  secondaryLight: 'secondaryLight',

  disabled: 'disabled',

  accent: 'accent',
  accentDark: 'accentDark',
  accentLight: 'accentLight',

  black: 'black',
  white: 'white',

  info: 'info',
  warning: 'warning',
  danger: 'danger',
  success: 'success',
};

export const colors = {
  [colorsSpec.primary]: '#372046',
  [colorsSpec.primaryDark]: '#000',
  [colorsSpec.primaryLight]: '#03C6FB',

  [colorsSpec.secondary]: '#FECB00',
  [colorsSpec.secondaryDark]: '#C19A00',
  [colorsSpec.secondaryLight]: '#E2B400',

  [colorsSpec.accent]: '#C4C4C4',
  [colorsSpec.accentDark]: '#F96842',
  [colorsSpec.accentLight]: '#00BA19',

  [colorsSpec.disabled]: '#ccc',

  [colorsSpec.black]: '#000',
  [colorsSpec.white]: '#FFF',

  [colorsSpec.info]: '#99E9FF',
  [colorsSpec.warning]: '#F68080',
  [colorsSpec.danger]: '#F68080',
  [colorsSpec.success]: '#AEF9B8',
};

export const fonts = {
  default: '"Open Sans", arial, sans-serif',
  monospace: '"Source Code Pro", monospace',
};

export const shadows = {
  short: '0 2px 4px rgba(0, 0, 0, 0.14)',
  long: `
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0 ,0, 0, 0.2)
      `,
};

export const transparency = {
  white70: 'rgba(255, 255, 255, 0.7)',
  white80: 'rgba(255, 255, 255, 0.8)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black80: 'rgba(0, 0, 0, 0.80)',
};

export const borderRadius = {
  small: '2.5px',
  medium: '3px',
  large: '4px',
  extraLarge: '5px',
  round: '50%',
};

export const spacing = {
  none: 0,
  extraSmall: '1px',
  small1: '5px',
  small2: '7px',
  small3: '8px',
  small4: '9px',
  medium1: '10px',
  medium2: '11px',
  medium3: '12px',
  large1: '14px',
  large2: '15px',
  large3: '16px',
  large4: '18px',
  extraLarge: '29px',
};

export const thicknesses = {
  normal: '1px',
  default: '1px',
  wide: '2px',
  thick: '2px',
  large: '2px',
  extraWide: '3px',
  extraLarge: '3px',
};

export const fontSizes = {
  extraSmall: '8px',
  smaller: '10px',
  small1: '11px',
  small2: '12px',
  default: '14px',
  large: '16px',
  extraLarge: '24px',

  heading1: '42px',
  heading2: '38px',
  heading3: '21px',
  heading4: '18px',
  heading5: '16px',
};
