import useIntl from 'hooks/useIntl';

export default function (isForgotPassword: boolean) {
  const { t: messages } = useIntl('app.ValidationMessages');

  const constraints = {
    email: {
      presence: {
        message: messages('emailRequired'),
      },
      email: {
        message: messages('emailInvalid'),
      },
    },
    password: null,
  };

  if (!isForgotPassword) {
    constraints.password = {
      presence: {
        message: messages('passwordRequired'),
      },
    };
  }
  return { constraints };
}
