// NOT COMPLETELY IMPLEMENTED
import { ComponentChildren, FunctionalComponent } from 'preact';
import { Children } from 'preact/compat';
import { useEffect, useRef, useState } from 'preact/hooks';

type Props = {
  className: string;
  children: ComponentChildren[];
};

const ContentSizeAwareContainer: FunctionalComponent<Props> = ({
  className,
  children,
}) => {
  const [renderLength, setRenderLength] = useState(children.length);
  const containerEl = useRef(null);

  useEffect(() => {
    const scrollHeight = containerEl.current.scrollHeight;
    const innerHeight = containerEl.current.clientHeight;

    const checkOverflow = () => {
      if (containerEl.current.scrollHeight > containerEl.current.clientHeight) {
        setRenderLength(renderLength - 1);
      } else if (renderLength < children.length) {
        setRenderLength(renderLength + 1);
      }
    };

    window.addEventListener('resize', checkOverflow);

    if (scrollHeight > innerHeight) {
      setRenderLength(renderLength - 1);
    }

    return () => window.removeEventListener('resize', checkOverflow);
  }, [renderLength, children.length]);

  return (
    <div className={className} ref={containerEl}>
      {Children.map(children, (child, index) =>
        index < renderLength ? child : null
      )}
    </div>
  );
};

export default ContentSizeAwareContainer;
