import { ErrorLabel } from 'components/Input/StyledInput';
import { INPUT_HEIGHT } from 'config/inputs';
import styled from 'styled-components';
import { colorsSpec, fontSizes, spacing } from 'styles';

const StyledCalendar = styled.div`
  position: relative;
  max-width: 500px;

  .react-datepicker__tab-loop {
    position: relative;
    z-index: 9999;
  }

  .react-datepicker {
    margin-top: -30px;
  }

  .react-datepicker-wrapper {
    width: 100%;
    height: ${INPUT_HEIGHT};

    + label {
      bottom: 10px;
      left: 17px;
      font-weight: 300;
      font-size: 14px;
      color: ${({ theme }) => theme.colors[colorsSpec.accent]};
      position: absolute;
      display: block;
      pointer-events: none;

      ${({ isDisabled, theme }) =>
        !isDisabled &&
        `
      &:after {
        content: '*';
        color: ${theme.colors[colorsSpec.accentDark]};
        margin-left: 3px;
      }
      `};
    }
  }

  ${({ error, theme }) =>
    error &&
    `
    > span { 
      background-color: ${theme.colors[colorsSpec.accentDark]};
      border-radius: 4px;
      font-weight: 800;
    }

    ${ErrorLabel} {
      display: block;
    }
`};

  input {
    cursor: pointer;
    padding-right: 0;
    font-size: ${fontSizes.default};
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    background: none;
    background-image: none;
    z-index: 1;
    cursor: pointer;
    width: 30px;
    height: 30px;
    outline: none;
    margin-left: 0;
  }

  input[type='date' i]::-webkit-calendar-picker-indicator:focus {
    outline: none;
  }

  input + label {
    bottom: 11px;
    left: 17px;
    font-weight: 300;
    font-size: 14px;
    color: ${({ theme }) => theme.colors[colorsSpec.accent]};
    position: absolute;
    display: block;
    pointer-events: none;
  }
`;

const StyledIcon = styled.span`
  position: absolute;
  bottom: ${spacing.small1};
  right: ${spacing.small3};
  width: 25px;
  height: 25px;
  pointer-events: none !important;
  border: none;
  background: transparent;
  filter: ${({ disabled }) => (disabled ? 'invert(1)' : 'invert')};
`;

export { StyledCalendar, StyledIcon };
