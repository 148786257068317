import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';
import styled from 'styled-components';
import { colorsSpec } from 'styles';

const InfoContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
`;

const HeadingContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Icon = styled.img`
  width: ${({ headingIconStyle }) => headingIconStyle?.width || '20px'};
  height: ${({ headingIconStyle }) => headingIconStyle?.height || '20px'};
`;

const HeadingLabel = styled.span`
  color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  margin-left: 10px;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.small2};
  height: 100%;
  width: fit-content;
`;

const CloseButton = styled.img`
  cursor: pointer;
`;

const InfoLabel = styled.span`
  color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  font-size: ${({ theme }) => theme.fontSizes.small2};
  line-height: 14px;
`;

const StyledInsurance = styled.div`
  background-color: ${({ theme }) => theme.colors[colorsSpec.accentLight]};
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  padding: 10px;
  color: ${({ theme }) => theme.colors.black};
  flex: 1;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizes.smaller};
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`;

type Props = {
  headingLabel: string;
  infoLabelStart: string;
  infoLabelEnd: string;
  linkLabel: string;
  link: string;
  headingIcon?: string;
  headingIconStyle?: any;
  isCloseBtnVisible?: boolean;
  closeIcon?: string;
  textStyle?: any;
};

const InformationBox: FunctionalComponent<Props> = ({
  headingLabel,
  infoLabelStart,
  infoLabelEnd,
  linkLabel,
  link,
  headingIcon,
  headingIconStyle,
  isCloseBtnVisible = false,
  closeIcon,
  textStyle
}) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <StyledInsurance isVisible={isVisible}>
      <InfoContainer>
        <HeadingContainer>
          <Icon headingIconStyle={headingIconStyle} src={headingIcon} />
          <HeadingLabel>{headingLabel}</HeadingLabel>
        </HeadingContainer>

        {isCloseBtnVisible && (
          <CloseButton src={closeIcon} onClick={() => setIsVisible(false)} />
        )}
      </InfoContainer>
      <InfoLabel>
        <span style={textStyle}>
          {infoLabelStart}
          {'\n'}
          <a target="_blank" rel="noreferrer" href={link}>
            {linkLabel}
            {'\n'}
          </a>
          {infoLabelEnd}
        </span>
      </InfoLabel>
    </StyledInsurance>
  );
};

export default InformationBox;
