import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import {
  InformationContainer,
  InformationItem,
  InformationList,
  InformationTitle,
  InformationValue,
} from 'screens/Cart/components';
import styled from 'styled-components';
import { IStayCartItem } from 'types/cache/Cart';

const StyledInformationContainer = styled(InformationContainer)`
  margin-left: 20px;
`;

const StyledInformationList = styled(InformationList)`
  margin-right: 0px;
`;

interface IProps {
  item: IStayCartItem;
}

const StayTravelPlanItemDetails: FunctionalComponent<IProps> = ({ item }) => {
  const { t } = useIntl('app.Cart');

  const {
    travelPeriod,
    people,
    children,
    title,
    rooms,
    room_type,
    city,
  } = item;

  const checkInDate: string = dayjs(travelPeriod[0]).format('DD.MM.YYYY');
  const checkOutDate: string = dayjs(travelPeriod[1]).format('DD.MM.YYYY');
  const numberOfAdults: number = people ? people : 0;
  const numberOfChildren: number = children ? children : 0;
  const roomsValue: string = `${rooms} ${room_type} Bed Room`;

  return (
    <>
      <StyledInformationContainer>
        <StyledInformationList>
          <InformationItem>
            <InformationTitle>{t('checkInTitle')}</InformationTitle>
            <InformationValue>{checkInDate}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('checkOutTitle')}</InformationTitle>
            <InformationValue>{checkOutDate}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('numberOfAdults')}</InformationTitle>
            <InformationValue>{`${numberOfAdults} ${t(
              'people'
            )}`}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('numberOfChildren')}</InformationTitle>
            <InformationValue>{`${numberOfChildren} ${t(
              'children'
            )}`}</InformationValue>
          </InformationItem>
        </StyledInformationList>
        <StyledInformationList>
          <InformationItem>
            <InformationTitle>{t('stayName')}</InformationTitle>
            <InformationValue>{title}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('rooms')}</InformationTitle>
            <InformationValue>{roomsValue}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('city')}</InformationTitle>
            <InformationValue>{city}</InformationValue>
          </InformationItem>
        </StyledInformationList>
      </StyledInformationContainer>
    </>
  );
};

export default StayTravelPlanItemDetails;
