import { ErrorLabel, StyledInputContainer } from 'components/Input/StyledInput';
import { INPUT_HEIGHT } from 'config/inputs';
import { FunctionalComponent } from 'preact';
import { useMemo } from 'preact/hooks';
import { FieldMetaState } from 'react-final-form';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { colorsSpec, fonts } from 'styles';
import { StyledLabel, StyledSelect } from './StyledSelect';

export type Option = {
  label: string;
  value: string;
  key?: any;
};

export type SelectProps = {
  input?: any; // TODO FieldInputProps<any>;
  meta?: FieldMetaState<any>;
  styleType?:
    | 'type_1' /* white */
    | 'type_2' /* yellow */
    | 'type_3' /* transparent */;
  options?: Option[];
  isSearchable?: boolean;
  isErrorVisible?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  required?: boolean;
  [otherProp: string]: any;
};

const customStyles = {
  option: (provided, state) => ({
    padding: '8px 12px',
    width: '100%',
    fontSize: '12px',
    fontFamily: fonts.default,
    borderBottom: `1px solid ${state.theme.colors[colorsSpec.accent]}`,
    cursor: 'pointer',
    backgroundColor: state.theme.colors[colorsSpec.secondaryLight],
    '&:hover': {
      backgroundColor: state.theme.colors[colorsSpec.secondary],
    },
    '&:active': {
      backgroundColor: state.theme.colors[colorsSpec.secondary],
      color: state.theme.colors[colorsSpec.black],
    },
    '&--is-selected:': {
      backgroundColor: state.theme.colors[colorsSpec.secondary],
      color: state.theme.colors[colorsSpec.black],
    },
  }),
  container: provided => ({
    ...provided,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  control: (provided, state) => {
    const { isDisabled } = state.selectProps;

    return {
      ...provided,
      minHeight: INPUT_HEIGHT,
      paddingLeft: '8px',
      fontSize: '14px',
      boxShadow: 'none',
      borderRadius: '4px',
      fontFamily: fonts.default,
      borderColor:
        state?.selectProps?.styleType === 'type_3' ||
        state?.selectProps?.styleType === 'type_2'
          ? 'transparent'
          : state?.selectProps?.meta?.error && state?.selectProps?.meta?.touched
          ? state.theme.colors[colorsSpec.accentDark]
          : state.theme.colors[colorsSpec.accent],
      '&:hover': {
        borderColor:
          state?.selectProps?.styleType === 'type_3' ||
          state?.selectProps?.styleType === 'type_2'
            ? 'transparent'
            : state?.selectProps?.meta?.error &&
              state?.selectProps?.meta?.touched
            ? state.theme.colors[colorsSpec.accentDark]
            : state.theme.colors[colorsSpec.secondaryLight],
      },

      // Style types
      backgroundColor: isDisabled
        ? state.theme.colors[colorsSpec.accent]
        : state?.selectProps?.styleType === 'type_1'
        ? state.theme.colors[colorsSpec.white]
        : state?.selectProps?.styleType === 'type_2'
        ? state.theme.colors[colorsSpec.secondaryLight]
        : 'rgb(29, 93, 144)',
      color: isDisabled
        ? state.theme.colors[colorsSpec.white]
        : state?.selectProps?.styleType === 'type_1'
        ? state.theme.colors[colorsSpec.primary]
        : state?.selectProps?.styleType === 'type_2'
        ? state.theme.colors[colorsSpec.white]
        : state.theme.colors[colorsSpec.white],
    };
  },
  placeholder: (provided, state) => ({
    ...provided,
    fontFamily: fonts.default,
    fontSize: '14px',
    padding: '10px 18px 10px 8px',

    // Style types
    color: state?.selectProps?.isDisabled
      ? state.theme.colors[colorsSpec.white]
      : state?.selectProps?.styleType === 'type_1'
      ? state.theme.colors[colorsSpec.accent]
      : state?.selectProps?.styleType === 'type_2'
      ? state.theme.colors[colorsSpec.white]
      : state.theme.colors[colorsSpec.white],
    fontWeight: state?.selectProps?.styleType === 'type_1' ? '300' : '700',
    '&:after': {
      content: state?.selectProps?.required ? '" *"' : '" "',
      color: state.theme.colors[colorsSpec.accentDark],
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state?.selectProps?.isDisabled
      ? state.theme.colors[colorsSpec.white]
      : state?.selectProps?.styleType === 'type_1'
      ? state.theme.colors[colorsSpec.primaryDark]
      : state?.selectProps?.styleType === 'type_2'
      ? state.theme.colors[colorsSpec.white]
      : state.theme.colors[colorsSpec.white],

    fontWeight: state?.selectProps?.styleType === 'type_1' ? '500' : '700',
    fontFamily: fonts.default,
    fontSize: '14px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,

    // Style types
    color:
      state?.selectProps?.styleType === 'type_1'
        ? state.theme.colors[colorsSpec.accent]
        : state.theme.colors[colorsSpec.white],
    '&:hover': {
      color:
        state?.selectProps?.styleType === 'type_1'
          ? state.theme.colors[colorsSpec.accent]
          : state.theme.colors[colorsSpec.white],
    },
  }),
};

const SelectComponent: FunctionalComponent<SelectProps> = ({
  input,
  meta = {},
  label,
  styleType = 'type_1',
  options = [],
  isSearchable = false,
  isErrorVisible = true,
  placeholder = null,
  isDisabled = false,
  required = false,
  ...rest
}) => {
  const theme = useTheme();
  const value = useMemo(() => {
    return options
      ? options.find(
          (option: Option) =>
            option.value === input?.value ||
            option.value === input?.value?.value
        )
      : '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, input?.value, options]);

  return (
    <StyledInputContainer label={label} isErrorVisible={isErrorVisible}>
      <StyledSelect error={meta?.touched && (meta.error || meta.submitError)}>
        {
          <ErrorLabel error={meta?.touched && (meta.error || meta.submitError)}>
            {meta.error || meta.submitError}
          </ErrorLabel>
        }

        {label && (
          <label>
            <StyledLabel>{label}</StyledLabel>
          </label>
        )}

        <Select
          theme={theme}
          {...rest}
          {...input}
          required={required}
          input={input}
          meta={meta}
          styleType={styleType}
          styles={customStyles}
          options={options}
          isSearchable={isSearchable}
          value={value || ''}
          placeholder={placeholder}
          isDisabled={isDisabled}
        />
      </StyledSelect>
    </StyledInputContainer>
  );
};

export default SelectComponent;
