import { useMutation } from '@apollo/client';
import {
  BOOK_ACTIVITY,
  VALIDATE_BOOK_ACTIVITY,
} from 'apollo/myDesti/mutations';
import config from 'config/config';
import dayjs from 'dayjs';
import { useCallback } from 'preact/hooks';
import { IActivityCartItem } from 'types/cache/Cart';
import { mapReactFormNameToBookingFieldLabel } from './components/ActivityBookingFields/useAdditionalBookingFieldsConfiguration';

const DAYJS_REZDY_ACTIVITY_DATE_FORMAT = 'YYYY-MM-DD';

const getBookingFieldValue = (
  value: string | { label: string; value: string } | Date | boolean
) => {
  if (value instanceof Date) {
    return dayjs(value).format(DAYJS_REZDY_ACTIVITY_DATE_FORMAT);
  } else if (
    typeof value === 'object' &&
    value.hasOwnProperty('label') &&
    value.hasOwnProperty('value')
  ) {
    return value.value;
  } else if (typeof value === 'boolean') {
    return value.toString();
  } else {
    return value;
  }
};

const useBookActivity = (item, user) => {
  const [bookActivity] = useMutation(BOOK_ACTIVITY, {
    fetchPolicy: 'no-cache',
  });
  const [validateBookActivity] = useMutation(VALIDATE_BOOK_ACTIVITY, {
    fetchPolicy: 'no-cache',
  });

  const createActivityBooking = useCallback(
    async (
      formValues: any,
      productOwnerGlobalId: string,
      paymentIntentId: string
    ) => {
      try {
        const activityItem = item.item as IActivityCartItem;
        const activityVariables = {
          customer: {
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            //gender: user.gender,
            //dob: user.date_of_birth,
            //countryCode:
            //  user && countries.length
            //    ? countries.find(c => c.id === user.country_id).country_code
            //    : '',
          },
          payments: [
            {
              amount: item.item.price.amount,
              currency: item.item.price.currency,
              //date: Date.now(),
              //label: '',
              //recipient: 'SUPPLIER',
              //type: 'CREDITCARD',
            },
          ],
          items: [
            {
              productCode: activityItem.id,
              //amount: activityItem.people,
              quantities: activityItem.quantity.map(q => {
                return { optionId: q.optionId, value: q.quantity };
              }),
              //startTimeLocal: activityItem.startTimeLocal,
              //endTimeLocal: activityItem.endTimeLocal,
              startTime: activityItem.startTime,
              //endTime: activityItem.endTime,
              participants: formValues.requiredPerParticipantFields
                ? Array.from({ length: activityItem.people }).map(
                    (p, index) => {
                      return {
                        fields: Object.keys(
                          formValues.requiredPerParticipantFields[index]
                        ).map(key => {
                          return {
                            label: mapReactFormNameToBookingFieldLabel(key),
                            value: getBookingFieldValue(
                              formValues.requiredPerParticipantFields[index][
                                key
                              ]
                            ),
                          };
                        }),
                      };
                    }
                  )
                : undefined,
            },
          ],
          fields:
            formValues.requiredPerBookingFields &&
            Object.keys(formValues.requiredPerBookingFields).map(key => {
              return {
                label: mapReactFormNameToBookingFieldLabel(key),
                value: getBookingFieldValue(
                  formValues.requiredPerBookingFields[key]
                ),
              };
            }),
          datePaid: dayjs().format('YYYY-MM-DD'), // only used in bookActivity, not needed for validateBookActivity
          sendNotifications: true,
          globalId: productOwnerGlobalId,
          paymentIntentId,
        };

        console.log(config);
        if (config.activityBookingTestingEnabled) {
          const response = await validateBookActivity({
            variables: activityVariables,
          });
          if (response instanceof Error) {
            return response;
          }
          let min = 10000;
          let max = 99999999999999999999;
          return {
            data: {
              bookActivity: {
                orderNumber: getRandomInt(max, min).toString(),
                status: 'success',
                __typename: 'BookActivityResponse',
              },
            },
          };
        } else {
          // live activity booking
          console.log('Live booking');
          return await bookActivity({
            variables: activityVariables,
          });
        }
      } catch (error) {
        return error;
      }
    },
    [item]
  );

  return { createActivityBooking };
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default useBookActivity;
