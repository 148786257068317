import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';
import { myPagesClient } from 'apollo/client';
import { PREPARE_CHECKOUT_ITINERARY } from 'apollo/myBookings/mutations';
import { useBookingMutation } from 'apollo/myBookings/useBooking';
import { GET_COUNTRIES } from 'apollo/myDesti/queries';
import {
  DELETE_TRAVEL_PLAN,
  DELETE_TRAVEL_PLAN_ITEM,
  UPDATE_TRAVEL_PLAN_ITEM,
} from 'apollo/myPages/mutations';
import { GET_USER_BY_PK } from 'apollo/myPages/queries';
import { useMyPagesQuery } from 'apollo/myPages/useMyPages';
import BinIcon from 'assets/icons/cart-trash-bin.svg';
import PayIcon from 'assets/icons/pay-icon.svg';
import BlurredModal from 'components/BlurredModal';
import Button from 'components/Button';
import InfoDisclaimer, { MessageType } from 'components/InfoDisclaimer';
import Input from 'components/Input';
import { MODAL_TIMEOUT } from 'config/inputs';
import { FormState } from 'final-form';
import useIntl from 'hooks/useIntl';
import useUserValues from 'hooks/useUserInfo';
import { FunctionalComponent } from 'preact';
import { MarkupText, Text } from 'preact-i18n';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';
import { locationAndPeriodVar } from 'screens/LocationAndPeriod/cache';
import { MarketplaceActionTypes } from 'screens/Marketplace/useMarketplace';
import useUserProfileConstraints from 'shared/constraints/useUserProfileConstraints';
import styled from 'styled-components';
import { colorsSpec } from 'styles/defaultTheme';
import { CartItem, ITransportCartItem } from 'types/cache/Cart';
import { ICountry } from 'types/cache/Country';
import { ITravelPlan } from 'types/cache/TravelPlan';
import { IUser } from 'types/cache/User';
import { CartTypes } from 'types/shared/Cart';
import validateForm from 'utils/form/validateForm';
import { formatPrice } from 'utils/price/priceOperations';
import { GetGestLogo } from '../GetGestModalHeader';
import MarketplaceItemCheckoutForm from '../MarketplaceItemCheckoutForm';
import MarketplaceItemMainInfo from '../MarketplaceItemMainInfo';
import FlightSubscriptionStatusTracker from './components/FlightSubscriptionStatusTracker';
import ItemsTyType from './components/ItemsByType';
import PlanItemTitle from './components/PlanItemTitle';
import useFlightsItineraryPaymentStatus from './hooks/useFlightsItineraryStatus';
import useStripeProductOwners from './hooks/useProductOwners';
import useRefreshFlightsAvailabilityAndPrice from './hooks/useRefreshFlightsAvailabilityAndPrice';
import ELEMENTS_OPTIONS from './stripeElementsOptions';

const Wrapper = styled.div`
  width: 760px;
  background: ${({ theme }) => theme.colors[colorsSpec.white]};
  border-radius: 4px;
  margin: 20px 20px 0px 20px;

  display: flex;
  flex-direction: column;
`;

const ItemHeaderContainer = styled.div`
  height: 72px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 40px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  }
`;

const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 20px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
    margin-right: 10px;
  }
`;

const PlanItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const PlanItemDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  margin-right: 10px;
`;

const PlanItemMainContainer = styled.div`
  width: 55%;
  margin-bottom: 10px;
`;

const PlanItemActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0px 10px 20px;
  width: 45%;
  text-align: center;

  h4 {
    font-size: 30px;
    line-height: 49px;
    color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  }
`;

const StyledButton = styled(Button)`
  min-width: 90px;
  height: 25px;
  border-radius: 30px;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }

  img {
    width: 20px;
    height: 20px;
  }

  ${({ isPaying }) =>
    isPaying &&
    `
    padding: 0px;
  height: 18px;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
  }

  img {
    width: 10px;
    height: 10px;
  }
`};
`;

const PaidButton = styled(StyledButton)`
  background: ${({ theme }) => theme.colors[colorsSpec.accentLight]};
  cursor: default;

  &:hover {
    background-color: ${({ theme }) => theme.colors[colorsSpec.accentLight]};
  }
`;

const PlanItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  border-radius: 4px;
  margin: 10px;
  background: ${({ theme }) => theme.colors[colorsSpec.white]};

  ${({ isPaying }) =>
    isPaying &&
    `
   align-items: start;`};
`;

const StyledBlurredModal = styled(BlurredModal)`
  height: 694px;
  background-color: #252947;
  text-align: center;
`;

const checkAvailabilityIntervalMs = 20000;

interface IProps {
  user: IUser;
  plan: ITravelPlan;
  setMarketplaceItem: (
    payload: ITravelPlan,
    action: MarketplaceActionTypes
  ) => void;
  toggleInstructionsText: (isShown: boolean) => void;
  setIsPaid: (isPaid: boolean) => void;
  setIsTravelPlanPaid: (isPaid: boolean) => void;
  setSelectedPlanAndItem: (
    selectedPlan: { travelPlanName: string; itemId: number } | null
  ) => void;
  selectedPlanAndItem: { travelPlanName: string; itemId: number };
  loadLeadUser: (
    props: FormState<Record<string, any>, Partial<Record<string, any>>>
  ) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsFillTooltipOpen?: any;
}

const MarketplacePlanItem: FunctionalComponent<IProps> = ({
  plan,
  setMarketplaceItem,
  toggleInstructionsText,
  setIsPaid,
  setIsTravelPlanPaid,
  setSelectedPlanAndItem,
  selectedPlanAndItem,
  loadLeadUser,
  setIsLoading,
  setIsFillTooltipOpen,
}) => {
  const { travelPlanName } = plan;
  const { t } = useIntl('app.Marketplace');
  const [isCheckoutSuccess, setIsCheckoutSuccess] = useState<boolean>(false);
  const [travelPlanItems, setTravelPlanItems] = useState(plan.travelPlanItems);
  const [contactEmail, setContactEmail] = useState<{ [id: number]: string }>(
    {}
  );
  const [contactEmailValid, setContactEmailValid] = useState<{
    [id: number]: boolean;
  }>({});

  const { constraints: userConstraints } = useUserProfileConstraints();

  const { travelPeriod, cityName } = useReactiveVar(locationAndPeriodVar);

  const [removeTravelPlanItem] = useMutation(DELETE_TRAVEL_PLAN_ITEM, {
    client: myPagesClient,
  });

  const [removeTravelPlan] = useMutation(DELETE_TRAVEL_PLAN, {
    client: myPagesClient,
  });

  const [updateTravelPlanItem] = useMutation(UPDATE_TRAVEL_PLAN_ITEM, {
    client: myPagesClient,
  });

  const {
    flightsInvalid,
    setInvalidFlight,
    flightsPriceChanged,
    callRefreshFlightAvailabilityAndPrice,
  } = useRefreshFlightsAvailabilityAndPrice(
    travelPlanItems,
    setTravelPlanItems
  );

  const {
    productOwnersMap,
    productOwnerCodes,
    loadProductOwners,
  } = useStripeProductOwners();

  useEffect(() => {
    loadProductOwners(travelPlanItems.map(item => item.item.product_owner));

    callRefreshFlightAvailabilityAndPrice();
    const timeout = setInterval(
      callRefreshFlightAvailabilityAndPrice,
      checkAvailabilityIntervalMs
    );
    return () => clearTimeout(timeout);
  }, []);

  const onRemove = (itemId: number): void => {
    const travelPlan: ITravelPlan = { ...plan };
    travelPlan.travelPlanItems.splice(
      travelPlan.travelPlanItems.findIndex(i => i.id === itemId),
      1
    );

    setMarketplaceItem(
      travelPlan,
      travelPlan.travelPlanItems.length > 0
        ? MarketplaceActionTypes.Add
        : MarketplaceActionTypes.Remove
    );

    if (
      travelPlan.travelPlanItems.every(i => i.paid) ||
      !travelPlan.travelPlanItems.length
    ) {
      removeTravelPlan({ variables: { travelPlanId: travelPlan.id } });
    } else {
      removeTravelPlanItem({
        variables: {
          travelPlanId: travelPlan.id,
          travelPlanItemId: itemId,
        },
      });
    }

    setSelectedPlanAndItem(null);
    toggleInstructionsText(true);
  };

  const handlePaidItem = travelPlanItem => {
    setTravelPlanItems(travelPlanItems);

    if (!plan.travelPlanItems.some(i => !i.paid)) {
      setIsTravelPlanPaid(true);

      if (plan.id) {
        removeTravelPlan({
          variables: {
            travelPlanId: plan.id,
          },
        });
      }
    } else if (plan.id && travelPlanItem) {
      updateTravelPlanItem({
        variables: {
          travelPlanId: plan.id,
          travelPlanItem,
        },
      });
    }
  };

  const {
    flightPaymentStatusById,
    savedFlightItemAndItineraryById,
    saveFlightItemAndItineraryId,
    saveFlightPaymentStatusById,
  } = useFlightsItineraryPaymentStatus(travelPlanItems, handlePaidItem);

  const [
    prepareCheckoutItinerary,
    { loading: savingItinerary },
  ] = useBookingMutation(PREPARE_CHECKOUT_ITINERARY, {
    fetchPolicy: 'no-cache',
  });

  const handleOnPay = (item: CartItem): void => {
    const transportItem = item.item as ITransportCartItem;

    if (transportItem.booking_url) {
      const travelPlan = getTravelPlanObject(item);
      prepareCheckoutItinerary({
        variables: {
          travelPlan,
          contactEmail: contactEmail[item.id] || userInfo.email,
        },
        fetchPolicy: 'no-cache',
      })
        .then(result => {
          const {
            data: {
              prepareCheckoutItinerary: { travelItineraryId },
            },
          } = result;

          saveFlightItemAndItineraryId({
            itemId: transportItem.booking_token,
            // booking token unambiguously identifies transport item
            // in both database and cart
            travelItineraryId,
          });

          const marketPlaceItem = {
            ...plan,
            travelItineraryId,
            travelPlanItems: plan.travelPlanItems,
          };

          setMarketplaceItem(marketPlaceItem, MarketplaceActionTypes.Add);
          setTravelPlanItems([...travelPlanItems]);
          // TODO: set item to some type of pending status
          window.open(
            `${transportItem.booking_url}&email=${
              contactEmail[item.id] || userInfo.email
            }`,
            '_blank'
          );
        })
        .catch(() => {
          setInvalidFlight(item.id);
        });
    } else {
      setSelectedPlanAndItem({ travelPlanName, itemId: item.id });
    }
  };

  const handleTermsAndPrivacyCheck = (
    itemId: number,
    type: CartTypes
  ): void => {
    const marketPlaceItem = {
      ...plan,
      travelPlanItems: plan.travelPlanItems.map((i: CartItem) => {
        if (i.id === itemId && i.type === type) {
          return {
            ...i,
            isTermsAndPrivacyChecked: !i.isTermsAndPrivacyChecked,
          };
        } else {
          return { ...i };
        }
      }),
    };

    setTravelPlanItems(marketPlaceItem.travelPlanItems);

    setMarketplaceItem(marketPlaceItem, MarketplaceActionTypes.Add);
  };

  const onSuccessPayment = (itemId: number): void => {
    setIsCheckoutSuccess(true);
    setSelectedPlanAndItem(null);

    const travelPlanItem = plan.travelPlanItems.find(
      item => item.id === itemId
    );
    handlePaidItem(travelPlanItem);

    setTimeout(() => {
      setIsCheckoutSuccess(false);
      setIsPaid(true);
    }, MODAL_TIMEOUT);
  };

  const { data: { countries = [] } = {} } = useQuery<{ countries: ICountry[] }>(
    GET_COUNTRIES
  );

  const { data: { user = null } = {} } = useMyPagesQuery<{ user: IUser }>(
    GET_USER_BY_PK,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ user }) => {
        saveUserInfo(user);
      },
    }
  );

  const { userInfo, saveUserInfo } = useUserValues(user, countries);

  useEffect(() => {
    if (selectedPlanAndItem) {
      toggleInstructionsText(false);
    }
  }, [selectedPlanAndItem]);

  const getPassengersCountByCategory = (cartItem: CartItem) => {
    let people = 0;
    let children = 0;
    let infants = 0;

    switch (cartItem.type) {
      case CartTypes.ACTIVITY:
        people = cartItem.item.people;
        break;
      case CartTypes.TRANSPORT:
      case CartTypes.STAY:
        ({ people, children, infants } = cartItem.item);
        break;
      default:
        break;
    }

    return {
      people,
      children,
      infants,
    };
  };

  const getPassengersCount = (cartItem: CartItem) => {
    const result = getPassengersCountByCategory(cartItem);
    return (
      (result.children || 0) + (result.infants || 0) + (result.people || 0)
    );
  };

  const getTravelPlanObject = (item: CartItem) => {
    const { travelPeriod: planTP = [] } = plan;
    return JSON.stringify({
      id: plan.travelItineraryId, // TODO: figure out if this a remnant of old plan/item logic
      start_date: new Date(planTP[0]),
      end_date: new Date(planTP[1]),
      name: plan.travelPlanName,
      ...getPassengersCountByCategory(item),
      travelPlan: [
        {
          id: item.id,
          type: item.type.toLowerCase(),
          item: {
            ...item.item,
            order_number: item.order_number,
            product_owner: productOwnersMap[item.item.product_owner]?.name,
            merchant_global_id:
              productOwnersMap[item.item.product_owner]?.global_id,
          },
        },
      ],
    });
  };

  const maxPassengerCount = useMemo(
    () =>
      travelPlanItems.reduce(
        (max, item) => Math.max(getPassengersCount(item), max),
        0
      ),
    [travelPlanItems]
  );

  const renderContactEmailForm = ({ values, valid }, item: CartItem) => {
    const mail = contactEmail[item.id];
    if (values.email !== mail) {
      contactEmailValid[item.id] = valid;
      setContactEmailValid({ ...contactEmailValid });

      contactEmail[item.id] = values.email;
      setContactEmail({ ...contactEmail });
    }

    return (
      <form>
        {userInfo && (
          <Field
            name="email"
            isDisabled={
              flightsInvalid.current[item.id] === undefined ||
              flightsInvalid.current[item.id] ||
              savingItinerary ||
              item.paid
            }
            placeholder={t('contactEmail')}
            required
            component={Input}
            initialValue={userInfo.email}
            validate={val =>
              validateForm({ email: val }, userConstraints, 'email')
            }
            isErrorVisible
          />
        )}
      </form>
    );
  };

  return (
    <>
      {Object.keys(productOwnersMap)?.length > 0 && (
        <Wrapper>
          <ItemHeaderContainer>
            <TitleContainer>
              <h4>{plan.travelPlanName}</h4>
            </TitleContainer>
            <MainInfoContainer>
              <p>{cityName}</p>
              <p>
                <Text
                  id="app.Marketplace.passengerCount"
                  plural={maxPassengerCount}
                  fields={{ count: maxPassengerCount }}
                />
              </p>
              <p>
                <ItemsTyType travelPlanItems={travelPlanItems} />
              </p>
            </MainInfoContainer>
          </ItemHeaderContainer>
          {travelPlanItems
            .filter(item => productOwnerCodes.includes(item.item.product_owner))
            .map((item: CartItem) => {
              const productOwner = productOwnersMap[item.item.product_owner];

              const isPaying: boolean =
                selectedPlanAndItem && selectedPlanAndItem.itemId === item.id;

              const transportCartItem = item.item as ITransportCartItem;

              return (
                <PlanItemWrapper isPaying={isPaying}>
                  <PlanItemContainer>
                    <PlanItemTitle
                      type={item.type}
                      isPaying={isPaying}
                      item={item.item}
                    />
                    <PlanItemDetailsContainer>
                      <PlanItemMainContainer>
                        {MarketplaceItemMainInfo(item.id, item.item)[item.type]}
                        {transportCartItem.booking_token && (
                          <Form
                            onSubmit={() => null}
                            render={input =>
                              renderContactEmailForm(input, item)
                            }
                          />
                        )}
                      </PlanItemMainContainer>
                      <PlanItemActionContainer>
                        <h4>{formatPrice(item.item.price)}</h4>
                        {item.paid ? (
                          <PaidButton title={t('paid')} icon={PayIcon} />
                        ) : transportCartItem.booking_token &&
                          flightsInvalid.current[item.id] === undefined ? (
                          <StyledButton
                            title={t('checking')}
                            icon={PayIcon}
                            disabled={true}
                          />
                        ) : savedFlightItemAndItineraryById[
                            transportCartItem.booking_token
                          ] ? (
                          <>
                            <StyledButton
                              title={t(
                                `flightPaymentStatus.${
                                  flightPaymentStatusById[
                                    transportCartItem.booking_token
                                  ]
                                }`
                              )}
                              icon={PayIcon}
                              secondary
                              isPaying={isPaying}
                              disabled={true}
                            />
                            <FlightSubscriptionStatusTracker
                              itemId={transportCartItem.booking_token}
                              saveFlightPaymentStatusById={
                                saveFlightPaymentStatusById
                              }
                            />
                          </>
                        ) : (
                          <StyledButton
                            title={isPaying ? t('remove') : t('pay')}
                            icon={isPaying ? BinIcon : PayIcon}
                            secondary
                            onClick={() =>
                              isPaying ? onRemove(item.id) : handleOnPay(item)
                            }
                            isPaying={isPaying}
                            disabled={
                              (transportCartItem.booking_token &&
                                (!contactEmailValid[item.id] ||
                                  flightsInvalid.current[item.id])) ||
                              savingItinerary
                            }
                          />
                        )}
                      </PlanItemActionContainer>
                    </PlanItemDetailsContainer>
                  </PlanItemContainer>
                  {transportCartItem.booking_token && (
                    <>
                      <InfoDisclaimer
                        text={t('kiwiRedirectInfo')}
                        icon="assets/icons/info-bubble.svg"
                        type={MessageType.Neutral}
                      />
                      <InfoDisclaimer
                        text={t('contactEmailInfo')}
                        icon="assets/icons/info-bubble.svg"
                        type={MessageType.Info}
                      />
                    </>
                  )}
                  {(flightsInvalid.current[item.id] ||
                    flightsPriceChanged.current[item.id]?.priceChange) && (
                    <InfoDisclaimer
                      text={
                        flightsInvalid.current[item.id] ? (
                          t('flightInvalid')
                        ) : (
                          <MarkupText
                            id="app.Marketplace.priceChanged"
                            fields={{
                              priceFrom: flightsPriceChanged.current[item.id].oldPrice,
                              priceTo: flightsPriceChanged.current[item.id].newPrice,
                            }}
                          />
                        )
                      }
                      icon="assets/icons/info-bubble.svg"
                      type={
                        flightsInvalid.current[item.id]
                          ? MessageType.Error
                          : MessageType.Warning
                      }
                    />
                  )}
                  {isPaying && (
                    <Elements
                      stripe={productOwner.loader}
                      options={ELEMENTS_OPTIONS}
                    >
                      <MarketplaceItemCheckoutForm
                        user={user}
                        handleTermsAndPrivacyCheck={handleTermsAndPrivacyCheck}
                        setMarketplaceItem={setMarketplaceItem}
                        onSuccessPayment={onSuccessPayment}
                        productOwner={productOwner}
                        plan={plan}
                        item={item}
                        loadLeadUser={loadLeadUser}
                        setIsLoading={setIsLoading}
                        countries={countries}
                        getTravelPlanObject={getTravelPlanObject}
                        setIsFillTooltipOpen={setIsFillTooltipOpen}
                      />
                    </Elements>
                  )}
                </PlanItemWrapper>
              );
            })}
        </Wrapper>
      )}
      {isCheckoutSuccess && (
        <>
          <StyledBlurredModal>
            <GetGestLogo />
            <div>
              <h4>{t('successModalTitle')}</h4>
              <p>{t('successModalText')}</p>
              <span>{t('successModalTextTwo')}</span>
            </div>
          </StyledBlurredModal>
        </>
      )}
    </>
  );
};

export default MarketplacePlanItem;
