import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import {
  InformationContainer,
  InformationItem,
  InformationList,
  InformationTitle,
  InformationValue,
} from 'screens/Cart/components';
import styled from 'styled-components';
import { IActivityCartItem } from 'types/cache/Cart';

const StyledInformationContainer = styled(InformationContainer)`
  margin-left: 20px;
`;

const StyledInformationList = styled(InformationList)`
  margin-right: 0px;
`;

interface IProps {
  item: IActivityCartItem;
}

const ActivityTravelPlanItemDetails: FunctionalComponent<IProps> = ({
  item,
}) => {
  const { t } = useIntl('app.Cart');

  const {
    people,
    children,
    title,
    activity_type,
    city,
    supplierName,
    date,
  } = item;

  const startDate: string = dayjs(date).format('DD.MM.YYYY');
  const startTime: string = `${dayjs.utc(item.date).format('HH:mm')}`;
  const numberOfAdults: number = people ? people : 0;
  const numberOfChildren: number = children ? children : 0;

  return (
    <StyledInformationContainer>
      <StyledInformationList>
        <InformationItem>
          <InformationTitle>{t('date')}</InformationTitle>
          <InformationValue>{startDate}</InformationValue>
        </InformationItem>
        <InformationItem>
          <InformationTitle>{t('startTime')}</InformationTitle>
          <InformationValue>{startTime}</InformationValue>
        </InformationItem>
        <InformationItem>
          <InformationTitle>{t('numberOfAdults')}</InformationTitle>
          <InformationValue>{`${numberOfAdults} ${t(
            'people'
          )}`}</InformationValue>
        </InformationItem>
        <InformationItem>
          <InformationTitle>{t('numberOfChildren')}</InformationTitle>
          <InformationValue>{`${numberOfChildren} ${t(
            'children'
          )}`}</InformationValue>
        </InformationItem>
      </StyledInformationList>
      <StyledInformationList>
        <InformationItem>
          <InformationTitle>{t('serviceProvider')}</InformationTitle>
          <InformationValue>{supplierName}</InformationValue>
        </InformationItem>
        <InformationItem>
          <InformationTitle>{t('activityType')}</InformationTitle>
          <InformationValue>{activity_type}</InformationValue>
        </InformationItem>
        <InformationItem>
          <InformationTitle>{t('city')}</InformationTitle>
          <InformationValue>{city}</InformationValue>
        </InformationItem>
      </StyledInformationList>
    </StyledInformationContainer>
  );
};

export default ActivityTravelPlanItemDetails;
