import { FunctionalComponent } from 'preact';
import { FieldMetaState } from 'react-final-form';
import {
  ErrorLabel,
  Label,
  StyledInput,
  StyledInputContainer,
} from './StyledInput';

type Props = {
  placeholder?: string;
  id?: string;
  label?: string;
  required?: boolean;
  isDisabled?: boolean;
  isDisabledStyleApplied?: boolean;
  isErrorVisible?: boolean;
  input?: any; // TODO: FieldInputProps<any>;
  meta?: FieldMetaState<any>;
  name?: string;
};

const Input: FunctionalComponent<Props> = ({
  input,
  meta = {},
  placeholder = '',
  required = false,
  isDisabled = false,
  isDisabledStyleApplied = true,
  label = '',
  isErrorVisible = false,
  id = null,
}) => (
  <StyledInputContainer label={label} isErrorVisible={isErrorVisible}>
    {label && <Label>{label}</Label>}
    <StyledInput
      isDisabledStyleApplied={isDisabledStyleApplied}
      error={meta?.touched && (meta.error || meta.submitError)}
    >
      {
        <ErrorLabel error={meta?.touched && (meta.error || meta.submitError)}>
          {meta.error || meta.submitError}
        </ErrorLabel>
      }
      <input
        id={id}
        error={meta?.touched && (meta.error || meta.submitError)}
        required={required}
        disabled={isDisabled}
        isDisabledStyleApplied={isDisabledStyleApplied}
        type="text"
        {...input}
      />
      {placeholder && (
        <label
          style={{
            display: input?.value ? 'none' : 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '90%',
          }}
        >
          {meta.active ? '' : placeholder}
        </label>
      )}
    </StyledInput>
  </StyledInputContainer>
);

export default Input;
