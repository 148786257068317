import { GET_TRAVEL_ITINERARY } from 'apollo/myPages/queries';
import { useMyPagesQuery } from 'apollo/myPages/useMyPages';
import Accordion from 'components/Accordion/';
import EmptyState from 'components/EmptyState/';
import Loading from 'components/Loading';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import Tabs, { mapActiveTab } from 'components/Tabs';
import { TABS_HEIGHT } from 'config/inputs';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { Fragment } from 'preact';
import { useMemo, useState } from 'preact/hooks';
import styled from 'styled-components';
import { ITravelItinerary, ITravelItineraryItem } from 'types/cache/TravelItinerary';
import { formatPrice, sumPrices } from 'utils/price/priceOperations';
import { MediumCard } from '../../Cart/components';
import { Container, ProfileTabsContainer, SupportMailInfoContainer } from '../components';
import ActivityItineraryItem from './Activity';
import {
  CartItem,
  CartItemInfo,
  CartItemTime,
  ItineraryTitle,
  LargeCard,
  Row,
  SmallCard,
  Value,
} from './components';
import StayItineraryItem from './Stay';
import TransportItineraryItem from './Transport';
import { useHistory } from 'react-router-dom';
import { Paths } from 'constants/paths';
import { MessageType, SupportMailInfoDisclaimer } from 'components/InfoDisclaimer';

const CartItemContainer = styled.div`
  margin-top: 20px;
`;

const ItineraryContainer = styled(Container)`
  max-height: 550px;
  overflow: auto;
`;

const TravelItinerary = ({ tabs = [], location }) => {
  const { t } = useIntl('app.TravelItinerary');
  const { activeTab } = location.state;
  const isThisPageVisited = IsPageVisited('TravelItinerary');
  const [isOpen, setOpen] = useState<boolean[]>([]);

  const history = useHistory();

  const { loading, data: { travel_itinerary = [] } = {} } = useMyPagesQuery(
    GET_TRAVEL_ITINERARY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ travel_itinerary }) => setOpen(travel_itinerary.map(() => false))
    }
  );

  const getItineraryPrice = (items: ITravelItineraryItem[]) =>
    sumPrices(...items.map(i => i.price));

  const handleSetIsOpen = (index: number) => {
    const updated = [...isOpen];
    updated[index] = !updated[index];
    setOpen(updated);
  }

  const filteredTravelItinerary: ITravelItinerary[] = useMemo(() => {
    return travel_itinerary
      .filter(ti =>
        ti.travel_itinerary_activities.length > 0 ||
        ti.travel_itinerary_stays.length > 0 ||
        ti.travel_itinerary_transports.length > 0
      ).map(ti => (
        {
          ...ti,
          travel_itinerary_activities: ti.travel_itinerary_activities.map(x => ({ ...x, price: { amount: x.price, currency: x.currency } })),
          travel_itinerary_stays: ti.travel_itinerary_stays.map(x => ({ ...x, price: { amount: x.price, currency: x.currency } })),
          travel_itinerary_transports: ti.travel_itinerary_transports.map(x => ({ ...x, price: { amount: x.price, currency: x.currency } }))
        } // map database field price and currency to IBasePrice
      ));
  }, [travel_itinerary]);

  return (
    <Fragment>
      <ModalHeader title={t('title')} />

      <ItineraryContainer>
        <ProfileTabsContainer>
          {tabs.length && (
            <Tabs
              config={mapActiveTab(tabs, activeTab)}
              height={TABS_HEIGHT}
              isSecondary
            />
          )}

          {loading ? (
            <Loading />
          ) : filteredTravelItinerary.length ? (
            filteredTravelItinerary.map(
              ({
                start_date,
                end_date,
                number_of_adults,
                number_of_children,
                travel_itinerary_stays,
                travel_itinerary_activities,
                travel_itinerary_transports,
              }, index) => (
                <Accordion isOpen={isOpen[index]} setOpen={() => handleSetIsOpen(index)}>
                  <CartItemContainer>
                    <CartItem>
                      <CartItemTime>
                        <h3>
                          {dayjs(start_date).format('DD.MM')} -
                          {dayjs(end_date).format('DD.MM')}
                        </h3>
                      </CartItemTime>
                      <CartItemInfo>
                        <Row>
                          <SmallCard>
                            <Value>
                              {number_of_adults} {t('adults')}
                            </Value>
                            <Value>
                              {number_of_children} {t('children')}
                            </Value>
                          </SmallCard>
                          <LargeCard>
                            <Value>{t('totalOffersBought')}</Value>
                            <Value>
                              {travel_itinerary_stays.length +
                                travel_itinerary_activities.length +
                                travel_itinerary_transports.length}
                            </Value>
                          </LargeCard>
                          <MediumCard>
                            <Value>{t('totalPrice')}</Value>
                            <Value>
                              {formatPrice(
                                sumPrices(
                                  getItineraryPrice(travel_itinerary_stays),
                                  getItineraryPrice(travel_itinerary_activities),
                                  getItineraryPrice(travel_itinerary_transports)
                                )
                              )}
                            </Value>
                          </MediumCard>
                        </Row>
                        {/* TODO: insurance and eco compensation */}
                        {/* <Row>
                          <InsuranceAndCompensationPart
                            transactionId={null}
                          />
                        </Row> */}
                      </CartItemInfo>
                    </CartItem>
                  </CartItemContainer>

                  <Fragment>
                    {!!travel_itinerary_activities.length && (
                      <Fragment>
                        <ItineraryTitle>
                          {travel_itinerary_activities.length === 1
                            ? t('activityOffer')
                            : t('activityOffers')}
                        </ItineraryTitle>
                        {travel_itinerary_activities.map(item => (
                          <ActivityItineraryItem item={item} />
                        ))}
                      </Fragment>
                    )}

                    {!!travel_itinerary_stays?.length && (
                      <Fragment>
                        <ItineraryTitle>
                          {travel_itinerary_stays.length === 1
                            ? t('stayOffer')
                            : t('stayOffers')}
                        </ItineraryTitle>
                        {travel_itinerary_stays.map(item => (
                          <StayItineraryItem
                            item={item}
                            stayOfferCount={travel_itinerary_stays.length}
                            adults={number_of_adults}
                            children={number_of_children}
                          />
                        ))}
                      </Fragment>
                    )}

                    {!!travel_itinerary_transports.length && (
                      <Fragment>
                        <ItineraryTitle>
                          {travel_itinerary_transports.length === 1
                            ? t('transportOffer')
                            : t('transportOffers')}
                        </ItineraryTitle>
                        {travel_itinerary_transports.map(item => (
                          <TransportItineraryItem
                            item={item}
                            adults={number_of_adults}
                            children={number_of_children}
                          />
                        ))}
                      </Fragment>
                    )}
                  </Fragment>
                </Accordion>
              )
            )
          ) : (
            <EmptyState config={{ title: t('noItems') }} />
          )}
        </ProfileTabsContainer>
      </ItineraryContainer>
      <SupportMailInfoContainer>
        <SupportMailInfoDisclaimer
          text={t('supportEmailInfo')}
          icon="assets/icons/info-bubble.svg"
          type={MessageType.Neutral}
          email={process.env.DESTI_ONE_SUPPORT_EMAIL}
        />
      </SupportMailInfoContainer>
      <ModalFooter
        buttonTitle={t('goDestiOneTitle')}
        onButtonClick={() => {history.push(Paths.TravelLocationAndPeriod)}}
        mapIconProps={{ disabled: true }}
        chatIconProps={{ disabled: true }}
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default TravelItinerary;
