import { ReactiveVar } from '@apollo/client';
import { IMarketplace } from 'types/cache/Marketplace';
import { ITravelPlan } from 'types/cache/TravelPlan';

export enum MarketplaceActionTypes {
  Add,
  Remove,
}

export function useMarketplace(marketplaceVar: ReactiveVar<IMarketplace>) {
  const setMarketplaceItem = (
    payload: ITravelPlan,
    action: MarketplaceActionTypes
  ) => {
    const oldValue = marketplaceVar();
    const { travelPlans } = oldValue;

    const marketplaceItemIdx = travelPlans.findIndex(
      i => i.travelPlanName === payload.travelPlanName
    );

    switch (action) {
      case MarketplaceActionTypes.Add:
        if (marketplaceItemIdx === -1) {
          travelPlans.push(payload);
        } else {
          travelPlans.splice(marketplaceItemIdx, 1, payload);
        }
        break;

      case MarketplaceActionTypes.Remove:
        travelPlans.splice(marketplaceItemIdx, 1);
        break;

      default:
        console.error('Action type not supported');
    }

    const updated = {
      ...oldValue,
      travelPlans,
    };
    marketplaceVar(updated);
  };

  const setMarketplaceState = (payload: ITravelPlan[]) => {
    marketplaceVar({ travelPlans: payload });
  };

  return { setMarketplaceItem, setMarketplaceState };
}
