import { createContext, FunctionalComponent } from 'preact';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  left: 0;
  overflow-y: scroll;
  padding: 50px 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  :after {
    content: '';
    display: block;
    padding-bottom: 50px;
    position: absolute;
  }
`;

const StyledModal = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  height: auto;
  margin: auto;
  max-width: 800px;
  width: 100%;

  .Toastify {
    position: relative;
  }
  .Toastify__toast-container {
    position: absolute;
  }
`;

const ModalContext = createContext(null);

type Props = {
  onDismiss(): void;
};

const Modal: FunctionalComponent<Props> = ({
  children = null,
  onDismiss = () => 0,
}) => (
  // <Overlay>
  <StyledModal onClick={e => e.stopPropagation()}>
    <ToastContainer />
    <ModalContext.Provider value={{ onModalClose: onDismiss }}>
      {children}
    </ModalContext.Provider>
  </StyledModal>
  // </Overlay>
);

export default Modal;
export { ModalContext };
