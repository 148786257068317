import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import { limitText } from 'utils/string/limitText';

const StyledSeeMore = styled.span`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
`;

type Props = {
  text?: string;
  charLimit?: number;
  label?: string;
  onSeeMore?: () => boolean;
};

const SeeMore: FunctionalComponent<Props> = ({
  text,
  charLimit = 170,
  label = 'see more',
  onSeeMore = () => 0,
}) => (
  <span>
    {limitText(text, charLimit)}
    {text.length > charLimit ? (
      <StyledSeeMore onClick={() => onSeeMore && onSeeMore()}>
        {label}
      </StyledSeeMore>
    ) : null}
  </span>
);

export default SeeMore;
