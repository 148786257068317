import TransportPlaneImage from 'assets/images/transport-plane.png';
import TransportBusImage from 'assets/images/transport-bus.png';
import TransportTrainImage from 'assets/images/transport-train.png';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import {
  ActivityAndTransportTotalPriceContainer,
  AdditionalInformationContainer,
  DetailsContainer,
  Image,
  ImageContainer,
  InformationContainer,
  InformationItem,
  InformationList,
  InformationTitle,
  InformationValue,
  TotalPriceTitle,
  TotalPriceValue,
} from 'screens/Cart/components';
import { ITransportCartItem } from 'types/cache/Cart';
import { formatPrice } from 'utils/price/priceOperations';
import { distinctCollectionToString } from 'utils/string/distinctCollectionToString';
import { splitApplyJoin } from 'utils/string/splitApplyJoin';

interface IProps {
  item: ITransportCartItem;
}

const TransportCartItemDetails: FunctionalComponent<IProps> = ({ item }) => {
  const { t } = useIntl('app.Cart');

  const {
    people,
    children,
    transport_type,
    price,
    date,
    infants = 0,
    trip_type,
    operatorNames,
  } = item;

  const departureDate: string = dayjs(date).format('DD.MM.YYYY');
  const numberOfAdults: number = people || 0;
  const numberOfChildren: number = children + infants || 0;
  const priceValue = formatPrice(price);

  const getTransportImage = () => {
    const transportTypes = transport_type.split(',');
    if (transportTypes[0].includes('Air')) return TransportPlaneImage;
    if (transportTypes[0].includes('Bus')) return TransportBusImage;
    if (transportTypes[0].includes('Train')) return TransportTrainImage;
  };

  return (
    <DetailsContainer>
      <InformationContainer>
        <InformationList>
          <InformationItem>
            <InformationTitle>{t('transportDate')}</InformationTitle>
            <InformationValue>{departureDate}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('numberOfAdults')}</InformationTitle>
            <InformationValue>{`${numberOfAdults} ${t(
              'people'
            )}`}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('numberOfChildren')}</InformationTitle>
            <InformationValue>{`${numberOfChildren} ${t(
              'children'
            )}`}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('serviceProvider')}</InformationTitle>
            <InformationValue>
              {distinctCollectionToString(operatorNames, '/')}
            </InformationValue>
          </InformationItem>
        </InformationList>
        <InformationList>
          <InformationItem>
            <InformationTitle>{t('transportType')}</InformationTitle>
            <InformationValue>
              {splitApplyJoin(transport_type.toLowerCase(), t, ', ')}
            </InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('tripType')}</InformationTitle>
            <InformationValue>{t(trip_type)}</InformationValue>
          </InformationItem>
        </InformationList>
      </InformationContainer>
      <AdditionalInformationContainer>
        <ImageContainer>
          <Image src={getTransportImage()} />
        </ImageContainer>
        <ActivityAndTransportTotalPriceContainer>
          <TotalPriceTitle>{t('totalPrice')}</TotalPriceTitle>
          <TotalPriceValue>{priceValue}</TotalPriceValue>
        </ActivityAndTransportTotalPriceContainer>
      </AdditionalInformationContainer>
    </DetailsContainer>
  );
};

export default TransportCartItemDetails;
