import styled from 'styled-components';

export const SelectContainer = styled.div`
  min-width: 220px;
  max-width: 220px;
`;

export const ChildrenSelectContainer = styled.div`
  margin-bottom: 10px;
  min-width: 180px;
  max-width: 180px;
`;
