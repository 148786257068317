import { ReactiveVar } from '@apollo/client';
import { Rooms } from 'types/cache/Rooms';

export function useRooms(roomsVar: ReactiveVar<Rooms>) {
  const setRoomsQuantity = (id: string, index: number, value: string) => {
    const oldValue = roomsVar();
    const { quantities } = oldValue;

    const idMap = quantities.get(id);
    quantities.set(id, { ...idMap, [index]: value });

    const updated = { ...oldValue, quantities };
    roomsVar(updated);
  };

  return { setRoomsQuantity };
}
