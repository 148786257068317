import Button from 'components/Button';
import { Paths } from 'constants/paths';
import useIntl from 'hooks/useIntl';
import { Fragment, FunctionalComponent } from 'preact';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { colorsSpec, transparency } from 'styles/defaultTheme';

export const Container = styled.div`
  padding: 150px 24px 0 24px;
  min-height: 569px;
  overflow-y: auto;
  text-align: center;
  position: absolute;
  z-index: 100;
  backdrop-filter: blur(5px);
  background-color: ${transparency.black50};
  height: 777px;
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors[colorsSpec.white]};
  margin-bottom: 15px !important;
  font-size: 28px !important;
`;

const SubTitle = styled.h4`
  color: ${({ theme }) => theme.colors[colorsSpec.white]};
  margin-bottom: 15px !important;
  font-size: 22px !important;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors[colorsSpec.white]};
  font-size: 18px !important;
  margin: 20px 0 !important;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  margin: 0 auto;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

enum ButtonActions {
  Login,
  Register,
}

const GuestCheckout: FunctionalComponent = () => {
  const { t } = useIntl('app.GuestCheckout');
  const { t: register } = useIntl('app.Register');
  const history = useHistory();

  const onButtonClick = (action: ButtonActions): void => {
    history.push(action === ButtonActions.Login ? Paths.Login : Paths.Register);
  };

  return (
    <Fragment>
      <Container>
        <Title>{t('heading')}</Title>
        <SubTitle>{t('subtitle')}</SubTitle>
        <Description>{t('description')}</Description>
        <Buttons>
          <Button
           secondary
            title={register('login')}
            onClick={() => onButtonClick(ButtonActions.Login)}
          />
          <Button
            secondary
            title={register('register')}
            onClick={() => onButtonClick(ButtonActions.Register)}
          />
        </Buttons>
      </Container>
    </Fragment>
  );
};

export default GuestCheckout;
