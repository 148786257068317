import { FunctionalComponent } from 'preact';
import { ExitIcon, Header, MainTitle, NormalFormattedText } from '../components';
import { Container } from 'screens/Account/components';
import styled from 'styled-components';

const StyledModal = styled(Container)`
  width: 100%;
  background-color: #2c3d5e;
  z-index: 9999;
  position: absolute;
  overflow: auto;
  bottom: 10px;
  margin: 0px;
`;

interface IProps {
  onDismiss: () => void;
  terms: string;
  title: string;
}

const TermsAndConditionsOverlay: FunctionalComponent<IProps> = ({
  onDismiss,
  terms,
  title
}) => {
  return (
    <StyledModal>
      <Header>
        <MainTitle>{title}</MainTitle>
        <ExitIcon onDismiss={onDismiss} />
      </Header>
      <NormalFormattedText>{terms}</NormalFormattedText>
    </StyledModal>
  );
}

export default TermsAndConditionsOverlay;
