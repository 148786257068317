import { TabProps } from 'components/Tabs/Tab';
import { FunctionalComponent } from 'preact';
import { Route } from 'react-router-dom';

type Props = {
  exact: boolean;
  path: string;
  component: any;
  tabs?: TabProps[];
};

const ModalRoute: FunctionalComponent<Props> = ({
  path = null,
  exact = false,
  component: Component = null,
  ...rest
}) => (
  <Route path={path} exact={exact}>
    <Component {...rest} />
  </Route>
);

export default ModalRoute;
