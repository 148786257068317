import { cartVar } from './cache';
import CartItemComponent from './components/CartItem';
import GuestCheckout from './components/GuestCheckout';
import TravelPlanActions from './components/TravelPlanActions/TravelPlanActions';
import { CartActionTypes, useCart } from './useCart';
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { myPagesClient } from 'apollo/client';
import { GET_COUNTRIES } from 'apollo/myDesti/queries';
import { SAVE_TRAVEL_PLAN, GET_TRAVEL_PLANS } from 'apollo/myPages/mutations';
import { GET_USER_BY_PK } from 'apollo/myPages/queries';
import BlurredModal from 'components/BlurredModal';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import { MODAL_TIMEOUT } from 'config/inputs';
import { Paths } from 'constants/paths';
import useIntl from 'hooks/useIntl';
import useToken from 'hooks/useToken';
import { Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import { locationAndPeriodVar } from 'screens/LocationAndPeriod/cache';
import { marketplaceVar } from 'screens/Marketplace/cache';
import {
  MarketplaceActionTypes,
  useMarketplace,
} from 'screens/Marketplace/useMarketplace';
import styled from 'styled-components';
import { colorsSpec } from 'styles/defaultTheme';
import { CartItem, TravelerTypes } from 'types/cache/Cart';
import { ICountry } from 'types/cache/Country';
import { IUser } from 'types/cache/User';
import { CartTypes } from 'types/shared/Cart';

export const Container = styled.div`
  min-height: 569px;
  max-height: 569px;
  overflow-y: auto;
  padding: 25px;
  margin: 50px 25px;
  background-color: ${({ theme }) => theme.colors[colorsSpec.white]};

  @media (max-width: 576px) {
    overflow-x: hidden;
  }
`;

const MessageContainer = styled.div`
  max-width: 100%;
  height: 151px;
  margin: 18px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MessageTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.default};
  text-align: center;
  line-height: 150%;
`;

export const Wrapper = styled.div`
  position: relative;
  height: 600px;
  overflow-y: auto;
`;

const Rect = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
  width: 10px;
  height: 10px;
  background: ${({ theme }) => theme.colors[colorsSpec.secondary]};
`;

const Details = styled.div`
  position: relative;
  padding-left: 24px;

  &:before {
    position: absolute;
    top: 12px;
    left: 4px;
    content: '';
    height: 95%;
    width: 1px;
    background: ${({ theme }) => theme.colors[colorsSpec.accent]};
  }
`;

const Title = styled.li`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors[colorsSpec.primary]};

  span {
    font-weight: lighter;
    margin-left: 4px;
  }
`;

const ClickableTitle = styled(Title)`
  cursor: ${({ count }) => (count > 0 ? 'pointer' : 'unset')};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 60px;
  margin-top: 20px;
`;

export const TermsAndPrivacy = styled.div`
  margin: 10px 0 15px 0;
`;

const AccordionTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: start;

  @media (max-width: 576px) {
    flex-wrap: wrap;
    padding-right: 400px;
    height: 100px;
  }
`;

const CartItemsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const StyledBlurredModal = styled(BlurredModal)`
  height: 752px;
`;

const Message = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Cart = () => {
  const { t } = useIntl('app.Cart');
  const history = useHistory();
  const isThisPageVisited = IsPageVisited('Cart');
  const { token } = useToken();

  const { cartItems = [], price } = useReactiveVar(cartVar);
  const { setCartItem } = useCart(cartVar);
  const { setMarketplaceItem } = useMarketplace(marketplaceVar);

  const [isRoutingToMarketplace, setIsRoutingToMarketplace] = useState<boolean>(
    false
  );

  const [travelPlanCount, setTravelPlanCount] = useState(0);

  const [isFormInvalid, setIsFormInvalid] = useState<boolean>(true);
  const [isFillTooltipOpen, setIsFillTooltipOpen] = useState<boolean>(
    undefined
  );
  const [isGuestOverlayVisible, setIsGuestOverlayVisible] = useState<boolean>(
    false
  );
  const [isSavePlanSuccess, setIsSavePlanSuccess] = useState<boolean>(false);
  const [isTravelPlanSaved, setIsTravelPlanSaved] = useState<boolean>(false);
  const [travelPlanName, setTravelPlanName] = useState<string>('');
  const [checkedCartItems, setCheckedCartItems] = useState<CartItem[]>([]);

  const { regionIds, cityIds, travelPeriod } = useReactiveVar(
    locationAndPeriodVar
  );

  const { data: { countries = [] } = {} } = useQuery<{ countries: ICountry[] }>(
    GET_COUNTRIES
  );

  const [getTravelPlan] = useMutation(GET_TRAVEL_PLANS, {
    variables: {
      id: '',
    },
    client: myPagesClient,
    onCompleted: ({ getTravelPlans }) =>
      setTravelPlanCount(getTravelPlans?.travelPlans?.length || 0),
  });

  useEffect(() => {
    if (token) {
      getTravelPlan();
    }
  }, [getTravelPlan, token]);

  const [saveTravelPlan] = useMutation(SAVE_TRAVEL_PLAN, {
    variables: {
      travelPlan: {
        travelPeriod,
        travelPlanItems: checkedCartItems,
        travelPlanName,
        travelItineraryId: null,
      },
    },
    client: myPagesClient,
    onCompleted: () => getTravelPlan(),
  });

  const [getUser] = useLazyQuery<{ user: IUser }>(GET_USER_BY_PK, {
    variables: { id: token },
    fetchPolicy: 'no-cache',
    client: myPagesClient,
    onCompleted: ({ user }) => {
      cartItems.forEach((cartItem: CartItem) => {
        setCartItem(
          {
            id: cartItem.id,
            type: cartItem.type,
            leadTraveler: {
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              dateOfBirth: Date.parse(user?.date_of_birth),
              country:
                user && countries.length
                  ? countries.find(c => c.id === user.country_id).name
                  : '',
              countryIsoCode2:
                user && countries.length
                  ? countries.find(c => c.id === user.country_id).country_code
                  : '',
              nationality:
                user && countries.length
                  ? countries.find(c => c.id === user.country_id).name
                  : '',
              gender: user.gender,
              type: TravelerTypes.Lead,
            },
          },
          CartActionTypes.UpdateLeadTraveler
        );
      });
    },
  });

  const onAddMore = (): void => {
    const isTravelInfoAvailable =
      travelPeriod.length && (cityIds.length || regionIds.length);

    isTravelInfoAvailable
      ? history.push({
          pathname: Paths.Arrangements,
        })
      : history.push(Paths.TravelLocationAndPeriod);
  };

  const handleBookNow = (): void => {
    if (token) {
      setMarketplaceItem(
        {
          travelPeriod,
          travelPlanItems: checkedCartItems,
          travelPlanName: t('guestTravelPlan'),
          travelItineraryId: null,
        },
        MarketplaceActionTypes.Add
      );

      setIsRoutingToMarketplace(true);

      setTimeout(() => {
        setIsRoutingToMarketplace(false);
        history.push(Paths.Marketplace);
        handleClearCartItems();
      }, MODAL_TIMEOUT);
    } else {
      setIsGuestOverlayVisible(true);
    }
  };

  const isTermsAndPrivacyUnchecked = (): boolean =>
    cartItems.some((item: CartItem) => !item.isTermsAndPrivacyChecked);

  const isCartItemVisible = (type: CartTypes): boolean =>
    [CartTypes.ACTIVITY, CartTypes.STAY, CartTypes.TRANSPORT].includes(type);

  const handleClearCartItems = (): void => {
    if (cartItems.length === checkedCartItems.length || cartItems.length === 1) {
      clearCartItems({}, CartActionTypes.CleanCart, true);
    } else {
      checkedCartItems.forEach((item: CartItem) => {
        clearCartItems(item, CartActionTypes.Remove, false);
      });
    }
  };

  const clearCartItems = (
    item: CartItem | {},
    actionType: CartActionTypes,
    isTravelPlanSaved: boolean
  ) => {
    setCartItem(item, actionType);
    setCheckedCartItems([]);
    setTimeout(() => {
      setIsSavePlanSuccess(false);
      setIsTravelPlanSaved(isTravelPlanSaved);
      setTravelPlanName('');
    }, MODAL_TIMEOUT);
  };

  const handleSaveTravelPlan = (): void => {
    if (token) {
      saveTravelPlan();

      setIsSavePlanSuccess(true);

      handleClearCartItems();
    } else {
      setIsGuestOverlayVisible(true);
    }
  };

  const handleRedirectToTravelPlans = () => {
    if (!travelPlanCount) {
      return;
    }

    history.push({
      pathname: Paths.TravelPlan,
      state: { activeTab: 'app.Profile.travelPlanTab' },
    });
  };

  return (
    <Fragment>
      <ModalHeader
        title={
          isGuestOverlayVisible ? t('congratulationsHeaderTitle') : t('title')
        }
      />

      <Wrapper>
        {isGuestOverlayVisible && <GuestCheckout />}
        {/*
        <Container isScrollDisabled={isGuestOverlayVisible}>

          {cartItems.length ? (
            <Details>
              <Rect />
              <TitleContainer>
                <Title>{`${t('travelPlan')} (${cartItems.length})`}</Title>
                <Title>
                  Total price: {parseFloat(price.toString()).toFixed(2)} SEK
                </Title>
              </TitleContainer>

              {cartItems.map(({ id, item, type, travelerList }) => (
                <Fragment>
                  {isCartItemVisible(type) && (
                    <Fragment>
                      <Accordion isLargeIcon isOpen={isOpen} setOpen={setOpen}>
                        <AccordionTitle>
                          <ItemHeader title={item?.title} isOpen={isOpen} />
                          <ItemDetails
                            label="Date:"
                            value={dayjs(item.date).format('DD.MM.YYYY')}
                            subLabel="Time:"
                            subValue={`@${dayjs
                              .utc(item.date)
                              .format('HH:mm')}`}
                            isOpen={isOpen}
                          />
                        </AccordionTitle>
                        <Fragment>
                          {CartItems(id, item, type, false, isOpen)[type]}
                        </Fragment>
                      </Accordion>

                      <TravelerList
                        cartItemId={id}
                        travelerList={travelerList}
                        type={type}
                        onFormValidation={isValid => setIsFormInvalid(isValid)}
                        onLoadLeadUser={() => {
                          if (isFillTooltipOpen === undefined && token) {
                            setIsFillTooltipOpen(true);
                          }
                        }}
                      />

                      <TermsAndPrivacy>
                        <Checkbox
                          id={`terms_${id}`}
                          name="terms"
                          input={{
                            checked: cartItems.find(i => i.id === id)
                              ?.isTermsAndPrivacyChecked,
                            onChange: e =>
                              setCartItem(
                                {
                                  id,
                                  isTermsAndPrivacyChecked: e.target.checked,
                                  type,
                                },
                                e.target.checked
                                  ? CartActionTypes.TermsAndPrivacyChecked
                                  : CartActionTypes.TermsAndPrivacyUnchecked
                              ),
                          }}
                          label={[
                            t('accept'),
                            <a href="#"> {t('termsOfBooking')}&nbsp;</a>,
                            t('and'),
                            <a href="#"> {t('termsOfPayment')}</a>,
                          ]}
                        />
                      </TermsAndPrivacy>
                    </Fragment>
                  )}
                </Fragment>
              ))}
            </Details>
          ) : (
            <EmptyState config={{ title: t('noItems') }} />
          )}
        </Container>

        <LoadLeadTravelerTooltip
          title={t('loadLeadTravelerTitle')}
          onConfirm={() => {
            getUser();
            setIsFillTooltipOpen(false);
          }}
          onCancel={() => setIsFillTooltipOpen(false)}
          isOpen={isFillTooltipOpen}
          tooltipPoweredBy={t('tooltipPoweredBy')}
        />
      */}

        <TitleContainer>
          <ClickableTitle
            count={travelPlanCount}
            onClick={handleRedirectToTravelPlans}
          >
            {t('savedTravelPlans')}
            <span>{`(${travelPlanCount})`}</span>
          </ClickableTitle>
        </TitleContainer>
        <TitleContainer>
          <Title>
            {t('travelPlan')}
            <span>{`(${cartItems.length})`}</span>
          </Title>
        </TitleContainer>

        <CartItemsListContainer>
          {cartItems.map(({ id, item, type, travelerList }) => (
            <CartItemComponent
              key={id}
              id={id}
              item={item}
              type={type}
              marketplaceItems={checkedCartItems}
              travelerList={travelerList}
              setCheckedCartItems={setCheckedCartItems}
            />
          ))}
          {!cartItems.length && (
            <MessageContainer>
              <MessageTitle>{t('emptyTravelPlan')}</MessageTitle>
            </MessageContainer>
          )}
        </CartItemsListContainer>
      </Wrapper>

      {!isTravelPlanSaved && (
        <TravelPlanActions
          travelPlanName={travelPlanName}
          isMarketplaceItems={checkedCartItems.length > 0}
          setTravelPlanName={setTravelPlanName}
          saveTravelPlan={handleSaveTravelPlan}
          handleBookNow={handleBookNow}
          isGuest={!token}
        />
      )}
      {isTravelPlanSaved ? (
        <ModalFooter
          showButton={true}
          buttonTitle={t('myPagesButton')}
          onButtonClick={() =>
            history.push({
              pathname: Paths.TravelPlan,
              state: { activeTab: 'app.Profile.travelPlanTab' },
            })
          }
        />
      ) : (
        <>
          <ModalFooter
            showButton={!isGuestOverlayVisible}
            buttonTitle={t('exploreMoreButtonTitle')}
            onButtonClick={onAddMore}
            tooltipTitle={
              isGuestOverlayVisible ? 'Checkout' : t('tooltipTitle')
            }
            tooltipText={t('tooltipText')}
            tooltipPoweredBy={t('tooltipPoweredBy')}
            doOpen={!isThisPageVisited}
          />
        </>
      )}
      {isSavePlanSuccess && (
        <>
          <StyledBlurredModal>
            <div>
              <h4>{t('saveTravelPlanSuccessTitle')}</h4>
              <p>{t('saveTravelPlanSuccessText')}</p>
              <span>{travelPlanName}</span>
            </div>
          </StyledBlurredModal>
        </>
      )}
      {isRoutingToMarketplace && (
        <StyledBlurredModal>
          <div>
            <h4>{t('successTitle')}</h4>
            <p>{t('successText')}</p>
          </div>
        </StyledBlurredModal>
      )}
    </Fragment>
  );
};

export default Cart;
