import { useQuery, useReactiveVar } from '@apollo/client';
import { myPagesClient } from 'apollo/client';
import { GET_COUNTRIES } from 'apollo/myDesti/queries';
import { GET_USER_BY_PK } from 'apollo/myPages/queries';
import BlurredModal from 'components/BlurredModal';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { Paths } from 'constants/paths';
import { FormState } from 'final-form';
import useIntl from 'hooks/useIntl';
import useToken from 'hooks/useToken';
import { FunctionalComponent } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import LoadLeadTravelerTooltip from 'screens/Cart/components/LoadLeadTravelerTooltip';
import styled from 'styled-components';
import { colorsSpec } from 'styles/defaultTheme';
import { CartItem, Traveler, TravelerTypes } from 'types/cache/Cart';
import { ICountry } from 'types/cache/Country';
import { ITravelPlan } from 'types/cache/TravelPlan';
import { marketplaceVar } from './cache';
import GetGestModalHeader, {
  GetGestLogo,
} from './components/GetGestModalHeader';
import MarketplacePlanItem from './components/MarketplacePlanItem';
import { MarketplaceActionTypes, useMarketplace } from './useMarketplace';

const MarketPlaceWrapper = styled.div`
  background-color: #252947;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Footer = styled.div`
  width: 800px;
  height: 90px;
  border-top: 2px solid ${({ theme }) => theme.colors[colorsSpec.primary]};

  div {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    button {
      margin-right: 10px;
    }
  }
`;

const TextContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: justify;
`;

const ListContainer = styled.div`
  width: 100%;
  height: ${({ showText }) => (showText ? '600px' : 'auto')};
  overflow-y: auto;
`;

const StyledBlurredModal = styled(BlurredModal)`
  height: 140%;
  z-index: 10;
  background-color: #252947;
`;

type SelectedPlan = {
  travelPlanName: string;
  itemId: number;
};

const Marketplace: FunctionalComponent = () => {
  const { t } = useIntl('app.Marketplace');
  const history = useHistory();
  const { token } = useToken();

  const { setMarketplaceItem } = useMarketplace(marketplaceVar);

  const [showInstructionsText, setShowInstructionsText] =
    useState<boolean>(true);
  const [selectedPlanAndItem, setSelectedPlanAndItem] =
    useState<SelectedPlan | null>(null);
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [isTravelPlanPaid, setIsTravelPlanPaid] = useState<boolean>(false);
  const [isFillTooltipOpen, setIsFillTooltipOpen] =
    useState<boolean>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { travelPlans = [] } = useReactiveVar(marketplaceVar);

  const toggleInstructionsText = (isShown: boolean): void => {
    setShowInstructionsText(isShown);
    setIsTravelPlanPaid(false);
  };

  const { data: { countries = [] } = {} } = useQuery<{ countries: ICountry[] }>(
    GET_COUNTRIES
  );

  const { data: { user = {} } = {} } = useQuery(GET_USER_BY_PK, {
    variables: { id: token },
    fetchPolicy: 'no-cache',
    client: myPagesClient,
  });

  const prefillInitialLeadTravelerDetails = useCallback(() => {
    const travelPlan: ITravelPlan = travelPlans.find(
      i => i.travelPlanName === selectedPlanAndItem.travelPlanName
    );
    const {
      travelPlanName,
      travelItineraryId,
      travelPlanItems,
      travelPeriod,
      id,
    } = travelPlan;

    setMarketplaceItem(
      {
        id,
        travelPeriod,
        travelPlanName,
        travelItineraryId,
        travelPlanItems: travelPlanItems.map((planItem: CartItem) => {
          if (planItem.id === selectedPlanAndItem.itemId) {
            return {
              ...planItem,
              leadTraveler: {
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                gender:
                  user.gender.charAt(0).toUpperCase() + user.gender.slice(1),
                dateOfBirth: user.date_of_birth,
                country:
                  user && countries.length
                    ? countries.find(c => c.id === user.country_id).name
                    : '',
                countryIsoCode2:
                  user && countries.length
                    ? countries.find(c => c.id === user.country_id).country_code
                    : '',
                nationality:
                  user && countries.length
                    ? countries.find(c => c.id === user.country_id).name
                    : '',
                type: TravelerTypes.Lead,
                title: '',
              },
            };
          }
          return planItem;
        }),
      },
      MarketplaceActionTypes.Add
    );
  }, [
    countries,
    selectedPlanAndItem?.itemId,
    selectedPlanAndItem?.travelPlanName,
    setMarketplaceItem,
    travelPlans,
    user,
  ]);

  const onLoadLeadUser = (): void => {
    if (isFillTooltipOpen === undefined && token) {
      setIsFillTooltipOpen(true);
    }
  };

  const isTravelerInfoEmpty = (traveler: Traveler): boolean => {
    return !Object.keys(traveler)
      .filter((key: string) => key !== 'type')
      .some(x => traveler[x] !== null && traveler[x] !== '');
  };

  const loadLeadUser = (
    props: FormState<Record<string, any>, Partial<Record<string, any>>>
  ) => {
    if (!props.active) {
      return;
    }
    const isLeadUserInfoEmpty = isTravelerInfoEmpty(
      travelPlans
        .find(
          plan => plan.travelPlanName === selectedPlanAndItem.travelPlanName
        )
        .travelPlanItems.find(i => i.id === selectedPlanAndItem.itemId)
        .travelerList[0]
    );

    if (isLeadUserInfoEmpty) {
      onLoadLeadUser();
    }
  };

  const showText: boolean = showInstructionsText || isTravelPlanPaid;

  return (
    <MarketPlaceWrapper>
      <GetGestModalHeader />
      <Wrapper>
        {isLoading && (
          <StyledBlurredModal>
            <GetGestLogo large />
            <Loading />
          </StyledBlurredModal>
        )}
        <ListContainer showText={showText}>
          <LoadLeadTravelerTooltip
            title={t('loadLeadTravelerTitle')}
            onConfirm={() => {
              prefillInitialLeadTravelerDetails();
              setIsFillTooltipOpen(false);
              window.scrollTo(0, 0);
            }}
            onCancel={() => {
              setIsFillTooltipOpen(false);
              window.scrollTo(0, 0);
            }}
            isOpen={isFillTooltipOpen}
            tooltipPoweredBy={t('tooltipPoweredBy')}
          />
          {travelPlans.map((plan: ITravelPlan) => (
            <MarketplacePlanItem
              user={user}
              plan={plan}
              setMarketplaceItem={setMarketplaceItem}
              toggleInstructionsText={toggleInstructionsText}
              setIsPaid={setIsPaid}
              setIsTravelPlanPaid={setIsTravelPlanPaid}
              setSelectedPlanAndItem={setSelectedPlanAndItem}
              selectedPlanAndItem={selectedPlanAndItem}
              loadLeadUser={loadLeadUser}
              setIsLoading={setIsLoading}
              setIsFillTooltipOpen={setIsFillTooltipOpen}
            />
          ))}
        </ListContainer>
        {showText && (
          <TextContainer>
            <Text>
              {t(isTravelPlanPaid ? 'travelPlanPaidText' : 'instructionsText')}
            </Text>
          </TextContainer>
        )}
      </Wrapper>

      <Footer>
        {isPaid && (
          <div>
            <Button
              secondary
              title={t('myPagesButton')}
              onClick={() =>
                history.push({
                  pathname: Paths.TravelPlan,
                  state: { activeTab: 'app.Profile.travelPlanTab' },
                })
              }
            />
            <Button
              secondary
              title={t('exploreMoreButton')}
              onClick={() => history.push(Paths.TravelLocationAndPeriod)}
            />
          </div>
        )}
      </Footer>
    </MarketPlaceWrapper>
  );
};

export default Marketplace;
