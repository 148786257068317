import { makeVar } from '@apollo/client';
import { TransportDetails } from 'types/cache/TransportDetails';

export const transportDetails: TransportDetails = {
  locationFrom: null,
  locationTo: null,
  stationFrom: null,
  stationTo: null,
  categoriesList: [],
  transportList: [],
  selectedTransportType: undefined,
  roundTrip: true,
  options: {},
  passengers: [],
  travelClass: null,
  travelClassMix: false,
  cabinBagCount: 0,
  checkedBagCount: 0,
  passengerCategories: {},
};
export const transportDetailsVar = makeVar<TransportDetails>(transportDetails);
