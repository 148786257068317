import { ReactiveVar } from '@apollo/client';
import { IRating } from 'types/cache/Rating';
import { StayList } from 'types/cache/StayList';

export function useStayList (stayListVar: ReactiveVar<StayList>) {
  const setOrderBy = (orderBy: any) => {
    const oldValue = stayListVar();
    const updated = { ...oldValue, orderBy };
    stayListVar(updated);
  };

  const setCity = (city: string) => {
    const oldValue = stayListVar();
    const updated = { ...oldValue, city };
    stayListVar(updated);
  };

  const setRatings = (stayRatings: { [id: string]: IRating }) => {
    const oldValue = stayListVar();
    const updated = { ...oldValue, stayRatings };
    stayListVar(updated);
  };

  return {
    setOrderBy,
    setCity,
    setRatings
  };
}
