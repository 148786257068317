import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import { transparency } from 'styles/defaultTheme';

const Container = styled.div`
  background-color: ${transparency.black80};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 102;
`;

const InnerContainer = styled.div`
  max-width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
`;

export const NotificationLine = styled.hr`
  height: 6px;
  width: 100%;
  background: ${({ theme }) => theme.colors[colorsSpec.white]};
`;

export const NotificationH3 = styled.h3`
  color: ${({ theme }) => theme.colors[colorsSpec.white]};
  font-size: 24px !important;
  margin: 5px 0 !important;
`;

export const NotificationTitle = styled.p`
  color: ${({ theme }) => theme.colors[colorsSpec.white]};
  margin: 10px 0 !important;
`;

export const NotificationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
`;

const Notification: FunctionalComponent = ({ children }) => (
  <Container>
    <InnerContainer>{children}</InnerContainer>
  </Container>
);

export default Notification;
