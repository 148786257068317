import { useQuery } from '@apollo/client';
import { GET_COUNTRIES } from 'apollo/myDesti/queries';
import {
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  UPDATE_USER,
} from 'apollo/myPages/mutations';
import { GET_GENDER, GET_USER_BY_PK } from 'apollo/myPages/queries';
import { useMyPagesMutation, useMyPagesQuery } from 'apollo/myPages/useMyPages';
import EditIcon from 'assets/icons/edit.svg';
import CloseIcon from 'assets/icons/x-button.svg';
import CalendarInput from 'components/CalendarInput';
import {
  MessageType,
  SupportMailInfoDisclaimer,
} from 'components/InfoDisclaimer';
import Input from 'components/Input';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import SelectComponent from 'components/Select/';
import Tabs, { mapActiveTab } from 'components/Tabs';
import { DATE_FORMAT, MIN_DATE, TABS_HEIGHT } from 'config/inputs';
import { Paths } from 'constants/paths';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import useUserValues from 'hooks/useUserInfo';
import storage from 'local-storage-fallback';
import { Fragment } from 'preact';
import { useState } from 'preact/hooks';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { NotificationScreen } from 'screens/NotificationScreen';
import useNotification from 'screens/NotificationScreen/useNotification';
import {
  useChangeEmailConstraints,
  usePasswordConstraints,
  useUserProfileConstraints,
} from 'shared/constraints';
import { ICountry } from 'types/cache/Country';
import { IUser } from 'types/cache/User';
import { NOTIFICATION_TYPES } from 'types/shared/Notification';
import validateForm from 'utils/form/validateForm';
import {
  CancelButton,
  Container,
  InputColumnLeft,
  InputColumnRight,
  LogoutButton,
  LogoutContainer,
  LogoutRow,
  ProfileInputRow,
  ProfileTabsContainer,
  RegisterButton,
  SupportMailInfoContainer,
} from '../components';

const RESET_FORM_TIMEOUT = 2000;

const Profile = ({ tabs = [], location }) => {
  const { t } = useIntl('app.Profile');
  const { t: controls } = useIntl('app.Controls');
  const { activeTab } = location.state;

  const history = useHistory();
  const isThisPageVisited = IsPageVisited('Profile');

  const [isUserEdit, setIsUserEdit] = useState<boolean>(false);
  const [isPasswordEdit, setIsPasswordEdit] = useState<boolean>(false);
  const [isEmailEdit, setIsEmailEdit] = useState<boolean>(false);
  const { notification, setNotification } = useNotification();

  const { data: { countries = [] } = {} } = useQuery<{ countries: ICountry[] }>(
    GET_COUNTRIES
  );
  const { data: { user_gender = [] } = {} } = useMyPagesQuery(GET_GENDER);
  const { data: { user = null } = {} } = useMyPagesQuery<{ user: IUser }>(
    GET_USER_BY_PK,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ user }) => saveUserInfo(user),
    }
  );

  const [updateUser, { loading: isUpdatingUser }] = useMyPagesMutation<{
    update_user_by_pk: IUser;
  }>(UPDATE_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ update_user_by_pk: user }) => saveUserInfo(user),
  });

  const [changePassword, { loading: isUpdatingPassword }] = useMyPagesMutation(
    CHANGE_PASSWORD
  );
  const [changeEmail, { loading: isEmailChangeLoading }] = useMyPagesMutation(
    CHANGE_EMAIL
  );

  const { userInfo, saveUserInfo } = useUserValues(user, countries);
  const { constraints: userConstraints } = useUserProfileConstraints();
  const { constraints: passwordConstraints } = usePasswordConstraints();
  const { constraints: changeEmailConstraints } = useChangeEmailConstraints();

  const resetFormInitialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const passwordInitialValue = {
    password: '******',
  };

  const onUserFormSubmit = async (data: any) => {
    setIsUserEdit(isUserEdit => !isUserEdit);
    const { email, firstName, lastName, country, gender, dateOfBirth } = data;
    const getCountryId = () =>
      country.value
        ? countries.find(c => c.name === country.value).id
        : countries.find(c => c.name === country).id;

    const getGender = () => (gender.value ? gender.value : gender);

    if (isUserEdit) {
      updateUser({
        variables: {
          id: user.id,
          email,
          firstName,
          lastName,
          countryId: getCountryId(),
          gender: getGender(),
          dateOfBirth: new Date(dateOfBirth),
        },
      }).catch(error => ({ message: error.message }));
    }
  };

  const onPasswordFormSubmit = async values => {
    const response = await changePassword({
      variables: {
        username: user.email,
        ...values,
      },
    })
      .then(() => {
        setNotification(NOTIFICATION_TYPES.PASSWORD_CHANGED);
        setTimeout(() => setIsPasswordEdit(false), RESET_FORM_TIMEOUT);
      })
      .catch(error => ({ message: error.message }));

    if (response && response.message) {
      return { oldPassword: response.message };
    }
  };

  const onChangeEmailFormSubmit = async (values, form) => {
    const response = await changeEmail({
      variables: values,
    })
      .then(() => {
        setNotification(NOTIFICATION_TYPES.EMAIL_CHANGED);

        setTimeout(() => {
          setIsEmailEdit(false);
          form.reset();
        }, RESET_FORM_TIMEOUT);
      })
      .catch(error => ({ message: error.message }));

    if (response && response.message) {
      return { oldEmail: response.message };
    }
  };

  const renderUserForm = ({
    handleSubmit,
    submitting,
    pristine,
    hasValidationErrors,
    form,
  }) => (
    <form onSubmit={handleSubmit}>
      <ProfileInputRow>
        <InputColumnLeft>
          <Field
            label={controls('firstNamePlaceholder')}
            name="firstName"
            id="firstName"
            required
            isDisabled={!isUserEdit}
            component={Input}
            isErrorVisible
          />
        </InputColumnLeft>

        <InputColumnRight>
          <Field
            label={controls('lastNamePlaceholder')}
            name="lastName"
            id="lastName"
            required
            isDisabled={!isUserEdit}
            component={Input}
            isErrorVisible
          />
        </InputColumnRight>
      </ProfileInputRow>

      <ProfileInputRow>
        <InputColumnLeft>
          <Field
            label={controls('emailPlaceholder')}
            name="email"
            id="email"
            isDisabled
            required
            component={Input}
            isErrorVisible
          />
        </InputColumnLeft>

        <InputColumnRight>
          <Field id="country" name="country" required>
            {({ meta, input }) => (
              <SelectComponent
                id="country"
                name="country"
                meta={meta}
                input={input}
                options={countries.map(country => ({
                  value: country.name,
                  label: country.name,
                }))}
                label={controls('countryPlaceholder')}
                isDisabled={!isUserEdit}
                isSearchable
              />
            )}
          </Field>
        </InputColumnRight>
      </ProfileInputRow>

      <ProfileInputRow>
        <InputColumnLeft>
          <Field id="gender" name="gender" required>
            {({ meta, input }) => (
              <SelectComponent
                meta={meta}
                input={input}
                options={user_gender.map(gender => ({
                  value: gender.value,
                  label: t(gender.value),
                }))}
                label={controls('gender')}
                isDisabled={!isUserEdit}
              />
            )}
          </Field>
        </InputColumnLeft>

        <InputColumnRight>
          <Field
            label={controls('dateOfBirthPlaceholder')}
            name="dateOfBirth"
            id="dateOfBirth"
            dateFormat={DATE_FORMAT}
            isDisabled={!isUserEdit}
            component={CalendarInput}
            minDate={MIN_DATE}
            maxDate={new Date(dayjs().subtract(18, 'year').toDate())}
            required
            isErrorVisible
          />
        </InputColumnRight>
      </ProfileInputRow>

      <ProfileInputRow>
        <InputColumnLeft>
          <RegisterButton
            icon={EditIcon}
            title={isUserEdit ? t('saveDetails') : t('changeDetails')}
            secondary
            disabled={
              isUserEdit
                ? submitting ||
                  hasValidationErrors ||
                  pristine ||
                  isUpdatingUser
                : false
            }
          />
        </InputColumnLeft>

        <InputColumnRight>
          {isUserEdit && (
            <CancelButton
              secondary
              onClick={() => {
                form.reset();
                setIsUserEdit(false);
              }}
              icon={CloseIcon}
              title={t('cancelEditing')}
            />
          )}
        </InputColumnRight>
      </ProfileInputRow>
    </form>
  );

  const renderPasswordForm = ({
    handleSubmit,
    submitting,
    pristine,
    hasValidationErrors,
  }) => {
    return (
      <form onSubmit={handleSubmit} noValidate>
        <Fragment>
          <ProfileInputRow>
            <InputColumnLeft>
              <Field
                label={controls('oldPassword')}
                type="password"
                name="oldPassword"
                required
                component={Input}
              />
            </InputColumnLeft>
          </ProfileInputRow>
          <ProfileInputRow>
            <InputColumnLeft>
              <Field
                label={controls('newPassword')}
                type="password"
                name="newPassword"
                required
                component={Input}
              />
            </InputColumnLeft>
          </ProfileInputRow>

          <ProfileInputRow>
            <InputColumnLeft>
              <Field
                label={controls('confirmPassword')}
                type="password"
                name="confirmPassword"
                required
                component={Input}
              />
            </InputColumnLeft>
          </ProfileInputRow>
        </Fragment>

        <ProfileInputRow>
          <InputColumnLeft>
            <RegisterButton
              icon={EditIcon}
              title={t('savePassword')}
              disabled={
                pristine ||
                submitting ||
                hasValidationErrors ||
                isUpdatingPassword
              }
            />
          </InputColumnLeft>

          <InputColumnLeft>
            <CancelButton
              secondary
              onClick={() => setIsPasswordEdit(false)}
              icon={CloseIcon}
              title={t('cancelEditing')}
            />
          </InputColumnLeft>
        </ProfileInputRow>
      </form>
    );
  };

  const renderPasswordField = ({ handleSubmit }) => (
    <form onSubmit={handleSubmit}>
      <ProfileInputRow>
        <InputColumnLeft>
          <Field
            initialValue={passwordInitialValue.password}
            label={controls('passwordPlaceholder')}
            name="password"
            isDisabled
            component={Input}
          />
        </InputColumnLeft>
      </ProfileInputRow>

      <ProfileInputRow>
        <InputColumnLeft>
          <RegisterButton
            secondary
            onClick={e => {
              e.preventDefault();
              setIsPasswordEdit(true);
            }}
            icon={EditIcon}
            title={t('changePassword')}
          />
        </InputColumnLeft>
      </ProfileInputRow>
    </form>
  );

  const renderEmailChangeForm = ({
    handleSubmit,
    pristine,
    submitting,
    hasValidationErrors,
  }) => (
    <form onSubmit={handleSubmit}>
      {isEmailEdit ? (
        <Fragment>
          <ProfileInputRow>
            <InputColumnLeft>
              <Field
                label={t('insertOldEmail')}
                name="oldEmail"
                component={Input}
              />
            </InputColumnLeft>
          </ProfileInputRow>

          <ProfileInputRow>
            <InputColumnLeft>
              <Field
                label={t('insertNewEmail')}
                name="newEmail"
                component={Input}
              />
            </InputColumnLeft>
          </ProfileInputRow>

          <ProfileInputRow>
            <InputColumnLeft>
              <Field
                label={t('password')}
                type="password"
                name="password"
                required
                component={Input}
              />
            </InputColumnLeft>
          </ProfileInputRow>

          <ProfileInputRow>
            <InputColumnLeft>
              <RegisterButton
                secondary
                disabled={
                  pristine ||
                  submitting ||
                  hasValidationErrors ||
                  isEmailChangeLoading
                }
                icon={EditIcon}
                title={t('changeEmail')}
              />
            </InputColumnLeft>

            <InputColumnRight>
              <CancelButton
                secondary
                onClick={() => setIsEmailEdit(false)}
                icon={CloseIcon}
                title={t('cancelEditing')}
              />
            </InputColumnRight>
          </ProfileInputRow>
        </Fragment>
      ) : (
        <Fragment>
          <ProfileInputRow>
            <InputColumnLeft>
              <RegisterButton
                secondary
                onClick={() => setIsEmailEdit(true)}
                icon={EditIcon}
                title={t('changeEmail')}
              />
            </InputColumnLeft>
          </ProfileInputRow>
        </Fragment>
      )}
    </form>
  );

  return (
    <Fragment>
      <ModalHeader title={t('personalTab')} />

      <Container>
        <NotificationScreen type={notification} />

        <ProfileTabsContainer>
          {tabs.length && (
            <Tabs
              config={mapActiveTab(tabs, activeTab)}
              height={TABS_HEIGHT}
              isSecondary
            />
          )}
        </ProfileTabsContainer>

        <Form
          onSubmit={values => onUserFormSubmit(values)}
          initialValues={userInfo}
          validate={values => validateForm(values, userConstraints)}
          render={renderUserForm}
        />

        <Form
          onSubmit={(values, form) => onChangeEmailFormSubmit(values, form)}
          validate={values => validateForm(values, changeEmailConstraints)}
          render={renderEmailChangeForm}
        />

        {isPasswordEdit ? (
          <Form
            onSubmit={values => onPasswordFormSubmit(values)}
            initialValues={resetFormInitialValues}
            validate={values => validateForm(values, passwordConstraints)}
            render={renderPasswordForm}
          />
        ) : (
          <Form
            onSubmit={() => null}
            initialValues={passwordInitialValue}
            render={renderPasswordField}
          />
        )}

        <LogoutRow isPasswordEdit={isPasswordEdit}>
          <LogoutContainer isPasswordEdit={isPasswordEdit}>
            <LogoutButton
              onClick={() => {
                storage.removeItem('DESTI1:TOKEN');
                history.replace({
                  pathname: 'login',
                  state: { activeTab: t('login') },
                });
              }}
              title={t('logout')}
              secondary
            />
          </LogoutContainer>
        </LogoutRow>
      </Container>
      <SupportMailInfoContainer>
        <SupportMailInfoDisclaimer
          text={t('supportEmailInfo')}
          icon="assets/icons/info-bubble.svg"
          type={MessageType.Neutral}
          email={process.env.DESTI_ONE_SUPPORT_EMAIL}
        />
      </SupportMailInfoContainer>
      <ModalFooter
        buttonTitle={t('goDestiOneTitle')}
        onButtonClick={() => {
          history.push(Paths.TravelLocationAndPeriod);
        }}
        mapIconProps={{ disabled: true }}
        chatIconProps={{ disabled: true }}
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default Profile;
