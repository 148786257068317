import ArrowDownIcon from 'assets/icons/arrow-down.svg';
import dayjs from 'dayjs';
import { FunctionComponent } from 'preact';
import { useCallback } from 'preact/hooks';
import ReactCalendar from 'react-calendar';
import styled from 'styled-components';
import useActiveStartDate from './useActiveStartDate';

const StyledCalendar = styled(ReactCalendar)`
  background-color: white;
  border-radius: 8px;
  max-width: 400px;
  padding: 23px 20px 20px 20px;
  width: 100%;

  .react-calendar__navigation__label {
    background-color: transparent;
    border: none;
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 10px;
  }

  .react-calendar__month-view__days {
    min-height: 181px;
  }

  .react-calendar__month-view__weekdays__weekday {
    text-align: center;

    abbr {
      font-size: 11px;
      font-weight: 700;
      line-height: 15px;
      text-decoration: none;
    }
  }

  .react-calendar__tile {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    height: 34px;
    justify-content: center;
    margin: 3px 0;
    outline: none;
    padding: 0;
    width: 34px;

    &:disabled {
      cursor: default;
    }

    abbr {
      align-items: center;
      color: #342244;
      display: inline-flex;
      font-size: 14px;
      height: 34px;
      justify-content: center;
      line-height: 19px;
      width: 34px;
    }
  }

  .react-calendar__tile--now {
    font-weight: 800;
  }

  // TILES DURING SELECTION (AFTER CHOOSING FIRST DATE AND BEFORE CHOOSING SECOND DATE)
  &.react-calendar--selectRange {
    .react-calendar__tile--hover {
      background: #3422441a;

      :first-child {
        background: transparent
          linear-gradient(to left, #3422441a, rgba(52, 34, 68, 0.01));
      }

      :last-child {
        background: transparent
          linear-gradient(to right, #3422441a, rgba(52, 34, 68, 0.01));
      }
    }

    .react-calendar__month-view__days__day--weekend {
      // LAST DAY OF WEEK
      + .react-calendar__month-view__days__day--weekend {
        &.react-calendar__tile--hover,
        &.react-calendar__tile--active {
          background: transparent
            linear-gradient(to right, #3422441a, rgba(52, 34, 68, 0.01));
        }

        &.react-calendar__tile--rangeBothEnds {
          background: transparent;
        }

        &.react-calendar__tile--hoverStart {
          background: transparent
            linear-gradient(
              to right,
              transparent 0%,
              transparent 50%,
              #3422441a 50%,
              rgba(52, 34, 68, 0.01) 100%
            );
        }

        &.react-calendar__tile--hoverEnd {
          background: transparent
            linear-gradient(
              to left,
              transparent 0%,
              transparent 50%,
              #3422441a 50%,
              #3422441a 100%
            );
        }

        &.react-calendar__tile--hoverBothEnds {
          background: transparent;
        }
      }

      // FIRST DAY OF WEEK
      + :not(.react-calendar__month-view__days__day--weekend) {
        &.react-calendar__tile--hover,
        &.react-calendar__tile--active {
          background: transparent
            linear-gradient(to left, #3422441a, rgba(52, 34, 68, 0.01));

          &:last-child {
            background: transparent
              linear-gradient(
                to right,
                rgba(52, 34, 68, 0.01) 0%,
                #3422441a 50%,
                rgba(52, 34, 68, 0.01) 100%
              );
          }
        }

        &.react-calendar__tile--hoverStart {
          background: transparent
            linear-gradient(
              to right,
              transparent 0%,
              transparent 50%,
              #3422441a 50%,
              #3422441a 100%
            );
        }

        &.react-calendar__tile--hoverEnd {
          background: transparent
            linear-gradient(
              to left,
              transparent 0%,
              transparent 50%,
              #3422441a 50%,
              rgba(52, 34, 68, 0.01) 100%
            );
        }
      }
    }

    .react-calendar__tile--hoverBothEnds {
      background: transparent;
    }

    .react-calendar__tile--hoverStart:not(.react-calendar__tile--hoverBothEnds) {
      background: transparent
        linear-gradient(
          to right,
          transparent 0%,
          transparent 50%,
          #3422441a 50%,
          #3422441a 100%
        );
    }

    .react-calendar__tile--hoverEnd:not(.react-calendar__tile--hoverBothEnds) {
      background: transparent
        linear-gradient(
          to left,
          transparent 0%,
          transparent 50%,
          #3422441a 50%,
          #3422441a 100%
        );
    }
  }

  .react-calendar__month-view__days__day--weekend {
    // LAST DAY OF WEEK
    + .react-calendar__month-view__days__day--weekend {
      &.react-calendar__tile--active {
        background: transparent
          linear-gradient(to right, #3422441a, rgba(52, 34, 68, 0.01));

        &:first-child {
          background: transparent
            linear-gradient(
              to right,
              rgba(52, 34, 68, 0.01) 0%,
              #3422441a 50%,
              rgba(52, 34, 68, 0.01) 100%
            );
        }
      }

      &.react-calendar__tile--rangeStart {
        background: transparent
          linear-gradient(
            to right,
            transparent 0%,
            transparent 50%,
            #3422441a 50%,
            rgba(52, 34, 68, 0.01) 100%
          );
      }

      &.react-calendar__tile--rangeEnd {
        background: transparent
          linear-gradient(
            to left,
            transparent 0%,
            transparent 50%,
            #3422441a 50%,
            #3422441a 100%
          );
      }
    }

    // FIRST DAY OF WEEK
    + :not(.react-calendar__month-view__days__day--weekend) {
      &.react-calendar__tile--active {
        background: transparent
          linear-gradient(to left, #3422441a, rgba(52, 34, 68, 0.01));
      }

      &.react-calendar__tile--rangeStart {
        background: transparent
          linear-gradient(
            to right,
            transparent 0%,
            transparent 50%,
            #3422441a 50%,
            #3422441a 100%
          );
      }

      &.react-calendar__tile--rangeEnd {
        background: transparent
          linear-gradient(
            to left,
            transparent 0%,
            transparent 50%,
            #3422441a 50%,
            rgba(52, 34, 68, 0.01) 100%
          );
      }
    }
  }

  & :not(.react-calendar__tile--rangeBothEnds) {
    &.react-calendar__tile--rangeStart {
      background: transparent
        linear-gradient(
          to right,
          transparent 0%,
          transparent 50%,
          #3422441a 50%,
          #3422441a 100%
        );
    }

    &.react-calendar__tile--rangeEnd {
      background: transparent
        linear-gradient(
          to left,
          transparent 0%,
          transparent 50%,
          #3422441a 50%,
          #3422441a 100%
        );
    }
  }

  .react-calendar__tile--rangeStart abbr,
  .react-calendar__tile--rangeEnd abbr,
  .react-calendar__tile--hoverStart abbr,
  .react-calendar__tile--hoverEnd abbr,
  .react-calendar__tile:hover abbr {
    background: #342244;
    border-radius: 50%;
    color: white;
    font-weight: 700;
    height: 34px;
    width: 34px;
  }

  .react-calendar__tile:disabled abbr {
    background: transparent;
    color: #c4c4c4;
    font-weight: 400;
  }

  .react-calendar__tile--active {
    background: #3422441a;

    :first-child {
      background: transparent
        linear-gradient(to left, #3422441a, rgba(52, 34, 68, 0.01));
    }
  }

  .react-calendar__tile--rangeBothEnds {
    background: transparent;
  }

  .react-calendar__tile--active:first-child {
    background: transparent
      linear-gradient(to left, #3422441a, rgba(52, 34, 68, 0.01));

    &.react-calendar__tile--rangeStart {
      background: transparent
        linear-gradient(
          to right,
          transparent 0%,
          transparent 50%,
          #3422441a 50%,
          #3422441a 100%
        );
    }

    &.react-calendar__tile--rangeEnd {
      background: transparent
        linear-gradient(
          to left,
          transparent 0%,
          transparent 50%,
          #3422441a 50%,
          rgba(52, 34, 68, 0.01) 100%
        );
    }
  }

  .react-calendar__tile--active:last-child {
    background: transparent
      linear-gradient(to right, #3422441a, rgba(52, 34, 68, 0.01));

    &.react-calendar__tile--rangeStart {
      background: transparent
        linear-gradient(
          to right,
          transparent 0%,
          transparent 50%,
          #3422441a 50%,
          rgba(52, 34, 68, 0.01) 100%
        );
    }

    &.react-calendar__tile--rangeEnd {
      background: transparent
        linear-gradient(
          to left,
          transparent 0%,
          transparent 50%,
          #3422441a 50%,
          #3422441a 100%
        );
    }
  }
`;

const StyledSelect = styled.select`
  appearance: none;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  line-height: 22px;
  margin-bottom: 22px;
  overflow: visible;
  padding-right: 14px;

  background-image: url(${ArrowDownIcon});
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
`;

const months = [];
const currentMonth = new Date().getMonth();
for (let month = 0; month < 12; month++) {
  months.push(month);
}

const years = [];
const currentYear = new Date().getFullYear();
for (let year = currentYear; year < currentYear + 5; year++) {
  years.push(year);
}

const CalendarNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NavLabel = ({ date, setMonth, setYear }) => {
  return (
    <CalendarNavigation>
      <StyledSelect
        onChange={setMonth}
        value={
          date.month < currentMonth && date.year === currentYear
            ? currentMonth
            : date.month
        }
      >
        {months.map(month => (
          <option
            value={month}
            disabled={month < currentMonth && date.year === currentYear}
          >
            {dayjs().month(month).format('MMMM')}
          </option>
        ))}
      </StyledSelect>
      <StyledSelect
        onChange={setYear}
        value={date.year}
      >
        {years.map(year => (
          <option value={year}>{year}</option>
        ))}
      </StyledSelect>
    </CalendarNavigation>
  );
};

type Props = {
  input?: any; // TODO FieldInputProps<string, any>;
  maxDate?: Date;
  minDate?: Date;
  selectRange?: boolean;
  tileDisabled?: any;
};

const Calendar: FunctionComponent<Props> = ({
  input = {},
  maxDate = null,
  minDate = new Date(),
  selectRange = false,
  tileDisabled = () => {},
}) => {
  const { activeStartDate, setActiveStartDate } = useActiveStartDate(
    input.value,
    minDate
  );

  const handleMonthChange = useCallback(
    e =>
      setActiveStartDate({
        ...activeStartDate,
        month: parseInt(e.target.value, 10),
      }),
    [activeStartDate, setActiveStartDate]
  );

  const handleYearChange = useCallback(
    e =>
      setActiveStartDate({
        month:
          parseInt(e.target.value, 10) === currentYear &&
          activeStartDate.month < currentMonth
            ? currentMonth
            : activeStartDate.month,
        year: parseInt(e.target.value, 10),
      }),
    [activeStartDate, setActiveStartDate]
  );

  return (
    <StyledCalendar
      activeStartDate={new Date(activeStartDate.year, activeStartDate.month, 1)}
      allowPartialRange
      maxDate={maxDate}
      minDate={minDate}
      prevLabel={null}
      nextLabel={null}
      prev2Label={null}
      next2Label={null}
      selectRange={selectRange}
      navigationLabel={() => (
        <NavLabel
          date={activeStartDate}
          setMonth={handleMonthChange}
          setYear={handleYearChange}
        />
      )}
      minDetail="month"
      tileDisabled={tileDisabled}
      showNeighboringMonth={false}
      {...input}
    />
  );
};

export default Calendar;
