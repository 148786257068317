import { useQuery, useReactiveVar } from '@apollo/client';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { CHECKOUT } from 'apollo/myBookings/mutations';
import { useBookingMutation } from 'apollo/myBookings/useBooking';
import { GET_COUNTRIES } from 'apollo/myDesti/queries';
import InsuranceIcon from 'assets/icons/insurance.svg';
import CloseIcon from 'assets/icons/x-button-black.svg';
import Checkbox from 'components/Checkbox';
import EnvironmentCompensation from 'components/EnvironmentCompensation';
import InformationBox from 'components/InformationBox';
import Input from 'components/Input';
import SelectComponent from 'components/Select';
import useIntl from 'hooks/useIntl';
import { Fragment, FunctionalComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { Field, Form } from 'react-final-form';
import { cartVar } from 'screens/Cart/cache';
import { CartActionTypes, useCart } from 'screens/Cart/useCart';
import { locationAndPeriodVar } from 'screens/LocationAndPeriod/cache';
import { useCheckoutConstraints } from 'shared/constraints';
import styled from 'styled-components';
import { colors, colorsSpec, fonts, fontSizes } from 'styles';
import { ICountry } from 'types/cache/Country';
import { CartTypes } from 'types/shared/Cart';
import { GenericPaymentOption } from 'types/shared/GenericConfig';
import validateForm from 'utils/form/validateForm';
import validate from 'validate.js';
import InfoIcon from '../../../assets/icons/info-black.svg';
import { InputColumnLeft, InputColumnRight } from '../components';
import { BillingTitle, Rect, Row } from './index';

// TODO: Remove when backend gets implemented
const COMPENSATION_OPTIONS: GenericPaymentOption[] = [
  { id: 1, label: '5 SEK', price: 5, isChecked: false },
  { id: 2, label: '10 SEK', price: 10, isChecked: false },
  { id: 3, label: '20 SEK', price: 20, isChecked: false },
  { id: 4, label: '30 SEK', price: 30, isChecked: false },
  { id: 5, label: '50 SEK', price: 50, isChecked: false },
];

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px auto;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const Details = styled.div`
  position: relative;
  padding-left: 24px;

  &:before {
    position: absolute;
    top: 12px;
    left: 4px;
    content: '';
    height: 95%;
    width: 1px;
    background: ${({ theme }) => theme.colors[colorsSpec.accent]};
  }

  .card-element {
    margin-top: 20px;
    border: 1px solid ${({ theme }) => theme.colors[colorsSpec.accent]};
    padding: 10px;
    border-radius: 4px;
    font-family: 'Open Sans', arial, sans-serif;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const EcoImpactRow = styled.div`
  margin-top: 15px;
`;

const FutureUseContainer = styled.div`
  margin-top: 10px;
  font-size: 12px;
`;

const cardStyle = {
  style: {
    base: {
      color: colors.primary,
      fontFamily: fonts.default,
      fontSize: fontSizes.default,
      '::placeholder': {
        color: colors.accent,
      },
    },
    invalid: {
      color: colors.accentDark,
      iconColor: colors.accentDark,
    },
  },
};

const productOwner = 'First Connect Test';

type Props = {
  onSuccessPayment: () => void;
  onErrorPayment: (error: StripeError | string) => void;
  onCardValidation: (valid: boolean) => void;
  onFormValidation: (valid: boolean) => void;
  onDonateChange?: (item: any) => void;
  onFormSubmit: () => void;
};

const CheckoutForm: FunctionalComponent<Props> = ({
  onSuccessPayment = () => 0,
  onErrorPayment = () => 0,
  onCardValidation = () => 0,
  onFormValidation = () => 0,
  onFormSubmit = () => 0,
}) => {
  const { t } = useIntl('app.Billing');
  const { t: controls } = useIntl('app.Controls');

  const [compensationOptions, setCompensationOptions] = useState<
    GenericPaymentOption[]
  >(COMPENSATION_OPTIONS);

  const { data: { countries = [] } = {} } = useQuery<{ countries: ICountry[] }>(
    GET_COUNTRIES
  );
  const [checkout] = useBookingMutation(CHECKOUT);
  const { cartItems = [], travelItineraryId } = useReactiveVar(cartVar);
  const { setCartItem, setTravelItineraryId } = useCart(cartVar);
  const { travelPeriod } = useReactiveVar(locationAndPeriodVar);
  const { constraints } = useCheckoutConstraints();

  const [isCardForFutureUse, setIsCardForFutureUse] = useState<boolean>(false);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setCompensationOptions(JSON.parse(JSON.stringify(COMPENSATION_OPTIONS)));
  }, []);

  // TODO: Send user info to backend
  const onSubmit = async ({ name /* country, street, city, cityCode */ }) => {
    onFormSubmit();

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name,
        // street,
        // city,
        // cityCode,
        // countryId: countries.find(c => c.name === country).id,
      },
    });

    result.error
      ? onErrorPayment(result.error)
      : stripePaymentMethodHandler(result);
  };

  const stripePaymentMethodHandler = async result => {
    if (result.error) {
      onErrorPayment(result.message);
      return;
    }

    // Get card token if its marked for future use
    let savePaymentMethod = false;
    if (isCardForFutureUse) {
      savePaymentMethod = true;
    }

    // TODO: Remove hardcoded values once agreed on travel plan logic
    checkout({
      variables: {
        paymentMethodId: result.paymentMethod.id,
        travelPlan: createTravelPlan(),
        productOwner,
        savePaymentMethod,
      },
    })
      .then(result => handleServerResponse(result.data.checkout))
      .catch(error => handleServerResponse(error));
  };

  const handleServerResponse = async response => {
    if (response.error || response.graphQLErrors?.length) {
      onErrorPayment(response.message);
      return;
    }

    if (response.requiresAdditionalAction) {
      const {
        error: errorAction,
        paymentIntent,
      } = await stripe.handleCardAction(response.clientSecret);

      if (errorAction) {
        onErrorPayment(`${t('paymentFailed')}: ${response.message}`);
        return;
      }

      checkout({
        variables: {
          paymentIntentId: paymentIntent.id,
          travelPlan: createTravelPlan(),
          savePaymentMethod: false,
          productOwner,
        },
      }).then(result => handleServerResponse(result));
    } else {
      onSuccessPayment();
      setTravelItineraryId(response.travelItineraryId);
    }
  };

  const onCardChange = async event =>
    onCardValidation(Boolean(!event.error && !event.empty && event.complete));

  const createTravelPlan = () => {
    return JSON.stringify({
      id: travelItineraryId,
      start_date: new Date(travelPeriod[0]),
      end_date: new Date(travelPeriod[1]),
      people: 2,
      children: 2,
      infants: 0,
      travelPlan: cartItems.map(cartItem => ({
        type: cartItem.type.toLocaleLowerCase(),
        item: { ...cartItem.item, product_owner_name: productOwner }, // TODO Remove
      })),
      compensationOptions: compensationOptions
        .filter(item => item.isChecked)
        .map(({ price, label }) => ({ price, label })),
    });
  };

  const renderForm = ({ handleSubmit }) => (
    <form id="checkoutForm" onSubmit={handleSubmit}>
      <InputRow>
        <InputColumnLeft>
          <Field
            placeholder={controls('namePlaceholder')}
            required
            component={Input}
            name="name"
            id="name"
            isErrorVisible={true}
          />
        </InputColumnLeft>

        <InputColumnRight>
          <Field
            placeholder={controls('streetPlaceholder')}
            required
            name="street"
            id="street"
            component={Input}
            isErrorVisible={true}
          />
        </InputColumnRight>
      </InputRow>

      <InputRow>
        <InputColumnLeft>
          <Field id="country" name="country" required>
            {({ meta, input }) => (
              <SelectComponent
                required
                id="country"
                name="country"
                meta={meta}
                input={input}
                options={countries.map(country => ({
                  value: country.name,
                  label: country.name,
                }))}
                placeholder={controls('countryPlaceholder')}
                isSearchable
              />
            )}
          </Field>
        </InputColumnLeft>

        <InputColumnRight>
          <SelectContainer>
            <InputColumnLeft>
              <Field
                placeholder={controls('cityPlaceholder')}
                required
                component={Input}
                name="city"
                id="city"
                isErrorVisible={true}
              />
            </InputColumnLeft>
            <InputColumnRight>
              <Field
                placeholder={controls('cityCodePlaceholder')}
                required
                component={Input}
                name="cityCode"
                id="cityCode"
                isErrorVisible={true}
              />
            </InputColumnRight>
          </SelectContainer>
        </InputColumnRight>
      </InputRow>
    </form>
  );

  return (
    <Fragment>
      <Row>
        <Details>
          <Rect />
          <BillingTitle>{t('billingDetails')}</BillingTitle>

          <Form
            onSubmit={onSubmit}
            validate={values => {
              const validateObj = validateForm(values, constraints);
              onFormValidation(validate.isEmpty(validateObj));
              return validateObj;
            }}
            render={renderForm}
          />
        </Details>
      </Row>

      <Row>
        <Details>
          <Rect />
          <BillingTitle>{t('paymentDetails')}</BillingTitle>
          <CardElement
            className="card-element"
            id="card-element"
            options={cardStyle}
            onChange={onCardChange}
          />
          <FutureUseContainer>
            <Checkbox
              id="saveCardForFutureUse"
              input={{
                checked: isCardForFutureUse,
                onChange: () =>
                  setIsCardForFutureUse(
                    (isCardForFutureUse: boolean) => !isCardForFutureUse
                  ),
              }}
              label={t('saveCardForFutureUse')}
            />
          </FutureUseContainer>

          <EcoImpactRow>
            <EnvironmentCompensation
              infoLabel={t('ecoInfoLabel')}
              iconLabel={t('ecoIconLabel')}
              compensationOptions={compensationOptions}
              onCompensationOptionClick={(itemId: number) =>
                compensationOptions.map((item: GenericPaymentOption) => {
                  if (item.id === itemId) {
                    item.isChecked = !item.isChecked;

                    setCartItem(
                      {
                        id: item.id,
                        type: CartTypes.ENVIRONMENT_COMPENSATION,
                        item: {
                          title: item.label,
                          price: item.price,
                        },
                      },
                      item.isChecked
                        ? CartActionTypes.AddEnvironmentCompensation
                        : CartActionTypes.RemoveEnvironmentCompensation
                    );
                  }
                  return item;
                })
              }
            />
          </EcoImpactRow>

          <EcoImpactRow>
            <InformationBox
              headingLabel={t('insuranceLabel')}
              headingIcon={InsuranceIcon}
              infoLabelStart={t('insuranceLabelStart')}
              infoLabelEnd={t('insuranceLabelEnd')}
              linkLabel={t('insuranceLinkLabel')}
              link="http://www.google.com"
            />
          </EcoImpactRow>

          <EcoImpactRow>
            <InformationBox
              headingLabel="Covid-19 Information"
              headingIcon={InfoIcon}
              infoLabelStart={controls('covid.startLabel')}
              infoLabelEnd={controls('covid.endLabel')}
              linkLabel="link"
              link="https://www.krisinformation.se/en/hazards-and-risks/disasters-and-incidents/2020/official-information-on-the-new-coronavirus/current-rules-and-recommendations"
              headingIconStyle={{ width: '18px', height: '18px' }}
              isCloseBtnVisible={false}
              closeIcon={CloseIcon}
            />
          </EcoImpactRow>
        </Details>
      </Row>
    </Fragment>
  );
};

export default CheckoutForm;
