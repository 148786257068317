import { useLazyQuery } from '@apollo/client';
import {
  GET_INTEGRATIONS_ACTIVITY_COUNT,
  GET_INTEGRATIONS_STAY_COUNT,
  GET_INTEGRATIONS_TRANSPORT_COUNT,
} from 'apollo/myDesti/queries';
import TransportImage from 'assets/images/airplane-transport 242x110.png';
import StayImage from 'assets/images/bedroom-stay 242x110.png';
import ActivityImage from 'assets/images/biker-activities 242x110.png';
import ThreeDotLoader from 'components/ThreeDotLoader';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import { useCallback, useEffect, useMemo } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { SelectedLocation } from 'types/cache/LocationAndPeriod';
import { CartTypes } from 'types/shared/Cart';
import { Paths } from '../../constants/paths';

const StyledFoundOptions = styled.div`
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  min-height: 153px;
  margin: auto -6px 0 -6px;
  padding-top: 10px;
`;

const Title = styled.h3`
  align-items: center;
  display: flex;
  font-size: 21px;
  line-height: 28px;
  flex: 0 1 62px;
  margin: 15px 0 20px 0px;
  margin-left: 6px !important;
  min-height: 28px;
  flex-basis: 100%;
  justify-content: flex-start;
`;

const Tile = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  height: 110px;
  min-width: 150px;
  margin: 15px 6px 0 6px;
  position: relative;
  pointer-events: auto;
  h3 {
    z-index: 10;
    position: absolute;
    user-select: none;
  }
`;

const TileCover = styled.span`
  background-color: ${({ theme }) => theme.colors.primaryDark};
  background: ${({ background }) => `url(${background})`};
  background-size: 100% 100%;
  bottom: 0;
  filter: brightness(0.49);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const TileText = styled.h3`
  color: ${({ theme, isLocationAndPeriodSelected }) =>
    isLocationAndPeriodSelected ? theme.colors.white : theme.colors.accent};
  font-size: 21px;
  line-height: 28px;
  margin-bottom: 10px !important;
  padding: 2px;
  text-transform: uppercase;
`;

const Message = styled.h3`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;

type Props = {
  selectedLocations: SelectedLocation[];
  travelPeriod: any[];
  isLocationAndPeriodSelected: boolean;
};

const MAX_COUNT = 1000;

const FoundOptions: FunctionalComponent<Props> = ({
  isLocationAndPeriodSelected,
  selectedLocations,
  travelPeriod,
}) => {
  const history = useHistory();
  const { t } = useIntl('app.LocationAndPeriod');

  const [
    loadStayCount,
    {
      loading: stayCountLoading,
      data: integrationsStayCount,
      error: stayCountError,
    },
  ] = useLazyQuery(GET_INTEGRATIONS_STAY_COUNT, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    loadTravelCount,
    {
      loading: transportCountLoading,
      data: integrationsTransportCount,
      error: transportCountError,
    },
  ] = useLazyQuery(GET_INTEGRATIONS_TRANSPORT_COUNT, {
    fetchPolicy: 'cache-and-network'
  });

  const [
    loadActivityCount,
    {
      loading: activityCountLoading,
      data: integrationsActivityCount,
      error: activityCountError,
    },
  ] = useLazyQuery(GET_INTEGRATIONS_ACTIVITY_COUNT, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (selectedLocations?.length && travelPeriod.length === 2) {
      const lastSelectedLocation = selectedLocations[selectedLocations.length - 1];

      const startDate = dayjs
        .utc(dayjs(travelPeriod[0]))
        .local()
        .format('YYYY-MM-DDTHH:mm:ssZ');
      const endDate = dayjs
        .utc(dayjs(travelPeriod[1]))
        .local()
        .format('YYYY-MM-DDTHH:mm:ssZ');

      const variables = {
        startDate,
        endDate,
      };

      loadStayCount({
        variables: {
          ...variables,
          city: lastSelectedLocation.name
        }
      });
      loadTravelCount({
        variables: {
          ...variables,
          cities: selectedLocations.sort((a, b) => a.name > b.name ? 1 : -1),
        },
      });
      loadActivityCount({
        variables: {
          ...variables,
          city: lastSelectedLocation.name,
          startDate: travelPeriod[0],
          endDate: travelPeriod[1],
        },
      });
    }
  }, [
    selectedLocations,
    travelPeriod,
    loadActivityCount,
    loadStayCount,
    loadTravelCount
  ]);

  const activitiesCount =
    integrationsActivityCount?.getIntegrationsActivityCount?.count;

  const stayCount = integrationsStayCount?.getIntegrationsStayCount?.count;

  const transportCount = integrationsTransportCount?.getIntegrationsTransportCount?.count;

  const categories = useMemo(() => {
    return [
      {
        type: CartTypes.ACTIVITY,
        title: t('activities'),
        isLoading: activityCountLoading,
        count: activitiesCount,
        image: ActivityImage,
      },
      {
        type: CartTypes.STAY,
        title: t('stay'),
        isLoading: stayCountLoading,
        count: stayCount,
        image: StayImage,
      },
      {
        type: CartTypes.TRANSPORT,
        title: t('transport'),
        isLoading: transportCountLoading,
        count: transportCount,
        image: TransportImage,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activitiesCount,
    activityCountLoading,
    transportCountLoading,
    stayCount,
    stayCountLoading,
    selectedLocations
  ]);

  const onCategoryClick = useCallback(
    (categoryType: CartTypes) => {
      if (!isLocationAndPeriodSelected) return;
      let path = {};
      switch (categoryType) {
        case CartTypes.ACTIVITY:
          path = {
            pathname: Paths.ActivitiesList,
            state: { activeTab: 'app.Controls.activitiesTab' }
          };
          break;
        case CartTypes.TRANSPORT:
          path = Paths.TransportDetails;
          break;
        case CartTypes.STAY:
          path = Paths.StayDetails;
          break;
      }
      history.push(path);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [history, isLocationAndPeriodSelected]
  );

  const error = transportCountError || activityCountError || stayCountError;

  return (
    <StyledFoundOptions>
      <Title>{t('foundOptionsTitle')}</Title>
      {error ? (
        <Message>{t('errorMessage')}</Message>
      ) : (
        categories.map(category => {
          return (
            <Tile
              id={category.title}
              onClick={() => onCategoryClick(category.type)}
              disabled={!isLocationAndPeriodSelected}
              style={{
                cursor: isLocationAndPeriodSelected ? 'pointer' : 'auto',
              }}
            >
              <TileCover background={category.image} />
              <TileText
                isLocationAndPeriodSelected={isLocationAndPeriodSelected}
              >
                {category.title}
              </TileText>
              <TileText
                isLocationAndPeriodSelected={isLocationAndPeriodSelected}
                style={{ marginTop: '40px' }}
              >
                {isLocationAndPeriodSelected ? (
                  category.isLoading ? (
                    <ThreeDotLoader />
                  ) : (
                    <>
                      {category.count} {category.count >= MAX_COUNT && '+'}
                    </>
                  )
                ) : null}
              </TileText>
            </Tile>
          );
        })
      )}
    </StyledFoundOptions>
  );
};

export default FoundOptions;
