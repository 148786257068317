import styled from 'styled-components';
import { colorsSpec, fonts, fontSizes } from './defaultTheme';

export default styled.div`
  font-family: ${fonts.default};
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondary]};
  margin: 0;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors[colorsSpec.primary]};

  * {
    box-sizing: border-box;
    /* scrollbar-width: thin; For Firefox */
    scrollbar-color: ${({ theme }) => theme.colors[colorsSpec.white]}
      transparent; /* For Firefox */
    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(209, 225, 246, 0) 0%,
        rgba(209, 225, 246, 0.2) 100%
      );
      border-radius: 7px;
    }
    ::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(209, 225, 246, 0.4);
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(209, 225, 246, 0.4);
      cursor: pointer;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0;
  }

  h1 {
    text-align: center;
    font-size: ${fontSizes.heading1};
  }

  h2 {
    font-size: ${fontSizes.heading2};
  }

  h3 {
    font-size: ${fontSizes.heading3};
  }

  h4 {
    font-size: ${fontSizes.heading4};
  }

  h5 {
    font-size: ${fontSizes.heading5};
  }
`;
