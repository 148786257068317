import { makeVar } from '@apollo/client';
import { StayList } from 'types/cache/StayList';
import { GenericConfig } from 'types/shared/GenericConfig';

// TODO: Update keys when we add popular and rating options to backend
export const AccommodationSort: GenericConfig = {
  popular: 'created_at:asc',
  priceAsc: 'name:asc',
  priceDesc: 'name:desc',
  ratingAsc: 'address:asc',
  ratingDesc: 'address:desc',
};

const stayList: StayList = {
  orderBy: { key: AccommodationSort.popular, value: '' },
  city: '',
  stayRatings: {}
};
export const stayListVar = makeVar<StayList>(stayList);
