import CloseIcon from 'assets/icons/x-button.svg';
import Icon from 'components/Icon';
import { FunctionalComponent } from 'preact';
import { useHistory } from 'react-router';
import { Container } from 'screens/Account/components';
import styled from 'styled-components';

const NormalText = styled.p`
  line-height: 1.2;
  margin-bottom: 16px !important;
  font-weight: 700;
`;

const NormalFormattedText = styled.p`
  line-height: 1.2;
  margin-bottom: 16px !important;
  font-weight: 700;
  white-space: pre-line;
`;

const NormalIndentedText = styled.p`
  line-height: 1.2;
  margin-bottom: 16px !important;
  margin-left: 2rem !important;
  font-weight: 700;
`;

const Title = styled.p`
  line-height: 1.6;
  font-weight: 700;
  margin-bottom: 16px !important;
`;

const MainTitle = styled.h3`
  line-height: 1.6;
  font-size: 21px;
  margin-bottom: 24px !important;
`;

const TACContainer = styled(Container)`
  max-height: 569px;
  overflow: auto;
`;

const IconContainer = styled(Icon)`
  margin-bottom: 30px;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

interface IProps {
  onDismiss?: () => void;
}

const ExitIcon: FunctionalComponent<IProps> = ({ onDismiss }) => {
  const history = useHistory();

  return (
    <IconContainer onClick={onDismiss || history.goBack} small icon={CloseIcon} />
  );
};

export {
  NormalText,
  NormalIndentedText,
  NormalFormattedText,
  Title,
  MainTitle,
  TACContainer,
  IconContainer,
  Header,
  ExitIcon,
};
