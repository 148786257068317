import Button from 'components/Button';
import CalendarInput from 'components/CalendarInput';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import SelectComponent from 'components/Select';
import styled from 'styled-components';
import { colors, colorsSpec, fonts, fontSizes } from 'styles';

export const Wrapper = styled.div`
  width: 97%;
  margin-left: 10px;
  margin-top: 20px;
  position: relative;
`;

export const FieldTitle = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  input {
    border: 1px solid ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
    border-radius: 4px;
    margin-top: 10px;
  }

  .card-number,
  .expiration-date,
  .cvc {
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
    border-radius: 4px;
    margin-top: 10px !important;
  }

  .react-datepicker-wrapper {
    height: unset;
  }
`;

export const StyledInput = styled(Input)`
  input {
    border: 1px solid ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
    border-radius: 4px;
  }
`;

export const StyledCalendar = styled(CalendarInput)`
  background-color: red;

  div {
    background-color: red;
  }
  .react-datepicker-wrapper {
    height: unset;
  }
`;

export const SectionTitle = styled.div`
  margin-top: 30px;
  margin-left: 15px;
  margin-bottom: 20px;

  li {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  }
`;

export const TermsAndPrivacy = styled.div`
  margin-top: 40px;
  margin-left: 20px;
`;

export const StyledCheckbox = styled(Checkbox)`
  width: 15px;
  height: 15px;
  color: ${({ theme }) => theme.colors[colorsSpec.black]};

  span {
    border: 1px solid ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  }

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
  }

  a {
    font-weight: bold;
  }
`;

export const CheckoutButton = styled(Button)`
  padding: 10px 20px;
  border-radius: 3px;
  float: right;
  margin-bottom: 10px;
  margin-top: 55px;

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
  }
`;

export const StyledSelectComponent = styled(SelectComponent)`
  border-radius: 4px;
  margin-top: 10px;
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors[colorsSpec.white]};
  div {
    border: none;
  }
`;

export const TravelerTitle = styled.div`
  background: ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  padding: 5px;
  margin-top: 20px;
  width: 119px;

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors[colorsSpec.white]};
  }
`;

export const TravelerSectionWrapper = styled.div`
  border-bottom: 1px solid
    ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  //height: 320px;
`;

export const cardStyle = {
  style: {
    base: {
      color: colors.primary,
      fontFamily: fonts.default,
      fontSize: fontSizes.default,
      '::placeholder': {
        color: colors.accentDark,
      }
    },
    invalid: {
      color: colors.accentDark,
      iconColor: colors.accentDark,
    },
  },
  placeholder: "**** **** **** ****"
};
