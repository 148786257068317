export enum Paths {
  TravelLocationAndPeriod = '/',
  Arrangements = '/choosing-travel-arrangements',
  ActivitiesDetails = '/activities-details',
  StayDetails = '/stay-details',
  TransportDetails = '/transport-details',
  ActivitiesList = '/activities-list',
  IndividualActivity = '/activity/:id',
  TransportList = '/transport-list',
  IndividualTransport = '/transport/:id',
  StayList = '/stay-list',
  IndividualStay = '/stay/:integration/:id',
  Rooms = '/stay/:integration/:id/rooms',
  Login = '/login',
  Register = '/register',
  Profile = '/profile',
  TravelItinerary = '/travel-itinerary',
  Invoices = '/invoices',
  TravelPlan = '/travel-plans',
  MapScreen = '/map/:tab?',
  Cart = '/cart',
  Billing = '/billing',
  TermsOfUse = '/terms-of-use',
  Marketplace = '/my-desti-marketplace',
}
