import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import { useCallback } from 'react';
import { Field } from 'react-final-form';
import { InputRow } from 'screens/Account/Billing/CheckoutForm';
import { InputColumnLeft, InputColumnRight } from 'screens/Account/components';
import { ICountry } from 'types/cache/Country';
import validate from 'validate.js';
import {
  FieldTitle,
  InputContainer,
  StyledInput,
  StyledSelectComponent,
  TravelerSectionWrapper,
  TravelerTitle,
} from './StyledComponents';

type TravelerSectionFormProps = {
  isTransport: boolean;
  countries: ICountry[];
  index: number;
};

const TravelerSectionForm: FunctionalComponent<TravelerSectionFormProps> = ({
  isTransport,
  index,
  countries,
}) => {
  const { t } = useIntl('app.Marketplace');
  const { t: validationMessages } = useIntl('app.ValidationMessages');

  const genders = [t('male'), t('female')];
  const validateField = useCallback(
    (value, message) => {
      if (isTransport) {
        return validate.single(value, { presence: { message } });
      }
    },
    [isTransport]
  );

  return (
    <TravelerSectionWrapper>
      <TravelerTitle>
        <p>{`${t('travelerTitle')} ${index} `}</p>
      </TravelerTitle>

      <InputRow>
        <InputColumnLeft>
          <InputContainer>
            <FieldTitle>{t('firstName')}</FieldTitle>
            <Field
              placeholder={t('firstName')}
              component={StyledInput}
              name={`firstNameTraveler${index}`}
              id={`firstNameTraveler${index}`}
              required
              validate={value =>
                validateField(value, validationMessages('firstNameRequired'))
              }
            />
          </InputContainer>
        </InputColumnLeft>

        <InputColumnRight>
          <InputContainer>
            <FieldTitle>{t('lastName')}</FieldTitle>
            <Field
              placeholder={t('lastName')}
              name={`lastNameTraveler${index}`}
              id={`lastNameTraveler${index}`}
              component={StyledInput}
              required
              validate={value =>
                validateField(value, validationMessages('lastNameRequired'))
              }
            />
          </InputContainer>
        </InputColumnRight>
      </InputRow>

      <InputRow>
        <InputColumnLeft>
          <InputContainer>
            <FieldTitle>{t('email')}</FieldTitle>
            <Field
              placeholder={t('email')}
              component={StyledInput}
              name={`emailTraveler${index}`}
              id={`emailTraveler${index}`}
              required
            />
          </InputContainer>
        </InputColumnLeft>
      </InputRow>
    </TravelerSectionWrapper>
  );
};

export default TravelerSectionForm;
