import CheckIcon from 'assets/icons/check-green.svg';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';

const Check = styled.img`
  opacity: 0;
  width: 10px;
  height: 10px;
`;

const Container = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accentLight};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: inline flex;
  height: 25px;
  justify-content: center;
  padding: ${({ theme: { spacing } }) => `${spacing.none} ${spacing.large1}`};
  width: fit-content;
  border: 1px solid ${({ theme }) => theme.colors.accentLight};
`;

const CheckBox = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.accentLight};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.small2};
  height: 12px;
  width: 12px;
  justify-content: center;
  line-height: 10px;
  margin-right: ${({ theme }) => theme.spacing.medium2};
`;

const Input = styled.input`
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
  z-index: -1;

  &:checked + ${Container} {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.accentLight};
    border: 1px solid ${({ theme }) => theme.colors.accentLight};
  }

  &:checked + ${Container} ${Check} {
    opacity: 1;
  }

  &:hover + ${Container} {
    background-color: ${({ theme }) => theme.colors.accentLight};
    color: ${({ theme }) => theme.colors.white};
  }

  &:focus + ${Container} {
    background-color: ${({ theme }) => theme.colors.accentLight};
    color: ${({ theme }) => theme.colors.white};
  }

  &:disabled + ${Container} {
    background-color: ${({ theme }) => theme.colors.accent};
    cursor: default;
  }

  &:disabled + ${Container} ${CheckBox} {
    background-color: ${({ theme }) => theme.colors.accent};
    border-color: ${({ theme }) => theme.colors.white};
  }
`;

const StyledTag = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.small2};
  width: fit-content;
  margin-right: 5px;
  margin-bottom: 5px;
`;

type Props = {
  disabled?: boolean;
  input?: any; // TODO
  title?: string;
  id?: any;
};

const EnvironmentTag: FunctionalComponent<Props> = ({
  disabled = false,
  input = {},
  title = '',
  id = null,
}) => (
  <StyledTag>
    <Input disabled={disabled} {...input} type="checkbox" />
    <Container id={id}>
      <CheckBox>
        <Check src={CheckIcon} alt="check" />
      </CheckBox>
      <span>{title}</span>
    </Container>
  </StyledTag>
);

export default EnvironmentTag;
