import LeafIcon from 'assets/icons/leaf.svg';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import EnvironmentTag from './EnvironmentTag';

const InfoContainer = styled.div`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  height: 100%;
`;

const LeafContainer = styled.span`
  align-items: center;
  display: flex;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 14px 0;
  flex-wrap: wrap;
`;

const Icon = styled.img`
  display: inline-block;
  opacity: 0;

  ${LeafContainer} > & {
    margin-right: ${({ theme }) => theme.spacing.small1};
    opacity: 1;
    top: 0;
  }
`;

const LeafLabel = styled.span`
  font-weight: 600;
  height: 100%;
  width: fit-content;
  font-size: ${({ theme }) => theme.fontSizes.small2};
`;

const InfoLabel = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.small2};
  line-height: 14px;
`;

const StyledEnvCompensation = styled.div`
  background-color: ${({ disabled, theme: { colors } }) =>
    disabled ? colors.accent : '#E3FEE3'};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  color: ${({ theme }) => theme.colors.accentLight};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizes.smaller};
  min-height: 46px;
  padding: ${({ theme: { spacing } }) =>
    `${spacing.small1} ${spacing.medium3}`};
  position: relative;
  width: 100%;
`;

type Props = {
  disabled?: boolean;
  iconLabel?: string;
  infoLabel?: string;
  compensationOptions?: any[];
  onCompensationOptionClick: (price: number) => void;
};

const EnvironmentCompensation: FunctionalComponent<Props> = ({
  disabled = false,
  iconLabel,
  infoLabel,
  compensationOptions,
  onCompensationOptionClick = () => null,
}) => (
  <StyledEnvCompensation disabled={disabled}>
    <InfoContainer disabled={disabled}>
      <TextContainer>
        <LeafContainer>
          <Icon src={LeafIcon} alt="leaf" />
          <LeafLabel>{iconLabel}</LeafLabel>
        </LeafContainer>
        <InfoLabel>{infoLabel}</InfoLabel>
      </TextContainer>
    </InfoContainer>
    <OptionsContainer>
      {compensationOptions.map(({ id, label, isChecked }) => (
        <EnvironmentTag
          title={label}
          input={{
            checked: isChecked,
            onClick: () => onCompensationOptionClick(id),
          }}
        />
      ))}
    </OptionsContainer>
  </StyledEnvCompensation>
);

export default EnvironmentCompensation;
