import { setContext } from '@apollo/client/link/context';
import storage from 'local-storage-fallback';

export const hostLink = setContext((_, { _headers }) => {
  const hostId = storage.getItem('DESTI1:HOSTID');
  let headers = {
    ..._headers,
    cookie: document.cookie,
  };

  if (hostId) {
    headers = { ...headers, 'x-hasura-host-id': hostId };
  }

  return {
    headers: {
      ...headers,
      'widget-language': window.DEST1_CONFIG.lang,
    },
  };
});
