import Icon from 'components/Icon';
import TooltipContainer from 'components/TooltipContainer';
import styled from 'styled-components';
import { colorsSpec } from 'styles';

const StyledIcon = styled(Icon)`
  height: 40px;
  width: 40px;
  position: relative;

  img {
    width: 37px;
    height: 32px;
  }

  &:first-child > img {
    height: 26px;
    width: 22px;
  }

  ${TooltipContainer} &:first-child img {
    height: 32px;
    width: 32px;
  }

  ${({ itemCount, theme }) =>
    itemCount &&
    `
    &:after {
      content: '${itemCount}';
      position: absolute;
      top: -16px;
      right: -14px;
      background-color: ${theme.colors[colorsSpec.secondaryLight]};
      color: ${theme.colors[colorsSpec.white]};
      border-radius: 50%;
      padding: 3px;
      font-weight: 700;
      min-width: 23px;
      min-height: 23px;
      line-height: 23px;
      border: 2px solid ${theme.colors.white};;
    }
  `};

  @media (max-width: 576px) {
    height: 30px;
    width: 30px;

    img {
      width: 28px;
      height: 28px;
    }
  }
`;

export default StyledIcon;
