import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import PinIcon from 'assets/icons/pin.svg';
import { FunctionalComponent } from 'preact';

type Props = {
  center: any;
  containerStyle: any;
  zoom: number;
  markers: any[];
  onMapLoad?: (map: any) => void;
  onMapUnmount?: (map: any) => void;
};

const MapComponent: FunctionalComponent<Props> = ({
  center,
  containerStyle,
  zoom,
  markers = [],
  onMapLoad,
  onMapUnmount,
}) => (
  <LoadScript googleMapsApiKey={process.env.PREACT_APP_GOOGLE_MAP_KEY}>
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onLoad={onMapLoad}
      onUnmount={onMapUnmount}
    >
      {markers.length &&
        markers.map(({ lng, lat, onClick }) => (
          <Marker onClick={onClick} position={{ lng, lat }} icon={PinIcon} />
        ))}
    </GoogleMap>
  </LoadScript>
);

export default MapComponent;
