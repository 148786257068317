import {
  CartItemType,
  IActivityCartItem,
  IStayCartItem,
  ITransportCartItem,
} from 'types/cache/Cart';
import { CartTypes } from 'types/shared/Cart';
import ActivityMarketplaceItemMainInfo from './Activity';
import StayMarketplaceItemMainInfo from './Stay';
import TransportMarketplaceItemMainInfo from './Transport';

const MarketplaceItemMainInfo = (id: number, item: CartItemType) => {
  return {
    [CartTypes.TRANSPORT]: (
      <TransportMarketplaceItemMainInfo item={item as ITransportCartItem} />
    ),
    [CartTypes.ACTIVITY]: (
      <ActivityMarketplaceItemMainInfo item={item as IActivityCartItem} />
    ),
    [CartTypes.STAY]: (
      <StayMarketplaceItemMainInfo item={item as IStayCartItem} />
    ),
  };
};

export default MarketplaceItemMainInfo;
