import ShowMoreIcon from 'assets/icons/show-more.svg';
import Icon from 'components/Icon';
import Tag from 'components/Tag';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { FunctionalComponent } from 'preact';
import { useRef, useState } from 'preact/hooks';
import { FieldInputProps } from 'react-final-form';
import styled from 'styled-components';

const StyledMultiSelect = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryDark};
  border-radius: 3px;
  /* box-sizing: border-box; */
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  font-size: 21px;
  line-height: 28px;
  font-weight: 700;
  height: 40px;
  padding: 10px;
  position: relative;
  width: 195px;
`;

const Arrow = styled(Icon)`
  height: 18px;
  margin-right: 6px;
  outline: none;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
  width: 18px;

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.colors.secondaryLight};
  }
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-size: 21px;
  font-weight: 700;
  line-height: 28px;
`;

const List = styled.ul`
  background-color: ${({ theme }) => theme.colors.secondaryDark};
  border: 1px solid ${({ theme }) => theme.colors.secondaryDark};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  left: 0;
  margin: 0;
  max-height: 200px;
  min-width: 195px;
  overflow: scroll;
  padding: 0;
  position: absolute;
  top: 100%;
  width: max-content;
  z-index: 2;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  li {
    flex-basis: 100%;
    width: 100%;
  }
`;

const ListItem = styled(Tag)`
  justify-content: flex-start;
  width: 100%;
`;

type Props = {
  input: FieldInputProps<string>;
  options: any[]; // TODO
  title: string;
};

const MultiSelect: FunctionalComponent<Props> = ({
  input = null,
  options = [],
  title = null,
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <StyledMultiSelect ref={ref} onClick={() => setIsOpen(!isOpen)}>
      <Arrow isOpen={isOpen} icon={ShowMoreIcon} small />
      <Title>{title}</Title>
      {isOpen ? (
        <List onClick={e => e.stopPropagation()}>
          {options.map(option => (
            <li>
              <ListItem
                input={{
                  value: option.value,
                  checked: option.selected,
                  ...input,
                  onClick: () => input?.onClick(option.value),
                }}
                title={option.title}
              />
            </li>
          ))}
        </List>
      ) : null}
    </StyledMultiSelect>
  );
};

export default MultiSelect;
