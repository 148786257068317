import storage from 'local-storage-fallback';
import { useState } from 'preact/hooks';

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export default function useToken() {
  const getToken = () => {
    const tokenString = storage.getItem('DESTI1:TOKEN');
    const token = tokenString && parseJwt(tokenString);
    return token?.sub || null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (idToken: string, refreshToken: string) => {
    storage.setItem('DESTI1:TOKEN', idToken);
    storage.setItem('DESTI1:REFRESH:TOKEN', refreshToken);

    setToken(idToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
