import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import { IStayCartItem } from 'types/cache/Cart';
import {
  PlanItemInfoContainer,
  PlanItemInfoRow,
  PlanItemInfoTitle,
  PlanItemInfoValue,
} from '../components';

interface IProps {
  item: IStayCartItem;
}

const StayMarketplaceItemMainInfo: FunctionalComponent<IProps> = ({ item }) => {
  const { t } = useIntl('app.Marketplace');

  const checkInDate: string = dayjs(item.travelPeriod[0]).format('DD.MM.YYYY');
  const checkOutDate: string = dayjs(item.travelPeriod[1]).format('DD.MM.YYYY');

  return (
    <PlanItemInfoContainer>
      <PlanItemInfoRow>
        <PlanItemInfoTitle>{t('checkIn')} :</PlanItemInfoTitle>
        <PlanItemInfoValue>{checkInDate}</PlanItemInfoValue>
      </PlanItemInfoRow>
      <PlanItemInfoRow>
        <PlanItemInfoTitle>{t('checkOut')} :</PlanItemInfoTitle>
        <PlanItemInfoValue>{checkOutDate}</PlanItemInfoValue>
      </PlanItemInfoRow>
      <PlanItemInfoRow>
        <PlanItemInfoTitle>{t('numberOfPeople')}</PlanItemInfoTitle>
        <PlanItemInfoValue>{item.people || 0}</PlanItemInfoValue>
      </PlanItemInfoRow>
      {item.children > 0 && (
        <PlanItemInfoRow>
          <PlanItemInfoTitle>{t('numberOfChildren')}</PlanItemInfoTitle>
          <PlanItemInfoValue>{item.children}</PlanItemInfoValue>
        </PlanItemInfoRow>
      )}
    </PlanItemInfoContainer>
  );
};

export default StayMarketplaceItemMainInfo;
