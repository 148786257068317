import ShowMoreIcon from 'assets/icons/show-more.svg';
import Icon from 'components/Icon';
import { FunctionalComponent } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import styled from 'styled-components';

const SliderContainer = styled.div`
  position: relative;
  max-width: 100%;
  margin-top: ${({ theme }) => theme.spacing.large4};
`;

const StyledSlider = styled.aside`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  top: 0;
  left: 0;
  padding-top: 20px;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const SliderInner = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
`;

const StyledIcon = styled(Icon)`
  height: 32px;
  outline: none;
  position: absolute;
  right: 0;
  top: 0px;
  width: 32px;

  &:nth-child(2) {
    right: 36px;
    transform: rotate(-180deg);
  }

  img {
    height: 20px;
    width: 20px;
  }
`;

type Props = {
  className: string;
  title: string;
};

const Slider: FunctionalComponent<Props> = ({
  title = '',
  children = null,
  className = '',
}) => {
  const [node, setNode] = useState<any>(false);
  const [scrollPos, setScrollPos] = useState<number>(0);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const [isPrevDisabled, setisPrevDisabled] = useState<boolean>(false);

  const measuredRef = useCallback(
    node => {
      if (node !== null) {
        setNode(node);
      }
    },
    [children]
  );

  useEffect(() => {
    setisPrevDisabled(node.scrollLeft <= 0);
    setIsNextDisabled(node.clientWidth === node.scrollWidth);
  }, [node, children]);

  useEffect(() => {
    setIsNextDisabled(scrollPos + node.clientWidth >= node.scrollWidth);
    setisPrevDisabled(scrollPos <= 0);
  }, [scrollPos]);

  useEffect(() => {
    setIsNextDisabled(
      node.clientWidth === node.scrollWidth ||
        scrollPos + node.clientWidth >= node.scrollWidth
    );
    setisPrevDisabled(scrollPos <= 0);
  }, [scrollPos]);

  useEffect(() => {
    if (node) {
      const sliderEl = node;
      const onScroll = e => setScrollPos(e.target.scrollLeft);
      sliderEl.addEventListener('scroll', onScroll);

      return () => sliderEl.removeEventListener('scroll', onScroll);
    }
  }, [node]);

  return (
    <SliderContainer className={className}>
      <h3>{title}</h3>

      <StyledSlider ref={measuredRef}>
        <StyledIcon
          icon={ShowMoreIcon}
          onClick={() => {
            setScrollPos((node.scrollLeft += 150));
          }}
          small
          disabled={isNextDisabled}
        />
        <StyledIcon
          icon={ShowMoreIcon}
          onClick={() => {
            setScrollPos((node.scrollLeft -= 150));
          }}
          small
          disabled={isPrevDisabled}
        />
        <SliderInner>{children}</SliderInner>
      </StyledSlider>
    </SliderContainer>
  );
};

export default Slider;
