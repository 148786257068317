import { RESET_PASSWORD, SIGNIN } from 'apollo/myPages/mutations';
import { useMyPagesMutation } from 'apollo/myPages/useMyPages';
import Input from 'components/Input';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import Tabs, { mapActiveTab } from 'components/Tabs';
import { TabProps } from 'components/Tabs/Tab';
import { TABS_HEIGHT } from 'config/inputs';
import { Paths } from 'constants/paths';
import useIntl from 'hooks/useIntl';
import useToken from 'hooks/useToken';
import { Fragment, FunctionalComponent } from 'preact';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { useLoginConstraints } from 'shared/constraints';
import validateForm from 'utils/form/validateForm';
import {
  Container,
  ForgotPassword,
  InputRow,
  RecoverLabel,
  RegisterButton,
  TabsContainer,
  Title,
} from '../components';

type Props = {
  location: any;
  tabs: TabProps[];
};

const Login: FunctionalComponent<Props> = ({ location, tabs = [] }) => {
  const email = location?.state?.email;
  const { t } = useIntl('app.Login');
  const { t: controls } = useIntl('app.Controls');
  const history = useHistory();
  const isThisPageVisited = IsPageVisited('Login');
  const { setToken } = useToken();

  const isForgotPassword = Boolean(location.hash.includes('#forgot-password'));
  const { constraints } = useLoginConstraints(isForgotPassword);

  const [login, { loading }] = useMyPagesMutation(SIGNIN);
  const [resetPassword] = useMyPagesMutation(RESET_PASSWORD);

  const onSubmit = async (data: any) => {
    const { email, password } = data;

    if (isForgotPassword) {
      const response = await resetPassword({ variables: { email } })
        .then(() => alert('Please check your email!'))
        .catch(error => ({ message: error.message }));

      if (response && response.message) {
        return { email: response.message };
      }
    } else {
      const response = await login({
        variables: { username: email, password },
      })
        .then(({ data }) => {
          setToken(
            data.usernamePasswordLogin.idToken,
            data.usernamePasswordLogin.refreshToken
          );

          history.replace({
            pathname: 'profile',
            state: { activeTab: 'app.Profile.personalTab' },
          });
        })
        .catch(error => ({ message: error.message }));

      if (response && response.message) {
        return { email: response.message };
      }
    }
  };

  const renderForm = ({
    handleSubmit,
    submitting,
    pristine,
    hasValidationErrors,
  }) => (
    <form onSubmit={handleSubmit}>
      <InputRow>
        <Field
          placeholder={controls('emailPlaceholder')}
          component={Input}
          required
          name="email"
          id="email"
          initialValue={email}
          isErrorVisible={true}
        />
      </InputRow>

      {!isForgotPassword && (
        <InputRow>
          <Field
            placeholder={controls('passwordPlaceholder')}
            name="password"
            id="password"
            required
            type="password"
            component={Input}
            isErrorVisible={true}
          />
        </InputRow>
      )}

      <InputRow>
        {isForgotPassword ? (
          <RecoverLabel>{t('recoverAddress')}</RecoverLabel>
        ) : (
          <ForgotPassword
            to={{
              pathname: Paths.Login,
              hash: '#forgot-password',
            }}
          >
            {t('forgotPassword')}
          </ForgotPassword>
        )}
      </InputRow>

      <InputRow>
        <RegisterButton
          secondary
          title={isForgotPassword ? t('resetBtn') : t('loginBtn')}
          disabled={submitting || pristine || loading || hasValidationErrors}
        />
      </InputRow>
    </form>
  );

  return (
    <Fragment>
      <ModalHeader title={t('title')} />
      <Container>
        <TabsContainer>
          {tabs.length && (
            <Tabs
              config={mapActiveTab(tabs, 'app.Login.login')}
              height={TABS_HEIGHT}
              isSecondary
            />
          )}
        </TabsContainer>

        <Title>{t('heading')}</Title>

        <Form
          onSubmit={values => onSubmit(values)}
          validate={values => validateForm(values, constraints)}
          render={renderForm}
        />
      </Container>
      <ModalFooter
        showButton={false}
        mapIconProps={{ disabled: true }}
        chatIconProps={{ disabled: true }}
        tooltipTitle={
          isForgotPassword ? t('forgotPasswordTooltipTitle') : t('tooltipTitle')
        }
        tooltipText={
          isForgotPassword ? t('forgotPasswordTooltipText') : t('tooltipText')
        }
        tooltipPoweredBy={t('tooltipPoweredBy')}
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default Login;
