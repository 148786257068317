import useIntl from 'hooks/useIntl';
import { useMemo } from 'preact/hooks';

export default function (isStay: boolean, dynamicValidationConstraints: any) {
  const { t } = useIntl('app.ValidationMessages');

  const { leadTravelerConstraints } = useLeadTravelerConstraints(isStay);

  const constraints = useMemo(() => {
    return {
      firstNameBilling: {
        presence: {
          message: t('firstNameRequired'),
        },
      },
      lastNameBilling: {
        presence: {
          message: t('lastNameRequired'),
        },
      },
      countryBilling: {
        presence: {
          message: t('countryRequired'),
        },
      },
      cityBilling: {
        presence: {
          message: t('cityRequired'),
        },
      },
      zipCodeBilling: {
        presence: {
          message: t('zipCodeRequired'),
        },
      },
      cardholderName: {
        presence: {
          message: t('cardholderNameRequired'),
        },
      },
      ...leadTravelerConstraints,
      ...dynamicValidationConstraints,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicValidationConstraints, leadTravelerConstraints]);

  return { constraints };
}

const useLeadTravelerConstraints = (isStay: boolean) => {
  const { t } = useIntl('app.ValidationMessages');
  const leadTravelerConstraints = useMemo(() => {
    if (isStay) {
      return {
        firstName: {
          presence: {
            message: t('firstNameRequired'),
          },
        },
        lastName: {
          presence: {
            message: t('lastNameRequired'),
          },
        },
        country: {
          presence: {
            message: t('countryRequired'),
          },
        },
        email: {
          presence: {
            message: t('emailRequired'),
          },
        },
        gender: {
          presence: {
            message: t('genderRequired'),
          },
        },
        dateOfBirth: {
          presence: {
            message: t('dateOfBirthRequired'),
          },
        },
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStay]);

  return { leadTravelerConstraints };
};
