import { useReactiveVar, useMutation } from '@apollo/client';
import { myPagesClient } from 'apollo/client';
import { DELETE_TRAVEL_PLAN, DELETE_TRAVEL_PLAN_ITEM, GET_TRAVEL_PLANS } from 'apollo/myPages/mutations';
import BlurredModal from 'components/BlurredModal';
import EmptyState from 'components/EmptyState';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import Tabs, { mapActiveTab } from 'components/Tabs';
import { TabProps } from 'components/Tabs/Tab';
import { MODAL_TIMEOUT, TABS_HEIGHT } from 'config/inputs';
import { Paths } from 'constants/paths';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import { marketplaceVar } from 'screens/Marketplace/cache';
import styled from 'styled-components';
import { colorsSpec } from 'styles/defaultTheme';
import { ITravelPlan } from 'types/cache/TravelPlan';
import { CartTypes } from 'types/shared/Cart';
import { Container, ProfileTabsContainer } from '../components';
import TravelPlan from './components/TravelPlan';

const TravelPlanContainer = styled(Container)`
  max-height: 550px;
  overflow: auto;
`;

const YearContainer = styled.div`
  width: 90px;
  height: 29px;
  background: ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors[colorsSpec.white]};
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 25px 50px;
`;

const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: justify;
`;

const StyledBlurredModal = styled(BlurredModal)`
  top: 173px;
  height: 571px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 145%;
    color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  }
`;

interface IProps {
  tabs: TabProps[];
  location: any;
}

const TravelPlans: FunctionalComponent<IProps> = ({ tabs = [], location }) => {
  const { t } = useIntl('app.TravelPlan');
  const history = useHistory();
  const isThisPageVisited = IsPageVisited('TravelPlan');

  const { activeTab } = location.state;

  const [plans, setPlans] = useState<ITravelPlan[]>([]);

  const [getTravelPlan] = useMutation(GET_TRAVEL_PLANS, {
    variables: {
      id: ""
    },
    client: myPagesClient,
    onCompleted: ({ getTravelPlans }) => {
      setPlans(getTravelPlans?.travelPlans);
    },
  });

  const [removeTravelPlan] = useMutation(DELETE_TRAVEL_PLAN, {
    client: myPagesClient,
    onCompleted: () => {
      getTravelPlan();
    },
  });

  const [removeTravelPlanItem] = useMutation(DELETE_TRAVEL_PLAN_ITEM, {
    client: myPagesClient,
    onCompleted: () => {
      getTravelPlan();
    },
  });

  const [isRoutingToMarketplace, setIsRoutingToMarketplace] = useState<boolean>(
    false
  );

  const { travelPlans } = useReactiveVar(marketplaceVar);

  const handleItemRemove = (
    travelPlanId: string,
    itemId: number,
    itemType: CartTypes
  ): void => {
    const travelPlan: ITravelPlan = plans.find(
      plan => plan.id === travelPlanId
    );

    if (travelPlan.travelPlanItems.filter(i => !i.paid).length === 1) {
      removeTravelPlan({
        variables: {
          travelPlanId: travelPlan.id,
        }
      });
    } else {
      travelPlan.travelPlanItems.splice(
        travelPlan.travelPlanItems.findIndex(
          item => item.id === itemId && item.type === itemType
        ),
        1
      );
      removeTravelPlanItem({
        variables: {
          travelPlanId: travelPlan.id,
          travelPlanItemId: itemId
        }
      });
    }
    getTravelPlan();
  };

  const handleButtonClick = () => {
    setIsRoutingToMarketplace(true);

    setTimeout(() => {
      setIsRoutingToMarketplace(false);
      history.push(Paths.Marketplace);
    }, MODAL_TIMEOUT);
  };

  useEffect(() => {
    getTravelPlan();
  }, [getTravelPlan]);

  return (
    <>
      <ModalHeader title={t('title')} />
      <TravelPlanContainer>
        <ProfileTabsContainer>
          {tabs.length && (
            <Tabs
              config={mapActiveTab(tabs, activeTab)}
              height={TABS_HEIGHT}
              isSecondary
            />
          )}
          {plans.length > 0 ? (
            plans.map((travelPlan: ITravelPlan) => (
              <>
                <TravelPlan
                  travelPlan={travelPlan}
                  handleItemRemove={handleItemRemove}
                />
              </>
            ))
          ) : (
            <EmptyState config={{ title: t('noItems') }} />
          )}
        </ProfileTabsContainer>
      </TravelPlanContainer>
      <TextContainer>
        <Text>
          {t('instructionsText')}
        </Text>
      </TextContainer>
      <ModalFooter
        showButton
        buttonTitle={t('footerButtonTitle')}
        onButtonClick={handleButtonClick}
        buttonProps={{
          disabled: !travelPlans.some(i => plans.includes(i)),
        }}
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        doOpen={!isThisPageVisited}
      />
      {isRoutingToMarketplace && (
        <StyledBlurredModal>
          <div>
            <h4>{t('successTitle')}</h4>
            <p>{t('successText')}</p>
          </div>
        </StyledBlurredModal>
      )}
    </>
  );
};

export default TravelPlans;
