import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { GET_INTEGRATIONS_TRANSPORTATION_OFFER } from 'apollo/myDesti/mutations';
import { SEARCH_STATION } from 'apollo/myDesti/queries';
import EmptyState from 'components/EmptyState';
import InfoDisclaimer, { MessageType } from 'components/InfoDisclaimer';
import Loading from 'components/Loading';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import Tabs, { mapActiveTab } from 'components/Tabs';
import TransportTile from 'components/TransportTile';
import { Paths } from 'constants/paths';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { Fragment } from 'preact';
import { Text } from 'preact-i18n';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import { cartVar } from 'screens/Cart/cache';
import { CartActionTypes, useCart } from 'screens/Cart/useCart';
import { NotificationScreen } from 'screens/NotificationScreen';
import useNotification from 'screens/NotificationScreen/useNotification';
import { InfiniteScrollWithRef } from 'shared/components';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import { Station } from 'types/cache/TransportDetails';
import { TransportTypes } from 'types/enums';
import { CartTypes } from 'types/shared/Cart';
import { NOTIFICATION_TYPES } from 'types/shared/Notification';
import { getRandomInt } from 'utils/number/getRandomInt';
import SearchInput from '../../../components/SearchInput';
import { transportDetailsVar } from '../../Details/Transport/cache';
import { useTransportDetails } from '../../Details/Transport/useTransportDetails';
import { locationAndPeriodVar } from '../../LocationAndPeriod/cache';
import {
  Container,
  ControlsContainer,
  EndMessage,
  InfiniteScrollLoader,
  ItemImage,
  LeftColumn,
  LogoContainer,
  RightColumn,
  Row,
  TransportTileContainer,
} from '../components';
import { MARKERS_MOCK } from '../config';
import { transportListVar } from './cache';
import { useTransportList } from './useTransportList';
import { ITransportCartItem } from 'types/cache/Cart';
import { v4 as uuidv4 } from 'uuid';

const MainControlsContainer = styled(ControlsContainer)`
  padding-bottom: 1px;
`;

const ListRow = styled(Row)`
  justify-content: space-between;
  margin-bottom: 0;
  padding-bottom: 10px;
`;

const FullRow = styled(Row)`
  justify-content: space-between;
  margin-bottom: 0;
  padding-bottom: 10px;
  padding-left: 3px;
  padding-top: 10px;
`;

const FullSearchInput = styled.div`
  line-height: 20px;
  flex-grow: 1;
  display: block;
  position: relative;
  z-index: 101;
`;

const Spacer = styled.div`
  width: 100%;
  max-width: 45px;
  position: relative;
`;

const InfoPill = styled.div`
  height: 37px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors[colorsSpec.primaryLight]};
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const InfoPillExpanded = styled(InfoPill)`
  height: fit-content;
  z-index: 102;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: absolute;
  left: 0;
  top: 37px;
  right: 0;
  bottom: 0;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 8%;
    bottom: 0;
    height: 1px;
    width: 80%;
    border-bottom: 1px solid
      ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  }
`;

const InfoLabel = styled.div`
  height: 24px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding-top: 2px;
  padding-left: 15px;
  padding-right: 20px;
`;

const TightRow = styled(Row)`
  height: auto;
  padding-bottom: 10px;
`;

const TravelOptionsArrow = styled.div`
  top: -2px;
  left: 5px;
  padding-left: 1px;
  position: relative;
  opacity: 0.9;
  transform: scale(1.7, 1);
`;

const RoundTripInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const RoundTripPill = styled.div.attrs(
  ({ chosenOutTrip, theme: { colors } }) => ({
    bgColor: chosenOutTrip
      ? colors[colorsSpec.accentLight]
      : colors[colorsSpec.secondaryLight],
  })
)`
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  width: 49%;
  height: 38px;
  color: #372046;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: default;
`;

const RoundTripPillRight = styled(RoundTripPill).attrs(
  ({ chosenReturnTrip, theme: { colors } }) => ({
    borderColor: chosenReturnTrip
      ? colors[colorsSpec.accentLight]
      : colors[colorsSpec.secondaryLight],
  })
)`
  max-width: 170px;
  background-color: ${({ borderColor }) => borderColor};
`;

const RoundTripPillSpacer = styled.div`
  min-width: 1px;
`;

const RoundTripTriangle = styled.div.attrs(
  ({ chosenOutTrip, theme: { colors } }) => ({
    borderColor: chosenOutTrip ? '#307c3a' : colors[colorsSpec.secondary],
  })
)`
  width: 0;
  height: 0;
  left: -10px;
  right: -14px;
  border-style: solid;
  border-width: 19px 0 19px 5px;
  border-color: transparent transparent transparent
    ${({ borderColor }) => borderColor};
`;

const RoundTripTriangleTop = styled(RoundTripTriangle).attrs(
  ({ chosenOutTrip, chosenReturnTrip, theme: { colors } }) => ({
    bgColor: chosenReturnTrip
      ? colors[colorsSpec.accentLight]
      : colors[colorsSpec.secondaryLight],
    borderLeftColor: chosenOutTrip
      ? colors[colorsSpec.accentLight]
      : colors[colorsSpec.secondaryLight],
  })
)`
  float: right;
  background-color: ${({ bgColor }) => bgColor};
  border-left-color: ${({ borderLeftColor }) => borderLeftColor};
`;

const RoundTripTriangleTopRight = styled(RoundTripTriangleTop).attrs(
  ({ chosenReturnTrip, theme: { colors } }) => ({
    bgColor: chosenReturnTrip ? '#307c3a' : colors[colorsSpec.secondaryLight],
    borderLeftColor: chosenReturnTrip
      ? colors[colorsSpec.accentLight]
      : colors[colorsSpec.secondaryLight],
  })
)`
  background-color: ${({ bgColor }) => bgColor};
  border-left-color: ${({ borderLeftColor }) => borderLeftColor};
`;

const ChosenTransportTile = styled.div`
  width: 100%;
  height: fit-content;
  padding-left: 5px;
  margin-bottom: 5px;
`;

const ChosenSeparator = styled.hr`
  width: 99%;
  height: 2px;
  background: #372046;
  border: none;
  opacity: 0.5;
  margin-left: 6px;
`;

const InfoPillExpandedStyle = {
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
};

const TransportList = ({ tabs = [], location }) => {
  const { t } = useIntl('app.TransportList');
  const { t: controls } = useIntl('app.Controls');
  const emptyStateConfig = { title: t('noItems'), minHeight: 450 };
  const { activeTab } = location.state;

  const PAGE_LIMIT = 20;

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const containerEl = useRef(null);
  const history = useHistory();
  const isThisPageVisited = IsPageVisited('TransportList');

  const { travelPeriod, cityName } = useReactiveVar(locationAndPeriodVar);
  const { cartItems } = useReactiveVar(cartVar);
  const { setCartItem } = useCart(cartVar);

  const { setNotification, notification } = useNotification();

  const {
    selectedTransportType,
    locationFrom,
    locationTo,
    stationFrom,
    stationTo,
    roundTrip,
    options,
    passengers,
    travelClass,
    travelClassMix,
    passengerCategories,
  } = useReactiveVar(transportDetailsVar);

  const {
    setLocationFrom,
    setLocationTo,
    setStationFrom,
    setStationTo,
  } = useTransportDetails(transportDetailsVar);

  const {
    setTransportItem,
    setReturnTransportItem,
    setJourneyId,
    setReturnJourneyId,
    setUniqueTransportId,
    setReturnUniqueTransportId
  } = useTransportList(transportListVar);

  const [resultTransport, setResultTransport] = useState([]);
  const [fromDebounce, setFromDebounce] = useState('');
  const [toDebounce, setToDebounce] = useState('');
  const [optionsExpanded, setOptionsExpanded] = useState(false);
  const [chosenOutTrip, setChosenOutTrip] = useState(null);
  const [chosenReturnTrip, setChosenReturnTrip] = useState(null);
  const [infoExpanded, setInfoExpanded] = useState(false);
  const [hasPets] = useState(
    selectedTransportType.id === 0 && options.addons.pets > 0
  );

  const [bookingTokens, addBookingToken] = useState(
    cartItems.filter(i => (i.item as ITransportCartItem).booking_token)
    .map(i => (i.item as ITransportCartItem).booking_token)
  );

  const { data: stationsFrom, loading: isStationsFromLoading } = useQuery<{
    getStations: Station[];
  }>(SEARCH_STATION, {
    variables: {
      city: fromDebounce || locationFrom?.name || cityName,
      transportationTypes: [selectedTransportType.key],
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: stationsTo, loading: isStationsToLoading } = useQuery<{
    getStations: Station[];
  }>(SEARCH_STATION, {
    variables: {
      city: toDebounce || locationTo?.name || cityName,
      transportationTypes: [selectedTransportType.key],
    },
    fetchPolicy: 'cache-and-network',
  });

  const [
    getTransportationOffer,
    { loading: loadingTransportOffer },
  ] = useMutation(GET_INTEGRATIONS_TRANSPORTATION_OFFER, {});

  const onNextPage = () => {
    const nextPage = page + 1;
    setPage(nextPage);
  };

  const addToCart = item => {
    const adultsId = passengerCategories[selectedTransportType.id].find(
      pc => pc.value === 'adults'
    ).id;
    const childrenId = passengerCategories[selectedTransportType.id].find(
      pc => pc.value === 'children'
    ).id;

    const adultsCount = passengers.filter(
      i => i.passengerCategoryId === adultsId
    ).length;
    const childrenCount = passengers.filter(
      i => i.passengerCategoryId === childrenId
    ).length;
    const infantsCount = passengers.length - adultsCount - childrenCount;

    setCartItem(
      {
        id: uuidv4(),
        type: CartTypes.TRANSPORT,
        item: {
          people: adultsCount > 0 ? adultsCount : null,
          children: childrenCount > 0 ? childrenCount : null,
          infants: infantsCount > 0 ? infantsCount : null,
          price: item?.price,
          title: `${locationFrom?.name} - ${locationTo?.name} ${
            roundTrip ? '(round trip)' : '(one way)'
          }`,
          operatorNames: item.operator_names,
          city_from: locationFrom?.name,
          city_to: locationTo?.name,
          date: item.departure_time,
          transport_type: TransportTypes.Air,
          product_owner: 'kiwi',
          integration: 'Kiwi',
          booking_url: item.transport_item.bookingLink,
          booking_token: item.transport_item.bookingToken,
          bags: options.addons.cabinBaggage + options.addons.checkedBaggage,
          passengers,
          trip_type: roundTrip ? 'roundTrip' : 'oneWayTrip',
          product_location: item.transport_item.productLocation,
          duration: item.duration
        },
      },
      CartActionTypes.Add
    );
    setNotification(NOTIFICATION_TYPES.ADD_TO_CART);
  };

  const getLocationId = (isTo: boolean, from: Station, to: Station) => {
    if (selectedTransportType.id === 0) {
      if (!to) to = stationTo;
      if (!from) from = stationFrom;
      return isTo
        ? `${to.producerId}-${to.id}`
        : `${from.producerId}-${from.id}`;
    }

    if (!to) to = locationTo;
    if (!from) from = locationFrom;
    return isTo ? to?.id : from?.id;
  };

  const getLocationName = (isTo: boolean) => {
    if (selectedTransportType.id === 0) {
      return isTo ? stationTo?.name : stationFrom?.name;
    }
    return isTo ? locationTo?.name : locationFrom?.name;
  };

  const mapRailResults = offer => {
    return offer?.data?.map(item => {
      const segments = item.itineraries.flatMap(
        itinerary => itinerary.segments
      );

      return {
        timetable_id: item.id,
        transport_line: `${segments[0]?.departureLocation.name}-${
          segments[segments.length - 1].arrivalLocation.name
        }`,
        transport_type: segments[0]?.travelMethodCode?.title,
        operator_names: segments.map(s => s.segmentProducerCode.title),
        departure_time: segments[0]?.departureDateTime,
        departure_station_code: segments[0]?.departureLocation.name,
        arrival_time: segments[segments.length - 1]?.arrivalDateTime,
        arrival_station_code:
          segments[segments.length - 1]?.arrivalLocation.name,
        price: item.fromPrice,
        transport_item: item,
        trip_info: segments[0]?.transportInformation?.join(),
        duration: item.duration,
        unique_transport_id: item.uniqueTransportId
      };
    });
  };

  const mapFlightResults = (offer, destinationName: string) => {
    return offer?.data?.map(item => {
      const itineraries = item.itineraries;

      const destination = itineraries.find(
        x => x.arrivalLocation.city === destinationName
      );

      return {
        timetable_id: item.id,
        transport_line: `${itineraries[0]?.departureLocation.city}-${destination.arrivalLocation.city}`,
        transport_type: 'Air',
        operator_names: itineraries.map(i => i.airline),
        departure_time: itineraries[0]?.departureTime,
        departure_station_code: itineraries[0]?.departureLocation.airportName,
        arrival_time: destination?.arrivalTime,
        arrival_station_code: destination?.arrivalLocation.airportName,
        price: item.fromPrice,
        transport_item: item,
        duration: item.duration
        // trip_info: itineraries[0]?.transportInformation?.join(),
      };
    });
  };

  const handleResultList = (
    data: { getTransportation },
    isReturnTripOffer?: boolean,
    destination?: Station
  ) => {
    const { flightOffer, railOffer } = data.getTransportation;

    let mappedTransports = [];
    let pageNumber: number;
    let totalPages: number;

    if (railOffer) {
      mappedTransports = mapRailResults(railOffer);
      pageNumber = railOffer.pageNumber;
      totalPages = railOffer.totalPages;
    } else {
      mappedTransports = mapFlightResults(flightOffer, destination.name);
      pageNumber = flightOffer.pageNumber;
      totalPages = flightOffer.totalPages;
    }

    setHasMore(totalPages > pageNumber);

    const transport = [...resultTransport];
    if (page === 0) {
      setResultTransport(mappedTransports);
      setPage(1);
    } else {
      transport.push(...mappedTransports);
      setResultTransport(transport);
    }
  };

  const callGetTransportationOffer = async (
    from: Station,
    to: Station,
    isReturnTripOffer?: boolean
  ) => {
    const departureDate =
      selectedTransportType.id === 0 && chosenOutTrip
        ? travelPeriod[1]
        : travelPeriod[0];

    const { data } = await getTransportationOffer({
      variables: {
        originLocationName:
          from != null && selectedTransportType.id === 0
            ? `${from.producerId}-${from.id}`
            : getLocationId(chosenOutTrip, from, to),

        destinationLocationName:
          to != null && selectedTransportType.id === 0
            ? `${to.producerId}-${to.id}`
            : getLocationId(!chosenOutTrip, from, to),

        departureDate: dayjs
          .utc(dayjs(departureDate))
          .local()
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        returnDate: roundTrip
          ? dayjs.utc(travelPeriod[1]).local().format('YYYY-MM-DDTHH:mm:ssZ')
          : null,
        nonStop: false,
        hasPets,
        transportationType: [selectedTransportType.key],
        passengers,
        travelClass,
        travelClassMix,
        cabinBagCount: options.addons.cabinBaggage,
        checkedBagCount: options.addons.checkedBaggage,
        pageNumber: page,
        pageSize: PAGE_LIMIT
      }
    });

    handleResultList(data, isReturnTripOffer, to ?? locationTo);
  };

  useEffect(() => {
    if (page === 1) {
      return;
    }

    if (!loadingTransportOffer && passengers) {
      if (passengers.length === 0) {
        return;
      }

      let from: Station;
      let to: Station;
      if (selectedTransportType.id === 0) {
        from = stationFrom;
        to = stationTo;
      } else {
        from = locationFrom;
        to = locationTo;
      }
      callGetTransportationOffer(
        !chosenOutTrip ? from : to,
        !chosenOutTrip ? to : from,
        chosenOutTrip
      );
    }
  }, [page]);

  const goToIndividualTransport = (id: number) => {
    setTimeout(() => {
      history.push({
        pathname: `/transport/${id}`,
        state: {
          activeTab: 'app.Controls.transportTab',
        },
      });
    }, 200);
  };

  const handleBuyClick = item => {
    if (selectedTransportType.id === 0) {
      const { timetable_id, unique_transport_id } = item;

      if (roundTrip) {
        if (!chosenOutTrip) {
          setTransportItem(item);
          setChosenOutTrip(item);
          setJourneyId(timetable_id);
          setUniqueTransportId(unique_transport_id);
        } else {
          if (!chosenReturnTrip) {
            setReturnTransportItem(item);
            setChosenReturnTrip(item);
            setReturnJourneyId(timetable_id);
            setReturnUniqueTransportId(unique_transport_id);
          }
        }

        if (!chosenOutTrip && !chosenReturnTrip) {
          setPage(0);
        }
      } else {
        setTransportItem(item);
        setJourneyId(timetable_id);
        setUniqueTransportId(unique_transport_id);
        setReturnJourneyId(null);
        goToIndividualTransport(timetable_id);
      }
    } else if (selectedTransportType.id === 1) {
      addBookingToken([...bookingTokens, item.transport_item.bookingToken]);
      addToCart(item);
    }
  };

  const getPassengerCount = (): number => {
    const passengers = options.passengers;

    if (selectedTransportType.id === 0) {
      return (
        passengers.VU +
        passengers.BO +
        passengers.SU +
        passengers.SE +
        passengers.PS
      );
    } else if (selectedTransportType.id === 1) {
      return passengers.adults + passengers.children + passengers.infants;
    }
  };

  const findLocationByName = (locations: Station[], name: string) =>
    locations?.find(x => x.name.toLowerCase() === name.toLowerCase());

  const renderPassengersInfo = () => (
    <InfoPillExpanded>
      {selectedTransportType.id === 0 ? (
        <RightColumn style={{ width: '49%' }}>
          {options.passengers.VU > 0 && (
            <p>
              <Text
                id="app.TransportList.adults"
                plural={options.passengers.VU}
                fields={{ count: options.passengers.VU }}
              />
            </p>
          )}
          {options.passengers.BO > 0 && (
            <p>
              <Text
                id="app.TransportList.childYouth"
                plural={options.passengers.BO}
                fields={{ count: options.passengers.BO }}
              />
            </p>
          )}
          {options.passengers.SU > 0 && (
            <p>
              <Text
                id="app.TransportList.student"
                plural={options.passengers.SU}
                fields={{ count: options.passengers.SU }}
              />
            </p>
          )}
          {options.passengers.SE > 0 && (
            <p>
              <Text
                id="app.TransportList.senior"
                plural={options.passengers.SE}
                fields={{ count: options.passengers.SE }}
              />
            </p>
          )}
          {options.passengers.PS > 0 && (
            <p>
              <Text
                id="app.TransportList.retired"
                fields={{ count: options.passengers.PS }}
              />
            </p>
          )}
        </RightColumn>
      ) : (
        <RightColumn style={{ width: '49%' }}>
          {options.passengers.adults > 0 && (
            <p>
              <Text
                id="app.TransportList.adults"
                plural={options.passengers.adults}
                fields={{ count: options.passengers.adults }}
              />
            </p>
          )}
          {options.passengers.children > 0 && (
            <p>
              <Text
                id="app.TransportList.children"
                plural={options.passengers.children}
                fields={{ count: options.passengers.children }}
              />
            </p>
          )}
          {options.passengers.infants > 0 && (
            <p>
              <Text
                id="app.TransportList.infant"
                plural={options.passengers.infants}
                fields={{ count: options.passengers.infants }}
              />
            </p>
          )}
        </RightColumn>
      )}
    </InfoPillExpanded>
  );

  return (
    <Fragment>
      <ModalHeader title={t('title')} />
      {tabs.length && <Tabs config={mapActiveTab(tabs, activeTab)} />}

      <div style={{ position: 'relative' }}>
        <NotificationScreen type={notification} />
        <MainControlsContainer>
          <ListRow onClick={() => setOptionsExpanded(!optionsExpanded)}>
            <LeftColumn
              style={{
                visibility:
                  selectedTransportType.id === 0 && roundTrip
                    ? 'visible'
                    : 'hidden',
                width: '50%',
                paddingLeft: '3px',
              }}
            >
              <RoundTripInfo>
                <RoundTripPill chosenOutTrip={chosenOutTrip}>
                  <RoundTripTriangle chosenOutTrip={chosenOutTrip} />
                  <p>OUTWARD TRIP</p>
                  <RoundTripTriangleTop
                    chosenOutTrip={chosenOutTrip}
                    chosenReturnTrip={chosenReturnTrip}
                  />
                </RoundTripPill>
                <RoundTripPillRight chosenReturnTrip={chosenReturnTrip}>
                  <RoundTripPillSpacer />
                  <p>RETURN TRIP</p>
                  <RoundTripTriangleTopRight
                    chosenReturnTrip={chosenReturnTrip}
                  />
                </RoundTripPillRight>
              </RoundTripInfo>
            </LeftColumn>

            <RightColumn style={{ width: '48%' }}>
              <InfoPill
                style={infoExpanded ? InfoPillExpandedStyle : null}
                onClick={() => setInfoExpanded(!infoExpanded)}
              >
                <InfoLabel>
                  {dayjs(
                    chosenOutTrip ? travelPeriod[1] : travelPeriod[0]
                  ).format('DD. MMM YYYY.')}
                </InfoLabel>
                <InfoLabel>
                  <Text
                    id="app.TransportList.passenger"
                    plural={getPassengerCount()}
                    fields={{ count: getPassengerCount() }}
                  />
                </InfoLabel>
                <TravelOptionsArrow>&or;</TravelOptionsArrow>
                {infoExpanded ? renderPassengersInfo() : null}
              </InfoPill>
            </RightColumn>
          </ListRow>
          <FullRow>
            <FullSearchInput>
              <SearchInput
                initValue={getLocationName(chosenOutTrip)}
                placeholder={t('station')}
                name="locationFrom"
                isLoading={
                  chosenOutTrip ? isStationsToLoading : isStationsFromLoading
                }
                data={
                  chosenOutTrip
                    ? stationsTo?.getStations
                    : stationsFrom?.getStations
                }
                optionSelected={option => {
                  setPage(0);

                  if (selectedTransportType.id === 0) {
                    setStationFrom(option);
                  } else {
                    setLocationFrom(option);
                  }
                }}
                debounceValueChanged={value => setFromDebounce(value)}
                escape={false}
                style={{ top: '44px' }}
                // outsideClickAction={() => {
                //   const location = findLocationByName(chosenOutTrip ? stationsTo?.getStations : stationsFrom?.getStations, fromDebounce);
                //   if (selectedTransportType.id === 0) {
                //     if (location && location.id !== stationFrom?.id) {
                //       setPage(0);
                //       setStationFrom(location);
                //     }
                //   } else {
                //     if (location && location.id !== locationFrom?.id) {
                //       setPage(0);
                //       setLocationFrom(location);
                //     }
                //   }
                // }}
              />
            </FullSearchInput>
            <Spacer />
            <FullSearchInput>
              <SearchInput
                initValue={getLocationName(!chosenOutTrip)}
                placeholder={t('station')}
                name="locationTo"
                isLoading={
                  chosenOutTrip ? isStationsFromLoading : isStationsToLoading
                }
                data={
                  chosenOutTrip
                    ? stationsFrom?.getStations
                    : stationsTo?.getStations
                }
                optionSelected={option => {
                  setPage(0);

                  if (selectedTransportType.id === 0) {
                    setStationTo(option);
                  } else {
                    setLocationTo(option);
                  }
                }}
                debounceValueChanged={value => setToDebounce(value)}
                escape={false}
                style={{ top: '44px' }}
                // outsideClickAction={() => {
                //   const location = findLocationByName(chosenOutTrip ? stationsFrom?.getStations : stationsTo?.getStations, toDebounce);
                //   if (selectedTransportType.id === 0) {
                //     if (location && location.id !== stationTo?.id) {
                //       setPage(0);
                //       setStationTo(location);
                //     }
                //   } else {
                //     if (location && location.id !== locationTo?.id) {
                //       setPage(0);
                //       setLocationTo(location);
                //     }
                //   }
                // }}
              />
            </FullSearchInput>
          </FullRow>
          {chosenOutTrip && (
            <ChosenTransportTile>
              <TransportTile
                isPreview
                title={'Chosen outward trip'}
                price={chosenOutTrip?.price}
                rating={getRandomInt(4, 5)}
                reviewCount={getRandomInt(1, 1000)}
                type={chosenOutTrip.transport_type}
                departureCode={chosenOutTrip.departure_station_code}
                departureTime={chosenOutTrip.departure_time}
                arivalCode={chosenOutTrip.arrival_station_code}
                arivalTime={chosenOutTrip.arrival_time}
                transportItem={chosenOutTrip.transport_item}
                transportType={selectedTransportType?.id}
                onBuyClick={e => {
                  e.stopPropagation();
                  goToIndividualTransport(chosenOutTrip.timetable_id);
                }}
              />
            </ChosenTransportTile>
          )}
          {chosenReturnTrip && (
            <ChosenTransportTile>
              <TransportTile
                isPreview
                title={'Chosen return trip'}
                price={chosenReturnTrip?.price}
                rating={getRandomInt(4, 5)}
                reviewCount={getRandomInt(1, 1000)}
                type={chosenReturnTrip.transport_type}
                departureCode={chosenReturnTrip.departure_station_code}
                departureTime={chosenReturnTrip.departure_time}
                arivalCode={chosenReturnTrip.arrival_station_code}
                arivalTime={chosenReturnTrip.arrival_time}
                transportItem={chosenReturnTrip.transport_item}
                transportType={selectedTransportType?.id}
                onBuyClick={e => {
                  e.stopPropagation();
                  goToIndividualTransport(chosenOutTrip?.timetable_id);
                }}
              />
            </ChosenTransportTile>
          )}

          {(chosenOutTrip || chosenReturnTrip) && <ChosenSeparator />}
        </MainControlsContainer>

        {loadingTransportOffer && page === 0 ? (
          <Container
            style={{
              minHeight: emptyStateConfig.minHeight,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Loading />
          </Container>
        ) : (
          <div>
            {resultTransport?.length ? (
              <Container ref={containerEl}>
                <InfiniteScrollWithRef
                  height={hasPets ? 370 : 425}
                  dataLength={resultTransport.length}
                  next={() => onNextPage()}
                  hasMore={hasMore}
                  loader={<InfiniteScrollLoader />}
                  endMessage={
                    <EndMessage>
                      <h4>{t('endMessage')}</h4>
                    </EndMessage>
                  }
                >
                  {resultTransport.map(item => (
                    <TightRow>
                      <LeftColumn style={{ display: 'none' }}>
                        <LogoContainer>
                          <ItemImage src={item.image} />
                        </LogoContainer>
                      </LeftColumn>
                      <RightColumn style={{ width: '100%' }}>
                        <TransportTileContainer>
                          <TransportTile
                            disabled={bookingTokens.includes(item.transport_item.bookingToken)}
                            title={''}
                            price={item.price}
                            rating={getRandomInt(4, 5)}
                            reviewCount={getRandomInt(1, 1000)}
                            type={item.transport_type}
                            departureCode={item.departure_station_code}
                            departureTime={item.departure_time}
                            arivalCode={item.arrival_station_code}
                            arivalTime={item.arrival_time}
                            transportItem={item.transport_item}
                            transportType={selectedTransportType?.id}
                            duration={item.duration}
                            onBuyClick={async e => {
                              e.stopPropagation();
                              handleBuyClick(item);
                            }}
                          />
                        </TransportTileContainer>
                      </RightColumn>
                    </TightRow>
                  ))}
                </InfiniteScrollWithRef>
                <InfoDisclaimer
                  text={controls('priceDisclaimer')}
                  icon="assets/icons/info-bubble.svg"
                />
                {hasPets && (
                  <InfoDisclaimer
                    text={t('petsSeat')}
                    icon="assets/icons/info-bubble.svg"
                    type={MessageType.Neutral}
                  />
                )}
              </Container>
            ) : (
              <EmptyState config={emptyStateConfig} />
            )}
          </div>
        )}
      </div>

      <ModalFooter
        mapIconProps={{
          onClick: () =>
            history.push({
              pathname: '/map',
              state: {
                markers: MARKERS_MOCK,
                activeTab: 'app.Controls.transportTab',
              },
            }),
        }}
        buttonTitle={roundTrip ? t('continue') : t('buttonTitle')}
        tooltipTitle={
          roundTrip ? t('tooltipTitleRoundTrip') : t('tooltipTitle')
        }
        tooltipText={roundTrip ? t('tooltipTextRoundTrip') : t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        buttonProps={{
          disabled: roundTrip ? !chosenOutTrip || !chosenReturnTrip : false,
        }}
        onButtonClick={() => {
          if (roundTrip) {
            if (chosenOutTrip && chosenReturnTrip) {
              goToIndividualTransport(chosenOutTrip.timetable_id);
              return;
            }
          }
          history.push(Paths.Arrangements);
        }}
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default TransportList;
