import { useBookingLazyQuery } from 'apollo/myBookings/useBooking';
import { GET_HOST_CONFIGRATION } from 'apollo/myDesti/queries';
import Modal from 'components/Modal';
import ModalRoute from 'components/ModalRoute';
import { PageVisitedProvider } from 'components/PageVisited';
import { TabProps } from 'components/Tabs/Tab';
import { Paths } from 'constants/paths';
import storage from 'local-storage-fallback';
import { IntlProvider, Text } from 'preact-i18n';
import { useEffect, useState } from 'preact/hooks';
import { MemoryRouter, Switch } from 'react-router-dom';
import Billing from 'screens/Account/Billing';
import Invoices from 'screens/Account/Invoices';
import TravelItinerary from 'screens/Account/Itinerary';
import Login from 'screens/Account/Login';
import Profile from 'screens/Account/Profile';
import Register from 'screens/Account/Register';
import TravelPlans from 'screens/Account/TravelPlans';
import Arrangements from 'screens/Arrangements';
import Cart from 'screens/Cart';
import ActivitiesDetails from 'screens/Details/Activity';
import StayDetails from 'screens/Details/Stay';
import TransportDetails from 'screens/Details/Transport';
import IndividualActivity from 'screens/Individual/Activity';
import Rooms from 'screens/Individual/Rooms';
import IndividualStay from 'screens/Individual/Stay';
import IndividualTransport from 'screens/Individual/Transport';
import ActivitiesList from 'screens/Lists/Activity';
import StayList from 'screens/Lists/Stay';
import TransportList from 'screens/Lists/Transport';
import TravelLocationAndPeriod from 'screens/LocationAndPeriod';
import MapScreen from 'screens/Map';
import Marketplace from 'screens/Marketplace';
import TermsAndConditions from 'screens/TermsAndConditions/TermsAndConditions';
import { ThemeProvider } from 'styled-components';
import {
  borderRadius,
  colors,
  fonts,
  fontSizes,
  spacing,
  thicknesses,
  WidgetStyle,
} from 'styles';
import { checkIfIframe } from 'utils/iframe/checkIfIframe';
import { onMsg } from 'utils/iframe/postMessage';
import * as locales from './translations';

const App = () => {
  const { theme: providedColors } = window.DEST1_CONFIG;
  const theme = {
    borderRadius,
    colors: { ...colors, ...providedColors },
    fonts,
    fontSizes,
    spacing,
    thicknesses,
  };

  const [trustedOrigin, setTrustedOrigin] = useState('');
  const [messageOrigin, setMessageOrigin] = useState('');
  const [widgetConfig, setWidgetConfig] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [usedLocale, setUsedLocale] = useState(null);
  const [
    loadHostConfig,
    { data: hostConfigurationsData },
  ] = useBookingLazyQuery<any, any>(GET_HOST_CONFIGRATION, {
    fetchPolicy: 'network-only',
    onError: () => {
      onMsg('hideIframe', messageOrigin);
    },
  });

  useEffect(() => {
    checkIfIframe()
      ? window.addEventListener('message', e => {
          // Todo: use host_id from e.data.config.user_id
          // to check if the message initiator has rights to communicate with the widget

          if (typeof e.data !== 'object' && e.data !== null) {
            let data;
            try {
              data = JSON.parse(e.data);
            } catch (err) {
              console.log(err.message);
            }
            if (data.message && data.message === 'dest1WidgetConfig') {
              storage.setItem('DESTI1:HOSTID', data.config.hostId);
              setWidgetConfig(data.config);
              setMessageOrigin(e.origin);
              loadHostConfig();
            }
          }
        })
      : setShowModal(true);
  }, [loadHostConfig, trustedOrigin]);

  useEffect(() => {
    onMsg('dest1WidgetInit', '*');
  }, []);

  useEffect(() => {
    if (hostConfigurationsData) {
      const trustedOrigins = ['https://js.stripe.com'];

      const hostUrl = hostConfigurationsData?.host_configuration[0]?.host_url;
      if (hostUrl !== '') {
        trustedOrigins.push(hostUrl);
      }

      if (trustedOrigins.includes(messageOrigin)) {
        setTrustedOrigin(hostUrl);
        window.DEST1_CONFIG = { ...widgetConfig };
        setShowModal(true);
      } else {
        setShowModal(false);
        onMsg('hideIframe', messageOrigin);
      }
    }
  }, [hostConfigurationsData, messageOrigin, widgetConfig]);

  const tabs: TabProps[] = [
    {
      title: <Text id="app.Controls.activitiesTab" />,
      id: 'app.Controls.activitiesTab',
      url: Paths.ActivitiesList,
    },
    {
      title: <Text id="app.Controls.stayTab" />,
      id: 'app.Controls.stayTab',
      url: Paths.StayDetails,
    },
    {
      title: <Text id="app.Controls.transportTab" />,
      id: 'app.Controls.transportTab',
      url: Paths.TransportDetails,
    },
  ];

  const loginRegisterTabs: TabProps[] = [
    {
      title: <Text id="app.Login.register" />,
      id: 'app.Login.register',
      url: Paths.Register,
    },
    {
      title: <Text id="app.Login.login" />,
      id: 'app.Login.login',
      url: Paths.Login,
    },
  ];

  const userProfileTabs: TabProps[] = [
    {
      title: <Text id="app.Profile.personalTab" />,
      id: 'app.Profile.personalTab',
      url: Paths.Profile,
    },
    {
      title: <Text id="app.Profile.invoicesTab" />,
      id: 'app.Profile.invoicesTab',
      url: Paths.Invoices,
    },
    {
      title: <Text id="app.Profile.travelTab" />,
      id: 'app.Profile.travelTab',
      url: Paths.TravelItinerary,
    },
    {
      title: <Text id="app.Profile.travelPlanTab" />,
      id: 'app.Profile.travelPlanTab',
      url: Paths.TravelPlan,
    },
  ];

  if (!showModal) return <></>;

  setInterval(() => {
    if (window.DEST1_CONFIG?.lang && window.DEST1_CONFIG.lang !== usedLocale) {
      setUsedLocale(window.DEST1_CONFIG.lang);
    }
  }, 500);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider definition={locales[usedLocale || 'en']}>
        <WidgetStyle>
          <PageVisitedProvider>
            {showModal ? (
              <MemoryRouter>
                <Modal
                  onDismiss={() => {
                    onMsg('hideIframe', trustedOrigin);
                  }}
                >
                  <Switch>
                    <ModalRoute
                      exact
                      path={Paths.TravelLocationAndPeriod}
                      component={TravelLocationAndPeriod}
                    />
                    <ModalRoute
                      exact
                      path={Paths.Arrangements}
                      component={Arrangements}
                    />
                    <ModalRoute
                      exact
                      path={Paths.ActivitiesDetails}
                      component={ActivitiesDetails}
                    />
                    <ModalRoute
                      exact
                      path={Paths.StayDetails}
                      component={StayDetails}
                    />
                    <ModalRoute
                      exact
                      path={Paths.TransportDetails}
                      component={TransportDetails}
                    />
                    <ModalRoute
                      exact
                      path={Paths.ActivitiesList}
                      component={ActivitiesList}
                      tabs={tabs}
                    />
                    <ModalRoute
                      exact
                      path={Paths.IndividualActivity}
                      component={IndividualActivity}
                      tabs={tabs}
                    />
                    <ModalRoute
                      exact
                      path={Paths.TransportList}
                      component={TransportList}
                      tabs={tabs}
                    />
                    <ModalRoute
                      exact
                      path={Paths.IndividualTransport}
                      component={IndividualTransport}
                      tabs={tabs}
                    />
                    <ModalRoute
                      exact
                      path={Paths.StayList}
                      component={StayList}
                      tabs={tabs}
                    />
                    <ModalRoute
                      exact
                      path={Paths.IndividualStay}
                      component={IndividualStay}
                      tabs={tabs}
                    />
                    <ModalRoute
                      exact
                      path={Paths.Rooms}
                      component={Rooms}
                      tabs={tabs}
                    />
                    <ModalRoute
                      exact
                      path={Paths.Login}
                      component={Login}
                      tabs={loginRegisterTabs}
                    />
                    <ModalRoute
                      exact
                      path={Paths.Register}
                      component={Register}
                      tabs={loginRegisterTabs}
                    />
                    <ModalRoute
                      exact
                      path={Paths.Profile}
                      component={Profile}
                      tabs={userProfileTabs}
                    />
                    <ModalRoute
                      exact
                      path={Paths.TravelItinerary}
                      component={TravelItinerary}
                      tabs={userProfileTabs}
                    />
                    <ModalRoute
                      exact
                      path={Paths.Invoices}
                      component={Invoices}
                      tabs={userProfileTabs}
                    />
                    <ModalRoute
                      exact
                      path={Paths.TravelPlan}
                      component={TravelPlans}
                      tabs={userProfileTabs}
                    />
                    <ModalRoute
                      exact
                      path={Paths.MapScreen}
                      component={MapScreen}
                      tabs={tabs}
                    />
                    <ModalRoute exact path={Paths.Cart} component={Cart} />
                    <ModalRoute
                      exact
                      path={Paths.Billing}
                      component={Billing}
                    />
                    <ModalRoute
                      exact
                      path={Paths.TermsOfUse}
                      component={TermsAndConditions}
                    />
                    <ModalRoute
                      exact
                      path={Paths.Marketplace}
                      component={Marketplace}
                    />
                  </Switch>
                </Modal>
              </MemoryRouter>
            ) : null}
          </PageVisitedProvider>
        </WidgetStyle>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
