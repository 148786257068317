import { SelectedLocation } from 'types/cache/LocationAndPeriod';

const useTravelLocationAndPeriod = travelLocationAndPeriodVar => {
  const toggleRegionSelected = regionId => {
    const travelLocationAndPeriod = travelLocationAndPeriodVar();
    const isSelected = travelLocationAndPeriod.regionIds.includes(regionId);

    isSelected
      ? travelLocationAndPeriodVar({
          ...travelLocationAndPeriod,
          regionIds: travelLocationAndPeriod.regionIds.filter(
            id => id !== regionId
          ),
        })
      : travelLocationAndPeriodVar({
        ...travelLocationAndPeriod,
        regionIds: [...travelLocationAndPeriod.regionIds, regionId],
      });
  };

  const toggleCitySelected = cityId => {
    const travelLocationAndPeriod = travelLocationAndPeriodVar();
    const isSelected = travelLocationAndPeriod.cityIds.includes(cityId);

    isSelected
      ? travelLocationAndPeriodVar({
          ...travelLocationAndPeriod,
          cityIds: travelLocationAndPeriod.cityIds.filter(id => id !== cityId),
        })
      : travelLocationAndPeriodVar({
        ...travelLocationAndPeriod,
        cityIds: [...travelLocationAndPeriod.cityIds, cityId],
      });
  };

  const setTravelPeriod = travelPeriod => {
    const travelLocationAndPeriod = travelLocationAndPeriodVar();
    const updatedTravelLocationAndPeriod = {
      ...travelLocationAndPeriod,
      travelPeriod,
    };

    travelLocationAndPeriodVar(updatedTravelLocationAndPeriod);
  };

  const getRegionIdForSearch = () => {
    const travelLocationAndPeriod = travelLocationAndPeriodVar();
    return travelLocationAndPeriod.regionIds.length
      ? travelLocationAndPeriod.regionIds[
          travelLocationAndPeriod.regionIds.length - 1
        ]
      : undefined;
  };

  const setSelectedLocations = (locations: SelectedLocation[]) => {
    const travelLocationAndPeriod = travelLocationAndPeriodVar();
    const updated = {
      ...travelLocationAndPeriod,
      selectedLocations: locations
    }

    travelLocationAndPeriodVar(updated);
  }

  return {
    toggleRegionSelected,
    toggleCitySelected,
    setTravelPeriod,
    getRegionIdForSearch,
    setSelectedLocations
  };
};

export default useTravelLocationAndPeriod;
