// Minimum 6 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
export const PASSWORD_REGEX =
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@#()$^!_%*\\-+=?&|\\/.>,< \\[\\]{}])[A-Za-z\\d@$()_#!^%*\\-+=?&|\\/.>,< \\[\\]{}]{6,}$';

// Allows text, numbers, ĆČ... Some'name, Some-Name123
export const EXCLUDE_SPECIAL_CHARS = "^[^- ][A-Za-z0-9 \u0080-\u024F'-]+$";

// Allows text, numbers, ĆČ... Some'name, Some-Name
export const EXCLUDE_SPECIAL_CHARS_AND_NUMBERS =
  "^[^- ][A-Za-z \u0080-\u024F'-]+$";

export const INPUT_HEIGHT = '40px';

export const TABS_HEIGHT = '55px';

// Date format used in input placeholders
export const DATE_FORMAT = 'dd/MM/yyyy';

// Min date used in profile pages
export const MIN_DATE = new Date(1900, 0, 1);

// Maximum children age used in select input
export const MAX_CHILDREN_AGE = 4;

export const SEARCH_DEBOUNCE_TIMEOUT = 500;

export const MODAL_TIMEOUT = 5000;
