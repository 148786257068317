import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import useIntl from 'hooks/useIntl';
import { useEffect, useState } from 'preact/hooks';
import {
  ActivityBookingField,
  ActivityBookingFieldTypes,
} from 'screens/Individual/Activity/activityModel';
import { IActivityCartItem } from 'types/cache/Cart';
import { ICountry } from 'types/cache/Country';
import validate from 'validate.js';
import BookingFieldLabel from './BookingFieldLabel';

const dest1dot = '_dest1dot_';
const allowedLinkAttributes = { ADD_ATTR: ['target'] };
const mapBookingFieldLabelToReactFormName = (label: string) =>
  label.replace(/\./g, dest1dot);
export const mapReactFormNameToBookingFieldLabel = (label: string) =>
  label.replace(new RegExp(dest1dot, 'g'), '.');
export interface AdditionalBookingFieldsConfiguration {
  requiredPerBookingFields: Array<Array<ActivityBookingField>>;
  requiredPerParticipantFields: Array<Array<ActivityBookingField>>;
  requiredPerBookingFieldsValidation: any;
  requiredPerParticipantFieldsValidation: any;
}

const chunk = array => {
  const chunkSize = 2;
  const newArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    newArray.push(chunk);
    // do whatever
  }
  return newArray;
};

const setBookingFieldsFormConfiguration = (
  field: ActivityBookingField,
  countries: ICountry[]
) => {
  return {
    ...field,
    required: field.requiredPerBooking,
    name: mapBookingFieldLabelToReactFormName(field.label), // react final form considers . as a sign for the object property and parses the label as an object - replacing the dot to circumvent the issue
    LabelComponent:
      field.fieldType === ActivityBookingFieldTypes.Boolean ? (
        <BookingFieldLabel
          html={DOMPurify.sanitize(field.label, allowedLinkAttributes)}
        />
      ) : undefined,
    maxDate:
      field.fieldType === ActivityBookingFieldTypes.Date &&
      field.label.toLowerCase().indexOf('birth') !== -1
        ? dayjs().subtract(18, 'year').toDate()
        : undefined,
    listOptionsParsed:
      field.fieldType === ActivityBookingFieldTypes.List
        ? generateFieldListOptions(field, countries)
        : undefined,
  };
};

const sortFields = (a: ActivityBookingField, b: ActivityBookingField) => {
  if (
    a.fieldType === ActivityBookingFieldTypes.Boolean &&
    b.fieldType !== ActivityBookingFieldTypes.Boolean
  ) {
    return 1;
  }
  if (
    a.fieldType !== ActivityBookingFieldTypes.Boolean &&
    b.fieldType === ActivityBookingFieldTypes.Boolean
  ) {
    return -1;
  }
  if (a.fieldType === b.fieldType) {
    return -1;
  }
};

const generateFieldListOptions = (
  field: ActivityBookingField,
  countries: ICountry[]
): Array<{ value: string; label: string }> => {
  if (field.label === 'Country') {
    return countries.map(country => ({
      value: country.country_code,
      label: country.name,
    }));
  }
  return field.listOptions
    ?.split('\r\n')
    .filter(o => o?.trim())
    .map(option => ({
      value: option,
      label: option,
    }));
};

const setParticipantFieldsFormConfiguration = (
  field: ActivityBookingField,
  t,
  countries: ICountry[]
) => {
  return {
    ...field,
    name: mapBookingFieldLabelToReactFormName(field.label), // react final form considers . as a sign for the object property and parses the label as an object - replacing the dot to circumvent the issue
    LabelComponent:
      field.fieldType === ActivityBookingFieldTypes.Boolean ? (
        <BookingFieldLabel
          html={DOMPurify.sanitize(field.label, allowedLinkAttributes)}
        />
      ) : undefined,
    required: field.requiredPerParticipant,
    validate: field.requiredPerParticipant
      ? value =>
          validate.single(value, { presence: { message: t('fieldRequired') } })
      : undefined,
    listOptionsParsed:
      field.fieldType === ActivityBookingFieldTypes.List
        ? generateFieldListOptions(field, countries)
        : undefined,
  };
};

const useAdditionalBookingFieldsConfiguration = (
  item: IActivityCartItem,
  countries: ICountry[]
) => {
  const { t } = useIntl('app.ValidationMessages');
  const [
    additionalBookingFields,
    setAdditionalBookingFields,
  ] = useState<AdditionalBookingFieldsConfiguration>({
    requiredPerBookingFields: [],
    requiredPerParticipantFields: [],
    requiredPerBookingFieldsValidation: {},
    requiredPerParticipantFieldsValidation: {},
  });
  const [
    additionalBookingDetailsState,
    setAdditionalBookingDetailsState,
  ] = useState<{
    hasAdditionalBookingFields: boolean;
    hasAdditionalPerBookingFields: boolean;
    hasAdditionalPerParticipantFields: boolean;
  }>({
    hasAdditionalBookingFields: false,
    hasAdditionalPerBookingFields: false,
    hasAdditionalPerParticipantFields: false,
  });

  useEffect(() => {
    const allRequiredPerBookingFields =
      item.fields
        ?.filter(bf => bf.visiblePerBooking)
        .map(f => setBookingFieldsFormConfiguration(f, countries))
        .sort(sortFields) || [];

    const allRequiredPerParticipantFields =
      item.fields
        ?.filter(bf => bf.visiblePerParticipant)
        .map(f => setParticipantFieldsFormConfiguration(f, t, countries))
        .sort(sortFields) || [];

    const hasAdditionalBookingFields =
      allRequiredPerBookingFields.length !== 0 ||
      allRequiredPerParticipantFields.length !== 0;

    setAdditionalBookingDetailsState({
      hasAdditionalBookingFields,
      hasAdditionalPerBookingFields: allRequiredPerBookingFields.length !== 0,
      hasAdditionalPerParticipantFields:
        allRequiredPerParticipantFields.length !== 0,
    });

    if (hasAdditionalBookingFields) {
      const requiredPerBookingFieldsValidation = allRequiredPerBookingFields.reduce(
        (accumulator, value) => {
          accumulator[`requiredPerBookingFields.${value.label}`] = {
            presence: value.required
              ? {
                  message: t('fieldRequired'),
                }
              : undefined,
          };

          return accumulator;
        },
        {}
      );

      const requiredPerBookingFields = chunk(allRequiredPerBookingFields); // chunking to smaller arrays of size 2 - creating input rows to render

      const requiredPerParticipantFields = chunk(
        allRequiredPerParticipantFields
      ); // chunking to smaller arrays of size 2 - creating input rows to render
      setAdditionalBookingFields({
        requiredPerBookingFields,
        requiredPerParticipantFields,
        requiredPerBookingFieldsValidation,
        requiredPerParticipantFieldsValidation: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return {
    hasAdditionalBookingFields:
      additionalBookingDetailsState.hasAdditionalBookingFields,
    hasAdditionalPerBookingFields:
      additionalBookingDetailsState.hasAdditionalPerBookingFields,
    hasAdditionalPerParticipantFields:
      additionalBookingDetailsState.hasAdditionalPerParticipantFields,
    additionalBookingFields,
  };
};

export default useAdditionalBookingFieldsConfiguration;
