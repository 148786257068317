import { IBasePrice, IPriceGroup } from 'types/cache/Cart';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import useIntl from 'hooks/useIntl';
import { formatPrice, sumPrices } from 'utils/price/priceOperations';
import { FunctionalComponent } from 'preact';
import Tag from 'components/Tag';
import { useState } from 'preact/hooks';
import styles from '../styles.module.css';
import { IFlexibility } from 'types/cache/TransportIndividual';

const TicketOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 325px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  padding: 10px;
  justify-content: space-between;
`;

const TicketOptionTitle = styled.p`
  font-size: 24px;
  letter-spacing: 0;
  line-height: 23px;
  padding-bottom: 15px;
`;

const TicketOptionClassPill = styled.div`
  height: 15 px;
  border-radius: 9.5px;
  background-color: ${({ theme }) => theme.colors[colorsSpec.primaryLight]};
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  font-size: 10px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
`;

const TagContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.white]};
  border-radius: 5px;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

type Props = {
  priceGroup: IPriceGroup;
  setClassOptions: ({ priceGroup, flexibility }: { priceGroup: IPriceGroup, flexibility: IFlexibility }) => void;
  checkedFlexibility: { priceGroup: IPriceGroup, flexibility: IFlexibility };
};

const TravelClassTile: FunctionalComponent<Props> = ({
  priceGroup, setClassOptions, checkedFlexibility
}) => {
  const { t } = useIntl('app.IndividualTransport');

  const getFlexibilitiesFromPriceGroup = () =>
    [...priceGroup.passengersPrices[0].priceFlexibilities]
      .sort((a, b) => a.price.amount - b.price.amount)

  const [flexibilities] = useState(getFlexibilitiesFromPriceGroup());

  const getAdditionalPriceByFlexibility = (flex: IFlexibility) => {
    const price = priceGroup.passengersPrices.reduce((partial, price) => {
      return sumPrices(partial,
        price.priceFlexibilities.find(x => x.flexibilityVariantNumber === flex.flexibilityVariantNumber).price)
    }, { amount: 0, currency: '' } as IBasePrice);

    return price;
  }

  const formatFlexTitle = (flexibility: IFlexibility) => {
    let flexTitle = flexibility.flexibility;
    if (flexibility.price.amount > 0) {
      flexTitle += ` + ${formatPrice(getAdditionalPriceByFlexibility(flexibility))}`;
    }

    return flexTitle;
  }

  const renderFlexibilities = () =>
    flexibilities.map(flex =>
      <TagContainer>
        <Tag
          id={`${priceGroup.value}${flex.flexibilityVariantNumber}`}
          input={{
            checked: checkedFlexibility?.flexibility.flexibilityVariantNumber === flex.flexibilityVariantNumber &&
              checkedFlexibility.priceGroup.value === priceGroup.value,
            onChange: () => {
              const price = getAdditionalPriceByFlexibility(flex);
              const flexSum = { ...flex, price };
              setClassOptions({ priceGroup, flexibility: flexSum })
            }
          }}
          title={formatFlexTitle(flex)}
          className={styles.TagContainerFull}
        />
      </TagContainer>
    )

  return (
    <TicketOptionContainer>
      <TicketOptionTitle>
        <strong>{t('seat')}</strong> {t('from')}{' '}
        {formatPrice(priceGroup.totalBasePrice)}
      </TicketOptionTitle>
      <TicketOptionClassPill>{priceGroup.title}</TicketOptionClassPill>
      <TagsContainer>
        {renderFlexibilities()}
      </TagsContainer>
    </TicketOptionContainer>
  );
}

export default TravelClassTile;
