import Input from 'components/Input';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import { StyledContainer, StyledContent } from '../../components';

const StyledLogo = styled.img`
  width: 72px;
  height: 40px;
`;

const Label = styled.label`
  font-size: 12px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  display: block;
  margin-bottom: 6px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

type Props = { item: any };

const AccordionContent: FunctionalComponent<Props> = ({ item }) => {
  const { t } = useIntl('app.Controls');

  // const getInvoiceNumber = () => {
  //   const { travel_itinerary = {} } = item;
  //   let orderNumber = null;
  //   Object.values(travel_itinerary).forEach((itinerary :any) => {
  //     if(Array.from(itinerary).length === 1){
  //       orderNumber = itinerary[0].external_id;
  //     }
  //   });
  //   return orderNumber !== null ? orderNumber : item?.invoice_number;
  // }

  // getInvoiceNumber();

  return (
    <StyledContent>
      <StyledContainer>
        <Label>{t('productOwner')}</Label>
        <StyledRow>
          <StyledLogo
            src={item?.product_owner_logo || 'assets/images/hotel-logo.png'}
          />
          <Input
            isDisabled
            isDisabledStyleApplied={false}
            input={{ value: item?.product_owner_name }}
          />
        </StyledRow>
      </StyledContainer>

      <StyledContainer>
        <Label>{t('invoiceNumber')}</Label>
        <StyledRow>
          <StyledLogo
            src={item?.product_owner_logo || 'assets/images/hotel-logo.png'}
          />
          <Input
            isDisabled
            isDisabledStyleApplied={false}
            input={{ value: item?.invoice_number }}
          />
        </StyledRow>
      </StyledContainer>
    </StyledContent>
  );
};

export default AccordionContent;
