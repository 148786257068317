import { ReactiveVar } from '@apollo/client';
import { Cart, CartItem, Traveler, TravelerTypes } from 'types/cache/Cart';

export enum CartActionTypes {
  Add,
  Remove,
  AddEnvironmentCompensation,
  RemoveEnvironmentCompensation,
  TermsAndPrivacyChecked,
  TermsAndPrivacyUnchecked,
  CleanCart,
  AddTraveler,
  UpdateTravelers,
  UpdateLeadTraveler,
}

export function useCart (cartVar: ReactiveVar<Cart>) {
  const clearCart = () => {
    cartVar({
      cartItems: [],
      price: { amount: 0, currency: '' },
      travelItineraryId: null,
    });
  };

  const setCartItem = (payload: any, action: CartActionTypes) => {
    const oldValue = cartVar();
    const { cartItems, price = { amount: 0, currency: '' } } = oldValue;

    if (action === CartActionTypes.CleanCart) {
      clearCart();
      return;
    }

    const cartItemIdx = cartItems.findIndex(
      i => i.id === payload.id && i.type === payload.type
    );
    const newPrice = price;

    switch (action) {
      case CartActionTypes.TermsAndPrivacyChecked:
        if (cartItems[cartItemIdx]) {
          cartItems[cartItemIdx] = {
            ...cartItems[cartItemIdx],
            isTermsAndPrivacyChecked: true,
          };
        } else {
          cartItems.push({ id: payload.id, isTermsAndPrivacyChecked: true });
        }
        break;

      case CartActionTypes.TermsAndPrivacyUnchecked:
        if (cartItems[cartItemIdx]) {
          cartItems[cartItemIdx] = {
            ...cartItems[cartItemIdx],
            isTermsAndPrivacyChecked: false,
          };
        } else {
          cartItems.push({ id: payload.id, isTermsAndPrivacyChecked: false });
        }
        break;

      case CartActionTypes.Add:
        cartItems.push({
          ...payload,
          travelerList: [
            {
              firstName: '',
              lastName: '',
              dateOfBirth: null,
              nationality: '',
              country: '',
              gender: '',
              type: TravelerTypes.Lead,
            },
          ],
        });
        newPrice.amount += payload.item.price.amount;
        break;

      case CartActionTypes.Remove:
        cartItems.splice(cartItemIdx, 1);
        newPrice.amount -= payload.item.price.amount;
        break;

      case CartActionTypes.AddEnvironmentCompensation:
        newPrice.amount += payload.item.price.amount;
        cartItems.push(payload);
        break;

      case CartActionTypes.RemoveEnvironmentCompensation:
        newPrice.amount -= payload.item.price.amount;
        cartItems.splice(cartItemIdx, 1);
        break;

      case CartActionTypes.UpdateTravelers:
        cartItems[cartItemIdx].travelerList = payload.travelerList;
        break;

      case CartActionTypes.UpdateLeadTraveler:
        cartItems.forEach((item: CartItem, index: number) => {
          cartItems[index].travelerList = cartItems[index].travelerList.map(
            (traveler: Traveler) => {
              if (traveler.type === TravelerTypes.Lead) {
                traveler = payload.leadTraveler;
                return traveler;
              }
              return traveler;
            }
          );
          return item;
        });
        break;

      default:
        console.error('Action type not supported');
    }

    const updated = {
      ...oldValue,
      cartItems,
      price: newPrice,
    };
    cartVar(updated);
  };

  const setTravelItineraryId = (travelItineraryId: string) => {
    const oldValue = cartVar();

    const updated = {
      ...oldValue,
      travelItineraryId,
    };
    cartVar(updated);
  };

  return { setCartItem, setTravelItineraryId };
}
