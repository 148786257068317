import ContentSizeAwareContainer from 'components/ContentSizeAwareContainer';
import MultiSelect from 'components/MultiSelect';
import Tag from 'components/Tag';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';

const StyledLocationPicker = styled.div`
  flex: 1;
  padding: 10px 0 10px 10px;
  position: relative;
  width: 100%;
`;

const StyledContentSizeAwareContainer = styled(ContentSizeAwareContainer)`
  display: flex;
  flex-wrap: wrap;
  max-height: 140px;
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const MultiSelectContainer = styled.div`
  flex-basis: 100%;
`;

const TagContainer = styled.div`
  margin: 10px 10px 0 10px;
`;

type Props = {
  input: any;
  options: any[];
  title: string;
  id: string;
};

const LocationPicker: FunctionalComponent<Props> = ({
  title = '',
  input = {},
  options = [],
  id,
}) => (
  <StyledLocationPicker>
    <MultiSelectContainer id={id}>
      <MultiSelect title={title} input={input} options={options} />
    </MultiSelectContainer>
    <StyledContentSizeAwareContainer>
      {options.slice(0, 4).map(option => (
        <TagContainer>
          <Tag
            {...option}
            input={{
              checked: option.selected,
              ...input,
              onClick: () => input.onClick(option.value),
            }}
          />
        </TagContainer>
      ))}
    </StyledContentSizeAwareContainer>
  </StyledLocationPicker>
);

export default LocationPicker;
