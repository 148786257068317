import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { colorsSpec } from 'styles';

const StyledTimePeriond = styled.div`
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  border-radius: 5px;
  padding: 10px 0 10px 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
`;

const TimePeriodButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondary]};
  color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  padding: 10px 10px;
  font-size: 12px;
  font-weight: normal;
  outline: none;
  cursor: pointer;
  margin-bottom: 7px;

  ${({ theme, isSelected }) =>
    isSelected &&
    `
    background-color: ${theme.colors[colorsSpec.secondaryLight]};
    border: 1px solid ${theme.colors[colorsSpec.secondaryLight]};
    color: ${theme.colors[colorsSpec.white]};
    `};
`;

type Props<T> = {
  periods: T[];
  onClick: (period: T) => void;
  value: T;
  valueExtractor?: (period: T) => string;
};

const TimePeriod = <T extends FunctionalComponent>({
  periods = [],
  onClick = () => 0,
  value = null,
  valueExtractor = period => period.toString(),
}: Props<T>) => (
  <StyledTimePeriond>
    {periods.map((period, idx) => (
      <TimePeriodButton
        type="button"
        isSelected={value && idx === periods.indexOf(value)}
        onClick={() => onClick && onClick(period)}
      >
        {valueExtractor(period)} (local)
      </TimePeriodButton>
    ))}
  </StyledTimePeriond>
);

export default TimePeriod;
