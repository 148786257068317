import { gql } from '@apollo/client';

export const GET_PASSENGER_CATEGORIES_INTEGRATION = gql`
  mutation ($transportType: String!) {
    getPassengerCategories(transportType: $transportType) {
      id
      title
      value
      passengerAgeRange
    }
  }
`;

export const GET_INTEGRATIONS_TRANSPORTATION_OFFER = gql`
  mutation (
    $originLocationName: String
    $destinationLocationName: String
    $departureDate: date!
    $returnDate: date
    $nonStop: Boolean
    $currencyCode: String
    $transportationType: [String]!
    $passengers: [passenger]!
    $hasPets: Boolean
    $travelClass: String
    $travelClassMix: Boolean
    $cabinBagCount: Int
    $checkedBagCount: Int
    $pageSize: Int
    $pageNumber: Int
  ) {
    getTransportation(
      originLocationCode: $originLocationName
      destinationLocationCode: $destinationLocationName
      originLocationName: null
      destinationLocationName: null
      departureDateFrom: $departureDate
      returnDate: $returnDate
      nonStop: $nonStop
      currencyCode: $currencyCode
      transportationType: $transportationType
      passengers: $passengers
      hasPets: $hasPets
      travelClass: $travelClass
      travelClassMix: $travelClassMix
      cabinBagCount: $cabinBagCount
      checkedBagCount: $checkedBagCount
      pageSize: $pageSize
      pageNumber: $pageNumber
    ) {
      flightOffer
      railOffer
    }
  }
`;

export const GET_INTEGRATION_TRANSPORTATION_DETAILS = gql`
  mutation (
    $journeyId: Int!
    $hasPets: Boolean
    $passengers: [passenger]!
    $originLocationCode: String!
    $destinationLocationCode: String!
    $departureDateFrom: date!
    $transportationType: [String]!
    $uniqueTransportId: String!
  ) {
    getTransportationDetails(
      journeyId: $journeyId
      hasPets: $hasPets
      passengers: $passengers
      originLocationCode: $originLocationCode
      destinationLocationCode: $destinationLocationCode
      departureDateFrom: $departureDateFrom
      transportationType: $transportationType
      uniqueTransportId: $uniqueTransportId
    ) {
      id
      itineraries
      productOwner
      productLocation
      duration
      integration
    }
  }
`;

export const GET_INTEGRATIONS_ACTIVITIES = gql`
  mutation (
    $people: Int!
    $endTime: date!
    $location: String!
    $startTime: date!
    $activityTypes: [String]
    $pageSize: Int
    $pageNumber: Int
  ) {
    getActivities(
      people: $people
      location: $location
      startTime: $startTime
      endTime: $endTime
      activityTypes: $activityTypes
      pageSize: $pageSize
      pageNumber: $pageNumber
    ) {
      activities {
        displayPrice
        id
        supplierName
        image {
          largeUrl
          mediumUrl
          thumbnailUrl
        }
        location
        name
        shortDescription
      }
    }
  }
`;

export const GET_INTEGRATIONS_ACTIVITY = gql`
  mutation ($id: String!, $people: Int!, $endTime: date!, $startTime: date!) {
    getActivityDetails(
      id: $id
      people: $people
      endTime: $endTime
      startTime: $startTime
    ) {
      bookingFields
      bookingTimeRequired
      description
      displayPrice
      id
      images {
        largeUrl
        mediumUrl
        thumbnailUrl
      }
      productLocation
      name
      quantityRequiredMax
      quantityRequiredMin
      sessions
      unitLabel
      productType
      productOwner
      supplierName
      terms
      duration
      integration
    }
  }
`;

export const GET_INTEGRATION_AVAILABLE_ACCOMMODATIONS = gql`
  mutation (
    $city: String!
    $arrivalDate: date!
    $departureDate: date!
    $numOfAdults: Int!
    $childrenAges: [Int]!
    $numOfRooms: Int!
  ) {
    getAvailableAccommodations(
      city: $city
      arrivalDate: $arrivalDate
      departureDate: $departureDate
      numOfAdults: $numOfAdults
      childrenAges: $childrenAges
      numOfRooms: $numOfRooms
    ) {
      accommodations {
        checkInTime
        city
        country
        integration
        latitude
        longitude
        name
        productOwner
        stayEstablishmentId
        minimumPriceRate {
          currency
          endDate
          price
          startDate
        }
      }
      pageNumber
      totalCount
      totalPages
    }
  }
`;

export const GET_INTEGRATIONS_AVAILABLE_ROOMS = gql`
  mutation (
    $city: String!
    $arrivalDate: date!
    $departureDate: date!
    $currency: String!
    $numOfAdults: Int!
    $childrenAges: [Int]!
    $numOfRooms: Int!
    $integration: String!
    $stayEstablishmentId: String!
  ) {
    getAccommodationAvailableRooms(
      city: $city
      arrivalDate: $arrivalDate
      departureDate: $departureDate
      currency: $currency
      numOfAdults: $numOfAdults
      childrenAges: $childrenAges
      numOfRooms: $numOfRooms
      integration: $integration
      stayEstablishmentId: $stayEstablishmentId
    ) {
      count
      rate
      roomType
      roomTypeId
      roomDescription
      facilities
      accommodationName
      cityName
      priceRateCode
      roomCapacity
    }
  }
`;

export const CHECK_AVAILABILTY_INTEGRATIONS_TRANSPORT = gql`
  mutation (
    $currency: String
    $bagCount: Int
    $id: String!
    $passengers: [passenger]!
  ) {
    checkTransportationAvailability(
      id: $id
      bagCount: $bagCount
      currency: $currency
      passengers: $passengers
    ) {
      price
      priceChange
      flightsInvalid
    }
  }
`;

export const VALIDATE_BOOK_ACTIVITY = gql`
  mutation (
    $customer: customer!
    $fields: [bookingField]
    $items: [activityItem]!
    $payments: [payment]!
    $paymentIntentId: String!
    $globalId: String!
    $sendNotifications: Boolean
  ) {
    validateActivityBooking(
      fields: $fields
      items: $items
      payments: $payments
      customer: $customer
      paymentIntentId: $paymentIntentId
      globalId: $globalId
      sendNotifications: $sendNotifications
    ) {
      totalPaid
      totalDue
    }
  }
`;

export const BOOK_ACTIVITY = gql`
  mutation (
    $customer: customer!
    $fields: [bookingField]
    $items: [activityItem]!
    $payments: [payment]!
    $datePaid: date!
    $paymentIntentId: String!
    $globalId: String!
    $sendNotifications: Boolean
  ) {
    bookActivity(
      fields: $fields
      items: $items
      payments: $payments
      customer: $customer
      datePaid: $datePaid
      paymentIntentId: $paymentIntentId
      globalId: $globalId
      sendNotifications: $sendNotifications
    ) {
      orderNumber
      status
    }
  }
`;

export const BOOK_ACCOMMODATION = gql`
  mutation (
    $arrivalDate: date!
    $departureDate: date!
    $roomType: String!
    $priceRateCode: String!
    $integration: String!
    $stayEstablishmentId: String!
    $customer: customer!
    $numOfAdults: Int!
    $childrenAges: [Int]!
    $paymentIntentId: String!
    $globalId: String!
  ) {
    bookAccommodation(
      arrivalDate: $arrivalDate
      departureDate: $departureDate
      roomType: $roomType
      priceRateCode: $priceRateCode
      integration: $integration
      stayEstablishmentId: $stayEstablishmentId
      customer: $customer
      numOfAdults: $numOfAdults
      childrenAges: $childrenAges
      paymentIntentId: $paymentIntentId
      globalId: $globalId
    ) {
      status
      reservationId
    }
  }
`;

export const BOOK_GROUP_ACCOMMODATION = gql`
  mutation (
    $arrivalDate: date!
    $departureDate: date!
    $roomType: String!
    $priceRateCode: String!
    $integration: String!
    $stayEstablishmentId: String!
    $customer: customer!
    $rooms: [Room!]!
    $paymentIntentId: String!
    $globalId: String!
  ) {
    bookGroupAccommodation(
      arrivalDate: $arrivalDate
      departureDate: $departureDate
      roomType: $roomType
      priceRateCode: $priceRateCode
      integration: $integration
      stayEstablishmentId: $stayEstablishmentId
      customer: $customer
      rooms: $rooms
      paymentIntentId: $paymentIntentId
      globalId: $globalId
    ) {
      status
      reservationIds
      groupReservationId
    }
  }
`;

export const BOOK_TRANSPORTATION = gql`
  mutation (
    $journeys: [journey]!
    $passengers: [passenger]!
    $integration: String!
    $paymentIntentId: String!
    $globalId: String!
  ) {
    bookTransportation(
      passengers: $passengers
      journeys: $journeys
      integration: $integration
      paymentIntentId: $paymentIntentId
      globalId: $globalId
    ) {
      id
      infoMessages
      items
    }
  }
`;

export const GET_ACCOMMODATION_RESTRICTIONS = gql`
  mutation getAccommodationRestrictions(
    $arrivalDate: date!
    $departureDate: date!
    $stayEstablishmentId: String!
  ) {
    getRestrictions(
      arrivalDate: $arrivalDate
      departureDate: $departureDate
      stayEstablishmentId: $stayEstablishmentId
    ) {
      date
    }
  }
`;
