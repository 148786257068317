import styled from 'styled-components';
import { colorsSpec } from 'styles/defaultTheme';

const BlurredModal = styled.div`
  position: absolute;
  width: 800px;
  height: 803px;
  top: 141px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  mix-blend-mode: normal;
  backdrop-filter: blur(10px);
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};

  div {
    width: 634px;
    min-height: 181px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(60px);
    border-radius: 4px;
    padding: 15px 15px 25px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h4 {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 145%;
    letter-spacing: 0.03em;
    padding: 10px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 145%;
    text-align: center;
    letter-spacing: 0.03em;
  }

  span {
    font-weight: bold;
  }
`;

export default BlurredModal;
