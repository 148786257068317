import Input from 'components/Input';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import { StyledContainer, StyledContent } from '../../components';

const Label = styled.label`
  font-size: 12px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  display: block;
  margin-bottom: 6px;
`;

type Props = { item: any };

const AccordionTitle: FunctionalComponent<Props> = ({ item }) => {
  const { t } = useIntl('app.Controls');

  return (
    <StyledContent>
      <StyledContainer>
        <Label>{t('description')}</Label>
        <Input
          isDisabled
          isDisabledStyleApplied={false}
          input={{ value: item?.description }}
        />
      </StyledContainer>

      <StyledContainer>
        <Label>{t('dateOfInvoice')}</Label>
        <Input
          isDisabled
          isDisabledStyleApplied={false}
          input={{ value: dayjs(item?.date).format('DD.MM.YYYY') }}
        />
      </StyledContainer>
    </StyledContent>
  );
};

export default AccordionTitle;
