import BinIcon from 'assets/icons/cart-trash-bin.svg';
import CollapseIcon from 'assets/icons/details-collapse-icon.svg';
import ExpandIcon from 'assets/icons/details-expand-icon.svg';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import useIntl from 'hooks/useIntl';
import useItemIcon from 'hooks/useItemIcon';
import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';
import { cartVar } from 'screens/Cart/cache';
import { CartActionTypes, useCart } from 'screens/Cart/useCart';
import styled from 'styled-components';
import { colorsSpec } from 'styles/defaultTheme';
import { CartItem, CartItemType, Traveler } from 'types/cache/Cart';
import { CartTypes } from 'types/shared/Cart';
import CartItemDetails from '../CartItemDetails';

const CartItemContainer = styled.div`
  width: 90%;
  background: ${({ theme }) => theme.colors[colorsSpec.white]};
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  /* box-sizing: border-box; */
  border-radius: 4px;
  /* height: ${({ isOpen }) => (isOpen ? '410px' : 'auto')}; */
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 10px 20px;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    overflow: scroll;
  }
`;

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CartItemTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 2;
  margin-left: 20px;
  max-height: 64px;
`;

const CartItemCheckbox = styled(Checkbox)`
  span {
    border: 1px solid ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  }
`;

const CartItemIconContainer = styled.div`
  width: 50px;
  height: 31px;
`;

const CartItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CartItemTitle = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
`;

const CartItemDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  flex: 1;
  justify-content: end;
`;

const CartItemActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CartItemDetailsTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px;
  margin: 3px 0px 10px 12px;
`;

const CartItemDetailsText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  padding-right: 10px;
`;

const RemoveButton = styled(Button)`
  color: ${({ theme }) => theme.colors[colorsSpec.white]};
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  border-radius: 25px;
  height: 18px;
  min-width: 89px;
  padding: 0px;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
  }

  img {
    width: 10px;
    height: 10px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors[colorsSpec.primary]};
    color: ${({ theme }) => theme.colors[colorsSpec.secondary]};

    img {
      filter: brightness(0) saturate(100%) invert(66%) sepia(94%) saturate(525%)
        hue-rotate(1deg) brightness(104%) contrast(103%);
    }
  }
`;

const ExpandCollapseIcon = styled.img`
  cursor: pointer;
`;

const CartItemIcon = styled.img`
  filter: invert(53%) sepia(0%) saturate(6%) hue-rotate(210deg)
    brightness(95%) contrast(90%);
`;

interface IProps {
  id: number;
  item: CartItemType;
  type: CartTypes;
  marketplaceItems: CartItem[];
  travelerList: Traveler[];
  setCheckedCartItems: (CartItem) => void;
}

const CartItemComponent: FunctionalComponent<IProps> = ({
  id,
  item,
  type,
  marketplaceItems,
  travelerList,
  setCheckedCartItems,
}) => {
  const { t } = useIntl('app.Cart');

  const { setCartItem } = useCart(cartVar);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const marketplaceItem: CartItem = marketplaceItems.find(
    i => i.id === id && i.type === type
  );
  const checkboxValue = !!marketplaceItem;

  const { icon } = useItemIcon(type, item);

  const handleItemCheck = (isChecked: boolean): void => {
    if (isChecked) {
      setCheckedCartItems([
        ...marketplaceItems,
        {
          id,
          item,
          type,
          travelerList,
        },
      ]);
    } else {
      setCheckedCartItems(
        marketplaceItems.filter((i: CartItem) => i.id !== id)
      );
    }
  };

  return (
    <CartItemContainer isOpen={isOpen}>
      <AccordionContainer>
        <CartItemTitleContainer>
          <CartItemCheckbox
            input={{
              checked: checkboxValue,
              onChange: e => handleItemCheck(e.target.checked),
            }}
          />
          <CartItemIconContainer>
            <CartItemIcon src={icon} />
          </CartItemIconContainer>
          <CartItemTitleWrapper>
            <CartItemTitle>{item.title}</CartItemTitle>
          </CartItemTitleWrapper>
        </CartItemTitleContainer>
        <CartItemDetailsWrapper>
          {/* {CartItemMainInfo(id, item)[type]}  removed as requested in ticket: https://epti-com.atlassian.net/browse/DES-626 */}
          <CartItemActionsContainer>
            <CartItemDetailsTextWrapper>
              <CartItemDetailsText>{t('details')}</CartItemDetailsText>
              <ExpandCollapseIcon
                src={isOpen ? CollapseIcon : ExpandIcon}
                onClick={() => setIsOpen(!isOpen)}
              />
            </CartItemDetailsTextWrapper>
            <RemoveButton
              title={t('removeButton')}
              icon={BinIcon}
              onClick={() =>
                setCartItem({ id, item, type }, CartActionTypes.Remove)
              }
            />
          </CartItemActionsContainer>
        </CartItemDetailsWrapper>
      </AccordionContainer>
      {isOpen ? <>{CartItemDetails(id, item)[type]}</> : null}
    </CartItemContainer>
  );
};

export default CartItemComponent;
