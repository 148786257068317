import { scrubInFocusItems } from './config';

const useActivitiesDetails = activitiesDetailsVar => {
  const setInFocusItems = activities => {
    const activitiesDetails = activitiesDetailsVar();

    activitiesDetailsVar({
      ...activitiesDetails,
      inFocusItems: scrubInFocusItems(activities),
    });
  };

  const setActivitiesCategories = activitiesCategories => {
    const activitiesDetails = activitiesDetailsVar();

    activitiesDetailsVar({
      ...activitiesDetails,
      activitiesCategories,
    });
  };

  const toggleActivityCategorySelected = activityCategory => {
    const activitiesDetails = activitiesDetailsVar();
    const isSelected = activitiesDetails.selectedActivitiesCategories.includes(
      activityCategory
    );

    isSelected
      ? activitiesDetailsVar({
          ...activitiesDetails,
          selectedActivitiesCategories: activitiesDetails.selectedActivitiesCategories.filter(
            category => category !== activityCategory
          ),
        })
      : activitiesDetailsVar({
          ...activitiesDetails,
          selectedActivitiesCategories: activitiesDetails.selectedActivitiesCategories.concat(
            activityCategory
          ),
        });
  };

  return {
    toggleActivityCategorySelected,
    setInFocusItems,
    setActivitiesCategories,
  };
};

export default useActivitiesDetails;
