import { FunctionalComponent } from 'preact';
import styled from 'styled-components';

const Container = styled.div`
  min-height: ${({ minHeight }) => minHeight || 170}px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.default};
`;

type Props = {
  config: EmptyStateConfig;
};

export type EmptyStateConfig = {
  title?: string;
  minHeight?: number;
};

const EmptyState: FunctionalComponent<Props> = ({ config }) => (
  <Container {...config}>
    <Title>{config.title}</Title>
  </Container>
);
export default EmptyState;
