import GetGestLogoImage from 'assets/images/GetGest.svg';
import ModalHeader from 'components/ModalHeader';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';

const LogoImg = styled.img`
  height: ${props => (props.large ? '95px' : '55px')};
`;
const GetGestModalHeader = () => (
  <ModalHeader title={<LogoImg src={GetGestLogoImage} />} />
);

interface ILogoProps {
  large?: boolean;
}
export const GetGestLogo: FunctionalComponent<ILogoProps> = () => (
  <LogoImg src={GetGestLogoImage} />
);
export default GetGestModalHeader;
