import { FunctionComponent } from 'preact';
import styled from 'styled-components';

export enum MessageType {
  Info,
  Warning,
  Error,
  Neutral
}

const Container = styled.div`
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  background-color: ${({ theme: { colors }, type }) =>
    type === MessageType.Error
      ? colors.danger
      : type === MessageType.Info
      ? colors.accentLight
      : type === MessageType.Neutral
      ? colors.secondaryDark
      : colors.warning};
  color: ${({ theme: { colors } }) => colors.white};
  display: flex;
  min-height: 32px;
  margin: 10px 0 0 0;
  padding: 10px;

  @media (max-width: 576px) {
    margin: 10px 26px 25px 26px;
  }
`;

const TitleContainer = styled.span`
  display: flex;
  font-size: 12px;
  color: ${({ theme: { colors } }) => colors.white};
  padding: 9px 0px;

  a {
    color: ${({ theme: { colors } }) => colors.white}
  }
`;

const Icon = styled.img`
  margin-left: 10px;
  margin-right: 10px;
`;

const MailTo = styled.a`
  padding: 0px 3px;
`;

type Props = {
  icon: string;
  text: string;
  type?: MessageType;
  email?: string;
};

const InfoDisclaimer: FunctionComponent<Props> = ({
  icon,
  text,
  type = MessageType.Info,
}) => {
  return (
    <Container type={type}>
      <Icon src={icon} />
      <TitleContainer>{text}</TitleContainer>
    </Container>
  );
};

export const SupportMailInfoDisclaimer: FunctionComponent<Props> = ({
  icon,
  text,
  email,
  type = MessageType.Neutral,
}) => {
  return (
    <Container type={type}>
      <Icon src={icon} />
      <TitleContainer>{text}
        <MailTo href={`mailTo:${email}`}>{` ${email}`}</MailTo>
      </TitleContainer>
    </Container>
  );
};

export default InfoDisclaimer;