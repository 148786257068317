import BinIcon from 'assets/icons/trash-bin.svg';
import TransportIcon from 'components/TransportIcon/';
import useIntl from 'hooks/useIntl';
import { Fragment, FunctionalComponent } from 'preact';
import {
  CartItem,
  CartItemInfo,
  LargeCard,
  RemoveButton,
  Row,
  Title,
  Value,
} from 'screens/Cart/components';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import { formatPrice } from 'utils/price/priceOperations';

const DotIcon = styled.span`
  display: block;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: ${({ theme }) => theme.colors[colorsSpec.accent]};
  position: relative;
  z-index: 1;
`;

const TransportTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    left: 0;
    right: 0;
    top: 50%;
    border-top: 1px dashed ${({ theme }) => theme.colors[colorsSpec.accent]};
    z-index: 0;
  }
`;

const CityFrom = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors[colorsSpec.accentDark]};
  font-size: 12px;
`;

const CityTo = styled.div`
  text-align: center;
  font-size: 12px;
`;

const CartItemContainer = styled.div`
  width: ${({ isSmall }) => (isSmall ? '140px' : '20%')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors[colorsSpec.white]};
  position: relative;
  bottom: ${({ isOpen }) => (isOpen ? '55px' : '0')};
  left: 4px;
  height: 120px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @media (max-width: 767.98px) {
    width: 40%;
    bottom: 0;
  }

  @media (max-width: 576px) {
    width: 30%;
    bottom: 45px;
    left: 0;
    margin-left: 8px;
  }
`;

const TransportImageContainer = styled.div`
  min-height: 100px;
  width: 70%;
  margin: 0 auto;
  padding-top: 30px;
  position: relative;
  margin-left: 5px;
`;

const Price = styled.span`
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 0;
  background-color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  color: ${({ theme }) => theme.colors[colorsSpec.white]};
  font-size: 10px;
  padding: 5px;
  text-align: center;
  font-weight: 600;
`;

const ItemTitle = styled.p`
  color: ${({ theme }) => theme.colors[colorsSpec.white]};
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 0;
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  height: 26px;
  display: flex;
  width: 100%;
  align-items: center;
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;
`;

const RemoveButtonComponent = styled(RemoveButton)`
  @media (max-width: 576px) {
    top: ${({ isOpen }) => (isOpen ? '183px' : '15px')};
    position: absolute;
    left: 5px;

    ${({ isOpen }) =>
      !isOpen &&
      `top: 58px;
`};
  }
`;

type Props = {
  item: any; // TODO
  onRemove?: (item: any) => void;
  isSmall?: boolean;
  isOpen?: boolean;
};

const TransportCartItem: FunctionalComponent<Props> = ({
  item,
  onRemove,
  isSmall = false,
  isOpen = false,
}) => {
  const { t } = useIntl('app.Cart');
  return (
    <CartItem isSmall={isSmall} isOpen={isOpen}>
      <CartItemContainer isSmall={isSmall} isOpen={isOpen}>
        <TransportImageContainer>
          <CityFrom>{item.departure_station_code}</CityFrom>
          <TransportTypeContainer>
            <DotIcon />
            <TransportIcon type={item.transport_type} />
            <DotIcon />
          </TransportTypeContainer>
          <CityTo>{item.arrival_station_code}</CityTo>
        </TransportImageContainer>
        <Price>{formatPrice(item.price)}</Price>
        <ItemTitle>Transport</ItemTitle>
      </CartItemContainer>

      {!isSmall && (
        <Fragment>
          <CartItemInfo>
            <Row>
              <LargeCard>
                <Title>{t('transportOfferNumbers')}</Title>
                <Value>{t('transportOffer')}</Value>
              </LargeCard>
              <LargeCard>
                <Title>{t('transportType')}</Title>
                <Value>{item.transport_type}</Value>
              </LargeCard>
              <LargeCard>
                <Title>{t('transportDestinations')}</Title>
                <Value>
                  {item.city_from}-{item.city_to}
                </Value>
              </LargeCard>
            </Row>
          </CartItemInfo>
          <RemoveButtonComponent
            title={t('removeButton')}
            icon={BinIcon}
            isOpen={isOpen}
            onClick={() => onRemove(item)}
          />
        </Fragment>
      )}
    </CartItem>
  );
};

export default TransportCartItem;
