import { VNode } from 'preact';
import { IProductLocation } from 'types/cache/ProductLocation';

interface Price {
  amount: number;
  currency: string;
}

export interface PriceOption {
  id: string;
  label: string;
  name: string;
  maxQuantity: number;
  minQuantity: number;
  price: number;
  priceGroupType: string;
  productCode: string;
  seatsUsed: number;
}

interface ActivityLocation {
  latitude: number;
  longitude: number;
  address: string;
  countryCode: number;
}

export interface Session {
  allDay: boolean;
  startTime: string;
  startTimeLocal: string;
  endTime: string;
  endTimeLocal: string;
  seatsAvailable: number;
  prices: Array<PriceOption>;
}

export interface ActivityBookingField {
  fieldType: ActivityBookingFieldTypes;
  label: string;
  name: string;
  LabelComponent: VNode | undefined;
  listOptions: string;
  listOptionsParsed: Array<{ value: string; label: string }>;
  requiredPerBooking: boolean;
  requiredPerParticipant: boolean;
  value: string; // all values are converted to string
  visiblePerBooking: boolean;
  visiblePerParticipant: boolean;
  required: boolean;
  maxDate: Date;
  validate: (value: any) => void;
}

export enum ActivityBookingFieldTypes {
  String = 'String',
  List = 'List',
  Boolean = 'Boolean',
  Phone = 'Phone',
  Date = 'Date',
  Hidden = 'Hidden',
}

export interface Image {
  largeUrl: string;
  mediumUrl: string;
  thumbnailUrl: string;
}

export interface ActivityModel {
  id: string;
  name: string;
  images: Array<Image>;
  description: string;
  displayPrice: Price;
  bookingFields: Array<ActivityBookingField>;
  bookingTimeRequired: boolean;
  quantityRequiredMax: number;
  quantityRequiredMin: number;
  sessions: Array<Session>;
  unitLabel: string;
  productType: string;
  productOwner: string;
  supplierName: string;
  terms: string;
  productLocation: IProductLocation;
  duration: number;
  integration: string;
}

export interface ActivityOverview {
  id: string;
  name: string;
  displayPrice: Price;
  image: Image;
  location: ActivityLocation;
  shortDescription: string;
}

export interface ActivitySearchResponse {
  activities: ActivityOverview[];
}
