import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import { Field } from 'react-final-form';
import { InputRow } from 'screens/Account/Billing/CheckoutForm';
import { InputColumnLeft, InputColumnRight } from 'shared/components';
import { ICountry } from 'types/cache/Country';
import {
  FieldTitle,
  InputContainer,
  SectionTitle,
  StyledInput,
  StyledSelectComponent,
} from './StyledComponents';

type BillingDetailsFormProps = {
  countries: ICountry[];
};

const BillingDetailsSectionForm: FunctionalComponent<BillingDetailsFormProps> = ({
  countries,
}) => {
  const { t } = useIntl('app.Marketplace');
  return (
    <>
      <SectionTitle>
        <li>{t('billingDetails')}</li>
      </SectionTitle>
      <InputRow>
        <InputColumnLeft>
          <InputContainer>
            <FieldTitle>{t('firstName')}</FieldTitle>
            <Field
              placeholder={t('firstName')}
              required
              component={StyledInput}
              name="firstNameBilling"
              id="firstNameBilling"
            />
          </InputContainer>
        </InputColumnLeft>

        <InputColumnRight>
          <InputContainer>
            <FieldTitle>{t('lastName')}</FieldTitle>
            <Field
              placeholder={t('lastName')}
              required
              name="lastNameBilling"
              id="lastNameBilling"
              component={StyledInput}
            />
          </InputContainer>
        </InputColumnRight>
      </InputRow>
      <InputRow>
        <InputColumnLeft>
          <InputContainer>
            <FieldTitle>{t('country')}</FieldTitle>
            <Field id="countryBilling" name="countryBilling" required>
              {({ meta, input }) => (
                <StyledSelectComponent
                  required
                  id="countryBilling"
                  name="countryBilling"
                  meta={meta}
                  input={input}
                  options={countries.map(country => ({
                    value: country.name,
                    label: country.name,
                  }))}
                  placeholder={t('country')}
                  isSearchable
                  isErrorVisible={false}
                />
              )}
            </Field>
          </InputContainer>
        </InputColumnLeft>

        <InputColumnLeft>
          <InputContainer>
            <FieldTitle>{t('cityName')}</FieldTitle>
            <Field
              placeholder={t('cityName')}
              required
              component={StyledInput}
              name="cityBilling"
              id="cityBilling"
            />
          </InputContainer>
        </InputColumnLeft>
      </InputRow>
      <InputRow>
        <InputColumnLeft>
          <InputContainer>
            <FieldTitle>{t('region')}</FieldTitle>
            <Field
              placeholder={t('region')}
              component={StyledInput}
              name="regionBilling"
              id="regionBilling"
            />
          </InputContainer>
        </InputColumnLeft>

        <InputColumnRight>
          <InputContainer>
            <FieldTitle>{t('zipCode')}</FieldTitle>
            <Field
              placeholder={t('zipCode')}
              required
              name="zipCodeBilling"
              id="zipCodeBilling"
              component={StyledInput}
            />
          </InputContainer>
        </InputColumnRight>
      </InputRow>
    </>
  );
};

export default BillingDetailsSectionForm;
