import {
  Container,
  ControlsContainer,
  EndMessage,
  InfiniteScrollLoader,
  ItemImage,
  LeftColumn,
  RightColumn,
  Row,
  SortContainer,
  SortLabel,
  TileContainer,
} from '../components';
import { activitiesListVar, ActivitiesSort } from './cache';
import { useActivitiesList } from './useActivityList';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { GET_INTEGRATIONS_ACTIVITIES } from 'apollo/myDesti/mutations';
import { GET_ALL_ACTIVITY_TYPES } from 'apollo/myDesti/queries';
import EmptyState from 'components/EmptyState';
import InfoDisclaimer from 'components/InfoDisclaimer';
import Loading from 'components/Loading';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import SelectComponent, { Option } from 'components/Select';
import Tabs, { mapActiveTab } from 'components/Tabs';
import Tile from 'components/Tile';
import { PAGE_LIMIT } from 'config/network';
import { Paths } from 'constants/paths';
import useIntl from 'hooks/useIntl';
import { Fragment } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import {
  ActivityOverview,
  ActivitySearchResponse,
} from 'screens/Individual/Activity/activityModel';
import { locationAndPeriodVar } from 'screens/LocationAndPeriod/cache';
import { InfiniteScrollWithRef } from 'shared/components/InfiniteScrollWithRef';
import { SelectContainer } from 'shared/components/SelectContainer';

const ActivitiesList = ({ tabs = [], location }) => {
  const { t } = useIntl('app.ActivitiesList');
  const { t: controls } = useIntl('app.Controls');
  const { t: types } = useIntl('app.ActivitiesDetails.activityTypes');
  const { activeTab } = location.state;

  const history = useHistory();
  const isThisPageVisited = IsPageVisited('ActivitiesList');
  const emptyStateConfig = { title: t('noItems') };
  const containerEl = useRef(null);

  const PAGE_DEFAULT = 1;

  const [activityTypes, setActivityTypes] = useState([]);
  const [page, setPage] = useState(PAGE_DEFAULT);
  const [hasMore, setHasMore] = useState(true);
  const [resultActivities, setResultActivities] = useState<ActivityOverview[]>(
    []
  );
  const { cityName, travelPeriod } = locationAndPeriodVar();
  const { orderBy, activityType } = useReactiveVar(activitiesListVar);
  const { setOrderBy, setActivityType } = useActivitiesList(activitiesListVar);

  useQuery(GET_ALL_ACTIVITY_TYPES, {
    onCompleted: ({ activity_type }) => {
      const activityTypes = [
        { value: null, label: controls('selectAll') },
        ...activity_type.map(type => ({
          value: type.value,
          key: type.value,
          label: types(type.value),
        })),
      ];

      setActivityTypes(activityTypes);
    },
  });

  const [getActivities, { loading: isActivitiesLoading }] = useMutation<{
    getActivities: ActivitySearchResponse;
  }>(GET_INTEGRATIONS_ACTIVITIES, {
    variables: {
      people: 1,
      startTime: travelPeriod[0],
      endTime: travelPeriod[1],
      location: cityName,
      activityTypes: activityType ? [activityType] : [],
      pageSize: PAGE_LIMIT,
      pageNumber: page,
    },
    onCompleted: ({ getActivities }) => {
      setHasMore(getActivities.activities.length >= PAGE_LIMIT);

      const activities = [...getActivities.activities];
      if (page > 1) {
        activities.unshift(...resultActivities);
      }
      setResultActivities(activities);
    },
  });

  useEffect(() => {
    if (page === PAGE_DEFAULT) {
      return;
    }

    getActivities();
  }, [page]);

  useEffect(() => {
    getActivities();
  }, [activityType]);

  const sortByOptions = [
    {
      label: controls('mostPopular'),
      value: controls('mostPopular'),
      key: ActivitiesSort.popular,
    },
    {
      label: controls('priceAscending'),
      value: controls('priceAscending'),
      key: ActivitiesSort.priceAsc,
    },
    {
      label: controls('priceDescending'),
      value: controls('priceDescending'),
      key: ActivitiesSort.priceDesc,
    },
    {
      label: controls('ratingAscending'),
      value: controls('ratingAscending'),
      key: ActivitiesSort.ratingAsc,
    },
    {
      label: controls('ratingDescending'),
      value: controls('ratingDescending'),
      key: ActivitiesSort.ratingDesc,
    },
  ];

  const onActivityTypeChange = (type: string) => {
    if (type === activityType) {
      return;
    }

    resetScroll(type);
    setActivityType(type);
    setPage(PAGE_DEFAULT);
  };

  const onOrderByChange = (value, key) => {
    if (orderBy.key === key) {
      return;
    }

    resetScroll(key);
    setPage(PAGE_DEFAULT);
    setOrderBy({ value, key });
  };

  const onNextPage = () => {
    const nextPage = page + 1;
    setPage(nextPage);
  };

  const resetScroll = option => {
    if (option === activityType || option === orderBy.key) {
      return;
    }

    if (containerEl?.current?.base?.firstChild) {
      containerEl.current.base.firstChild.scrollTop = 0;
    }
  };

  const activitiesSortedFiltered = useMemo(() => {
    if (orderBy.key === ActivitiesSort.priceAsc) {
      return resultActivities.sort(
        (x, y) => x.displayPrice.amount - y.displayPrice.amount
      );
    } else if (orderBy.key === ActivitiesSort.priceDesc) {
      return resultActivities.sort(
        (x, y) => y.displayPrice.amount - x.displayPrice.amount
      );
    }

    return resultActivities;
  }, [resultActivities, orderBy.key]);

  return (
    <Fragment>
      <ModalHeader title={t('title')} />
      {tabs.length && <Tabs config={mapActiveTab(tabs, activeTab)} />}

      <ControlsContainer>
        <Row>
          <LeftColumn>
            <SelectComponent
              input={{
                onChange: ({ value }: Option) => onActivityTypeChange(value),
                value: activityType,
              }}
              options={activityTypes}
              styleType="type_3"
            />
          </LeftColumn>
          <RightColumn>
            <SortContainer>
              <SortLabel>{controls('sortBy')}</SortLabel>
              <SelectContainer>
                <SelectComponent
                  input={{
                    onChange: ({ value, key }: Option) =>
                      onOrderByChange(value, key),
                    value: orderBy.value || controls('mostPopular'),
                  }}
                  options={sortByOptions}
                  styleType="type_3"
                />
              </SelectContainer>
            </SortContainer>
          </RightColumn>
        </Row>
      </ControlsContainer>

      {isActivitiesLoading && page === PAGE_DEFAULT ? (
        <Loading />
      ) : (
        <div>
          {resultActivities?.length ? (
            <Container>
              <InfiniteScrollWithRef
                ref={containerEl}
                height={425}
                dataLength={resultActivities.length}
                next={onNextPage}
                hasMore={hasMore}
                loader={<InfiniteScrollLoader />}
                endMessage={
                  <EndMessage>
                    <h4>{t('endMessage')}</h4>
                  </EndMessage>
                }
              >
                {activitiesSortedFiltered.map(
                  ({ id, image, name, shortDescription, displayPrice }) => (
                    <Row
                      onClick={() => {
                        history.push({
                          pathname: `/activity/${id}`,
                          state: {
                            activeTab: 'app.Controls.activitiesTab',
                          },
                        });
                      }}
                    >
                      <LeftColumn>
                        <ItemImage src={image?.thumbnailUrl} />
                      </LeftColumn>
                      <RightColumn>
                        <TileContainer>
                          <Tile
                            title={name}
                            description={shortDescription}
                            price={displayPrice}
                            amenities={[]}
                          />
                        </TileContainer>
                      </RightColumn>
                    </Row>
                  )
                )}
              </InfiniteScrollWithRef>
              <InfoDisclaimer
                text={controls('priceDisclaimer')}
                icon="assets/icons/info-bubble.svg"
              />
            </Container>
          ) : (
            <EmptyState config={emptyStateConfig} />
          )}
        </div>
      )}

      <ModalFooter
        mapIconProps={{
          disabled:
            resultActivities.filter(
              x => x.location?.latitude && x.location?.longitude
            ).length === 0,
          onClick: () =>
            history.push({
              pathname: '/map',
              state: {
                markers: resultActivities.map(x => ({
                  lat: x.location?.latitude,
                  lng: x.location?.longitude,
                  onClick: () =>
                    history.push({
                      pathname: `/activity/${x.id}`,
                      state: {
                        activeTab: 'app.Controls.activitiesTab',
                      },
                    }),
                })),
                activeTab: 'app.Controls.activitiesTab',
              },
            }),
        }}
        buttonTitle={t('buttonTitle')}
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        onButtonClick={() => history.push(Paths.Arrangements)}
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default ActivitiesList;
