import {
  EXCLUDE_SPECIAL_CHARS,
  EXCLUDE_SPECIAL_CHARS_AND_NUMBERS,
} from 'config/inputs';
import useIntl from 'hooks/useIntl';

export default function () {
  const { t: messages } = useIntl('app.ValidationMessages');

  const constraints = {
    name: {
      presence: {
        message: messages('nameRequired'),
      },
      format: {
        pattern: EXCLUDE_SPECIAL_CHARS_AND_NUMBERS,
        flags: 'gi',
        message: messages('nameInvalid'),
      },
    },
    country: {
      presence: {
        message: messages('countryRequired'),
      },
    },
    street: {
      format: {
        pattern: EXCLUDE_SPECIAL_CHARS,
        flags: 'gi',
        message: messages('streetNameInvalid'),
      },
      presence: {
        message: messages('streetRequired'),
      },
    },
    city: {
      format: {
        pattern: EXCLUDE_SPECIAL_CHARS_AND_NUMBERS,
        flags: 'gi',
        message: messages('cityInvalid'),
      },
      presence: {
        message: messages('cityRequired'),
      },
    },
    cityCode: {
      format: {
        pattern: EXCLUDE_SPECIAL_CHARS,
        flags: 'gi',
        message: messages('cityCodeInvalid'),
      },
      presence: {
        message: messages('cityCodeRequired'),
      },
    },
  };

  return { constraints };
}
