import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { myPagesClient } from 'apollo/client';
import {
  CANCEL_PAYMENT_INTENT,
  CHECKOUT,
  INITIALIZE_PAYMENT,
} from 'apollo/myBookings/mutations';
import { useBookingMutation } from 'apollo/myBookings/useBooking';
import { GET_PRODUCT_OWNER_BY_PK } from 'apollo/myDesti/queries';
import { GET_TRAVEL_PLANS } from 'apollo/myPages/mutations';
import { FormApi, FormState } from 'final-form';
import arrayMutators from 'final-form-arrays';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent, VNode } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { Form, FormSpy } from 'react-final-form';
import { toast } from 'react-toastify';
import { marketplaceVar } from 'screens/Marketplace/cache';
import { MarketplaceActionTypes } from 'screens/Marketplace/useMarketplace';
import TermsAndConditionsOverlay from 'screens/TermsAndConditions/TermsAndConditionsOverlay';
import useMarketplaceCheckoutConstraints from 'shared/constraints/useMarketplaceCheckoutConstraints';
import { CartItem, IActivityCartItem, IStayCartItem } from 'types/cache/Cart';
import { ICountry } from 'types/cache/Country';
import { ITravelPlan } from 'types/cache/TravelPlan';
import { IUser } from 'types/cache/User';
import { CartTypes } from 'types/shared/Cart';
import validateForm from 'utils/form/validateForm';
import { IProductOwnerExtended } from '../MarketplacePlanItem/types';
import ActivityBookingFields from './components/ActivityBookingFields';
import useAdditionalBookingFieldsConfiguration from './components/ActivityBookingFields/useAdditionalBookingFieldsConfiguration';
import BillingDetailsSectionForm from './components/BillingDetailsSectionForm';
import LeadTravelerSectionForm from './components/LeadTravelerSectionForm';
import PaymentDetailsSectionForm from './components/PaymentDetailsSectionForm';
import {
  CheckoutButton,
  StyledCheckbox,
  TermsAndPrivacy,
  TravelerTitle,
  Wrapper,
} from './components/StyledComponents';
import TravelerSectionForm from './components/TravelerSectionForm';
import useBookItem from './useBookItem';
import useCheckoutFormSetup, { CheckoutSections } from './useCheckoutFormSetup';

type FormStateType = FormState<
  Record<string, string>,
  Partial<Record<string, string>>
>;

interface IProps {
  user: IUser;
  plan: ITravelPlan;
  item: CartItem;
  handleTermsAndPrivacyCheck: (itemId: number, type: CartTypes) => void;
  setMarketplaceItem: (
    payload: ITravelPlan,
    action: MarketplaceActionTypes
  ) => void;
  onSuccessPayment: (itemId: number) => void;
  productOwner: IProductOwnerExtended;
  loadLeadUser: (props: FormStateType) => void;
  setIsLoading: (isLoading: boolean) => void;
  countries: ICountry[];
  getTravelPlanObject: (cartItem: CartItem) => string;
  setIsFillTooltipOpen?: (isOpen: boolean) => void;
}

interface IFormProps {
  handleSubmit?: () => void;
  form: FormApi;
}

enum DocumentType {
  Payment = 'payment',
  Booking = 'booking',
}

interface IDocument {
  type: string;
  name: string;
  url: string;
  location: string;
  language: string;
}

interface IProductOwnerTerms {
  documents?: IDocument[];
  terms?: string;
}

const MarketplacePlanCheckoutForm: FunctionalComponent<IProps> = ({
  user,
  plan,
  item,
  handleTermsAndPrivacyCheck,
  setMarketplaceItem,
  onSuccessPayment,
  productOwner,
  loadLeadUser,
  setIsLoading,
  countries,
  getTravelPlanObject,
  setIsFillTooltipOpen,
}) => {
  const { t } = useIntl('app.Marketplace');
  const stripe = useStripe();
  const elements = useElements();
  const termsRef = useRef(null);
  const { travelPlanName } = { ...plan };

  const [
    productOwnerTerms,
    setProductOwnerTerms,
  ] = useState<IProductOwnerTerms>(null);
  const [showTerms, setShowTerms] = useState(false);
  const [terms, setTerms] = useState('');
  const [termsTitle, setTermsTitle] = useState('');

  const [initialValues, setInitialValues] = useState({});

  const { travelPlans } = useReactiveVar(marketplaceVar);

  const [checkout] = useBookingMutation(CHECKOUT);
  const [initializePayment] = useBookingMutation(INITIALIZE_PAYMENT);
  const [cancelPaymentIntention] = useBookingMutation(CANCEL_PAYMENT_INTENT);
  const { hasSection } = useCheckoutFormSetup(item);
  const isTransport = useMemo(() => item.type === CartTypes.TRANSPORT, [
    item.type,
  ]);
  const isStay = useMemo(() => item.type === CartTypes.STAY, [item.type]);
  const {
    hasAdditionalBookingFields,
    hasAdditionalPerBookingFields,
    hasAdditionalPerParticipantFields,
    additionalBookingFields,
  } = useAdditionalBookingFieldsConfiguration(
    item.item as IActivityCartItem,
    countries
  );

  const { constraints } = useMarketplaceCheckoutConstraints(isStay, {
    ...additionalBookingFields?.requiredPerBookingFieldsValidation,
    ...additionalBookingFields?.requiredPerParticipantFieldsValidation,
  });

  const { bookItem } = useBookItem(item, user);

  const [plans, setPlans] = useState<ITravelPlan[]>([]);

  const [getTravelPlan] = useMutation(GET_TRAVEL_PLANS, {
    variables: {
      id: '',
    },
    client: myPagesClient,
    onCompleted: ({ getTravelPlans }) => {
      setPlans(getTravelPlans?.travelPlans);
    },
  });

  const numberOfTravellers = useMemo(
    () =>
      (item.item.people ?? 0) +
      (item.item.children ?? 0) +
      (item.item.infants ?? 0),
    [item]
  );

  useEffect(() => {
    getTravelPlan();
  }, []);

  useEffect(() => {
    setIsFillTooltipOpen(item.type !== CartTypes.ACTIVITY);
  }, [setIsFillTooltipOpen, item.type]);

  useEffect(() => {
    if (!termsRef?.current?.base || !showTerms) {
      return;
    }

    termsRef.current.base.style.top = window.pageYOffset - 358 + 'px';
  }, [showTerms]);

  useEffect(() => {
    if (showTerms) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.removeProperty('overflow');
    }

    if (termsRef?.current?.base && showTerms) {
      termsRef.current.base.style.top = window.pageYOffset - 358 + 'px';
    }
  }, [showTerms]);

  useEffect(() => {
    const itemIdx: number = plan.travelPlanItems.findIndex(
      i => i.id === item.id
    );
    const initialValueObject = {
      ...plan.travelPlanItems[itemIdx].leadTraveler,
    };

    if (hasAdditionalPerParticipantFields) {
      initialValueObject['requiredPerParticipantFields'] = Array.from({
        length: item.item.people,
      }).map(p => {
        return {};
      });
    }

    setInitialValues(initialValueObject);
  }, [plan, item.item.people, hasAdditionalPerParticipantFields]);

  const { loading: termsLoading, called: termsCalled } = useQuery(
    GET_PRODUCT_OWNER_BY_PK,
    {
      variables: {
        id: productOwner.id,
      },
      onCompleted: ({ product_owner_by_pk = {} }) => {
        const {
          product_owner_legal_documents = [],
          product_owner_profile = {},
        } = product_owner_by_pk;

        const productOwnerTerms = {
          documents: product_owner_legal_documents,
          terms: product_owner_profile?.terms,
        };

        setProductOwnerTerms(productOwnerTerms);
      },
    }
  );

  const onErrorPayment = (message: string): void => {
    setIsLoading(false);
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const stripeCheckoutConfirmPayment = async (
    paymentMethodId: string,
    formValues: any
  ) => {
    try {
      // create payment intent, but do not confirm it
      const paymentIntentResponse = await initializePayment({
        variables: {
          paymentMethodId,
          travelPlan: getTravelPlanObject({
            ...item,
          }),
          globalId: productOwner.global_id,
        },
      });

      const clientSecret =
        paymentIntentResponse.data.initializePayment.clientSecret;

      const {
        error: errorOnPaymentIntentRetrieve,
        paymentIntent,
      } = await stripe.retrievePaymentIntent(clientSecret);

      if (errorOnPaymentIntentRetrieve) {
        onErrorPayment(
          `${t('paymentFailed')}: ${errorOnPaymentIntentRetrieve.message}`
        );
        return;
      }

      // Handle authorization and/or confirm payment intent, check card errors - stolen, insufficien funds etc.
      const {
        error: errorOnPaymentIntentConfirm,
        paymentIntent: paymentIntentConfirmed,
      } = await stripe.confirmCardPayment(clientSecret, {
        // @ts-ignore
        payment_method: paymentIntent.payment_method,
      });

      // If the authorization/confirmation fails, cancel the payment intention so the reserved funds on the card gets released
      if (errorOnPaymentIntentConfirm) {
        await cancelPaymentIntention({
          variables: {
            paymentIntentId: paymentIntent.id,
            productOwnerExternalId: productOwner.external_id,
          },
        });

        onErrorPayment(
          `${t('paymentFailed')}: ${errorOnPaymentIntentConfirm.message}`
        );
        return;
      }

      // Booking
      const bookingResponse = await bookItem(
        formValues,
        productOwner.global_id,
        paymentIntent.id
      );
      // If the booking request fails cancel the payment intention
      if (bookingResponse instanceof Error) {
        try {
          await cancelPaymentIntention({
            variables: {
              paymentIntentId: paymentIntentConfirmed.id,
              productOwnerExternalId: productOwner.external_id,
            },
          });
        } catch (error) {
          onErrorPayment(`${t('paymentFailedContactSupport')}`);
          return;
        }
        onErrorPayment(`${t('bookingFailed')}`);
        return;
      }
      const { data = {} } = bookingResponse;
      const {
        bookAccommodation = {},
        bookGroupAccommodation = {},
        bookActivity = {},
        bookTransportation = {},
      } = data;
      const stayItem = item.item as IStayCartItem;
      let orderNumber = '';
      if (item.type === CartTypes.ACTIVITY) {
        orderNumber = bookActivity.orderNumber;
      } else if (item.type === CartTypes.STAY) {
        orderNumber =
          stayItem.number_of_rooms > 1
            ? bookGroupAccommodation.groupReservationId
            : bookAccommodation.reservationId;
      } else {
        orderNumber = bookTransportation.id;
      }

      // Capturing the funds for the payment intention
      const { data: responseData = {} } = await checkout({
        variables: {
          paymentIntentId: paymentIntent.id,
          travelPlan: getTravelPlanObject({
            ...item,
            order_number: orderNumber,
          }),
          globalId: productOwner.global_id,
        },
      });

      handleSuccessfullCheckout(responseData.checkout?.travelItineraryId);
    } catch (error) {
      onErrorPayment(error.message);
    }
  };

  const handleSuccessfullCheckout = (travelItineraryId: string) => {
    const travelPlan: ITravelPlan =
      travelPlans.find(plan => plan.travelPlanName === travelPlanName) ||
      plans.find(plan => plan['travelPlanName'] === travelPlanName);

    const itemIdx: number = travelPlan.travelPlanItems.findIndex(
      i => i.id === item.id
    );
    travelPlan.travelPlanItems.splice(itemIdx, 1, {
      ...travelPlan.travelPlanItems[itemIdx],
      paid: true,
    });

    setMarketplaceItem(
      {
        id: travelPlan.id,
        travelPeriod: travelPlan.travelPeriod,
        travelPlanItems: travelPlan.travelPlanItems,
        travelPlanName: travelPlan.travelPlanName,
        travelItineraryId,
      },
      MarketplaceActionTypes.Add
    );
    setIsLoading(false);
    onSuccessPayment(item.id);
  };

  const onSubmit = async values => {
    const { cardholderName } = values;
    setIsLoading(true);
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: cardholderName,
      },
    });
    result.error
      ? onErrorPayment(result.error.message)
      : stripeCheckoutConfirmPayment(result.paymentMethod.id, values);
  };

  const RenderForm: FunctionalComponent<IFormProps> = ({
    form,
    handleSubmit,
  }) => {
    const state: FormStateType = form.getState();

    const [isCardValid, setIsCardValid] = useState<boolean>(false);

    const isCheckoutDisabled: boolean =
      !isCardValid || state.invalid || !item.isTermsAndPrivacyChecked;

    const onCardChange = async event =>
      setIsCardValid(Boolean(!event.error && !event.empty && event.complete));

    const mapTravelerSectionForm = (numberOfPeople: number): VNode[] => {
      const travelerSection: VNode[] = [];

      for (let i = 1; i < numberOfPeople; i++) {
        travelerSection.push(
          <TravelerSectionForm
            index={i}
            isTransport={isTransport}
            countries={countries}
          />
        );
      }

      return travelerSection;
    };

    return (
      <form id="checkoutForm" onSubmit={handleSubmit}>
        {(isTransport || isStay) && (
          <>
            <TravelerTitle>
              <p>{t('leadTraveler')}</p>
            </TravelerTitle>
            <LeadTravelerSectionForm
              isTransport={isTransport}
              countries={countries}
            />
            {isTransport &&
              numberOfTravellers &&
              mapTravelerSectionForm(numberOfTravellers)}
          </>
        )}
        {hasSection(CheckoutSections.AdditionalDetails) &&
          hasAdditionalBookingFields && (
            <ActivityBookingFields
              additionalBookingFields={additionalBookingFields}
              hasAdditionalPerBookingFields={hasAdditionalPerBookingFields}
              hasAdditionalPerParticipantFields={
                hasAdditionalPerParticipantFields
              }
            />
          )}

        <BillingDetailsSectionForm countries={countries} />
        <PaymentDetailsSectionForm onCardChange={onCardChange} />

        <CheckoutButton
          secondary
          title={t('checkout')}
          disabled={isCheckoutDisabled}
          type="submit"
        />

        <FormSpy
          onChange={(props: FormStateType) => {
            loadLeadUser(props);
          }}
        />
      </form>
    );
  };

  const handleTermsClick = (e, terms: string, title: string) => {
    e.preventDefault();
    setTermsTitle(title);
    setTerms(terms);
    setShowTerms(true);
  };

  const getInlineTerms = (globalTerms: string, itemTerms: string) => {
    if (!globalTerms?.length && !itemTerms?.length) {
      return [t('termsOfBooking')];
    }

    const elements = [];
    if (globalTerms?.length) {
      const title = t('termsOfBooking');
      elements.push(
        <a href="#" onClick={e => handleTermsClick(e, globalTerms, title)}>
          {' '}
          {title}&nbsp;
        </a>
      );
    }
    if (itemTerms?.length) {
      if (elements.length) {
        elements.push(` ${t('and')} `);
      }
      const title = t('productTerms');
      elements.push(
        <a href="#" onClick={e => handleTermsClick(e, itemTerms, title)}>
          {' '}
          {title}&nbsp;
        </a>
      );
    }

    return elements;
  };

  const getDocumentTerms = (documents: IDocument[]) => {
    const checkoutDocs = documents.filter(
      d => d.location.toLowerCase() === 'checkout'
    );
    const bookingDoc = checkoutDocs.find(
      d => d.type.toLowerCase() === DocumentType.Booking
    );
    const paymentDoc = checkoutDocs.find(
      d => d.type.toLowerCase() === DocumentType.Payment
    );

    // TODO: lang

    if (!bookingDoc && !paymentDoc) {
      return [t('termsOfBooking')];
    }

    const elements = [];
    let title = t('termsOfBooking');

    if (bookingDoc) {
      elements.push(
        <a href={bookingDoc.url} target="_blank">
          {' '}
          {title}&nbsp;
        </a>
      );
    }
    if (paymentDoc) {
      title = t('termsOfPayment');
      if (elements.length) {
        elements.push(` ${t('and')} `);
      }
      elements.push(
        <a href={paymentDoc.url} target="_blank">
          {' '}
          {title}&nbsp;
        </a>
      );
    }

    return elements;
  };

  const getTerms = () => {
    if (productOwnerTerms?.documents?.length) {
      return getDocumentTerms(productOwnerTerms.documents);
    }
    if (
      productOwnerTerms?.terms?.length ||
      (item.item as IActivityCartItem).terms?.length
    ) {
      return getInlineTerms(
        productOwnerTerms?.terms,
        (item.item as IActivityCartItem).terms
      );
    }
    return [t('termsOfBooking')];
  };

  return (
    <Wrapper>
      {showTerms && (
        <TermsAndConditionsOverlay
          ref={termsRef}
          onDismiss={() => setShowTerms(false)}
          terms={terms}
          title={termsTitle}
        />
      )}
      <Form
        onSubmit={onSubmit}
        render={RenderForm}
        keepDirtyOnReinitialize={true}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        validate={values => validateForm(values, constraints)}
      />

      {!termsLoading && termsCalled && (
        <TermsAndPrivacy>
          <StyledCheckbox
            name="terms"
            label={[`${t('accept')} `, ...getTerms()]}
            input={{
              checked: item.isTermsAndPrivacyChecked,
              onChange: () => handleTermsAndPrivacyCheck(item.id, item.type),
            }}
          />
        </TermsAndPrivacy>
      )}
    </Wrapper>
  );
};

export default MarketplacePlanCheckoutForm;
