import { makeVar } from '@apollo/client';
import { LocationAndPeriod } from 'types/cache/LocationAndPeriod';

const locationAndPeriod: LocationAndPeriod = {
  regionIds: [],
  cityIds: [],
  travelPeriod: [],
  cityName: '',
  selectedLocations: []
};

export const locationAndPeriodVar = makeVar<LocationAndPeriod>(
  locationAndPeriod
);
