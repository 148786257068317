import { colorsSpec } from '@dest1/widget/src/styles/defaultTheme';
import preact, { FunctionalComponent } from 'preact';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const StyledTab = styled.div`
  ${({ isSecondary, count, theme, isSelected, isDisabled }) =>
    // White tab
    isSecondary
      ? `
    width: ${100 / count}%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 700;
    font-size: ${theme.fontSizes.large};
    background-color: ${theme.colors[colorsSpec.secondary]};
  
    ${
      isSelected &&
      `
    background-color: ${theme.colors[colorsSpec.secondaryLight]};
    font-weight: 700;
    `
    };
  
    ${
      isDisabled &&
      `
      
    font-weight: 700;
    cursor: default;
  `
    };
  `
      : // Yellow tab
        `
    width: ${100 / count}%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 700;
   
    ${
      isSelected &&
      `
    background-color: ${theme.colors[colorsSpec.secondaryLight]};
    color: ${theme.colors[colorsSpec.primaryLight]};
    font-weight: 700;
    `
    };
    `};

  ${({ count }) =>
    count > 3 &&
    `
    @media (max-width: 576px) {
      height: 50px;
      width: 50%;
    }
    `}
`;

export type TabProps = {
  id?: string | number;
  isSelected?: boolean;
  isDisabled?: boolean;
  title?: string | preact.JSX.Element;
  url?: string;
  count?: number;
  isSecondary?: boolean;
};

const Tab: FunctionalComponent<TabProps> = ({
  id = '',
  isSelected = false,
  isDisabled = false,
  title = '',
  url = '',
  count = 1,
  isSecondary = false,
}) => {
  const history = useHistory();

  return (
    <StyledTab
      id={id}
      isSelected={isSelected}
      isDisabled={isDisabled}
      isSecondary={isSecondary}
      count={count}
      onClick={() =>
        !isDisabled &&
        history.replace({
          pathname: url,
          state: { activeTab: id },
        })
      }
    >
      {title}
    </StyledTab>
  );
};

export default Tab;
