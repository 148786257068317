export enum NotificationTypes {
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  EXPLORE_MORE = 'EXPLORE_MORE',
  ADD_TO_CART = 'ADD_TO_CART',
  EMAIL_CHANGED = 'EMAIL_CHANGED',
  PASSWORD_CHANGED = 'PASSWORD_CHANGED',
  USER_REGISTERED = 'USER_REGISTERED',
}

export type NotificationType = {
  [NotificationTypes.PAYMENT_COMPLETED]: string;
  [NotificationTypes.EXPLORE_MORE]: string;
  [NotificationTypes.ADD_TO_CART]: string;
  [NotificationTypes.EMAIL_CHANGED]: string;
  [NotificationTypes.PASSWORD_CHANGED]: string;
  [NotificationTypes.USER_REGISTERED]: string;
};

export const NOTIFICATION_TYPES: NotificationType = {
  [NotificationTypes.PAYMENT_COMPLETED]: 'paymentCompleted',
  [NotificationTypes.EXPLORE_MORE]: 'exploreNotification',
  [NotificationTypes.ADD_TO_CART]: 'addToCart',
  [NotificationTypes.EMAIL_CHANGED]: 'emailChanged',
  [NotificationTypes.PASSWORD_CHANGED]: 'passwordChanged',
  [NotificationTypes.USER_REGISTERED]: 'userRegistered',
};
