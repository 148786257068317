import ShowMoreIcon from 'assets/icons/show-more.svg';
import EmptyState from 'components/EmptyState';
import Icon from 'components/Icon';
import { FunctionalComponent } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { colorsSpec } from 'styles/defaultTheme';

const GalleryContainer = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 100%;
  margin-top: ${({ theme }) => theme.spacing.large4};
  min-height: 150px;
`;

const Gallery = styled.aside`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  top: 0;
  left: 0;
  padding-top: 20px;
  scroll-behavior: smooth;
  margin-left: -${({ theme }) => theme.spacing.small1};
  margin-right: -${({ theme }) => theme.spacing.small1};

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const GalleryTile = styled.div`
  width: 220px;
  position: relative;
  padding: 0 ${({ theme }) => theme.spacing.small1};
  cursor: pointer;
`;

const GalleryInner = styled.div`
  white-space: nowrap;
  width: 100wv;
  display: flex;
  flex-direction: row;
`;

const ItemImage = styled.img.attrs(({ src }) => ({
  src,
}))`
  max-width: 100%;
  display: block;
`;

const ItemTitle = styled.h4`
  position: absolute;
  width: calc(100% - 10px);
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  bottom: 0;
  left: ${({ theme }) => theme.spacing.small1};
  right: ${({ theme }) => theme.spacing.small1};
  font-size: ${({ theme }) => theme.fontSizes.default} !important;
  padding: ${({ theme }) => theme.spacing.small1};
`;

const GalleryTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.default};
`;

const StyledIcon = styled(Icon)`
  outline: none;
  position: absolute;
  right: 0;
  top: 0px;
  height: 32px;
  width: 32px;

  img {
    width: 18px;
    height: auto;
  }

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? '' : theme.colors[colorsSpec.secondaryDark]};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  }

  &:active {
    background-color: ${({ theme, disabled }) =>
      disabled ? '' : theme.colors[colorsSpec.secondaryLight]};
  }

  &:nth-child(2) {
    right: 37px;
    transform: rotate(-180deg);
  }
`;

type StateConfig = {
  title: string;
  state?: string;
};
const emptyStateConfig: StateConfig = { title: 'No In Focus Items' };

type Props = {
  title: string;
  stateConfig: StateConfig;
  galleryItems: {
    itemTitle: string;
    itemImage: string;
    pathname?: string;
    activeTab?: string;
  }[];
};

const ImageSlider: FunctionalComponent<Props> = ({
  title = '',
  galleryItems,
  stateConfig = {},
}) => {
  const [node, setNode] = useState<any>(false);
  const [scrollPos, setScrollPos] = useState<number>(0);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const [isPrevDisabled, setisPrevDisabled] = useState<boolean>(false);
  const history = useHistory();

  const measuredRef = useCallback(
    node => {
      if (node !== null) {
        setNode(node);
      }
    },
    [galleryItems]
  );

  useEffect(() => {
    setisPrevDisabled(node?.scrollLeft <= 0);
    setIsNextDisabled(node?.clientWidth === node.scrollWidth);
  }, [node, galleryItems]);

  useEffect(() => {
    setIsNextDisabled(scrollPos + node?.clientWidth > node?.scrollWidth);
    setisPrevDisabled(scrollPos <= 0);
  }, [scrollPos]);

  return galleryItems.length ? (
    <GalleryContainer>
      <GalleryTitle>{title}</GalleryTitle>

      <Gallery ref={measuredRef}>
        <StyledIcon
          icon={ShowMoreIcon}
          onClick={() => {
            node.scrollLeft += 150;
            setScrollPos((node.scrollLeft += 150));
          }}
          disabled={isNextDisabled}
          small
        />
        <StyledIcon
          icon={ShowMoreIcon}
          onClick={() => {
            node.scrollLeft -= 150;
            setScrollPos((node.scrollLeft -= 150));
          }}
          disabled={isPrevDisabled}
          small
        />

        <GalleryInner>
          {galleryItems.map(({ itemTitle, itemImage, pathname, activeTab }) => (
            <GalleryTile
              onClick={() => history.push({ pathname, state: { activeTab } })}
            >
              <ItemImage src={itemImage} />
              <ItemTitle>{itemTitle}</ItemTitle>
            </GalleryTile>
          ))}
        </GalleryInner>
      </Gallery>
    </GalleryContainer>
  ) : (
    <EmptyState config={stateConfig || emptyStateConfig} />
  );
};

export default ImageSlider;
