import { GET_INVOICES } from 'apollo/myPages/queries';
import { useMyPagesQuery } from 'apollo/myPages/useMyPages';
import Accordion from 'components/Accordion/';
import EmptyState from 'components/EmptyState/';
import Loading from 'components/Loading';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import Tabs, { mapActiveTab } from 'components/Tabs';
import { TABS_HEIGHT } from 'config/inputs';
import useIntl from 'hooks/useIntl';
import { Fragment } from 'preact';
import { useState } from 'preact/hooks';
import styled from 'styled-components';
import { Invoice } from 'types/cache/Invoice';
import { Container, ProfileTabsContainer, SupportMailInfoContainer } from '../components';
import AccordionContent from './components/AccordionContent';
import AccordionTitle from './components/AccordionTitle';
import { useHistory } from 'react-router-dom';
import { Paths } from 'constants/paths';
import { MessageType, SupportMailInfoDisclaimer } from 'components/InfoDisclaimer';

const InvoicesContainer = styled(Container)`
  max-height: 550px;
  overflow: auto;
`;

const InvoicesInnerContainer = styled.div`
  margin-top: 20px;
`;

const Invoices = ({ tabs = [], location }) => {
  const { t } = useIntl('app.Invoices');
  const { activeTab } = location.state;
  const isThisPageVisited = IsPageVisited('Invoices');
  const [isOpen, setOpen] = useState<boolean[]>([]);
  const history = useHistory();

  const { loading, data: { invoice_view = [] } = {} } = useMyPagesQuery<{invoice_view: Invoice[]}>(
    GET_INVOICES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ invoice_view }) => setOpen(invoice_view.map(() => false))
    }
  );

  const handleSetIsOpen = (index: number) => {
    const updated = [...isOpen];
    updated[index] = !updated[index];
    setOpen(updated);
  }

  return (
    <Fragment>
      <ModalHeader title={t('title')} />

      <InvoicesContainer>
        <ProfileTabsContainer>
          {tabs.length && (
            <Tabs
              config={mapActiveTab(tabs, activeTab)}
              height={TABS_HEIGHT}
              isSecondary
            />
          )}

          {loading ? (
            <Loading />
          ) : invoice_view.length ? (
            invoice_view.map((item, idx) => (
              <InvoicesInnerContainer>
                <Accordion key={idx} isOpen={isOpen[idx]} setOpen={() => handleSetIsOpen(idx)}>
                  <AccordionTitle item={item} />
                  <AccordionContent item={item} />
                </Accordion>
              </InvoicesInnerContainer>
            ))
          ) : (
            <EmptyState config={{ title: t('noItems') }} />
          )}
        </ProfileTabsContainer>
      </InvoicesContainer>
      <SupportMailInfoContainer>
        <SupportMailInfoDisclaimer
          text={t('supportEmailInfo')}
          icon="assets/icons/info-bubble.svg"
          type={MessageType.Neutral}
          email={process.env.DESTI_ONE_SUPPORT_EMAIL}
        />
      </SupportMailInfoContainer>
      <ModalFooter
        buttonTitle={t('goDestiOneTitle')}
        onButtonClick={() => {history.push(Paths.TravelLocationAndPeriod)}}
        mapIconProps={{ disabled: true }}
        chatIconProps={{ disabled: true }}
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default Invoices;
