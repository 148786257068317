import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { GET_PASSENGER_CATEGORIES_INTEGRATION } from 'apollo/myDesti/mutations';
import { GET_TRANSPORT_TYPES, SEARCH_STATION, GET_TRANSPORT_CLASSES } from 'apollo/myDesti/queries';
import InfoDisclaimer from 'components/InfoDisclaimer';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import NumberInput from 'components/NumberInput';
import { IsPageVisited } from 'components/PageVisited';
import SearchInput from 'components/SearchInput';
import { Paths } from 'constants/paths';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { Fragment } from 'preact';
import { Text } from 'preact-i18n';
import { useRef } from 'preact/compat';
import { useEffect, useState } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import TimePicker from 'react-time-picker';
import { toast } from 'react-toastify';
import {
  AgeRange,
  Passenger,
  PassengerCategory,
  Station,
  ITransportClass
} from 'types/cache/TransportDetails';
import CalendarInput from '../../../components/CalendarInput';
import Input from '../../../components/Input';
import RadioInput from '../../../components/RadioInput';
import { locationAndPeriodVar } from '../../LocationAndPeriod/cache';
import useTravelLocationAndPeriod from '../../LocationAndPeriod/useTravelLocationAndPeriod';
import { transportDetailsVar } from './cache';
import {
  CheckBox,
  CheckBoxText,
  Column,
  ColumnInputs,
  Container,
  LocationContainer,
  MainLoader,
  OptionInputWrapper,
  PassengerAgeRangeInfo,
  RadioText,
  Row,
  Spacer,
  StyledCalendarInput,
  StyledCheckboxContainer,
  StyledTimeInput,
  TightRow,
  TightSpacer,
  TitleLocation,
  TripDirectionLabel,
  TripOptionCategoriesTitle,
  TripOptionNumberInput,
  TripOptionsContainer,
  TripOptionTitle,
  TripOptionTitleRight,
} from './components/StyledComponents';
import styles from './styles.module.css';
import { useTransportDetails } from './useTransportDetails';

const TransportDetails = () => {
  const history = useHistory();
  const isThisPageVisited = IsPageVisited('TransportDetails');
  const { t } = useIntl('app.TransportDetails');
  const { t: travelClassTitle } = useIntl('app.TransportDetails.travelClass');
  const { t: validationMessage } = useIntl(
    'app.TransportDetails.validationMessages'
  );

  const {
    setTransportList,
    setSelectedTransportType,
    setStationFrom,
    setStationTo,
    setLocationFrom,
    setLocationTo,
    setRoundTrip,
    setOptions,
    setPassengers,
    setPassengerCategories,
    setTravelClass,
    toggleTravelClassMix,
  } = useTransportDetails(transportDetailsVar);

  const {
    transportList,
    selectedTransportType,
    stationFrom,
    stationTo,
    locationFrom,
    locationTo,
    roundTrip,
    options,
    travelClass,
    travelClassMix,
    passengerCategories,
  } = useReactiveVar(transportDetailsVar);

  const [travelClasses, setTravelClasses] = useState([]);

  const { setTravelPeriod } = useTravelLocationAndPeriod(locationAndPeriodVar);

  const { travelPeriod, cityName, selectedLocations } = useReactiveVar(locationAndPeriodVar);

  const [stationFromDebounce, setStationFromDebounce] = useState(
    stationFrom?.name || ''
  );
  const [stationToDebounce, setStationToDebounce] = useState(
    stationTo?.name || ''
  );

  const [locationFromDebounce, setLocationFromDebounce] = useState(
    locationFrom?.name || ''
  );
  const [locationToDebounce, setLocationToDebounce] = useState(
    locationTo?.name || ''
  );

  const [tripDate, setTripDate] = useState(travelPeriod[0]);
  const [returnDate, setReturnDate] = useState(
    new Date(travelPeriod[1].toDateString())
  ); // time to 00:00

  const [tipTitle, setTipTitle] = useState(t('tooltipTitle'));
  const [tipText, setTipText] = useState(t('tooltipText'));
  const [showTip, setShowTip] = useState(false);
  // Cabin baggage - 1 per passenger, checked baggage - 2 per passenger (excluding infants)
  // Pets - 1 per passenger
  const [maxCabinBaggageCount, setMaxCabinBaggageCount] = useState(Infinity);
  const [maxCheckedBaggageCount, setMaxCheckedBaggageCount] = useState(
    Infinity
  );
  const [maxPets, setMaxPets] = useState(Infinity);

  const [passengerCount, setPassengerCount] = useState(1);
  const [addonCount, setAddonCount] = useState(1);

  const [selectedTravelClass, setSelectedTravelClass] = useState(travelClass);

  const initOptionDetails = {
    passengers: {
      VU: 1, // Adult
      BO: 0, // Child/Youth
      BOAges: [],
      SE: 0, // Senior
      SEAges: [],
      PS: 0, // Retired
      PSAges: [],
      SU: 0, // Student
      SUAges: [],
      adults: 1,
      children: 0,
      infants: 0,
    },
    addons: {
      pets: 0,
      cabinBaggage: 0,
      checkedBaggage: 0,
    },
  };

  const childMinAge = 7;
  const maxAirplanePassengers = 9; // Tequila API restriction
  const [maxInfants, setMaxInfants] = useState(Infinity); // Max 1 per adult

  const [optionDetails, setOptionDetails] = useState(
    options && Object.entries(options).length > 1 ? options : initOptionDetails
  );
  const childrenOptionParent = useRef(null);
  const locationFromInput = useRef(null);

  const { data: transportTypes } = useQuery(GET_TRANSPORT_TYPES);

  const [
    getTransportClasses,
    { loading: transportClassesLoading }
  ] = useLazyQuery<{ getTransportationClasses: ITransportClass[] }>(
    GET_TRANSPORT_CLASSES,
    {
      onCompleted: (data) => {
        const travelClasses = data.getTransportationClasses
          .map(transportClass => ({
            title: travelClassTitle(transportClass.key),
            value: transportClass.value
          }));

        if (!travelClasses.length) {
          return;
        }

        setTravelClasses(travelClasses);

        if (!selectedTravelClass || !travelClasses.find(x => x.value === selectedTravelClass)) {
          setSelectedTravelClass(travelClasses[0].value);
        }
      }
    });

  const [
    getStationsFrom,
    { data: stationsFrom, loading: isStationsFromLoading },
  ] = useLazyQuery(SEARCH_STATION, {
    variables: {
      city: stationFromDebounce || cityName,
      transportationTypes: [transportList[1]?.key],
    },
  });

  const [
    getStationsTo,
    { data: stationsTo, loading: isStationsToLoading },
  ] = useLazyQuery(SEARCH_STATION, {
    variables: {
      city: stationToDebounce || cityName,
      transportationTypes: [transportList[1]?.key],
    },
  });

  const [
    getLocationsFrom,
    { data: locationsFrom, loading: isLocationsFromLoading },
  ] = useLazyQuery(SEARCH_STATION, {
    variables: {
      city: locationFromDebounce,
      transportationTypes: [transportList[0]?.key],
    },
  });

  const [
    getLocationsTo,
    { data: locationsTo, loading: isLocationsToLoading },
  ] = useLazyQuery(SEARCH_STATION, {
    variables: {
      city: locationToDebounce || selectedLocations[0]?.name,
      transportationTypes: [transportList[0]?.key],
      longitude: locationToDebounce ? undefined : selectedLocations[0]?.longitude,
      latitude: locationToDebounce ? undefined : selectedLocations[0]?.latitude,
    },
  });

  const [
    loadPassengerCategories,
    { loading: isPassengerCategoriesLoading },
  ] = useMutation(GET_PASSENGER_CATEGORIES_INTEGRATION);

  useEffect(() => {
    if (!transportTypes) {
      return;
    }

    // create options and enable allowed transport types
    setTransportList(transportTypes);
  }, [transportTypes]);

  useEffect(() => {
    if (!selectedTransportType) {
      setSelectedTransportType(transportList[0]);
    }
  }, [transportList]);

  useEffect(() => {
    if (locationFromInput.current) {
      locationFromInput.current.base?.querySelector('input').focus();
    }
  }, [locationFromInput.current]);

  useEffect(() => {
    if (selectedTransportType?.id === 0 && stationFromDebounce) {
      getStationsFrom();
    } else if (selectedTransportType?.id === 1 && locationFromDebounce) {
      getLocationsFrom();
    }
  }, [stationFromDebounce, locationFromDebounce]);

  // useEffect(() => {
  //   if (!selectedTransportType || (!stationFrom && !locationFrom)) {
  //     return;
  //   }

  //   if (selectedTransportType.id === 0) {
  //     const stationFromName = stationFrom?.name.split(' ')[0];
  //     setStationLocation(locationsFrom?.getStations, stationFromName, setLocationFrom);
  //     // setLocationFromDebounce(stationFromName);
  //   } else if (selectedTransportType?.id === 1) {
  //     const stationFromName = locationFrom?.name.split(' ')[0];
  //     setStationLocation(stationsFrom?.getStations, stationFromName, setStationFrom);
  //     // setStationFromDebounce(stationFromName);
  //   }
  // }, [stationFrom, locationFrom]);

  // useEffect(() => {
  //   if (!selectedTransportType || (!stationTo && !locationTo)) {
  //     return;
  //   }

  //   if (selectedTransportType.id === 0) {
  //     const stationToName = stationTo?.name.split(' ')[0];
  //     setStationLocation(locationsTo?.getStations, stationToName, setLocationTo);
  //     // setLocationToDebounce(stationToName);
  //   } else if (selectedTransportType?.id === 1) {
  //     const stationToName = locationTo?.name.split(' ')[0];
  //     setStationLocation(stationsTo?.getStations, stationToName, setStationTo);
  //     // setStationToDebounce(stationToName);
  //   }
  // }, [stationTo, locationTo]);

  useEffect(() => {
    if (optionDetails.addons.cabinBaggage > maxCabinBaggageCount) {
      optionDetails.addons.cabinBaggage = maxCabinBaggageCount;
      setOptionDetails({ ...optionDetails });
      updateOptionDetails();
    }
  }, [maxCabinBaggageCount]);

  useEffect(() => {
    if (optionDetails.addons.checkedBaggage > maxCheckedBaggageCount) {
      optionDetails.addons.checkedBaggage = maxCheckedBaggageCount;
      setOptionDetails({ ...optionDetails });
      updateOptionDetails();
    }
  }, [maxCheckedBaggageCount]);

  useEffect(() => {
    if (optionDetails.addons.pets > maxPets) {
      optionDetails.addons.pets = maxPets;
      setOptionDetails({ ...optionDetails });
      updateOptionDetails();
    }
  }, [maxPets]);

  useEffect(() => {
    if (optionDetails.passengers.infants > maxInfants) {
      optionDetails.passengers.infants = maxInfants;
      setOptionDetails({ ...optionDetails });
      updateOptionDetails();
    }
  }, [maxInfants]);

  useEffect(() => {
    switch (selectedTransportType?.id) {
      case 0:
        if (dayjs().add(120, 'day').isBefore(returnDate)) {
          setReturnDate(
            new Date(dayjs().add(120, 'day').toDate().toDateString())
          );
        }
        if (dayjs().add(120, 'day').isBefore(tripDate)) {
          setTripDate(
            new Date(dayjs().add(120, 'day').toDate().toDateString())
          );
        }
        break;
      case 1:
        break;
      default:
        return;
    }

    updateOptionDetails();

    getTransportClasses({
      variables: { transportType: selectedTransportType.key }
    });

    if (passengerCategories[selectedTransportType.id]) {
      return;
    }

    loadPassengerCategories({
      variables: {
        transportType: selectedTransportType.key,
      },
    }).then(x => {
      passengerCategories[selectedTransportType.id] = x.data.getPassengerCategories;
      setPassengerCategories(passengerCategories);

      setPassengers(getPassengersFromOptions(x.data.getPassengerCategories));
    });
  }, [selectedTransportType?.id]);

  useEffect(() => {
    getStationsTo().then(({ data }) => {
      if (data?.getStations.length) {
        const station = data.getStations[0];
        setStationTo(station);
        setStationToDebounce(station?.name);
      }
    });
    getLocationsTo().then(({ data }) => {
      if (data?.getStations.length) {
        const station = data.getStations[0];
        setLocationTo(station);
        setLocationToDebounce(station?.name);
      }
    });
  }, []);

  useEffect(() => {
    if (!passengerCategories) return;

    options.passengers &&
      Object.entries(options.passengers).forEach(entry => {
        // we are only interested in array entries
        if (entry[1] instanceof Array) {
          const value = options.passengers[entry[0]];
          for (let i = 0; i < value.length; i++) {
            setTimeout(() => {
              if (entry[0].startsWith('PSAges')) {
                for (let i = 0; i < value.length; i++) {
                  // retired category depends on senior controls
                  const el2 = document.querySelector(
                    '#option-category-number-input-SEAges'
                  );
                  (el2?.nextElementSibling as HTMLInputElement)?.click();
                }
              }
            }, 50);

            setTimeout(() => {
              const checkBoxes = document.querySelectorAll(
                '#mark-SE input[type="checkbox"]'
              );
              for (let j = 0; j < checkBoxes.length; j++) {
                const check = checkBoxes[j] as HTMLInputElement;
                if (
                  (check.previousElementSibling as HTMLInputElement)?.value in
                  ['', undefined, null]
                ) {
                  check.click();
                }
              }

              const inputs = document.querySelectorAll(
                `.${entry[0].replace('Ages', '')}-age-input`
              );
              inputs.forEach((input: HTMLInputElement, index: number) => {
                input.value = entry[1][index] || '';
              });
            }, 100);
          }
        }
      });
  }, [passengerCategories, options]);

  useEffect(() => {
    if (roundTrip && tripDate > returnDate) {
      setTripDate(returnDate);
    }
  }, [returnDate]);

  useEffect(() => {
    if (roundTrip && tripDate > returnDate) {
      setReturnDate(tripDate);
    }
  }, [roundTrip]);

  const getPassengersFromOptions = (
    categories: PassengerCategory[]
  ): Passenger[] => {
    const addToResult = (category: PassengerCategory, results: Passenger[]) => {
      const categoryTitle = category.value;
      for (let i = 0; i < optionDetails.passengers[categoryTitle]; i++) {
        results.push({
          id: (results.length + 1).toString(),
          age:
            category.value === 'VU'
              ? 0
              : optionDetails.passengers[categoryTitle + 'Ages']?.at(i) || 100,
          passengerCategoryId: category.id,
        });
      }
    };

    const result: Passenger[] = [];
    categories?.forEach(item => addToResult(item, result));
    return result;
  };

  const assignAge = (
    source: number[],
    value: number,
    input: number
  ): number[] => {
    if (source.length < value) {
      source.push(input);
    } else {
      source[value - 1] = input;
    }
    return source;
  };

  const setPassengerCategoryInfoIcon = (option: string) => {
    const inputIcon = document.createElement('img');
    inputIcon.className = `${styles.InputIcon}`;

    const [title, text] = {
      BO: [t('childYouthTitle'), t('childYouthText')],
      SE: [t('seniorTitle'), t('seniorText')],
      SU: [t('studentTitle'), t('studentText')],
    }[option];

    inputIcon.onclick = () => {
      setTipTitle(title);
      setTipText(text);
      setShowTip(true);
    };

    return inputIcon;
  };

  const setOptionInputs = (
    stringValue: string,
    element: any,
    category: PassengerCategory,
    ageRange: AgeRange,
    index?: number
  ) => {
    const { value: option, title } = category;
    const value = parseInt(stringValue);
    const markElement = document.querySelector(
      `#mark-${option}`
    ) as HTMLElement;

    if (markElement.children.length > value) {
      markElement.removeChild(
        markElement.children[markElement.children.length - 1]
      );
    } else {
      const inputDiv = document.createElement('div');
      inputDiv.className = `${styles.InputDiv} ${element.base.parentNode.parentNode.className}`;

      const inputElement = document.createElement('input');
      inputElement.onchange = (e: any) => {
        const inputAge = parseInt(e.target.value);
        let ages: number[];
        switch (option) {
          case 'BO':
            ages = optionDetails.passengers.BOAges;
            optionDetails.passengers.BOAges = assignAge(ages, value, inputAge);
            break;
          case 'SE':
            ages = optionDetails.passengers.SEAges;
            optionDetails.passengers.SEAges = assignAge(ages, value, inputAge);
            break;
          case 'SU':
            ages = optionDetails.passengers.SUAges;
            optionDetails.passengers.SUAges = assignAge(ages, value, inputAge);
            break;
        }
        setOptionDetails({ ...optionDetails });
      };
      inputElement.onblur = () => {
        if (!inputElement.checkValidity()) {
          inputElement.value = '';
        }
      };

      inputElement.type = 'number';
      inputElement.inputMode = 'numeric';
      inputElement.placeholder = `${title} ${t('ages')}`;
      inputElement.className = `${option}-age-input ${option}-age-input-${value} ${styles.InputElement}`;
      inputElement.min = ageRange.passengerAgeFrom.toString();
      inputElement.max = ageRange.passengerAgeTo.toString();
      inputElement.required = true;

      const age = optionDetails.passengers[option + 'Ages'][index];
      inputElement.value = age;

      const inputLabel = document.createElement('label');
      inputLabel.className = `${styles.InputLabel}`;
      inputLabel.appendChild(inputElement);

      inputDiv.appendChild(inputLabel);
      markElement.appendChild(inputDiv);

      if (option === 'SE') {
        const checkBox = document.createElement('input');
        checkBox.type = 'checkbox';
        checkBox.className = `${styles.Checkbox}`;
        checkBox.onchange = () => {
          inputElement.disabled = checkBox.checked;
          inputElement.required = !checkBox.checked;

          const retiredLen =
            optionDetails.passengers.SE -
            optionDetails.passengers.SEAges.length;
          if (retiredLen > 0) {
            const adjustBy = checkBox.checked ? 1 : -1;
            optionDetails.passengers.PS += adjustBy;
            optionDetails.passengers.SE -= adjustBy;
            if (optionDetails.passengers.PSAges.length !== retiredLen) {
              if (adjustBy < 0) {
                optionDetails.passengers.PSAges.splice(
                  optionDetails.passengers.PSAges.length - 1,
                  1
                );
              } else {
                optionDetails.passengers.PSAges.push(100);
              }
            }
          }
          setOptionDetails({ ...optionDetails });
        };

        const checkBoxLabel = document.createElement('label');
        checkBoxLabel.innerHTML = t('retired');
        checkBoxLabel.className = `${styles.CheckboxLabel}`;
        checkBoxLabel.appendChild(checkBox);

        inputDiv.appendChild(checkBoxLabel);
      }

      if (value !== 1) {
        return;
      }

      inputLabel.appendChild(setPassengerCategoryInfoIcon(option));
    }
  };

  useEffect(() => {
    const { passengers, addons } = optionDetails;

    const passengerCount =
      selectedTransportType?.id === 0
        ? passengers.VU +
          passengers.BO +
          passengers.SE +
          passengers.PS +
          +passengers.SU
        : passengers.adults + passengers.children + passengers.infants;

    setPassengerCount(passengerCount);

    const addonCount =
      selectedTransportType?.id === 0
        ? addons.pets
        : addons.cabinBaggage + addons.checkedBaggage;

    setAddonCount(addonCount);

    if (selectedTransportType?.id === 1) {
      const passengerBaggageCount = passengers.adults + passengers.children;
      setMaxCabinBaggageCount(passengerBaggageCount);
      setMaxCheckedBaggageCount(2 * passengerBaggageCount);
      setMaxPets(passengerCount);
      setMaxInfants(passengers.adults);
    }
  }, [optionDetails, selectedTransportType?.id]);

  const getMaxCategoryValue = (category: PassengerCategory) => {
    const value = category.value;

    const categoryValue = options.passengers
      ? options.passengers[value]
      : initOptionDetails.passengers[value];

    const max: number = maxAirplanePassengers - passengerCount + categoryValue;

    return value === 'infants' ? Math.min(max, maxInfants) : max;
  };

  const updateOptionDetails = () => {
    const passengers = optionDetails.passengers;

    const passengerBaggageCount = passengers.adults + passengers.children;
    setMaxCabinBaggageCount(passengerBaggageCount);
    setMaxCheckedBaggageCount(2 * passengerBaggageCount);
    setMaxPets(passengerCount);
    setMaxInfants(passengers.adults);
  };

  const getMarkInputs = () => {
    const children = document.querySelectorAll('#mark-BO input');
    const seniors = document.querySelectorAll('#mark-SE input[type="number"]');
    const seniorsRetired = document.querySelectorAll(
      '#mark-SE input[type="checkbox"]'
    );
    const students = document.querySelectorAll('#mark-SU input');
    return { children, seniors, seniorsRetired, students };
  };

  const onSubmit = () => {
    if (selectedTransportType?.id === 0) {
      validatePassengers();

      if (!stationFrom?.name) {
        throw new Error(validationMessage('locationFrom'));
      }
      if (!stationTo?.name) {
        throw new Error(validationMessage('locationTo'));
      }

      if (
        stationFrom.id === stationTo.id &&
        stationFrom.producerId === stationTo.producerId
      ) {
        throw new Error(validationMessage('locationsSame'));
      }
    } else {
      if (!locationFrom?.name) {
        throw new Error(validationMessage('locationFrom'));
      }
      if (!locationTo?.name) {
        throw new Error(validationMessage('locationTo'));
      }

      if (locationFrom.id === locationTo.id) {
        throw new Error(validationMessage('locationsSame'));
      }
    }

    if (
      !tripDate ||
      tripDate < dayjs(new Date()).subtract(2, 'hour').toDate()
    ) {
      throw new Error(validationMessage('travelDate'));
    }

    const { children, seniors, seniorsRetired, students } = getMarkInputs();

    children.forEach((child: HTMLInputElement) => {
      if (!child.value || child.value.length < 1) {
        throw new Error(validationMessage('childrenAge'));
      }
    });

    seniors.forEach((senior: HTMLInputElement, index: number) => {
      if (!senior.value && senior.value.length < 1) {
        const retiredChecked = (Array.from(seniorsRetired)[
          index
        ] as HTMLInputElement).checked;
        if (!retiredChecked) {
          throw new Error(validationMessage('seniorAge'));
        }
      }
    });

    students.forEach((student: HTMLInputElement) => {
      if (!student.value || student.value.length < 1) {
        throw new Error(validationMessage('studentAge'));
      }
    });

    if (!selectedTransportType) {
      throw new Error(validationMessage('transportType'));
    }

    setTravelClass(selectedTravelClass);
    travelPeriod[0] = tripDate;
    travelPeriod[1] = returnDate;
    setTravelPeriod(travelPeriod);
    setOptions(optionDetails);
    setPassengers(
      getPassengersFromOptions(passengerCategories[selectedTransportType?.id])
    );
  };

  const getCategoryString = (passengerCategory: PassengerCategory) => {
    const ageFrom = passengerCategory.passengerAgeRange.passengerAgeFrom;
    const ageTo = passengerCategory.passengerAgeRange.passengerAgeTo;

    if (ageFrom !== null && ageTo !== null) {
      return (
        <PassengerAgeRangeInfo>
          ({ageFrom} - {ageTo} {t('years')})
        </PassengerAgeRangeInfo>
      );
    }

    if (ageFrom === null) {
      return (
        <PassengerAgeRangeInfo>
          (
          <Text
            id="app.TransportDetails.passengerCategoryAgeRangeUnder"
            fields={{ age: ageTo + 1 }} // +1 because ageTo is included in range
          />
          )
        </PassengerAgeRangeInfo>
      );
    }

    if (ageTo === null) {
      return (
        <PassengerAgeRangeInfo>
          (
          <Text
            id="app.TransportDetails.passengerCategoryAgeRangeOver"
            fields={{ age: ageFrom - 1 }} // -1 because ageFrom is included
          />
          )
        </PassengerAgeRangeInfo>
      );
    }
  };

  const findLocationByName = (locations: Station[], name: string) => {
    return locations?.find(x => {
      const location = { ...x };
      if (name && !name.includes(' ')) {
        location.name = location.name.split(' ')[0];
      }
      return location.name.toLowerCase() === name?.toLowerCase();
    });
  };

  const setStationLocation = (
    locations: Station[],
    locationName: string,
    prevStationId: string,
    setter: (location: Station) => void
  ) => {
    const station = findLocationByName(locations, locationName);
    if (station?.id !== prevStationId) {
      setter(station);
    }
  };

  const validatePassengers = () => {
    if (passengerCount === 0) {
      throw new Error(validationMessage('passengerCount'));
    }

    const childrenAges: number[] = optionDetails.passengers.BOAges;
    const childrenCount: number = optionDetails.passengers.BO;
    if (passengerCount > childrenCount) {
      return;
    }

    if (Math.max(...childrenAges.slice(0, childrenCount)) < childMinAge) {
      throw new Error(validationMessage('childrenUnder7'));
    }
  };

  const renderComponents = () => (
    <Fragment>
      {transportList.length && (
        <Row>
          {transportList.map(transportType => (
            <RadioInput
              disabled={false}
              input={{
                checked: selectedTransportType?.id === transportType.id,
                onClick: () => setSelectedTransportType(transportType),
              }}
            >
              <RadioText>{t(transportType.key.toLowerCase())}</RadioText>
            </RadioInput>
          ))}
        </Row>
      )}
      {selectedTransportType?.id === 0 ? (
        <Row>
          <LocationContainer>
            <TitleLocation>{t('locationFromTitle')}</TitleLocation>
            <SearchInput
              initValue={stationFrom?.name || stationFromDebounce}
              placeholder={t('station')}
              name="stationsFrom"
              isLoading={isStationsFromLoading}
              data={stationsFrom?.getStations}
              ref={locationFromInput}
              optionSelected={(option: Station) => {
                setStationFromDebounce(option.name);
                setStationFrom(option);
              }}
              debounceValueChanged={value => setStationFromDebounce(value)}
              escape={false}
              outsideClickAction={() =>
                setStationLocation(
                  stationsFrom?.getStations,
                  stationFromDebounce,
                  stationFrom?.id,
                  setStationFrom
                )
              }
            />
          </LocationContainer>
          <Spacer />
          <LocationContainer>
            <TitleLocation>{t('locationToTitle')}</TitleLocation>
            <SearchInput
              initValue={stationTo?.name || stationToDebounce}
              placeholder={t('station')}
              name="stationsTo"
              isLoading={isStationsToLoading}
              data={stationsTo?.getStations}
              optionSelected={(option: Station) => {
                setStationToDebounce(option.name);
                setStationTo(option);
              }}
              debounceValueChanged={value => setStationToDebounce(value)}
              escape={false}
              outsideClickAction={() =>
                setStationLocation(
                  stationsTo?.getStations,
                  stationToDebounce,
                  stationTo?.id,
                  setStationTo
                )
              }
            />
          </LocationContainer>
        </Row>
      ) : (
        <Row>
          <LocationContainer>
            <TitleLocation>{t('locationFromTitle')}</TitleLocation>
            <SearchInput
              initValue={locationFrom?.name || locationFromDebounce}
              placeholder={t('location')}
              name="locationsFrom"
              isLoading={isLocationsFromLoading}
              data={locationsFrom?.getStations}
              ref={locationFromInput}
              optionSelected={option => {
                setLocationFromDebounce(option.name);
                setLocationFrom(option);
              }}
              debounceValueChanged={value => setLocationFromDebounce(value)}
              escape={false}
              outsideClickAction={() =>
                setStationLocation(
                  locationsFrom?.getStations,
                  locationFromDebounce,
                  locationFrom?.id,
                  setLocationFrom
                )
              }
            />
          </LocationContainer>
          <Spacer />
          <LocationContainer>
            <TitleLocation>{t('locationToTitle')}</TitleLocation>
            <SearchInput
              initValue={locationTo?.name || locationToDebounce}
              placeholder={t('location')}
              name="locationsTo"
              isLoading={isLocationsToLoading}
              data={locationsTo?.getStations}
              optionSelected={option => {
                setLocationToDebounce(option.name);
                setLocationTo(option);
              }}
              debounceValueChanged={value => setLocationToDebounce(value)}
              escape={false}
              outsideClickAction={() =>
                setStationLocation(
                  locationsTo?.getStations,
                  locationToDebounce,
                  locationTo?.id,
                  setLocationTo
                )
              }
            />
          </LocationContainer>
        </Row>
      )}
      <Row style={{ margin: '-2px' }}>
        <LocationContainer>
          <TripDirectionLabel>{t('outwardTrip')}</TripDirectionLabel>
        </LocationContainer>
      </Row>
      <Row>
        <LocationContainer style={{ maxWidth: '44%' }}>
          <Row>
            <StyledCalendarInput>
              <CalendarInput
                id="calInputOutward1"
                dateFormat={'dd. MMM yyyy.'}
                minDate={new Date()}
                maxDate={
                  !roundTrip
                    ? selectedTransportType?.id === 0
                        ? dayjs().add(120, 'day').toDate()
                        : dayjs().add(3, 'year').toDate()
                    : returnDate
                }
                input={{
                  onChange: e => setTripDate(e),
                  selected: tripDate,
                  value: dayjs(tripDate).format('DD. MMM YYYY.'),
                  name: '',
                  onBlur: () => null,
                  onFocus: () => null,
                }}
                isDisabled={false}
                isErrorVisible={false}
                required={true}
              />
            </StyledCalendarInput>
            <StyledTimeInput>
              <TimePicker
                value={
                  (tripDate instanceof Date &&
                    dayjs(tripDate)?.format('HH:mm')) ||
                  '00:00'
                }
                format={'HH:mm'}
                clearIcon={null}
                disableClock={true}
                onChange={value => {
                  const time = value.split(':');
                  tripDate?.setHours(parseInt(time[0]), parseInt(time[1]), 0);
                  setTripDate(tripDate);
                }}
              />
            </StyledTimeInput>
          </Row>
        </LocationContainer>
        <Spacer />
        <LocationContainer>
          <OptionInputWrapper>
            <Input
              input={{
                value: `${passengerCount} ${t(
                  'passengersTitle'
                )}, ${addonCount} ${t('addonsTitle')}`,
              }}
              isDisabled={true}
              isDisabledStyleApplied={false}
            />
          </OptionInputWrapper>
        </LocationContainer>
      </Row>
      {roundTrip ? (
        <>
          <Row style={{ margin: '-2px' }}>
            <LocationContainer>
              <TripDirectionLabel>{t('returnTrip')}</TripDirectionLabel>
            </LocationContainer>
          </Row>
          <Row>
            <LocationContainer style={{ maxWidth: '44%' }}>
              <Row>
                <StyledCalendarInput>
                  <CalendarInput
                    id="calInputReturn1"
                    dateFormat={'dd. MMM yyyy.'}
                    minDate={new Date()}
                    maxDate={
                      selectedTransportType?.id === 0
                        ? dayjs().add(120, 'day').toDate()
                        : dayjs().add(3, 'year').toDate()
                    }
                    input={{
                      onChange: e => setReturnDate(e),
                      selected: returnDate,
                      value: dayjs(returnDate).format('DD. MMM YYYY.'),
                      name: '',
                      onBlur: () => null,
                      onFocus: () => null,
                    }}
                    isDisabled={false}
                    isErrorVisible={false}
                    required={false}
                  />
                </StyledCalendarInput>
                <StyledTimeInput>
                  <TimePicker
                    value={
                      (returnDate instanceof Date &&
                        dayjs(returnDate)?.format('HH:mm')) ||
                      '00:00'
                    }
                    format={'HH:mm'}
                    clearIcon={null}
                    disableClock={true}
                    onChange={value => {
                      const time = value.split(':');
                      returnDate?.setHours(
                        parseInt(time[0]),
                        parseInt(time[1]),
                        0
                      );
                      setReturnDate(returnDate);
                    }}
                  />
                </StyledTimeInput>
              </Row>
            </LocationContainer>
          </Row>
        </>
      ) : null}
      <Row>
        <RadioInput
          disabled={false}
          input={{
            onChange: () => {
              setRoundTrip(false);
            },
            checked: !roundTrip,
          }}
        >
          <RadioText>{t('oneWay')}</RadioText>
        </RadioInput>
        <RadioInput
          disabled={false}
          input={{
            onChange: () => setRoundTrip(true),
            checked: roundTrip,
          }}
        >
          <RadioText>{t('roundTrip')}</RadioText>
        </RadioInput>
      </Row>
      {selectedTransportType?.id === 1 && (
        <Row>
          {travelClasses.map(travelClass => (
            <RadioInput
              disabled={false}
              input={{
                checked: travelClass.value === selectedTravelClass,
                onChange: () => setSelectedTravelClass(travelClass.value),
              }}
            >
              <RadioText>{travelClass.title}</RadioText>
            </RadioInput>
          ))}
          <StyledCheckboxContainer>
            <CheckBox
              type="checkbox"
              onChange={() => toggleTravelClassMix()}
              checked={travelClassMix}
            />
            <CheckBoxText>{t('mixedClasses')}</CheckBoxText>
          </StyledCheckboxContainer>
          <img
            class={styles.InputIconBlack}
            onClick={() => {
              setTipText(t('mixedClassesTooltip'));
              setTipTitle(t('mixedClasses'));

              setShowTip(true);
            }}
          />
        </Row>
      )}
      <Row>
        <TripOptionsContainer>
          <TightRow style={{ minWidth: '100%' }}>
            <TripOptionCategoriesTitle>
              {t('passengerCategories')}
            </TripOptionCategoriesTitle>
            <TightSpacer />
            <TripOptionCategoriesTitle>
              {t('addOnCategories')}
            </TripOptionCategoriesTitle>
          </TightRow>
          <TightRow>
            {selectedTransportType?.id === 0 && (
              <Column>
                {passengerCategories[0]
                  ?.filter(x => x.value !== 'PS')
                  .map(category => (
                    <>
                      <TightRow>
                        <TripOptionTitle>{category.title}</TripOptionTitle>
                        <TripOptionNumberInput>
                          <NumberInput
                            canDisable={true}
                            min={
                              passengerCount < 2
                                ? optionDetails.passengers[category.value]
                                : 0
                            }
                            ref={childrenOptionParent}
                            onInit={(value, index) => {
                              if (category.passengerAgeRange) {
                                setOptionInputs(
                                  value,
                                  childrenOptionParent.current,
                                  category,
                                  category.passengerAgeRange,
                                  index
                                );
                              }
                            }}
                            input={{
                              id: `option-category-number-input-${
                                category.value + 'Ages'
                              }`,
                              className: 'category-number-input',
                              value: options.passengers
                                ? options.passengers[category.value] +
                                  (category.value === 'SE'
                                    ? options.passengers.PS
                                    : 0)
                                : initOptionDetails.passengers[category.value],
                              onChange: e => {
                                if (category.passengerAgeRange) {
                                  setOptionInputs(
                                    e.target.value,
                                    childrenOptionParent.current,
                                    category,
                                    category.passengerAgeRange
                                  );
                                }

                                let value = parseInt(e.target.value);
                                if (category.value === 'SE') {
                                  value -= optionDetails.passengers.PS;
                                }
                                optionDetails.passengers[
                                  category.value
                                ] = value;

                                setOptionDetails({ ...optionDetails });
                                setOptions({ ...optionDetails });
                                updateOptionDetails();
                              },
                            }}
                          />
                        </TripOptionNumberInput>
                      </TightRow>
                      <ColumnInputs
                        id={`mark-${category.value}`}
                        visibility={
                          category.value === 'VU' ? 'hidden' : 'visible'
                        }
                      />
                    </>
                  ))}
              </Column>
            )}
            {selectedTransportType?.id === 1 && (
              <Column>
                {passengerCategories[1]?.map(category => (
                  <>
                    <TightRow>
                      <TripOptionTitle>
                        {t(category.title.toLowerCase())}{' '}
                        {getCategoryString(category)}
                      </TripOptionTitle>
                      <TripOptionNumberInput>
                        <NumberInput
                          min={category.value === 'adults' ? 1 : 0}
                          max={getMaxCategoryValue(category)}
                          canDisable={true}
                          input={{
                            className: 'category-number-input',
                            value: options.passengers
                              ? options.passengers[category.value]
                              : initOptionDetails.passengers[category.value],
                            onChange: e => {
                              optionDetails.passengers[
                                category.value
                              ] = parseInt(e.target.value);
                              setOptionDetails({ ...optionDetails });
                              setOptions({ ...optionDetails });
                              updateOptionDetails();
                            },
                          }}
                        />
                      </TripOptionNumberInput>
                    </TightRow>
                    <ColumnInputs
                      id={`mark-${category.value}`}
                      visibility={
                        category.value === 'VU' ? 'hidden' : 'visible'
                      }
                    />
                  </>
                ))}
              </Column>
            )}
            <Column>
              {selectedTransportType?.id === 0 ? (
                <TightRow>
                  <TripOptionTitleRight>
                    {t('numberOfPets')}
                  </TripOptionTitleRight>
                  <TripOptionNumberInput>
                    <NumberInput
                      min={0}
                      max={maxPets}
                      canDisable={true}
                      input={{
                        value: optionDetails.addons.pets,
                        onChange: e => {
                          optionDetails.addons.pets = parseInt(e.target.value);
                          setOptionDetails({ ...optionDetails });
                          updateOptionDetails();
                        },
                      }}
                    />
                  </TripOptionNumberInput>
                </TightRow>
              ) : (
                <>
                  <TightRow>
                    <TripOptionTitleRight>
                      {t('cabinBaggage')}
                    </TripOptionTitleRight>
                    <TripOptionNumberInput>
                      <NumberInput
                        min={0}
                        max={maxCabinBaggageCount}
                        canDisable={true}
                        input={{
                          value: optionDetails.addons.cabinBaggage,
                          onChange: e => {
                            optionDetails.addons.cabinBaggage = parseInt(
                              e.target.value
                            );
                            setOptionDetails({ ...optionDetails });
                            updateOptionDetails();
                          },
                        }}
                      />
                    </TripOptionNumberInput>
                  </TightRow>
                  <TightRow>
                    <TripOptionTitleRight>
                      {t('checkedBaggage')}
                    </TripOptionTitleRight>
                    <TripOptionNumberInput>
                      <NumberInput
                        min={0}
                        max={maxCheckedBaggageCount}
                        canDisable={true}
                        input={{
                          value: optionDetails.addons.checkedBaggage,
                          onChange: e => {
                            optionDetails.addons.checkedBaggage = parseInt(
                              e.target.value
                            );
                            setOptionDetails({ ...optionDetails });
                            updateOptionDetails();
                          },
                        }}
                      />
                    </TripOptionNumberInput>
                  </TightRow>
                </>
              )}
            </Column>
          </TightRow>
        </TripOptionsContainer>
      </Row>
      {selectedTransportType?.id === 0 && (
        <Row>
          <InfoDisclaimer
            text={t('trainReservationLimit')}
            icon="assets/icons/info-bubble.svg"
          />
        </Row>
      )}
    </Fragment>
  );

  if (isPassengerCategoriesLoading) {
    if (stationTo?.name == null) {
      setStationTo({ name: cityName, transportationType: 0, id: null });
    }
  }

  return (
    <Fragment>
      <ModalHeader title={t('title')} />

      {!passengerCategories[selectedTransportType?.id] || transportClassesLoading ? (
        <MainLoader />
      ) : (
        <Container>{renderComponents()}</Container>
      )}
      <ModalFooter
        mapIconProps={{ disabled: true }}
        buttonTitle={t('buttonTitle')}
        tooltipTitle={tipTitle}
        tooltipText={tipText}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        doOpen={showTip || !isThisPageVisited}
        doOnDismiss={() => setShowTip(false)}
        onButtonClick={() => {
          try {
            onSubmit();
          } catch (e) {
            toast.error(e.message, {
              position: 'top-right',
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            return;
          }
          history.push({
            pathname: Paths.TransportList,
            state: { activeTab: 'app.Controls.transportTab' },
          });
        }}
      />
    </Fragment>
  );
};

export default TransportDetails;
