import Loading from 'components/Loading';
import styled from 'styled-components';
import { colorsSpec } from 'styles';

export const Container = styled.div`
  padding: 0 25px 15px 25px;
  max-height: 500px;
`;

export const ControlsContainer = styled.div`
  padding: 25px 25px 10px 25px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
  cursor: pointer;

  @media (max-width: 767.98px) {
    flex-direction: column;
  }
`;

export const LeftColumn = styled.div`
  width: 45%;
  padding-right: 5px;

  @media (max-width: 767.98px) {
    width: 100%;
    padding-right: 0;
    max-width: 350px;
    margin: 0 auto;
  }
`;

export const RightColumn = styled.div`
  width: 55%;
  padding-left: 5px;

  @media (max-width: 767.98px) {
    width: 100%;
    padding-left: 0;
    max-width: 350px;
    margin: 0 auto;
  }
`;

export const SortContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
`;

export const SortLabel = styled.label`
  margin-right: 10px;
`;

export const TileContainer = styled.div`
  height: 126px;
  overflow: hidden;
  width: 100%;

  @media (max-width: 767.98px) {
    height: auto;
  }
`;

export const TransportTileContainer = styled(TileContainer)`
  height: 100%;
`;

export const ItemImage = styled.img.attrs(({ src }) => ({
  src,
}))`
  width: auto;
  max-width: 100%;
  display: block;
  height: 126px;

  @media (max-width: 767.98px) {
    height: auto;
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    width: auto;
    height: auto;
    margin: 0 auto;
  }
`;

export const InfiniteScrollLoader = styled(Loading)`
  height: 20px;
  padding-bottom: 20px;
`;

export const EndMessage = styled.div`
  margin-top: 20px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors[colorsSpec.primary]};
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;
