import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  GET_INTEGRATIONS_ACTIVITY_COUNT,
  GET_INTEGRATIONS_STAY_COUNT,
  GET_INTEGRATIONS_TRANSPORT_COUNT,
} from 'apollo/myDesti/queries';
import ShowMoreIcon from 'assets/icons/show-more-right2.svg';
import ActivityImage from 'assets/images/activity-background2.png';
import StayImage from 'assets/images/stay-background2.png';
import TransportImage from 'assets/images/transport-background2.png';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import { Paths } from 'constants/paths';
import useIntl from 'hooks/useIntl';
import { Fragment } from 'preact';
import { useHistory } from 'react-router-dom';
import { cartVar } from 'screens/Cart/cache';
import { locationAndPeriodVar } from 'screens/LocationAndPeriod/cache';
import styled from 'styled-components';
import { CartTypes } from 'types/shared/Cart';
import dayjs from 'dayjs';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  padding: 15px 25px 30px 25px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: 36px;
  font-style: normal;
  line-height: 49px;
  padding-left: 50px;
  text-align: left;
  text-transform: uppercase;
  z-index: 1;
`;

const ShowMore = styled.img`
  width: 41px;
  height: 41px;
  margin-right: 30px;
  z-index: 1;
`;

const Tile = styled.button`
  align-items: center;
  background: linear-gradient(90.39deg, #0B629E 12.43%, #FFFFFF 78.43%);
  background: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-position: 80%;
  border: none;
  cursor: pointer;
  display: flex;
  flex: 1 0 100%;
  justify-content: space-between;
  margin-top: 20px;
  max-width: 750px;
  height: 170px;
  position: relative;
  width: 100%;

  :disabled {
    cursor: default;

    ${Title} {
      color: ${({ theme }) => theme.colors.accent};
    }

    ${ShowMore} {
      filter: brightness(77%);
    }
  }
`;

const TileCover = styled.span`
  background-color: ${({ theme }) => theme.colors.secondaryLight};
  height: 100%;
  left: 0;
  opacity: 0.49;
  position: absolute;
  top: 0;
  width: 100%;
`;

const CartItemsCounter = styled.p`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: 30px;
  left: 55px;
  font-size: 18px;
  font-weight: normal;
  line-height: 25px;
  font-style: normal;
`;

const MAX_COUNT = 1000;

const Arrangements = () => {
  const history = useHistory();
  const isThisPageVisited = IsPageVisited('Arrangements');
  const { t } = useIntl('app.Arrangements');

  const { travelPeriod, selectedLocations } = useReactiveVar(locationAndPeriodVar);
  const { cartItems = [] } = useReactiveVar(cartVar);

  const variables = {
    city: selectedLocations[selectedLocations.length - 1].name,
    startDate: dayjs
      .utc(dayjs(travelPeriod[0]))
      .local()
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    endDate: dayjs
      .utc(dayjs(travelPeriod[1]))
      .local()
      .format('YYYY-MM-DDTHH:mm:ssZ')
  };

  const [
    loadStayCount,
    {
      called: calledICount,
      loading: loadingICount,
      data: integrationsStayCount,
    },
  ] = useLazyQuery(GET_INTEGRATIONS_STAY_COUNT, {
    variables: {
      ...variables,
      city: selectedLocations[selectedLocations.length - 1].name
    },
    fetchPolicy: 'cache-first',
  });

  const [loadTravelCount, { data: integrationsTransportCount }] = useLazyQuery(
    GET_INTEGRATIONS_TRANSPORT_COUNT,
    {
      variables: {
        ...variables,
        cities: selectedLocations.sort((a, b) => a.name > b.name ? 1 : -1)
      },
      fetchPolicy: 'cache-first'
    }
  );

  const [loadActivityCount, { data: integrationsActivityCount }] = useLazyQuery(
    GET_INTEGRATIONS_ACTIVITY_COUNT,
    {
      variables: {
        ...variables,
        city: selectedLocations[selectedLocations.length - 1].name,
        startDate: travelPeriod[0],
        endDate: travelPeriod[1]
      },
      fetchPolicy: 'cache-first',
    }
  );

  const categories = [
    {
      title: 'activities',
      count:
        integrationsActivityCount?.getIntegrationsActivityCount?.count,
      image: ActivityImage,
      onClick: () => history.push({
        pathname: Paths.ActivitiesList,
        state: { activeTab: 'app.Controls.activitiesTab' }
      }),
      cartItemCount: () => getCountTitle(CartTypes.ACTIVITY),
    },
    {
      title: 'stay',
      count:
        /* accommodation_aggregate?.aggregate.count || 0 + */
        integrationsStayCount?.getIntegrationsStayCount?.count,
      image: StayImage,
      onClick: () => history.push(Paths.StayDetails),
      cartItemCount: () => getCountTitle(CartTypes.STAY),
    },
    {
      title: 'transport',
      count: integrationsTransportCount?.getIntegrationsTransportCount?.count,
      image: TransportImage,
      onClick: () => history.push(Paths.TransportDetails),
      cartItemCount: () => getCountTitle(CartTypes.TRANSPORT),
    },
  ];

  const getCountTitle = type => {
    const itemsCount = cartItems.filter(item => item.type === type).length;
    if (itemsCount === 0) {
      return '';
    }

    const labelSingle =
      type === CartTypes.TRANSPORT
        ? t('transportInCart')
        : type === CartTypes.STAY
          ? t('stayInCart')
          : t('activityInCart');
    const labelPlural =
      type === CartTypes.TRANSPORT
        ? t('transportsInCart')
        : type === CartTypes.STAY
          ? t('staysInCart')
          : t('activitiesInCart');

    return itemsCount === 1 ? labelSingle : `${itemsCount} ${labelPlural}`;
  };

  if (!calledICount && !loadingICount) {
    loadStayCount();
    loadTravelCount();
    loadActivityCount();
  } else {
    categories[1].count =
      integrationsStayCount?.getIntegrationsStayCount?.count || 0;
  }

  return (
    <Fragment>
      <ModalHeader title={t('title')} />
      <Container>
        <div>
          {categories.map(category => (
            <Tile
              onClick={category.onClick}
              background={category.image}
              // disabled={category.count === 0}
              id={category.title}
            >
              <TileCover />
              <Title>
                {t(category.title)} { category.count > -1 && `(${category.count}${category.count >= MAX_COUNT ? '+' : ''})` }
              </Title>
              <CartItemsCounter>{category.cartItemCount()}</CartItemsCounter>
              <ShowMore src={ShowMoreIcon} />
            </Tile>
          ))}
        </div>
      </Container>
      <ModalFooter
        buttonTitle={t('buttonTitle')}
        showButton={cartItems.length > 0}
        mapIconProps={{ disabled: true }}
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        onButtonClick={() => history.push(Paths.Cart)}
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default Arrangements;
