import { gql } from '@apollo/client';

export const GET_ACCOMMODATION_CATEGORY_AND_TYPE = gql`
  query {
    accommodation_category {
      id
      name
    }
    accommodation_type {
      value
    }
  }
`;

export const GET_INTEGRATIONS_ACCOMMODATION_BY_TYPE = gql`
  query($city: String!, $type: String!, $categoryId: Int!) {
    getIntegrationsAccommodationByType(
      city: $city
      type: $type
      categoryId: $categoryId
    ) {
      id
      name
      type
      categoryId
      integration
      latitude
      longitude
    }
  }
`;

export const GET_INTEGRATIONS_ACCOMMODATION_BY_PK = gql`
  query($city: String!, $id: String!) {
    getIntegrationsAccommodationByPk(city: $city, id: $id) {
      code
      description
      name
      productOwner
      integration
      productLocation
      checkInTime
    }
  }
`;

export const SEARCH_STATION = gql`
  query(
    $city: String!
    $transportationTypes: [String]
    $longitude: Float
    $latitude: Float
  ) {
    getStations(
      city: $city
      transportationTypes: $transportationTypes
      longitude: $longitude
      latitude: $latitude
    ) {
      id
      name
      producerId
      transportationType
    }
  }
`;

export const STAY_DETAILS = gql`
  query {
    stayDetails @client {
      people
      children
      rooms
      bookingType
    }
  }
`;

export const GET_ALL_ACTIVITY_TYPES = gql`
  query ActivityTypes {
    activity_type: activity_type {
      value
    }
  }
`;

export const SEARCH_CITY_BY_NAME = gql`
  query($search: String!) {
    city: city(where: { name: { _ilike: $search } }) {
      id
      name
    }
  }
`;

export const GET_INTEGRATIONS_STAY_COUNT = gql`
  query GetIntegrationStayCount(
    $city: String!
    $startDate: date!
    $endDate: date!
    $language: String
  ) {
    getIntegrationsStayCount(
      city: $city
      startDate: $startDate
      endDate: $endDate
      language: $language
    ) {
      count
      providers
    }
  }
`;

export const GET_INTEGRATIONS_TRANSPORT_COUNT = gql`
  query($cities: [cityInput]!, $startDate: date!, $endDate: date!) {
    getIntegrationsTransportCount(
      cities: $cities
      startDate: $startDate
      endDate: $endDate
    ) {
      count
      providers
    }
  }
`;

export const GET_COUNTRIES = gql`
  query {
    countries: country(order_by: { name: asc }) {
      name
      id
      country_code
    }
  }
`;

export const GET_TRANSPORT_CATEGORIES = gql`
  query {
    transport_category {
      value
      transport_subcategories {
        name
        id
      }
    }
  }
`;

export const GET_TRANSPORT_TYPES = gql`
  query {
    transport_type {
      id
      order
      key_name
    }
  }
`;

export const TRANSPORT_DETAILS = gql`
  query {
    transportDetails @client {
      selectedTransportTypes
    }
  }
`;

export const TRANSPORT_LIST = gql`
  query {
    transportList @client {
      transportItem
    }
  }
`;

export const GET_INTEGRATIONS_ACTIVITY_COUNT = gql`
  query($city: String!, $startDate: date!, $endDate: date!) {
    getIntegrationsActivityCount(
      city: $city
      startDate: $startDate
      endDate: $endDate
    ) {
      count
      providers
    }
  }
`;

export const GET_PRODUCT_OWNERS_BY_CODE = gql`
  query($codes: [String!]) {
    product_owners: product_owner(where: { code: { _in: $codes } }) {
      id
      name
      code
      global_id
    }
  }
`;

export const GET_HOST_CONFIGRATION = gql`
  query getHostConfiguration {
    host_configuration {
      host_url
    }
  }
`;

export const GET_PRODUCT_OWNER_BY_PK = gql`
  query($id: uuid!) {
    product_owner_by_pk(id: $id) {
      product_owner_legal_documents {
        url
        type
        name
        url
        location
        language
      }
      product_owner_profile {
        terms
      }
    }
  }
`;

export const GET_TRANSPORT_CLASSES = gql`
  query($transportType: String!) {
    getTransportationClasses(transportType: $transportType) {
      key
      name
      value
    }
  }
`;

export const GET_CITIES = gql`
  query MyQuery {
    city(where: { region: { name: { _eq: "Lapland" } } }) {
      id
      latitude
      longitude
      name
      country_id
    }
  }
`;
