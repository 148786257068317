import styled from 'styled-components';
import { colorsSpec, fonts } from 'styles';

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  min-height: ${({ label, isErrorVisible }) => {
    if (label && isErrorVisible) {
      return '81px';
    } else if (isErrorVisible) {
      return '60px';
    }
    return '0px';
  }};
`;

const StyledInput = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;

  input {
    font-family: ${fonts.default};
    line-height: 19px;
    height: ${20};
    width: 100%;
    padding: 10px 16px;
    background-color: ${({ theme }) => theme.colors[colorsSpec.white]};
    border: 1px solid
      ${({ theme, error }) =>
        error
          ? theme.colors[colorsSpec.accentDark]
          : theme.colors[colorsSpec.accent]};

    color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
    border-radius: 4px;
    outline: none;
    font-size: ${({ theme }) => theme.fontSizes.default};

    + label {
      bottom: 11px;
      left: 17px;
      font-weight: 300;
      font-size: 14px;
      color: ${({ theme }) => theme.colors[colorsSpec.accent]};
      position: absolute;
      display: block;
      pointer-events: none;
    }

    :required + label:after {
      content: ' * ';
      color: ${({ theme }) => theme.colors[colorsSpec.accentDark]};
    }

    ${({ isDisabledStyleApplied, theme }) =>
      isDisabledStyleApplied &&
      `
      &:disabled,
      &:disabled + label {
        cursor: default;
        font-weight: 400;
        background-color:  ${theme.colors[colorsSpec.accent]};
        color: ${theme.colors[colorsSpec.white]};
        border: 1px solid ${theme.colors[colorsSpec.accent]};
      }
    `};

    &:focus,
    &:hover {
      border: 1px solid
        ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      font-family: ${fonts.default};
      -webkit-text-fill-color: ${({ theme }) =>
        theme.colors[colorsSpec.primaryDark]};
      -webkit-box-shadow: 0 0 0px 1000px
        ${({ theme }) => theme.colors[colorsSpec.white]} inset;
      transition: background-color 5000s ease-in-out 0s;
      position: relative;
    }

    &:-webkit-autofill::first-line {
      font-family: ${fonts.default};
    }
  }

  ${({ theme, error }) =>
    error &&
    `
      background-color: ${theme.colors[colorsSpec.accentDark]};
      border-radius: 4px;

      ${ErrorLabel} {
        display: block;
      }
  `};
`;

const ErrorLabel = styled.span`
  /* box-sizing: border-box; */
  display: none;
  border-radius: 3px 3px 0 0;
  color: white;
  text-align: right;
  font-size: 11px;
  padding: 4px 11px;
  font-weight: 800;

  + input {
    border-color: ${({ theme, error }) =>
      error ? theme.colors[colorsSpec.accentDark] : ''};
  }
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.default};
  margin-bottom: 5px;
`;

export { ErrorLabel, StyledInput, StyledInputContainer, Label };
