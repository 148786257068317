import { gql } from '@apollo/client';

export const GET_GENDER = gql`
  query {
    user_gender {
      value
    }
  }
`;

export const GET_USER_BY_PK = gql`
  query($id: String!) {
    user: user_by_pk(id: $id) {
      id
      country_id
      created_at
      date_of_birth
      email
      first_name
      gender
      last_name
    }
  }
`;

export const GET_INVOICES = gql`
query MyQuery {
  invoice_view {
    date
    description
    invoice_number
    product_owner_logo
    product_owner_name
    travel_itinerary_id
  }
}
`;

export const GET_TRAVEL_ITINERARY = gql`
  query {
    travel_itinerary(order_by: { created_at: desc }) {
      id
      description
      end_date
      start_date
      number_of_adults
      number_of_children
      travel_itinerary_activities {
        id
        price
        currency
        number_of_adults
        number_of_children
        type_of_activity
        transaction_id
        date_time_activity
      }
      travel_itinerary_stays {
        id
        number_of_rooms
        price
        currency
        product_owner_name
        city
        check_out_date
        check_in_date
        transaction_id
        description
      }
      travel_itinerary_transports {
        id
        price
        currency
        city_to
        city_from
        description
        product_owner_name
        type_of_transport
        transaction_id
        start_transport
        end_transport
      }
    }
  }
`;
