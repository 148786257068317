import { ReactiveVar } from '@apollo/client';
import { TransportList } from 'types/cache/TransportList';

export function useTransportList (transportListVar: ReactiveVar<TransportList>) {
  const setOrderBy = (orderBy: any) => {
    const oldValue = transportListVar();
    const updated = { ...oldValue, orderBy };
    transportListVar(updated);
  };

  const setType = (type: any) => {
    const oldValue = transportListVar();
    const updated = { ...oldValue, type };
    transportListVar(updated);
  };

  const setTransportItem = (transportItem: any) => {
    const oldValue = transportListVar();
    const updated = { ...oldValue, transportItem };
    transportListVar(updated);
  }

  const setReturnTransportItem = (returnTransportItem: any) => {
    const oldValue = transportListVar();
    const updated = { ...oldValue, returnTransportItem };
    transportListVar(updated);
  }

  const setJourneyId = (journeyId: number) => {
    const oldValue = transportListVar();
    const updated = { ...oldValue, journeyId };
    transportListVar(updated);
  }

  const setReturnJourneyId = (returnJourneyId: number) => {
    const oldValue = transportListVar();
    const updated = { ...oldValue, returnJourneyId };
    transportListVar(updated);
  }

  const setUniqueTransportId = (uniqueTransportId: string) => {
    const oldValue = transportListVar();
    const updated = { ...oldValue, uniqueTransportId };
    transportListVar(updated);
  }

  const setReturnUniqueTransportId = (returnUniqueTransportId: string) => {
    const oldValue = transportListVar();
    const updated = { ...oldValue, returnUniqueTransportId };
    transportListVar(updated);
  }

  return {
    setOrderBy,
    setType,
    setTransportItem,
    setReturnTransportItem,
    setJourneyId,
    setReturnJourneyId,
    setUniqueTransportId,
    setReturnUniqueTransportId
  };
}
