import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { myDestiClient } from 'apollo/client';
import {
  GET_INTEGRATIONS_ACCOMMODATION_BY_PK,
  STAY_DETAILS,
} from 'apollo/myDesti/queries';
import StayIcon from 'assets/icons/item-accommodation.svg';
import Calendar from 'components/Calendar';
import InfoDisclaimer, { MessageType } from 'components/InfoDisclaimer';
import Loading from 'components/Loading/';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import NumberInput from 'components/NumberInput/';
import { IsPageVisited } from 'components/PageVisited';
import SelectComponent, { Option } from 'components/Select/';
import Tabs, { mapActiveTab } from 'components/Tabs/';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { Fragment } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MARKERS_MOCK } from 'screens/Lists/config';
import { locationAndPeriodVar } from 'screens/LocationAndPeriod/cache';
import { ChildrenSelectContainer } from 'shared/components/SelectContainer';
import useTravelLocationAndPeriod from '../../LocationAndPeriod/useTravelLocationAndPeriod';
import {
  CalendarContainer,
  CalendarLabel,
  ChildrenControlsRow,
  Container,
  ControlsRow,
  Description,
  GalleryImage,
  GalleryRow,
  GalleryTitle,
  HeaderLeftColumn,
  HeaderRightColumn,
  HeaderRow,
  ImageOverlay,
  IndividualGallery,
  ItemImage,
  LeftColumn,
  Line,
  RightColumn,
  Row,
  ScrollRow,
  Title,
} from '../components';
import { childrenYearsOptions, galleryItems } from '../config';
import { individualStayVar } from './cache';
import useHotelDescription from './useHotelDescription';
import useIndividualAccommodationRestrictions from './useIndividualAccommodationRestrictions';
import { useIndividualStay } from './useIndividualStay';

const IndividualStay = ({ tabs = [], computedMatch, location }) => {
  const galleryRef = useRef(null);
  const { t } = useIntl('app.IndividualStay');
  const { t: controls } = useIntl('app.Controls');
  const history = useHistory();
  const isThisPageVisited = IsPageVisited('IndividualStay');

  const { activeTab } = location.state;
  const { integration, id } = computedMatch.params;
  const { stayDetails } = myDestiClient.readQuery({ query: STAY_DETAILS });
  const { cityName } = useReactiveVar(locationAndPeriodVar);

  const [resultAccommodation, setResultAccommodation] = useState<any>(null);

  const [accommodationCode, newAccommodationCode] = useState(null);

  const { description } = useHotelDescription(accommodationCode);
  const {
    setNumValues,
    updateChildren,
    setYearsForChild,
    setAccommodationName,
    setAccommodationCode,
    setProductOwner,
    setProductLocation,
    setCheckInTime
  } = useIndividualStay(individualStayVar);
  const { people, children, rooms, childrenYears } = useReactiveVar(
    individualStayVar
  );

  const { travelPeriod: initialTravelPeriod } = useReactiveVar(
    locationAndPeriodVar
  );
  const [stayTravelPeriod, setStayTravelPeriod] = useState(initialTravelPeriod);

  const { setTravelPeriod } = useTravelLocationAndPeriod(locationAndPeriodVar);

  useEffect(() => {
    // setting initial values for individualStayVar
    setNumValues(stayDetails.rooms, 'rooms');
    setNumValues(stayDetails.people, 'people');
    updateChildren(stayDetails.children);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    loadAccommodation,
    { called: calledAc, loading: loadingAc },
  ] = useLazyQuery(GET_INTEGRATIONS_ACCOMMODATION_BY_PK, {
    variables: {
      city: cityName,
      id,
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setResultAccommodation(data?.getIntegrationsAccommodationByPk);
      setAccommodationName(data?.getIntegrationsAccommodationByPk?.name);
      setAccommodationCode(data?.getIntegrationsAccommodationByPk?.code);
      newAccommodationCode(data?.getIntegrationsAccommodationByPk?.code);
      setTravelPeriod(stayTravelPeriod);
      setProductOwner(data?.getIntegrationsAccommodationByPk.productOwner);
      setProductLocation(
        data?.getIntegrationsAccommodationByPk.productLocation
      );
      setCheckInTime(data?.getIntegrationsAccommodationByPk?.checkInTime);
    },
  });

  const {
    isDateDisabled,
    isTravelPeriodValid,
  } = useIndividualAccommodationRestrictions(
    id,
    stayTravelPeriod[0],
    stayTravelPeriod[1]
  );

  const isTravelPeriodFreeOfRestrictions = useMemo(() => {
    return stayTravelPeriod.length < 2
      ? true
      : isTravelPeriodValid(stayTravelPeriod[0], stayTravelPeriod[1]);
  }, [stayTravelPeriod, isTravelPeriodValid]);

  useEffect(() => {
    // if there are restrictions in the picked travel period, reset the current stay travel period
    // if the user picks a new travel period at this screen, this period will again be checked for the restrictions
    // if it has restrictions, it will again be reset on this screen
    // if the travel period has no restrictions, it will be saved as the global date period
    if (!isTravelPeriodFreeOfRestrictions) {
      setStayTravelPeriod([]);
      toast.info(t('dateRestrictionsFoundInfo'));
    } else if (
      isTravelPeriodFreeOfRestrictions &&
      stayTravelPeriod.length === 2
    ) {
      setTravelPeriod(stayTravelPeriod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTravelPeriodFreeOfRestrictions, stayTravelPeriod]);

  useEffect(() => {
    loadAccommodation();
  }, []);

  return (
    <Fragment>
      <ModalHeader title={resultAccommodation?.name || ''} />
      {tabs.length && <Tabs config={mapActiveTab(tabs, activeTab)} />}
      {loadingAc && <Loading />}
      {!loadingAc && calledAc && resultAccommodation != null && (
        <Container>
          <HeaderRow onClick={() => galleryRef.current.scrollIntoView()}>
            <HeaderLeftColumn>
              <ItemImage
                src={`assets/images/hotel-cover-${accommodationCode || ''}.png`}
              />
            </HeaderLeftColumn>
            <HeaderRightColumn>
              <ItemImage
                src={`assets/images/hotel-cover-small-${
                  accommodationCode || ''
                }.png`}
              />
              <ImageOverlay>7 {t('photosTitle')}</ImageOverlay>
            </HeaderRightColumn>
          </HeaderRow>
          <ScrollRow>
            <Row>
              <LeftColumn>
                <Title>{resultAccommodation?.name}</Title>
                <img src={StayIcon} />
                <Description>
                  {description || resultAccommodation?.description}
                </Description>
              </LeftColumn>
              <RightColumn>
                <CalendarLabel>{t('dateTime')}</CalendarLabel>
                <CalendarContainer>
                  <Calendar
                    minDate={stayTravelPeriod[0]}
                    maxDate={stayTravelPeriod[1]}
                    selectRange
                    input={{
                      onChange: value => {
                        if (value.length === 2) {
                          return dayjs(value[0]).diff(value[1], 'day') === 0
                            ? setStayTravelPeriod([])
                            : setStayTravelPeriod([
                                value[0] < new Date() ? new Date() : value[0],
                                value[1],
                              ]);
                        }
                      },
                      value: stayTravelPeriod,
                    }}
                    tileDisabled={isDateDisabled}
                  />
                </CalendarContainer>
                <ControlsRow>
                  <h5>{t('howManyPeople')}</h5>
                  <NumberInput
                    min={1}
                    input={{
                      value: parseInt(people || stayDetails.people, 10),
                      onChange: e =>
                        setNumValues(parseInt(e.target.value), 'people'),
                    }}
                  />
                </ControlsRow>
                <ControlsRow>
                  <h5>{t('howManyChildren')}</h5>
                  <NumberInput
                    input={{
                      value: parseInt(children || stayDetails.children, 10),
                      onChange: e => {
                        updateChildren(e.target.value);
                      },
                    }}
                  />
                </ControlsRow>
                {children > 0 && (
                  <>
                    {Array.from(Array(Number(children))).map((e, idx) => (
                      <ChildrenControlsRow>
                        <h5>
                          {idx + 1}.{'\n'}
                          {t('childrenYears')}
                        </h5>
                        <ChildrenSelectContainer>
                          <SelectComponent
                            input={{
                              onChange: ({ value }: Option) =>
                                setYearsForChild(idx, value),
                              value: childrenYears[idx]
                                ? childrenYears[idx].value
                                : controls('yearsOld'),
                            }}
                            options={childrenYearsOptions}
                            styleType="type_2"
                            isErrorVisible={false}
                            placeholder={controls('yearsOld')}
                          />
                        </ChildrenSelectContainer>
                      </ChildrenControlsRow>
                    ))}
                  </>
                )}

                <ControlsRow>
                  <h5>{t('howManyRooms')}</h5>
                  <NumberInput
                    min={1}
                    input={{
                      value: parseInt(rooms || stayDetails.rooms, 10),
                      onChange: e => setNumValues(e.target.value, 'rooms'),
                    }}
                  />
                </ControlsRow>
              </RightColumn>
            </Row>
            <InfoDisclaimer
              text={t('childrenAgeInfo')}
              icon="assets/icons/info-bubble.svg"
              type={MessageType.Neutral}
            />

            <Line />

            <GalleryTitle>{t('gallery')}</GalleryTitle>
            <IndividualGallery ref={galleryRef}>
              {galleryItems(accommodationCode).map(src => (
                <GalleryRow>
                  <GalleryImage src={src} />
                </GalleryRow>
              ))}
            </IndividualGallery>
          </ScrollRow>
        </Container>
      )}

      <ModalFooter
        buttonTitle={t('buttonTitle')}
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        onButtonClick={() =>
          history.push({
            pathname: `/stay/${integration}/${id}/rooms`,
            state: {
              activeTab: 'app.Controls.stayTab',
            },
          })
        }
        buttonProps={{
          disabled: stayTravelPeriod.length === 0,
        }}
        mapIconProps={{
          onClick: () =>
            history.push({
              pathname: '/map',
              state: {
                markers: [
                  {
                    ...MARKERS_MOCK[0],
                    onClick: () => {
                      history.push({
                        pathname: `/stay/${integration}/${id}`,
                        state: {
                          activeTab: 'app.Controls.stayTab',
                        },
                      });
                    },
                  },
                ],
                activeTab: 'app.Controls.stayTab',
                isIndividual: true,
              },
            }),
        }}
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default IndividualStay;
