import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import useIntl from 'hooks/useIntl';
import { Fragment, FunctionalComponent } from 'preact';
import {
  ExitIcon,
  Header,
  MainTitle,
  NormalIndentedText,
  NormalText,
  TACContainer,
  Title,
} from '../components';

const TermsAndConditions: FunctionalComponent = () => {
  const { t } = useIntl('app.Terms');
  const isThisPageVisited = IsPageVisited('MyPagesTerms');

  return (
    <Fragment>
      <ModalHeader title={t('DestiOneTermsTitle')} />
      <TACContainer>
        <Header>
          <MainTitle>DESTI.ONE – ANVÄNDARVILLKOR</MainTitle>
          <ExitIcon />
        </Header>

        <NormalText>
          VIKTIGT – LÄS DESSA ANVÄNDARVILLKOR (“VILLKOREN”) NOGGRANT. GENOM ATT
          ANVÄNDA MYDESTI - PLATTFORMEN, BEKRÄFTAR ”DU” (ELLER ”ANVÄNDAREN”) ATT
          DU HAR LÄST OCH GODKÄNT VILLKOREN HÄRI. OM DU INTE GODKÄNNER VILLKOREN
          KAN DU INTE ANVÄNDA MYDESTI - PLATTFORMEN.
        </NormalText>

        <Title>1 INGRESS </Title>

        <NormalText>
          1.1 D1 Technology AB, org. Nr 559267-6463 (“Desti”), tillhandahåller
          en onlineconciergetjänst som underlättar Användarens skapande av
          reseplaner enligt sina preferenser (”Plattformen”).
        </NormalText>
        <NormalText>
          1.2 Dessa Villkor anger villkoren för användning av Plattformen.
        </NormalText>

        <Title>2 PLATTFORMEN OCH TILLÅTEN ANVÄNDNING</Title>

        <NormalText>
          2.1 Plattformen utgörs av en onlineconciergetjänst som underlättar
          Användarens skapande av reseplaner som inkluderar bokningsbara
          produkter och tjänster som tillhandahålls av företagare (”Partner”)
          inom resebranschen.
        </NormalText>
        <NormalText>
          2.2 Desti tillhandahåller Plattformen till Användaren utan kostnad.
          Trafikavgifter kan tillkomma bestående i pålägg av Användarens
          mobiloperatör eller internetleverantör.
        </NormalText>
        <NormalText>
          2.3 Desti förbehåller sig rätten att när som helst stänga ned eller
          begränsa Användarens tillgång till Plattformen utan att ange skäl.
        </NormalText>
        <NormalText>
          2.4 Användaren får endast använda Plattformen i enlighet med de
          villkor som anges häri och andra tillämpliga lagar och regler.
        </NormalText>
        <NormalText>
          2.5 Användaren får inte:
          <NormalIndentedText>
            a) kopiera, bakåtkonstruera, dekompilera eller demontera Plattformen
            eller på annat sätt försöka erhålla källkod, metoder, algoritmer
            eller procedurer från Plattformen,{' '}
          </NormalIndentedText>
          <NormalIndentedText>
            b) modifiera, anpassa eller skapa nya verk, produkter eller
            programvaror baserat på Plattformen, eller
          </NormalIndentedText>
          <NormalIndentedText>
            c) försöka kringgå licensnycklar eller andra användarbegränsningar i
            Plattformen.
          </NormalIndentedText>
        </NormalText>
        <NormalText>
          2.6 Desti är inte under några omständigheter ansvarigt för Användarens
          eller en tredje parts missbruk av Plattformen, inklusive eventuella
          konsekvenser och eventuella skador därav.
        </NormalText>

        <Title>3 IMMATERIELLA RÄTTIGHETER</Title>

        <NormalText>
          3.1 Plattformen och alla tillhörande immateriella rättigheter ägs av
          D1Technology AB (Desti) och/eller dess licensgivare och är skyddade av
          upphovsrättslagar samt andra lagar och regler.
        </NormalText>
        <NormalText>
          3.2 Desti behåller och förvärvar all rätt, äganderätt och intresse för
          transaktions- och prestandadata relaterade till all användning av
          Plattformen. Användaren bekräftar att sådan transaktions- och
          prestandadata tillhandahålls och kan användas av Desti. Desti har
          vidare rätt att använda aggregerade och anonymiserade användardata för
          att utveckla och förbättra Plattformen.
        </NormalText>
        <NormalText>
          3.3 Dessa Villkor ger inte Användaren några rättigheter till, eller i,
          patent, upphovsrätt, databasrätt, företagshemligheter, handelsnamn,
          varumärken (oavsett om de är registrerade eller oregistrerade) eller
          andra rättigheter eller licenser avseende Plattformen.
        </NormalText>

        <Title>4 PERSONUPPGIFTER</Title>

        <NormalText>
          4.1 Information som lagras eller behandlas av Desti via Plattformen
          kan innehålla personuppgifter för vilka Desti är
          personuppgifts-ansvarig för (enligt definition i
          Dataskyddsförordningen (EU) 2016/679 (GDPR)). Information om Destis
          personuppgiftsbehandling framgår av Destis integritetspolicy.
          Användaren bekräftar att denne har noggrant läst och förstått
          integritetspolicyn.
        </NormalText>
        <NormalText>
          4.2 Användaren är medveten om att denne är skyldig att tillse att alla
          personer som Användaren har lämnat personuppgifter om till Desti vid
          användande av Plattformen noggrant läser och förstår Destis
          integritetspolicy.
        </NormalText>

        <Title>5 ANSVARSBEGRÄNSNING</Title>

        <NormalText>
          5.1 Plattformen tillhandahålls “i befintligt skick” och Desti lämnar
          inga garantier avseende Plattformens innehåll, användbarhet,
          tillgänglighet, säkerhet, kvalitet eller funktion.
        </NormalText>
        <NormalText>
          5.2 Desti ansvarar inte för (i) Användarens användning av Plattformen,
          (ii) innehållet på webbplatser, onlineplattformar eller sociala medier
          som Plattformen informerar om eller länkar till, (iii) förluster,
          skador eller kostnader som kan uppstå i samband med, eller som ett
          resultat av, användningen av Plattformen eller webbplatser som
          Plattformen informerar om eller länkar till eller (iv) Partners
          förmåga att fullgöra, dess faktiska fullgörande eller brist på
          fullgörande i enlighet med Användarens avtal med Partner.
        </NormalText>
        <NormalText>
          5.3 Användaren är medveten om att bokningar av de produkter och
          tjänster som ingår i Användarens reseplaner inte är produkter och
          tjänster som tillhandahålls av Desti och att sådana bokningar omfattas
          av villkor som varje Partner som tillhandahåller avseende de
          bokningsbara produkterna och tjänsterna. Användaren är medveten om att
          sådana villkor sannolikt kommer att variera mellan olika Partners
          avseende bland annat om- och avbokningsvillkor och att Användaren är
          skyldig att noggrant läsa och förstå respektive Partners villkor innan
          Användaren bokar Partners produkter och tjänster.
        </NormalText>
        <NormalText>
          5.4 Desti eller dess leverantörer är inte, i den utsträckning det inte
          är förbjudet enligt lag, under några omständigheter ansvariga för
          några som helst skador, inklusive men inte begränsat till,
          tillfälliga, speciella, följd, exemplariska eller indirekt skador.
        </NormalText>

        <Title>6 ÖVRIGT</Title>

        <NormalText>
          6.1 Desti har rätt att när som helst ändra Plattformen, ändra dessa
          Villkor eller upphöra med tillhandahållandet av Plattformen. Desti
          kommer att publicera eventuella ändringar av Villkoren via
          Plattformen.
        </NormalText>
        <NormalText>
          6.2 Detta Avtal är bindande för parts efterträdare och för tillåtna
          förvärvare av parts rättigheter och skyldigheter enligt Avtalet.
          Avtalet får överlåtas av Desti utan föregående skriftligt medgivande
          från Användaren.
        </NormalText>
        <NormalText>
          6.3 Dessa Villkor är ursprungligen upprättade på engelska. Vid
          eventuella motstridiga uppgifter i de språkliga versionerna av
          Villkoren ska den engelska versionen äga företräde framför denna
          svenska version.
        </NormalText>
        <NormalText>
          6.4 Dessa Villkor ska tolkas i enlighet med och regleras av svensk
          lag.
        </NormalText>
        <NormalText>
          6.5 Alla tvister som uppstår i anledning av dessa Villkor ska
          uteslutande avgöras av Sveriges allmänna domstolar, med Göteborgs
          tingsrätt som första instans.
        </NormalText>
      </TACContainer>
      <ModalFooter
        showButton={false}
        mapIconProps={{ disabled: true }}
        chatIconProps={{ disabled: true }}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default TermsAndConditions;
