import styled from 'styled-components';
import { colorsSpec } from 'styles';
import { Row } from 'screens/Individual/components';
import RadioInput from 'components/RadioInput';
import { StateUpdater, useEffect, useState } from 'preact/hooks';
import { IProductCode, ISegmentPlacement } from 'types/cache/Cart';
import { FunctionalComponent } from 'preact';

const PlacementOptionRow = styled(Row)`
  justify-content: space-between;
  height: fit-content;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
  text-align: right;
`;

const RadioText = styled.p`
  padding: 5px;
  padding-right: 30px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  white-space: nowrap;
`;

const PlacementCharacteristicRow = styled(PlacementOptionRow)`
  overflow: auto;
  justify-content: flex-start;
  background: none;
  height: fit-content;
  min-height: 50px;
`;

const PlacementCharacteristicOption = styled.div`
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  width: fit-content;
  margin: 5px;
  padding-left: 10px;
  padding-top: 10px;
  height: 40px;
  max-height: 40px;
  
  p {
    font-size: 12px;
    line-height: 10px;
    padding-right: 15px;
  }
`;

type Props = {
  segmentPlacement: ISegmentPlacement;
  setOptions: (options: any) => void;
};

const Placements: FunctionalComponent<Props> = ({ segmentPlacement, setOptions }) => {
  const [selectedCharacteristic, setSelectedCharacteristic] = useState<IProductCode>(
    segmentPlacement.placementCharacteristics[0]
  );
  const [selectedOrientation, setSelectedOrientation] = useState<IProductCode>(
    segmentPlacement.placementOrientation[0]
  );
  const [selectedCompartment, setSelectedCompartment] = useState<IProductCode>(
    segmentPlacement.placementCompartments[0]
  );

  useEffect(() => {
    setInitialPlacementSelection();
  }, [segmentPlacement]);

  useEffect(() => {
    setOptions({
      feature: selectedCharacteristic,
      orientation: selectedOrientation,
      compartment: selectedCompartment
    });
  }, [selectedCharacteristic, selectedOrientation, selectedCompartment]);

  const setInitialPlacementSelection = () => {
    setSelectedCharacteristic(segmentPlacement.placementCharacteristics[0]);
    setSelectedOrientation(segmentPlacement.placementOrientation[0]);
    setSelectedCompartment(segmentPlacement.placementCompartments[0]);
  }

  const placementOption = (po: IProductCode, optionState: IProductCode, optionStateUpdater: StateUpdater<IProductCode>) => {
    return (
      <>
        <PlacementCharacteristicOption>
          <RadioInput
            disabled={false}
            input={{
              checked: po.title === optionState?.title,
              onChange: () => {
                optionStateUpdater(po)
              }
            }}
          >
            <RadioText>{po.title}</RadioText>
          </RadioInput>
        </PlacementCharacteristicOption>
      </>
    );
  };

  return (
    <>
      {
        segmentPlacement?.placementCharacteristics.length > 1 &&
        <PlacementCharacteristicRow>
          {segmentPlacement
            ?.placementCharacteristics.map(x => placementOption(x, selectedCharacteristic, setSelectedCharacteristic))}
        </PlacementCharacteristicRow>
      }
      {
        segmentPlacement?.placementOrientation.length > 1 &&
        <PlacementCharacteristicRow>
          {segmentPlacement
            ?.placementOrientation.map(x => placementOption(x, selectedOrientation, setSelectedOrientation))}
        </PlacementCharacteristicRow>
      }
      {
        segmentPlacement?.placementCompartments.length > 1 &&
        <PlacementCharacteristicRow>
          {segmentPlacement
            ?.placementCompartments.map(x => placementOption(x, selectedCompartment, setSelectedCompartment))}
        </PlacementCharacteristicRow>
      }
    </>
  );
}

export default Placements;
