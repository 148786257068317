import {
  MutationHookOptions,
  QueryHookOptions,
  useMutation as useMutationOriginal,
  useQuery as useQueryOriginal,
  useLazyQuery as useLazyQueryOriginal
} from '@apollo/client';
import { myBookingsAnonymousClient, myBookingsClient } from 'apollo/client';

export function useBookingMutation<A = any, B = any> (
  x: any,
  options?: MutationHookOptions<A, B>
) {
  return useMutationOriginal<A, B>(x, { ...options, client: myBookingsClient });
}

export function useBookingQuery<A, B> (
  x: any,
  options?: QueryHookOptions<A, B>
) {
  return useQueryOriginal<A, B>(x, { ...options, client: myBookingsClient });
}

export function useBookingLazyQuery<A, B> (
  x: any,
  options?: QueryHookOptions<A, B>
) {
  return useLazyQueryOriginal<A, B>(x, { ...options, client: myBookingsAnonymousClient });
}
