import CalendarIcon from 'assets/icons/calendar.svg';
import {
  ErrorLabel,
  Label,
  StyledInput,
  StyledInputContainer,
} from 'components/Input/StyledInput';
import { FunctionalComponent } from 'preact';
import DatePicker from 'react-datepicker';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import { StyledCalendar, StyledIcon } from './StyledCalendar';

type Props = {
  required?: boolean;
  id?: string;
  isDisabled?: boolean;
  isDisabledStyleApplied?: boolean;
  isErrorVisible?: boolean;
  input: FieldInputProps<string>;
  meta?: FieldMetaState<any>;
  dateFormat?: string;
  label?: string;
  placeholderText?: string;
  maxDate?: Date;
  minDate?: Date;
};

const CalendarInput: FunctionalComponent<Props> = ({
  input = null,
  meta = { visited: false },
  required = false,
  isDisabled = false,
  isDisabledStyleApplied = true,
  dateFormat = 'MM/dd/yyyy',
  label = '',
  placeholderText = '',
  maxDate = null,
  minDate = null,
  isErrorVisible = false,
  id = null,
}) => {
  return (
    <StyledInputContainer label={label} isErrorVisible={isErrorVisible}>
      {label && <Label>{label}</Label>}
      <StyledCalendar
        isDisabled={isDisabled}
        error={meta?.touched && (meta.error || meta.submitError)}
      >
        {
          <ErrorLabel error={meta?.touched && (meta.error || meta.submitError)}>
            {meta.error || meta.submitError}
          </ErrorLabel>
        }
        <StyledInput
          isDisabledStyleApplied={isDisabledStyleApplied}
          error={meta?.touched && (meta.error || meta.submitError)}
        >
          <DatePicker
            id={id}
            disabled={isDisabled}
            selected={input?.value}
            dateFormat={dateFormat}
            required={required}
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            dropdownMode="select"
            maxDate={maxDate}
            minDate={minDate}
            {...input}
            onChange={date => date && input?.onChange(date)}
          />
          <label
            style={{
              display: input?.value || meta?.active ? 'none' : 'block',
            }}
          >
            {placeholderText}
          </label>

          <StyledIcon disabled={isDisabled}>
            <img src={CalendarIcon} alt="calendar" />
          </StyledIcon>
        </StyledInput>
      </StyledCalendar>
    </StyledInputContainer>
  );
};

export default CalendarInput;
