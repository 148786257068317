import ShowMoreIcon from 'assets/icons/show-more.svg';
import { FunctionComponent } from 'preact';
import styled from 'styled-components';
import { colorsSpec } from 'styles';

const ArrowDown = styled.div`
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  width: ${({ isLargeIcon }) => (isLargeIcon ? '55px' : '25px')};
  height: ${({ isLargeIcon }) => (isLargeIcon ? '55px' : '25px')};
  background-image: ${({ isLargeIcon }) =>
    `url(${isLargeIcon ? ShowMoreIcon : ShowMoreIcon})`};
  cursor: pointer;

  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  position: absolute;
  right: 2px;
  top: 2px;
  transform: rotate(90deg);
  z-index: 1;
  border-radius: 3px;
`;

const ArrowUp = styled(ArrowDown)`
  transform: rotate(-90deg);
`;

const AccordionWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondary]};
  & + * {
    margin-top: 0.5em;
  }
`;

const AccordionItem = styled.div`
  overflow: hidden;
  height: auto;
  max-height: 9999px;

  ${({ isCollapsed }) =>
    isCollapsed
      ? `
    max-height: 0;
  `
      : `
  overflow: visible;
`};
`;

const AccordionTitle = styled.div`
  ${({ isOpen }) =>
    isOpen &&
    `
    &::after {
        content: "";
        border-top: 0;
        border-bottom: 5px solid;
      }
`};
`;

type Props = {
  isLargeIcon?: boolean;
  isOpen?: boolean;
  setOpen?: (isOpen: boolean) => void;
};

const Accordion: FunctionComponent<Props> = ({
  children = [],
  isLargeIcon = false,
  isOpen = false,
  setOpen,
}) => {
  return (
    <AccordionWrapper>
      <AccordionTitle
        isOpen={isOpen}
        onClick={() => {
          setOpen ? setOpen(!isOpen) : {};
        }}
      >
        {children[0]}
        {isOpen ? (
          <ArrowUp isLargeIcon={isLargeIcon} />
        ) : (
          <ArrowDown isLargeIcon={isLargeIcon} />
        )}
      </AccordionTitle>

      <AccordionItem isCollapsed={!isOpen}>
        <div>{children[1]}</div>
      </AccordionItem>
    </AccordionWrapper>
  );
};

export default Accordion;
