import { PASSWORD_REGEX } from 'config/inputs';
import useIntl from 'hooks/useIntl';

export default function () {
  const { t: messages } = useIntl('app.ValidationMessages');

  const constraints = {
    oldPassword: {
      presence: {
        message: messages('passwordRequired'),
      },
    },
    newPassword: {
      presence: {
        message: messages('passwordRequired'),
      },
      format: {
        pattern: PASSWORD_REGEX,
        message: messages('passwordInvalid'),
      },
    },
    confirmPassword: {
      presence: {
        message: messages('confirmPasswordRequired'),
      },
      equality: {
        attribute: 'newPassword',
        message: messages('passwordMustMatch'),
      },
    },
  };

  return { constraints };
}
