import { ActivityBookingField } from 'screens/Individual/Activity/activityModel';
import { CartTypes } from 'types/shared/Cart';
import { IProductLocation } from './ProductLocation';
import { Passenger } from './TransportDetails';

interface IQuantity {
  label: string;
  quantity: number;
  optionId: number;
}

interface ITravelDate {
  [key: string]: string;
}

export interface IBasePrice {
  amount: number;
  currency: string;
}

export interface IPriceFlexibility {
  flexibility: string;
  flexibilityVariantNumber: number;
  price: IBasePrice;
}

interface IPassengerPrice {
  basePrice: IBasePrice;
  id: string;
  priceFlexibilities: IPriceFlexibility[];
}

export interface IPriceGroup {
  passengersPrices: IPassengerPrice[];
  title: string;
  totalBasePrice: IBasePrice;
  value: string;
}

interface ILocation {
  id: number;
  name: string;
  producerId: number;
}

export interface IProductCode {
  title: string;
  value: string;
}

interface ISegmentProducerCode extends IProductCode {}
interface ITransportInformation extends IProductCode {}
interface ITravelMethodCode extends IProductCode {}

export interface ISegmentPlacement {
  placementCharacteristics: IProductCode[];
  placementCompartments: IProductCode[];
  placementOrientation: IProductCode[];
  priceGroup: string;
}

export interface ISegment {
  arrivalDateTime: string;
  arrivalLocation: ILocation;
  bookingClass: null | any;
  bookingRule: number;
  departureDateTime: string;
  departureLocation: ILocation;
  id: string;
  productCode: IProductCode;
  segmentProducerCode: ISegmentProducerCode;
  segmentsPlacements: ISegmentPlacement[];
  transportId: string;
  transportInformation: ITransportInformation[];
  travelMethodCode: ITravelMethodCode;
}

export interface IItinerary {
  id: number;
  priceGroups: IPriceGroup[];
  segments: ISegment[];
}
export interface IActivityCartItem {
  activity_type: string;
  city: string;
  date: string;
  startTimeLocal: string;
  endTimeLocal: string;
  startTime: string;
  endTime: string;
  id: string;
  people: number;
  children: number;
  infants: number;
  title: string;
  subtitle: string;
  price: IBasePrice;
  quantity: IQuantity[];
  product_owner: string;
  fields: Array<ActivityBookingField>;
  booking_time_required: boolean;
  supplierName: string;
  terms: string;
  location: IProductLocation;
}

export interface IStayCartItem {
  accommodationName: string;
  stayEstablishmentId: string;
  children: number | null;
  childrenAges: number[] | null;
  infants: number;
  city: string;
  people: number | null;
  price: IBasePrice;
  room_type: string;
  rooms: number;
  number_of_rooms?: number;
  title: string;
  subtitle: string;
  travelPeriod: ITravelDate;
  product_owner: string;
  priceRateCode: string;
  integration: string;
  roomDescription: string;
  roomCapacity: string;
  location: IProductLocation;
}

export interface ITransportCartItem {
  city_from: string;
  city_to: string;
  date: string;
  return_date: string;
  price: IBasePrice;
  title: string;
  subtitle: string;
  transport_type: string;
  getTransportationDetails?: {
    id: number;
    itineraries: IItinerary[];
    __typename: string;
  };
  people: number | null;
  children: number;
  infants: number;
  booking_url?: string;
  booking_token?: string;
  bags?: number;
  passengers: Passenger[];
  product_owner: string;
  trip_type: string;
  book_transportation: any;
  operatorNames: string[];
  location: IProductLocation;
  integration: string;
}

export type CartItemType =
  | IActivityCartItem
  | IStayCartItem
  | ITransportCartItem;

export enum TravelerTypes {
  Lead,
  Child,
  Traveler,
}

export interface Traveler {
  firstName: string;
  lastName: string;
  gender: string;
  country: string;
  dateOfBirth: Date;
  title: string;
  type: TravelerTypes;
  nationality: string;
}

export interface CartItem {
  id: number;
  item?: CartItemType;
  type?: CartTypes;
  travelerList?: Traveler[];
  isTermsAndPrivacyChecked?: boolean;
  paid?: boolean;
  order_number?: string;
  leadTraveler?: {
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth: string;
    country: string;
    countryIsoCode2: string;
    nationality: string;
    gender: string;
    type: TravelerTypes;
  };
}

export interface Cart {
  cartItems: CartItem[];
  price: IBasePrice;
  travelItineraryId: string;
}
