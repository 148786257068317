import MapComponent from 'components/Map';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import Tabs, { mapActiveTab } from 'components/Tabs';
import useIntl from 'hooks/useIntl';
import { Fragment } from 'preact';
import { useCallback, useMemo } from 'preact/hooks';
import { Container } from 'screens/Individual/components';

const containerStyle = {
  width: '100%',
  height: '550px',
};

const MAP_ZOOM = 10;

const MapScreen = ({ tabs = [], location }) => {
  const { t } = useIntl('app.Map');

  const { markers, activeTab, isIndividual = false } = location.state;
  const isThisPageVisited = IsPageVisited('MapScreen');

  const center = useMemo(() => {
    return {
      lat:
        markers.reduce(
          (accummulator, currentValue) => currentValue.lat + accummulator,
          0
        ) / markers.length,
      lng:
        markers.reduce(
          (accummulator, currentValue) => currentValue.lng + accummulator,
          0
        ) / markers.length,
    };
  }, [markers]);

  const onMapLoad = useCallback(
    map => {
      const bounds = new window.google.maps.LatLngBounds(center);
      markers.forEach(m =>
        bounds.extend(new window.google.maps.LatLng(m.lat, m.lng))
      );
      map.fitBounds(bounds);
    },
    [center, markers]
  );

  return (
    <Fragment>
      <ModalHeader title={t('title')} />
      {tabs.length && <Tabs config={mapActiveTab(tabs, activeTab)} />}

      <Container>
        <MapComponent
          center={center}
          containerStyle={containerStyle}
          onMapLoad={onMapLoad}
          zoom={MAP_ZOOM}
          markers={markers}
        />
      </Container>

      <ModalFooter
        showButton={false}
        tooltipTitle={
          isIndividual ? t('tooltipTitleIndividual') : t('tooltipTitle')
        }
        tooltipText={
          isIndividual ? t('tooltipTextIndividual') : t('tooltipText')
        }
        tooltipPoweredBy={t('tooltipPoweredBy')}
        mapIconProps={{ disabled: false }}
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default MapScreen;
