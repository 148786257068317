import { cartVar } from 'screens/Cart/cache';
import { CartActionTypes, useCart } from 'screens/Cart/useCart';
import {
  IActivityCartItem,
  IStayCartItem,
  ITransportCartItem,
} from 'types/cache/Cart';
import { CartTypes } from 'types/shared/Cart';
import ActivityCartItem from './Activity';
import StayCartItem from './Stay';
import TransportCartItem from './Transport';

const CartItems = (
  id: number,
  item: IActivityCartItem | IStayCartItem | ITransportCartItem,
  type: CartTypes,
  isSmall: boolean,
  isOpen?: boolean
) => {
  const { setCartItem } = useCart(cartVar);

  return {
    [CartTypes.TRANSPORT]: (
      <TransportCartItem
        item={item}
        isSmall={isSmall}
        isOpen={isOpen}
        onRemove={item =>
          setCartItem({ id, item, type }, CartActionTypes.Remove)
        }
      />
    ),
    [CartTypes.ACTIVITY]: (
      <ActivityCartItem
        item={item}
        isSmall={isSmall}
        isOpen={isOpen}
        onRemove={item =>
          setCartItem({ id, item, type }, CartActionTypes.Remove)
        }
      />
    ),
    [CartTypes.STAY]: (
      <StayCartItem
        item={item}
        isSmall={isSmall}
        isOpen={isOpen}
        onRemove={item =>
          setCartItem({ id, item, type }, CartActionTypes.Remove)
        }
      />
    ),
  };
};

export default CartItems;
