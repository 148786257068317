import Button from 'components/Button';
import styled from 'styled-components';
import { colorsSpec } from 'styles';

export const CartItem = styled.div`
  background: ${({ theme }) => theme.colors[colorsSpec.secondary]};
  display: flex;
  flex-direction: row;
  margin: ${({ isSmall }) => (isSmall ? '0' : '10')};
  height: ${({ isOpen }) => (isOpen ? '80px' : 'auto')};

  @media (max-width: 767.98px) {
    flex-wrap: wrap;
    height: auto;
  }
`;

export const CartItemImage = styled.div`
  width: ${({ isSmall }) => (isSmall ? '140px' : '20%')};
  padding-right: ${({ isSmall }) => (isSmall ? '0' : '5px')};
  position: relative;
  border-top-left-radius: 3px;
  bottom: ${({ isOpen }) => (isOpen ? '55px' : '0')};
  left: 5px;
  display: flex;
  flex-direction: column;

  @media (max-width: 767.98px) {
    width: 30%;
    bottom: 0;
  }

  @media (max-width: 576px) {
    width: 30%;
    bottom: 45px;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @media (max-width: 767.98px) {
      width: 100%;
    }
  }

  p {
    color: ${({ theme }) => theme.colors[colorsSpec.white]};
    font-size: 10px;
    font-weight: 700;
    margin-bottom: 0;
    background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
    display: flex;
    width: 100%;
    align-items: center;
    padding: 5px 18px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    justify-content: center;
  }

  span {
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 0;
    background-color: ${({ theme }) => theme.colors[colorsSpec.primary]};
    color: ${({ theme }) => theme.colors[colorsSpec.white]};
    font-size: 10px;
    padding: 5px;
    text-align: center;
    font-weight: 600;
    border-top-right-radius: 5px;
  }
`;

export const CartItemTime = styled.div`
  width: calc(25% - 10px);
  background: ${({ theme }) => theme.colors[colorsSpec.white]};
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;

  @media (max-width: 767.98px) {
    width: calc(60% - 10px);
  }
`;

export const CartItemTimeHeading = styled.p`
  border-bottom: 1px solid
    ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CartItemTimeTitle = styled.p`
  margin-bottom: 5px !important;
  font-size: 12px;
  font-weight: 700;
`;

export const CartItemTimeValue = styled.h3`
  &:first-of-type {
    margin-bottom: 10px;
  }
`;

export const CartItemInfo = styled.div`
  width: 80%;
  padding: 0 3px;

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

export const Title = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors[colorsSpec.white]};
  border-radius: 4px;
  height: 26px;
  text-align: center;
  margin: 2px 0;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Value = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors[colorsSpec.white]};
  border-radius: 4px;
  height: 26px;
  text-align: center;
  margin: 2px 0;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 576px) {
    margin: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px 0;
  margin-top: 13px;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const SmallCard = styled.div`
  width: 20%;
  padding: 0 5px;

  @media (max-width: 767.98px) {
    width: 50%;
  }
`;

export const MediumCard = styled.div`
  width: 40%;
  padding: 0 5px;

  @media (max-width: 767.98px) {
    width: 50%;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const LargeCard = styled.div`
  width: 60%;
  padding: 0 5px;

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

export const ExtraLargeCard = styled.div`
  width: 85%;
  padding: 0 5px;

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

export const StyledIcon = styled.i`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  width: 25px;
  height: 25px;
  border-radius: 3px;
  padding: 5px;
  display: inline-block;

  img {
    margin: auto;
  }
`;

export const RemoveButton = styled(Button)`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 70px;
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  color: ${({ theme }) => theme.colors[colorsSpec.black]};
  width: 40px;
  height: 30px;
  border-radius: 30px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: start;

  img {
    width: 15px;
    height: 15px;
  }

  span {
    position: relative;
    height: 14px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;

    text-align: center;
  }

  @media (max-width: 576px) {
   

    ${({ isOpen }) =>
      isOpen
        ? `
    left: -100px;
    top: 92px;
    margin-left: 0px;
    position: relative;
  `
        : ` top: 58px;
    margin-left: 5px;
    right: auto;
  `};


    img {
      display: block;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
    color: ${({ theme }) => theme.colors[colorsSpec.white]};

    img {
      filter: invert(100%) sepia(28%) saturate(291%) hue-rotate( 245deg
        ) brightness(177%) contrast(100%);
    }
`;

export const DetailsContainer = styled.div`
  width: 90%;
  background: ${({ theme }) => theme.colors[colorsSpec.white]};
  height: 410px;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-left: 20px;
  margin-top: 10px;
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const InformationTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  display: inline-block;
`;

export const InformationValue = styled(InformationTitle)`
  float: right;
  font-weight: 600;
`;

export const InformationList = styled.ul`
  width: 300px;
  margin-right: 30px;
`;

export const InformationItem = styled.li`
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  border-bottom: 1px solid ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  white-space: break-spaces;
  margin-bottom: 10px;
  padding-bottom: 10px;
  ::after {
    content: '';
    clear: both;
    display: table;
  }
`;

export const AdditionalInformationContainer = styled.div`
  /* width: 719px;
  height: 40%; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  /* margin-left: 39px; */
`;

export const Image = styled.img`
  width: 250px;
  height: 112px;
`;

export const TotalPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-left: 45px;
  margin-top: 21px; */

  /* @media (max-width: 576px) {
    margin-left: 75px;
  } */
`;

export const ActivityAndTransportTotalPriceContainer = styled(
  TotalPriceContainer
)`
  /* margin-left: 200px; */
`;

export const TotalPriceTitle = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  /* margin-left: 25px */
`;

export const TotalPriceValue = styled.p`
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
`;
