import InformationBox from 'components/InformationBox';
import styled from 'styled-components';
import ExternalLinkIcon from 'assets/icons/external-link.svg';
import useIntl from 'hooks/useIntl';

const ExploreD1InfoContainer = styled.div`
  margin-top: 10px;
  margin-right: 30px;
`;

const ExploreD1Info = () => {
  const { t } = useIntl('app.LocationAndPeriod');

  return (
    window.DEST1_CONFIG.showcaseVersion &&
    <ExploreD1InfoContainer>
      <InformationBox
        headingLabel={t('d1MessageTitle')}
        infoLabelEnd='.'
        infoLabelStart={t('d1Message')}
        link='https://kirunalapland.se/en/'
        linkLabel='link'
        headingIcon={ExternalLinkIcon}
        textStyle={{ lineHeight: '15px' }}
      />
    </ExploreD1InfoContainer>
  )
}

export default ExploreD1Info;
