import { ReactiveVar } from '@apollo/client';
import { Selector } from 'components/DualSelector/SelectorItem';
import { StayDetails } from 'types/cache/StayDetails';
import { scrubInFocusItems, scrubListItemsForUI } from './config';

export function useStayDetails(stayDetailsVar: ReactiveVar<StayDetails>) {
  const setBookingType = (bookingType: string) => {
    const oldValue = stayDetailsVar();
    const updated = { ...oldValue, bookingType };
    stayDetailsVar(updated);
  };

  const setInFocusItems = (accommodation: any, activeTab: string) => {
    const oldValue = stayDetailsVar();
    const updated = {
      ...oldValue,
      inFocusItems: scrubInFocusItems(accommodation, activeTab),
    };
    stayDetailsVar(updated);
  };

  const setAccommodationType = (item: Selector, subItem: Selector) => {
    const oldValue = stayDetailsVar();
    const updated = {
      ...oldValue,
      accommodationType: { item, subItem },
    };
    stayDetailsVar(updated);
  };

  const setAccommodationList = (data: any) => {
    const oldValue = stayDetailsVar();
    const updated = {
      ...oldValue,
      accommodationList: scrubListItemsForUI(data),
    };
    stayDetailsVar(updated);
  };

  const setNumValues = (data: any, key: string) => {
    const oldValue = stayDetailsVar();
    const updated = {
      ...oldValue,
      [key]: parseInt(data),
    };
    stayDetailsVar(updated);
  };

  return {
    setBookingType,
    setAccommodationType,
    setInFocusItems,
    setAccommodationList,
    setNumValues,
  };
}
