import ComputerIcon from 'assets/icons/computer-icon.svg';
import ElevatorIcon from 'assets/icons/elevator-icon.svg';
import ForkIcon from 'assets/icons/fork-icon.svg';
import PetIcon from 'assets/icons/pet-icon.svg';
import TemperatureIcon from 'assets/icons/temperature-icon.svg';
import WifiIcon from 'assets/icons/wifi-icon.svg';
import StayImage from 'assets/images/stay-image.png';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import {
  AdditionalInformationContainer,
  DetailsContainer,
  Image,
  ImageContainer,
  InformationContainer,
  InformationItem,
  InformationList,
  InformationTitle,
  InformationValue,
  TotalPriceContainer,
  TotalPriceTitle,
  TotalPriceValue,
} from 'screens/Cart/components';
import styled from 'styled-components';
import { IStayCartItem } from 'types/cache/Cart';
import { formatPrice } from 'utils/price/priceOperations';

const IncludedServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;

  @media (max-width: 576px) {
    margin-left: 80px;
  }
`;

const IncludedServicesColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: center;
`;

const ServiceImage = styled.img`
  margin: 5px;
  width: 28px;
  height: 28px;
  margin-bottom: 20px;
`;

interface IProps {
  item: IStayCartItem;
}

const StayCartItemDetails: FunctionalComponent<IProps> = ({ item }) => {
  const {
    travelPeriod,
    people,
    children,
    title,
    number_of_rooms,
    room_type,
    roomDescription,
    city,
    price,
  } = item;

  const { t } = useIntl('app.Cart');

  const checkInDate = dayjs(travelPeriod[0]).format('DD.MM.YYYY');
  const checkOutDate = dayjs(travelPeriod[1]).format('DD.MM.YYYY');
  const numberOfAdults = people || 0;
  const numberOfChildren = children || 0;
  const priceValue = formatPrice(price);
  const roomsValue = `${number_of_rooms}x ${room_type} ${roomDescription}`;

  return (
    <DetailsContainer>
      <InformationContainer>
        <InformationList>
          <InformationItem>
            <InformationTitle>{t('checkInTitle')}</InformationTitle>
            <InformationValue>{checkInDate}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('checkOutTitle')}</InformationTitle>
            <InformationValue>{checkOutDate}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('numberOfAdults')}</InformationTitle>
            <InformationValue>{`${numberOfAdults} ${t(
              'people'
            )}`}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('numberOfChildren')}</InformationTitle>
            <InformationValue>{`${numberOfChildren} ${t(
              'children'
            )}`}</InformationValue>
          </InformationItem>
        </InformationList>
        <InformationList>
          <InformationItem>
            <InformationTitle>{t('stayName')}</InformationTitle>
            <InformationValue>{title}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('rooms')}</InformationTitle>
            <InformationValue>{roomsValue}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('city')}</InformationTitle>
            <InformationValue>{city}</InformationValue>
          </InformationItem>
        </InformationList>
      </InformationContainer>
      <AdditionalInformationContainer>
        <ImageContainer>
          <Image src={StayImage} />
        </ImageContainer>
        <TotalPriceContainer>
          <TotalPriceTitle>{t('totalPrice')}</TotalPriceTitle>
          <TotalPriceValue>{priceValue}</TotalPriceValue>
        </TotalPriceContainer>
        <IncludedServicesContainer>
          <IncludedServicesColumn>
            <ServiceImage src={WifiIcon} />
            <ServiceImage src={ElevatorIcon} />
          </IncludedServicesColumn>
          <IncludedServicesColumn>
            <ServiceImage src={ComputerIcon} />
            <ServiceImage src={TemperatureIcon} />
          </IncludedServicesColumn>
          <IncludedServicesColumn>
            <ServiceImage src={ForkIcon} />
            <ServiceImage src={PetIcon} />
          </IncludedServicesColumn>
        </IncludedServicesContainer>
      </AdditionalInformationContainer>
    </DetailsContainer>
  );
};

export default StayCartItemDetails;
