import { useMutation, useReactiveVar } from '@apollo/client';
import { GET_INTEGRATION_TRANSPORTATION_DETAILS } from 'apollo/myDesti/mutations';
import Loading from 'components/Loading';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import Tabs, { mapActiveTab } from 'components/Tabs';
import { Paths } from 'constants/paths';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import { cartVar } from 'screens/Cart/cache';
import { CartActionTypes, useCart } from 'screens/Cart/useCart';
import { transportDetailsVar } from 'screens/Details/Transport/cache';
import { transportListVar } from 'screens/Lists/Transport/cache';
import { locationAndPeriodVar } from 'screens/LocationAndPeriod/cache';
import {
  ADD_TO_CART_TIMEOUT,
  NotificationScreen,
} from 'screens/NotificationScreen';
import useNotification from 'screens/NotificationScreen/useNotification';
import styled from 'styled-components';
import {
  CartItem,
  IItinerary,
  ISegment,
  ITransportCartItem,
} from 'types/cache/Cart';
import {
  ITransportDetails,
  TransportSelectedOptions,
} from 'types/cache/TransportIndividual';
import { CartTypes } from 'types/shared/Cart';
import { NOTIFICATION_TYPES } from 'types/shared/Notification';
import { TransportMappings } from 'types/shared/Transport';
import { formatPrice, sumPrices } from 'utils/price/priceOperations';
import { distinctCollectionToString } from 'utils/string/distinctCollectionToString';
import { Container, Row } from '../components';
import PassengersInfo from './components/PassengersInfo';
import TransportItinerary from './components/TransportItinerary';
import { v4 as uuidv4 } from 'uuid';

const SummaryInfoRow = styled(Row)`
  margin: 5px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  justify-content: space-between;

  p {
    margin-right: 10px;
    text-align: center;
  }

  & p:last-child {
    margin-left: auto;
    float: right;
  }
`;

const IndividualTransport = ({ tabs = [], location }) => {
  const { t } = useIntl('app.IndividualTransport');

  const [searchReturn, setSearchReturn] = useState(false);
  const [currentOptions, setCurrentOptions] = useState<
    TransportSelectedOptions[]
  >([]);
  const [currentOutwardOptions, setCurrentOutwardOptions] = useState<
    TransportSelectedOptions[]
  >([]);
  const [currentItineraryIndex, setCurrentItineraryIndex] = useState(0);
  const [outwardItem, setOutwardItem] = useState(null);

  const containerRef = useRef(null);

  const { activeTab } = location.state;
  const history = useHistory();
  const isThisPageVisited = IsPageVisited('IndividualTransport');

  const { travelPeriod: initialTravelPeriod } = useReactiveVar(
    locationAndPeriodVar
  );

  const { setNotification, notification } = useNotification();
  const { setCartItem } = useCart(cartVar);

  const {
    stationTo,
    stationFrom,
    passengers,
    passengerCategories,
    options,
    selectedTransportType,
  } = useReactiveVar(transportDetailsVar);

  const {
    transportItem,
    returnTransportItem,
    journeyId,
    returnJourneyId,
    uniqueTransportId,
    returnUniqueTransportId,
  } = useReactiveVar(transportListVar);

  const transportDetail = useMemo(
    () => (searchReturn ? returnTransportItem || {} : transportItem || {}),
    [searchReturn]
  );

  const currentTotal = useMemo(() => {
    const totalFlex = currentOptions
      .concat(currentOutwardOptions)
      .reduce(
        (partial_sum, selectedOption) =>
          sumPrices(
            partial_sum,
            selectedOption.flexibility.price,
            selectedOption.priceGroup.totalBasePrice
          ),
        { amount: 0, currency: '' }
      );

    return totalFlex;
  }, [currentOptions]);

  const [
    getTransportationDetails,
    {
      data: { getTransportationDetails: transportationDetails } = {
        getTransportationDetails: null,
      },
      loading,
    },
  ] = useMutation<{ getTransportationDetails: ITransportDetails }>(
    GET_INTEGRATION_TRANSPORTATION_DETAILS
  );

  const callGetTransportationDetails = async (
    journeyId: number,
    uniqueTransportId
  ) => {
    const originLocationCode = `${stationFrom.producerId}-${stationFrom.id}`;
    const destinationLocationCode = `${stationTo.producerId}-${stationTo.id}`;

    await getTransportationDetails({
      variables: {
        journeyId,
        passengers,
        hasPets: options.addons.pets > 0,
        originLocationCode: !searchReturn
          ? originLocationCode
          : destinationLocationCode,
        destinationLocationCode: !searchReturn
          ? destinationLocationCode
          : originLocationCode,
        departureDateFrom: dayjs
          .utc(dayjs(transportDetail.departure_time))
          .local()
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        transportationType: [selectedTransportType.key],
        uniqueTransportId,
      },
    });
  };

  useEffect(() => {
    if (!searchReturn) {
      return;
    }
    callGetTransportationDetails(returnJourneyId, returnUniqueTransportId);
  }, [searchReturn]);

  useEffect(() => {
    if (!transportItem) return;
    callGetTransportationDetails(journeyId, uniqueTransportId);
  }, [transportItem]);

  const itineraryOptionsChosen = useMemo(
    () => transportationDetails?.itineraries.length === currentItineraryIndex,
    [currentItineraryIndex]
  );

  const buildOptions = (itineraryOptions: any): TransportSelectedOptions => {
    return {
      ...itineraryOptions,
      journeyId: transportationDetails.id,
    };
  };

  const formatSegments = (segments: ISegment[], tIndex: number) => {
    return segments.map((segment, sIndex) => ({
      id: segment.id,
      departureDateTime: segment.departureDateTime,
      arrivalDateTime: segment.arrivalDateTime,
      departureLocation: {
        id: segment.departureLocation?.id,
        name: segment.departureLocation?.name,
        producerId: segment.departureLocation?.producerId,
      },
      arrivalLocation: {
        id: segment.arrivalLocation?.id,
        name: segment.arrivalLocation?.name,
        producerId: segment.arrivalLocation?.producerId,
      },
      travelMethod: segment.travelMethodCode?.value,
      transportId: segment.transportId,
      productCode: segment.productCode?.value,
      segmentProducerCode: segment.segmentProducerCode?.value,
      bookingClass: segment.bookingClass,
      bookingRule: segment.bookingRule,
      placementOption:
        currentOptions[tIndex].segmentPlacements[sIndex].feature?.value,
      placementOrientation:
        currentOptions[tIndex].segmentPlacements[sIndex].orientation?.value,
      placementCompartment:
        currentOptions[tIndex].segmentPlacements[sIndex].compartment?.value,
      transportInformation: segment.transportInformation.map(x => x.value),
    }));
  };

  const formatBookTransportationOBJ = (id: string) => {
    const itineraries = transportationDetails.itineraries;
    const journeyOptions = currentOptions.filter(
      x => x.journeyId === transportationDetails.id
    );

    return {
      passengers: [...passengers],
      journeys: [
        {
          id,
          hasPets: options.addons.pets > 0,
          itineraries: itineraries.map((item, index) => ({
            id: item.id,
            priceGroup: journeyOptions[index].priceGroup.value,
            variantNumber:
              journeyOptions[index].flexibility.flexibilityVariantNumber,
            segments: formatSegments([...item.segments], index),
          })),
        },
      ],
    };
  };

  const formatTransportTypeTitles = (types: string[] = []) => {
    const set = new Set<string>(
      transportationDetails.itineraries.flatMap(x =>
        x.segments.map(
          y =>
            TransportMappings[y.travelMethodCode.value] ??
            y.travelMethodCode.title
        )
      )
    );
    types.forEach(t => set.add(t));

    return Array.from(set).join(', ');
  };

  const setReturnItem = (cartItem: CartItem) => {
    const transportCartItem = cartItem.item as ITransportCartItem;

    const journeys = transportCartItem.book_transportation.journeys;
    journeys.push(
      ...formatBookTransportationOBJ(returnJourneyId.toString()).journeys
    );

    const operatorNames = transportCartItem.operatorNames.concat(
      transportDetail.operator_names
    );

    cartItem.item = {
      ...transportCartItem,
      price: currentTotal,
      book_transportation: transportCartItem.book_transportation,
      transport_type: formatTransportTypeTitles(
        transportCartItem.transport_type.split(', ')
      ),
      trip_type: returnJourneyId ? 'roundTrip' : 'oneWayTrip',
      return_date:
        transportationDetails.itineraries[0].segments[0].departureDateTime,
      operatorNames,
    };
  };

  const onSubmit = (goBack: boolean) => {
    let cartItem = outwardItem;

    if (cartItem?.item) {
      setReturnItem(cartItem);
    } else {
      const childrenCategory = passengerCategories[
        selectedTransportType.id
      ].find(pc => pc.value === 'BO');

      const numberOfChildren = passengers.filter(
        i => i.passengerCategoryId === childrenCategory.id
      ).length;
      const numberOfPeople = passengers.length - numberOfChildren;
      const tripType = returnJourneyId ? 'roundTrip' : 'oneWayTrip';

      cartItem = {
        id: uuidv4(),
        type: CartTypes.TRANSPORT,
        item: {
          people: numberOfPeople,
          children: numberOfChildren > 0 ? numberOfChildren : null,
          price: currentTotal,
          book_transportation: formatBookTransportationOBJ(
            journeyId.toString()
          ),
          title: `${stationFrom?.name} - ${stationTo?.name} ${
            tripType === 'roundTrip' ? '(round trip)' : ''
          }`,
          operatorNames: transportDetail.operator_names,
          city_from: stationFrom?.name,
          city_to: stationTo?.name,
          date: transportDetail.departure_time,
          passengers,
          product_owner: transportationDetails.productOwner,
          transport_type: formatTransportTypeTitles(),
          trip_type: tripType,
          product_location: transportDetail.transport_item.productLocation,
          duration: transportDetail.duration,
          integration: transportationDetails.integration,
        },
      };
    }

    if (goBack) {
      setCartItem(cartItem, CartActionTypes.Add);
      setNotification(NOTIFICATION_TYPES.ADD_TO_CART);

      setTimeout(() => {
        history.replace({
          pathname: Paths.TransportList,
          state: { activeTab: 'app.Controls.transportTab' },
        });
      }, ADD_TO_CART_TIMEOUT);
    } else {
      setOutwardItem(cartItem);
    }
  };

  const renderItinerarySummary = (itinerary: IItinerary, index: number) => {
    const opts = currentOptions[index];

    const pg = itinerary.priceGroups.find(
      x => x.value === opts.priceGroup.value
    );
    return pg?.passengersPrices?.map(pp => {
      const travelerFlex = pp.priceFlexibilities.find(
        x =>
          x.flexibilityVariantNumber ===
          opts.flexibility.flexibilityVariantNumber
      );
      return (
        <SummaryInfoRow>
          <p>{pg.title}</p>
          <p>
            {opts.segmentPlacements[0].compartment
              ? opts.segmentPlacements[0].compartment.title
              : opts.segmentPlacements[0].feature
              ? `${t('seatReservation')}: ` +
                opts.segmentPlacements[0].feature.title +
                (opts.segmentPlacements[0].orientation
                  ? '-' + opts.segmentPlacements[0].orientation.title
                  : '')
              : t('freeSeating')}
          </p>
          <p>{opts.flexibility.flexibility}</p>
          <p>
            {
              passengerCategories[selectedTransportType.id].find(
                pc =>
                  pc.id ===
                  passengers.find(p => p.id === pp.id)?.passengerCategoryId
              ).title
            }
          </p>
          <p>{formatPrice(sumPrices(pp.basePrice, travelerFlex.price))}</p>
        </SummaryInfoRow>
      );
    });
  };

  const setItineraryOptions = (itineraryOptions: any) => {
    const options = buildOptions(itineraryOptions);
    if (currentOptions.length > currentItineraryIndex) {
      currentOptions[currentItineraryIndex] = options;
    } else {
      currentOptions.push(options);
    }
    setCurrentOptions([...currentOptions]);
  };

  return (
    <>
      <ModalHeader
        title={
          returnJourneyId
            ? searchReturn
              ? distinctCollectionToString(
                  transportDetail?.operator_names,
                  '/'
                ) + ` ${t('returnTrip')}`
              : distinctCollectionToString(
                  transportDetail?.operator_names,
                  '/'
                ) + ` ${t('outwardTrip')}`
            : distinctCollectionToString(transportDetail?.operator_names, '/')
        }
      />
      {tabs.length && <Tabs config={mapActiveTab(tabs, activeTab)} />}

      {loading && (
        <Container
          style={{
            minHeight: '450px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Loading />
        </Container>
      )}
      {!loading && (
        <Container ref={containerRef}>
          <NotificationScreen type={notification} />
          <PassengersInfo
            price={currentTotal}
            travelDate={
              searchReturn ? initialTravelPeriod[1] : initialTravelPeriod[0]
            }
            passengers={options.passengers}
          />
          {transportationDetails?.itineraries.map((itinerary, index) => (
            <>
              <TransportItinerary
                itinerary={itinerary}
                setItineraryOptions={setItineraryOptions}
                isCurrent={currentItineraryIndex === index}
              />
              {itineraryOptionsChosen &&
                renderItinerarySummary(itinerary, index)}
            </>
          ))}
        </Container>
      )}
      <ModalFooter
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        doOpen={!isThisPageVisited}
        buttonTitle={
          itineraryOptionsChosen && (!returnJourneyId || outwardItem)
            ? t('addToTravelPlan')
            : t('continue')
        }
        buttonProps={{
          disabled: !(
            currentOptions[currentItineraryIndex] || itineraryOptionsChosen
          ),
        }}
        onButtonClick={() => {
          if (itineraryOptionsChosen) {
            if (returnJourneyId && !searchReturn) {
              onSubmit(false);
              setSearchReturn(true);
              setCurrentItineraryIndex(0);
              setCurrentOutwardOptions(currentOptions);
              setCurrentOptions([]);
            } else {
              onSubmit(true);
            }
          } else {
            setCurrentItineraryIndex(currentItineraryIndex + 1);
          }
        }}
      />
    </>
  );
};

export default IndividualTransport;
