import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import { Field } from 'react-final-form';
import { InputRow } from 'screens/Account/Billing/CheckoutForm';
import { InputColumnLeft, InputColumnRight } from 'shared/components';
import {
  cardStyle,
  FieldTitle,
  InputContainer,
  SectionTitle,
  StyledInput,
} from './StyledComponents';

type PaymentDetailsFormProps = {
  onCardChange: (event: any) => void;
};

const PaymentDetailsSectionForm: FunctionalComponent<PaymentDetailsFormProps> = ({
  onCardChange,
}) => {
  const { t } = useIntl('app.Marketplace');
  return (
    <>
      <SectionTitle>
        <li>{t('paymentDetails')}</li>
      </SectionTitle>

      <InputRow>
        <InputColumnLeft>
          <InputContainer>
            <FieldTitle>{t('cardNumber')}</FieldTitle>
            <CardNumberElement
              className="card-number"
              id="card-number"
              options={cardStyle}
              onChange={onCardChange}
            />
          </InputContainer>
        </InputColumnLeft>

        <InputColumnRight>
          <InputContainer>
            <FieldTitle>{t('cardholderName')}</FieldTitle>
            <Field
              className="cardholderName"
              id="cardholderName"
              name="cardholderName"
              component={StyledInput}
              placeholder={t('cardholderName')}
              required
            />
          </InputContainer>
        </InputColumnRight>
      </InputRow>

      <InputRow>
        <InputColumnLeft>
          <InputContainer>
            <FieldTitle>{t('expirationDate')}</FieldTitle>
            <CardExpiryElement
              className="expiration-date"
              options={{placeholder: "MM / YY (*)"}}
              id="expiration-date"
            />
          </InputContainer>
        </InputColumnLeft>

        <InputColumnRight>
          <InputContainer>
            <FieldTitle>{t('cvc')}</FieldTitle>
            <CardCvcElement
            className="cvc"
            options={{placeholder: "CVC (*)"}}
            id="cvc"
            />
          </InputContainer>
        </InputColumnRight>
      </InputRow>
    </>
  );
};

export default PaymentDetailsSectionForm;
