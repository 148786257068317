import { checkIfUuid } from '../../Individual/utils';

export const scrubListItemsForUI = (data: any) =>
  data.accommodation_category.map((item: any, idx: number) => ({
    ...item,
    id: `main-item-${idx}`,
    selectorKey: item.id,
    subItems: data.accommodation_type.map((subItem: any, subIdx: number) => ({
      ...subItem,
      selectorKey: subItem.value,
      id: `sub-item-${idx}-${subIdx}`,
    })),
  }));

export const scrubInFocusItems = (data: any, activeTab: string) =>
  data.accommodation.map(item => ({
    ...item,
    itemTitle: item.name,
    itemImage: `assets/images/gallery-item-${
      checkIfUuid(item.id) ? 'AML' : item.id
    }.png`,
    pathname: `/stay/${item.integration}/${item.id}`,
    activeTab,
  }));
