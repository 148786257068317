import BinIcon from 'assets/icons/trash-bin.svg';
import BackgroundImage from 'assets/images/hotel-cover-small.png';
import useIntl from 'hooks/useIntl';
import { Fragment, FunctionalComponent } from 'preact';
import { formatPrice } from 'utils/price/priceOperations';
import {
  CartItem,
  CartItemImage,
  CartItemInfo,
  LargeCard,
  MediumCard,
  RemoveButton,
  Row,
  Title,
  Value,
} from '../../../components';

type Props = {
  item: any;
  onRemove: (item: any) => void;
  isSmall?: boolean;
  isOpen?: boolean;
};

const ActivityCartItem: FunctionalComponent<Props> = ({
  item,
  onRemove,
  isSmall = false,
  isOpen = false,
}) => {
  const { t } = useIntl('app.Cart');
  return (
    <CartItem isSmall={isSmall} isOpen={isOpen}>
      <CartItemImage isSmall={isSmall} isOpen={isOpen}>
        <img src={BackgroundImage} />
        <p>{item.title}</p>
        <span>{formatPrice(item.price)}</span>
      </CartItemImage>

      {!isSmall && (
        <Fragment>
          <RemoveButton
            title={t('removeButton')}
            icon={BinIcon}
            isOpen={isOpen}
            onClick={() => onRemove(item)}
          />
          <CartItemInfo>
            <Row>
              <MediumCard>
                {item.quantity
                  .filter(q => q.quantity)
                  .map(q => (
                    <Value>
                      {q.quantity} {q.label}
                    </Value>
                  ))}
              </MediumCard>
              <LargeCard>
                <Title>{t('activityCity')}</Title>
                <Value>{item.city}</Value>
              </LargeCard>
              <LargeCard>
                <Title>{t('activityType')}</Title>
                <Value>{item.activity_type}</Value>
              </LargeCard>
              <LargeCard>
                <Title>{t('activityPrice')}</Title>
                <Value>{formatPrice(item.price)}</Value>
              </LargeCard>
            </Row>
          </CartItemInfo>
        </Fragment>
      )}
    </CartItem>
  );
};

export default ActivityCartItem;
