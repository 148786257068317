import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { Fragment, FunctionalComponent } from 'preact';
import { SmallCard } from 'screens/Cart/components';
import { ITravelItineraryTransport } from 'types/cache/TravelItinerary';
import { formatPrice } from 'utils/price/priceOperations';
import { splitApplyJoin } from 'utils/string/splitApplyJoin';
import {
  CartItem,
  CartItemInfo,
  CartItemTime,
  CartItemTimeTitle,
  CartItemTimeValue,
  ExtraLargeCard,
  MediumCard,
  MediumSmall,
  Row,
  Title,
  Value,
} from '../components';

type Props = {
  item: ITravelItineraryTransport;
  adults: number;
  children: number;
};

const TransportItineraryItem: FunctionalComponent<Props> = ({
  item,
  adults,
  children,
}) => {
  const { t } = useIntl('app.TravelItinerary');

  return (
    <Fragment>
      <CartItem>
        <Fragment>
          <CartItemTime>
            <CartItemTimeTitle>{t('transportDate')}</CartItemTimeTitle>
            <CartItemTimeValue>
              {dayjs.utc(item.start_transport).format('DD.MM.YYYY')}
            </CartItemTimeValue>
            <CartItemTimeTitle>{t('transportReturnDate')}</CartItemTimeTitle>
            <CartItemTimeValue>
              {item.end_transport
                ? dayjs.utc(item.end_transport).format('DD.MM.YYYY')
                : '-'}
            </CartItemTimeValue>
          </CartItemTime>
          <CartItemInfo>
            <Row>
              <SmallCard>
                <Value>{`${adults} ${t('adults')}`}</Value>
                <Value>{`${children || 0} ${t('children')}`}</Value>
              </SmallCard>
              <MediumCard>
                <Title>{t('transportType')}</Title>
                <Value>{splitApplyJoin(item.type_of_transport.toLowerCase(), t, ", ")}</Value>
              </MediumCard>
            </Row>
            <Row>
              <ExtraLargeCard>
                <Title>{t('fromToDestinations')}</Title>
                <Value>
                  {t('from')}: {item.city_from} - {t('to')}: {item.city_to}
                </Value>
              </ExtraLargeCard>
              <MediumSmall>
                <Title>{t('summaryPrice')}</Title>
                <Value>{formatPrice(item.price)}</Value>
              </MediumSmall>
            </Row>
          </CartItemInfo>
        </Fragment>
      </CartItem>
    </Fragment>
  );
};

export default TransportItineraryItem;
