import { makeVar } from '@apollo/client';
import { StayDetails } from 'types/cache/StayDetails';

export const stayDetails: StayDetails = {
  people: 2,
  children: 0,
  rooms: 1,
  bookingType: 'Private',
  accommodationType: 'Hotel',
  inFocusItems: [],
  accommodationList: [],
};
export const stayDetailsVar = makeVar<StayDetails>(stayDetails);
