import Loading from 'components/Loading';
import styled from 'styled-components';
import { colorsSpec } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  padding: 15px 25px 20px 25px;
  position: relative;
`;

export const MainLoader = styled(Loading)`
  min-height: 550px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin-bottom: 5px;
  align-items: center;
`;

export const Column = styled(Row)`
  flex-direction: column;
  width: 45%;
  align-items: flex-start;
`;

export const ColumnInputs = styled(Column)`
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const TightRow = styled(Row)`
  margin-bottom: 0;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Spacer = styled.div`
  width: 100%;
  max-width: 75px;
  position: relative;
`;

export const TightSpacer = styled(Spacer)`
  max-width: 45px;
`;

export const TitleLocation = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  padding: 5px;
`;

export const TripOptionsContainer = styled.div`
  position: relative;
  display: block;
  background: white;
  border-radius: 4px;
  padding-bottom: 10px;
  flex-grow: 1;
  overflow: auto;
  max-height: 400px;
`;

export const TripOptionTitle = styled.p`
  width: 25%;
  padding: 10px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  flex-grow: 1;
`;

export const TripOptionTitleRight = styled(TripOptionTitle)`
  padding-left: 5px;
`;

export const TripOptionCategoriesTitle = styled.p`
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  flex-grow: 1;
  padding: 10px;
`;

export const TripOptionNumberInput = styled.div`
  -moz-transform: scale(0.85);
  -moz-transform-origin: 0 0;
  zoom: 0.85;
  padding-right: 10px;
  display: inline-grid;
  justify-items: center;
`;

export const LocationContainer = styled(Row)`
  position: relative;
  display: block;
  line-height: 20px;
  flex-grow: 1;
`;

export const TripDirectionLabel = styled.p`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  padding-left: 5px;
`;

export const StyledCalendarInput = styled.div`
  position: relative;
  top: 0;
  padding-right: 4px;
  width: 100%;
`;

export const StyledTimeInput = styled.div`
  position: relative;
  top: 0;
  flex-grow: 1;
  left: 6px;

  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;

  .react-time-picker__wrapper {
    border-radius: 3.18px;
    border: 1px solid #c4c4c4;
  }

  input[name='time'],
  input[name='time']:focus {
    visibility: visible !important;
    margin-left: -13px;
    border: none;
    height: 38px;
    z-index: 2 !important;
    outline: none;
    clip-path: inset(0 20px 0 0);
    color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  }

  .react-time-picker__inputGroup {
    background: white;
    height: 38px;
    padding-left: 20px;
    min-width: 88px;
    width: 58px;
    border-radius: 3px;
  }
  .react-time-picker__inputGroup__hour {
    width: 15px;
  }
  .react-time-picker__inputGroup__minute {
    width: 15px;
  }
  .react-time-picker__inputGroup__input {
    min-width: 0;
    width: 0 !important;
  }
  .react-time-picker__clock-button {
    background: white;
  }
`;

export const RadioText = styled.p`
  padding: 5px 30px 5px 5px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
`;

export const CheckBoxText = styled.p`
  padding: 5px 0px 5px 5px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
`;

export const CheckBox = styled.input`
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 10px;
  height: 18px;
  width: 18px;
  cursor: pointer;
`;

export const StyledCheckboxContainer = styled.label`
  align-items: center;
  display: flex;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const OptionInputWrapper = styled.div`
  padding-left: 8px;
`;

export const PassengerAgeRangeInfo = styled.span`
  font-size: 13px;
  font-weight: lighter;
`;
