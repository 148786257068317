import { gql } from '@apollo/client';

export const GET_PAYMENT_FOR_TRANSACTION = gql`
  query PaymentForTransaction($externalId: uuid!) {
    payment_compensation(where: { payment_id: { _eq: $externalId } }) {
      amount
      description
      id
    }
    payment_insurance(where: { payment_id: { _eq: $externalId } }) {
      amount
      description
    }
  }
`;

export const GET_MERCHANTS_BY_GLOBAL_ID = gql`
  query GetMerchantsByGlobalId($globalIds: [uuid!]) {
    merchant: merchant(where: { global_id: { _in: $globalIds } }) {
      id
      external_id
      global_id
    }
  }
`;
