import EmptyState from 'components/EmptyState/';
import Input from 'components/Input';
import Loading from 'components/Loading';
import { SEARCH_DEBOUNCE_TIMEOUT } from 'config/inputs';
import useDebounce from 'hooks/useDebounce';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { Fragment, FunctionalComponent } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { FieldInputProps } from 'react-final-form';
import styled from 'styled-components';

const Container = styled.div``;

const List = styled.ul`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  right: 0;
  margin: 0;

  overflow: scroll;
  padding: 0;
  top: 100%;
  width: max-content;
  z-index: 2;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  li {
    flex-basis: 100%;
    width: 100%;
  }
`;

const ListItem = styled.div`
  width: auto;
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
`;

const SearchContainer = styled.div`
  width: 100%;
  min-height: 200px;
  max-height: 300px;
  min-width: 200px;
  background-color: ${({ theme }) => theme.colors.secondaryLight};
  border: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  position: absolute;
  right: 0;
  top: 75px;
  z-index: 3;
  overflow: scroll;
`;

const emptyStateConfig = { title: 'No items found', minHeight: 200 };

type Props = {
  disabled?: boolean;
  input?: FieldInputProps<string>;
  initValue?: string;
  placeholder?: string;
  name?: string;
  isLoading?: boolean;
  data?: any[]; // TODO
  optionSelected: (option: any) => void; // TODO
  debounceValueChanged: (value: string) => void;
  escape?: boolean;
  style?: any;
  outsideClickAction?: () => void;
};

const SearchInput: FunctionalComponent<Props> = ({
  initValue = '',
  placeholder = '',
  name = '',
  isLoading = false,
  data = [],
  optionSelected = () => 0,
  debounceValueChanged = () => 0,
  escape = true,
  style = null,
  outsideClickAction = () => 0
}) => {
  const ref = useRef(null);
  const [value, setValue] = useState<string>('');
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

  const debounceValue = useDebounce(value, SEARCH_DEBOUNCE_TIMEOUT);

  useOnClickOutside(ref, () => {
    outsideClickAction();
    setIsSearchOpen(false);
  });

  useEffect(() => {
    if (!debounceValue) {
      setIsSearchOpen(false);
      return;
    }

    if (debounceValue !== initValue) {
      setIsSearchOpen(true);
      debounceValueChanged( escape ? `%${debounceValue}%` : debounceValue);
    }
  }, [debounceValue]);

  useEffect(() => setValue(initValue), [initValue]);

  return (
    <Container ref={ref}>
      <Input
        name={name}
        placeholder={placeholder}
        input={{
          value: value,
          onChange: e => setValue(e.target.value),
        }}
      />
      {isSearchOpen ? (
        <SearchContainer style={style}>
          {isLoading ? (
            <Loading />
          ) : (
            <Fragment>
              {data?.length ? (
                <List onClick={e => e.stopPropagation()}>
                  {data.map(option => (
                    <li>
                      <ListItem
                        onClick={() => {
                          setIsSearchOpen(false);
                          optionSelected(option);
                        }}
                      >
                        {option.name}
                      </ListItem>
                    </li>
                  ))}
                </List>
              ) : (
                <EmptyState config={emptyStateConfig} />
              )}
            </Fragment>
          )}
        </SearchContainer>
      ) : null}
    </Container>
  );
};

export default SearchInput;
