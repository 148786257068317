export const MARKERS_MOCK = [
  {
    lat: 59.33827857724666,
    lng: 18.00103777897436,
  },
  {
    lat: 59.356939826993006,
    lng: 18.100845374174266,
  },
  {
    lat: 59.232323,
    lng: 17.935243222924523,
  },
  {
    lat: 59.892389283,
    lng: 17.935243222924523,
  },
  {
    lat: 59.49907043053365,
    lng: 17.935243222924523,
  },
  {
    lat: 59.23390293,
    lng: 17.935243222924523,
  },
  {
    lat: 59.454545323,
    lng: 17.935243222924523,
  },
  {
    lat: 59.892389283,
    lng: 17.935243222924523,
  },
  {
    lat: 59.499072343053365,
    lng: 17.935243222924523,
  },
  {
    lat: 59.224124433,
    lng: 17.935243222924523,
  },
  {
    lat: 59.4545452423,
    lng: 17.935243222924523,
  },
];
