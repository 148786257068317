import { getRandomInt } from '@dest1/widget/src/utils/number/getRandomInt';

const scrubInFocusItems = (data: any) =>
  data.city_activity.map((item: any) => ({
    ...item,
    itemTitle: item.name,
    itemImage: `assets/images/gallery-item-${getRandomInt(1, 4)}.png`,
  }));

export { scrubInFocusItems };
