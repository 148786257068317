import styled from 'styled-components';
import { colorsSpec } from 'styles';
import { transparency } from 'styles/defaultTheme';

export const Container = styled.div`
  padding: 28px 25px 25px 25px;
  position: relative;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 140px;
  cursor: pointer;
`;

export const HeaderLeftColumn = styled.div`
  width: 75%;
  position: relative;
  background: ${({ theme }) => theme.colors[colorsSpec.white]};

  @media (max-width: 767.98px) {
    width: 65%;
    img {
      object-fit: cover;
      object-position: center;
    }
  }
`;

export const HeaderRightColumn = styled.div`
  width: 25%;
  position: relative;

  @media (max-width: 767.98px) {
    width: 35%;
    img {
      object-fit: cover;
      object-position: center;
    }
  }
`;

export const TitleOverlay = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
  font-size: ${({ theme }) => theme.fontSizes.heading3};
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  font-weight: 700;
  padding-left: 28px;
  padding-top: 7px;

  h3 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  font-size: ${({ theme }) => theme.fontSizes.large};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${transparency.black50};
  color: ${({ theme }) => theme.colors[colorsSpec.accent]};
  font-weight: 900;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;

  @media (max-width: 767.98px) {
    flex-direction: column;
  }
`;

export const ScrollRow = styled.div`
  max-height: 410px;
  overflow: auto;
  scroll-behavior: smooth;
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
`;

export const LeftColumn = styled.div`
  width: 50%;
  padding-right: 5px;

  @media (max-width: 767.98px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 15px;
  }
`;

export const RightColumn = styled.div`
  width: 50%;
  padding-left: 5px;
  
  p {
    color: ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  }

  @media (max-width: 767.98px) {
    width: 100%;
    padding-left: 0;
    margin-bottom: 15px;
  }
`;

export const TileContainer = styled.div`
  height: 126px;
  overflow: hidden;
  width: 100%;
`;

export const Title = styled.h3`
  line-height: 33px;
  letter-spacing: 1.8px;
  margin-bottom: 16px !important;
`;

export const Description = styled.div`
  line-height: 19px;
  margin-top: 16px;
  font-size: ${({ theme }) => theme.fontSizes.default};
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.accent]};
  border-radius: 8px;
  margin: 16px 0;

  @media (max-width: 767.98px) {
    max-width: 400px;
    margin: 16px auto;
  }
`;

export const CalendarLabel = styled.h5`
  margin-top: 8px !important;
`;

export const ItemImage = styled.img.attrs(({ src }) => ({
  src,
}))`
  width: 100%;
  height: auto;
  min-height: 140px;
  max-height: 140px;
  object-fit: cover;
`;

export const ControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;
`;

export const ChildrenControlsRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 20px;

  h5 {
    margin-top: 14px;
  }
`;

export const ChildrenYearsInputs = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  margin: 0 20px;
  height: 1px;
  width: calc(100% - 40px);
  background-color: ${({ theme }) => theme.colors[colorsSpec.white]};
`;

export const IndividualGallery = styled.div`
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 20px 20px 0 20px;
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const GalleryRow = styled.div`
  width: 50%;
  height: 220px;
  margin-bottom: 20px;

  &:nth-child(even) {
    padding-left: 10px;
  }

  &:nth-child(odd) {
    padding-right: 10px;
  }

  @media (max-width: 576px) {
    height: unset;
    width: 100%;
    &:nth-child(even) {
      padding-left: 0;
    }

    &:nth-child(odd) {
      padding-right: 0;
    }
  }

  img {
    object-fit: fill;
  }
`;

export const GalleryTitle = styled.h4`
  margin: 20px 0 0 20px !important;
`;

export const GalleryImage = styled.img.attrs(({ src }) => ({
  src,
}))`
  width: 100%;
  height: auto;
`;

export const TransportAmenities = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const TransportAmenityImage = styled.img.attrs(({ src }) => ({
  src,
}))`
  display: inline-block;
  margin-right: 15px;
`;

export const TransportAmenityDescription = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes.default};
`;

export const TransportAmenityContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;
