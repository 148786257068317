import { MAX_CHILDREN_AGE } from 'config/inputs';

export const childrenYearsOptions = Array.from(Array(MAX_CHILDREN_AGE).keys())
  .filter(value => value !== 0)
  .map(value => ({ value: value.toString(), label: value.toString() }));

export const galleryItems = (code: string) => [
  `assets/images/hotel-${code}-1.png`,
  `assets/images/hotel-${code}-2.png`,
  `assets/images/hotel-${code}-3.png`,
  `assets/images/hotel-${code}-4.png`,
  `assets/images/hotel-${code}-5.png`,
  `assets/images/hotel-${code}-6.png`,
  `assets/images/hotel-${code}-7.png`,
];
