import { FunctionalComponent } from 'preact';
import { Text } from 'preact-i18n';
import { useMemo } from 'preact/hooks';
import { CartItem } from 'types/cache/Cart';
import { CartTypes } from 'types/shared/Cart';

interface IProps {
  travelPlanItems: CartItem[];
}

const ItemsTyType: FunctionalComponent<IProps> = ({ travelPlanItems }) => {
  const itemCountByType = useMemo(() => {
    let activity = 0;
    let stay = 0;
    let transport = 0;
    travelPlanItems.forEach(item => {
      switch (item.type) {
        case CartTypes.ACTIVITY:
          activity += 1;
          break;
        case CartTypes.STAY:
          stay += 1;
          break;
        case CartTypes.TRANSPORT:
          transport += 1;
          break;
        default:
          break;
      }
    });

    return {
      activity,
      stay,
      transport,
    };
  }, [travelPlanItems]);

  return (
    <>
      {' '}
      <Text
        id="app.Marketplace.activityCount"
        plural={itemCountByType.activity}
        fields={{ count: itemCountByType.activity }}
      />{' '}
      <Text
        id="app.Marketplace.stayCount"
        plural={itemCountByType.stay}
        fields={{ count: itemCountByType.stay }}
      />{' '}
      <Text
        id="app.Marketplace.transportCount"
        plural={itemCountByType.transport}
        fields={{ count: itemCountByType.transport }}
      />
    </>
  );
};

export default ItemsTyType;
