import { makeVar } from '@apollo/client';
import { ActivityIndividual } from 'types/cache/ActivityIndividual';

// Initial values are inherited from stay details
const activityIndividual: ActivityIndividual = {
  people: undefined,
  children: undefined,
  bookingType: undefined,
  travelPeriod: undefined,
  childrenYears: [],
  timePeriod: undefined,
  priceCategories: [],
};

export const individualActivityVar = makeVar<ActivityIndividual>(
  activityIndividual
);
