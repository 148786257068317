import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import { useCallback } from 'preact/hooks';
import { Field } from 'react-final-form';
import { InputRow } from 'screens/Account/Billing/CheckoutForm';
import { InputColumnLeft, InputColumnRight } from 'screens/Account/components';
import { ICountry } from 'types/cache/Country';
import validate from 'validate.js';
import {
  FieldTitle,
  InputContainer,
  StyledInput,
  StyledSelectComponent,
} from './StyledComponents';

type TravelerSectionFormProps = {
  isTransport: boolean;
  countries: ICountry[];
};

const LeadTravelerSectionForm: FunctionalComponent<TravelerSectionFormProps> = ({
  isTransport,
  countries,
}) => {
  const { t } = useIntl('app.Marketplace');
  const { t: validationMessages } = useIntl('app.ValidationMessages');

  const validateField = useCallback(
    (value, message) => {
      if (isTransport) {
        return validate.single(value, { presence: { message } });
      }
    },
    [isTransport]
  );
  const genders = [t('male'), t('female')];
  return (
    <>
      <InputRow>
        <InputColumnLeft>
          <InputContainer>
            <FieldTitle>{t('firstName')}</FieldTitle>
            <Field
              placeholder={t('firstName')}
              component={StyledInput}
              name="firstName"
              id="firstName"
              validate={value =>
                validateField(value, validationMessages('firstNameRequired'))
              }
              required
            />
          </InputContainer>
        </InputColumnLeft>

        <InputColumnRight>
          <InputContainer>
            <FieldTitle>{t('lastName')}</FieldTitle>
            <Field
              placeholder={t('lastName')}
              name="lastName"
              id="lastName"
              component={StyledInput}
              validate={value =>
                validateField(value, validationMessages('lastNameRequired'))
              }
              required
            />
          </InputContainer>
        </InputColumnRight>
      </InputRow>
      <InputRow>
        <InputColumnLeft>
          <InputContainer>
            <FieldTitle>{t('email')}</FieldTitle>
            <Field
              placeholder={t('email')}
              component={StyledInput}
              name="email"
              id="email"
              required
            />
          </InputContainer>
        </InputColumnLeft>

        <InputColumnLeft>
          <InputContainer>
            <FieldTitle>{t('country')}</FieldTitle>
            <Field id="country" name="country" required>
              {({ meta, input }) => (
                <StyledSelectComponent
                  required
                  id="country"
                  name="country"
                  meta={meta}
                  input={input}
                  options={countries.map(country => ({
                    value: country.name,
                    label: country.name,
                    extra: {
                      isoCode2: country.country_code,
                    },
                  }))}
                  placeholder={t('country')}
                  isSearchable
                  isErrorVisible={false}
                />
              )}
            </Field>
          </InputContainer>
        </InputColumnLeft>
      </InputRow>
      <InputRow>
        <InputColumnLeft>
          <InputContainer>
            <FieldTitle>{t('gender')}</FieldTitle>
            <Field id="gender" name="gender" required>
              {({ meta, input }) => (
                <StyledSelectComponent
                  required
                  id="gender"
                  name="gender"
                  meta={meta}
                  input={input}
                  options={genders.map(gender => ({
                    value: gender,
                    label: gender,
                  }))}
                  placeholder={t('gender')}
                  isSearchable
                  isErrorVisible={false}
                />
              )}
            </Field>
          </InputContainer>
        </InputColumnLeft>

        <InputColumnRight>
          <InputContainer>
            <FieldTitle>{t('dateOfBirth')}</FieldTitle>
            <Field
              placeholder={t('dateOfBirth')}
              name="dateOfBirth"
              id="dateOfBirth"
              component={StyledInput}
              required
            />
          </InputContainer>
        </InputColumnRight>
      </InputRow>
    </>
  );
};

export default LeadTravelerSectionForm;
