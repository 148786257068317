import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_CITIES } from 'apollo/myDesti/queries';
import ExploreIcon from 'assets/icons/explore.svg';
import InfoIcon from 'assets/icons/info.svg';
import CloseIcon from 'assets/icons/x-button.svg';
import Calendar from 'components/Calendar';
import InformationBox from 'components/InformationBox';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import { Paths } from 'constants/paths';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { useEffect, useState } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { locationAndPeriodVar } from './cache';
import ExploreD1Info from './ExploreD1Info';
import FoundOptions from './FoundOptions';
import LocationPicker from './LocationPicker';
import useTravelLocationAndPeriod from './useTravelLocationAndPeriod';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 620px;
  padding: 15px 25px 20px 25px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Picker = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 355px;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const Title = styled.h3`
  align-items: center;
  align-self: flex-start;
  display: flex;
  font-size: 21px;
  line-height: 28px;
  flex: 0 1 62px;
  margin: 15px 0 20px 0;
`;

const Message = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const CovidContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex: 1;
`;

const LocationAndPeriod = () => {
  const isThisPageVisited = IsPageVisited('LocationAndPeriod');
  const history = useHistory();
  const { t } = useIntl('app.LocationAndPeriod');
  const { t: controls } = useIntl('app.Controls');

  const {
    regionIds,
    cityIds,
    travelPeriod,
    selectedLocations,
  } = useReactiveVar(locationAndPeriodVar);

  const [
    isLocationAndPeriodSelected,
    setIsLocationAndPeriodSelected,
  ] = useState(false);

  const {
    setTravelPeriod,
    getRegionIdForSearch,
    toggleRegionSelected,
    setSelectedLocations,
  } = useTravelLocationAndPeriod(locationAndPeriodVar);

  const {
    data: citiesData,
    loading: loadingCitiesData,
    error: errorCities,
  } = useQuery(GET_CITIES);

  const getOptionsLondon = (options: any[]) => {
    const items = [...options];

    const result = items.sort((x, y) =>
      x.name === 'London' ? -1 : y.name === 'London' ? 1 : 0
    );
    return result;
  };

  useEffect(() => {
    const parametersValid =
      (cityIds.length !== 0 || regionIds.length !== 0) &&
      travelPeriod.length === 2;

    setIsLocationAndPeriodSelected(parametersValid);
  }, [cityIds, regionIds, travelPeriod]);

  const renderBody = () => {
    if (loadingCitiesData) {
      return <Message>{controls('loadingMessage')}</Message>;
    }

    if (loadingCitiesData) {
      return <Message>{t('errorMessage')}</Message>;
    }

    return (
      <Container>
        <div style={{ width: '50%' }}>
          <Picker>
            <Title>{t('locationTitle')}</Title>
            <LocationPicker
              id="regions"
              input={{
                onClick: id => {
                  const city = citiesData.city.filter(x => x.id === id)[0];

                  const coordinates = {
                    longitude: city?.longitude,
                    latitude: city?.latitude,
                  };
                  toggleRegionSelected(id);
                  const newCityForSearch = citiesData.city.find(
                    x => x.id === getRegionIdForSearch()
                  );
                  const cityName = newCityForSearch?.name;
                  locationAndPeriodVar({
                    ...locationAndPeriodVar(),
                    cityName,
                  });

                  const index = selectedLocations.findIndex(
                    x => x.name === city?.name
                  );
                  if (index > -1) {
                    selectedLocations.splice(index, 1);
                    setSelectedLocations([...selectedLocations]);
                  } else {
                    setSelectedLocations([
                      ...selectedLocations,
                      { name: city.name, ...coordinates },
                    ]);
                  }
                },
              }}
              options={getOptionsLondon(citiesData.city).map((city, idx) => ({
                title: city.name,
                value: city.id,
                selected: regionIds?.includes(city.id),
                id: `region_${idx}`,
              }))}
              title={`${t('locationRegions')}`}
            />
            {/* {regionsAndCitiesData.city.length ? (
            <LocationPicker
              id="cities"
              input={{
                onClick: id => {
                  toggleCitySelected(id);

                  locationAndPeriodVar({
                    ...locationAndPeriodVar(),
                    cityName: regionsAndCitiesData.city.filter(
                      x => x.id === id
                    )[0]?.name,
                  });
                },
              }}
              options={getOptionsLondon(regionsAndCitiesData.city).map(
                (city, idx) => ({
                  title: city.name,
                  value: city.id,
                  selected: cityIds.includes(city.id),
                  id: `city_${idx}`,
                })
              )}
              title={`${t('locationCities')} (${
                regionsAndCitiesData.city.length
              })`}
            />
          ) : (
            <></>
          )} */}
          </Picker>
          <ExploreD1Info />
        </div>
        <Picker>
          <Title>{t('calendarTitle')}</Title>
          <CalendarContainer>
            <Calendar
              selectRange
              input={{
                onChange: value => {
                  value[0] =
                    dayjs(value[0]).diff(dayjs(new Date())) > 0
                      ? value[0]
                      : dayjs(new Date()).add(1, 'h').toDate();
                  if (value.length === 2) {
                    return new Date(value[0]).toDateString() ===
                      new Date(value[1]).toDateString()
                      ? setTravelPeriod([])
                      : setTravelPeriod(value);
                  }
                  setTravelPeriod(value);
                },
                value:
                  travelPeriod.length === 1
                    ? [...travelPeriod, ...travelPeriod]
                    : travelPeriod,
              }}
            />
          </CalendarContainer>
        </Picker>
        <FoundOptions
          isLocationAndPeriodSelected={isLocationAndPeriodSelected}
          selectedLocations={selectedLocations}
          travelPeriod={travelPeriod}
        />

        <CovidContainer>
          <InformationBox
            headingLabel="Covid-19 Information"
            headingIcon={InfoIcon}
            headingIconStyle={{ width: '15px', height: '15px' }}
            infoLabelStart={controls('covid.startLabel')}
            infoLabelEnd={controls('covid.endLabel')}
            linkLabel="link"
            link="https://www.krisinformation.se/en/hazards-and-risks/disasters-and-incidents/2020/official-information-on-the-new-coronavirus/current-rules-and-recommendations"
            isCloseBtnVisible={false}
            closeIcon={CloseIcon}
          />
        </CovidContainer>
      </Container>
    );
  };

  return (
    <>
      <ModalHeader title={t('title')} />
      {renderBody()}
      <ModalFooter
        buttonTitle={t('buttonTitle')}
        buttonIcon={ExploreIcon}
        buttonProps={{
          disabled:
            (regionIds.length === 0 && cityIds.length === 0) ||
            travelPeriod.length !== 2,
        }}
        onButtonClick={() => history.push(Paths.Arrangements)}
        mapIconProps={{ disabled: true }}
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        doOpen={!isThisPageVisited}
      />
    </>
  );
};

export default LocationAndPeriod;
