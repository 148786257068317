import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { TransportMappings } from 'types/shared/Transport';
import { colorsSpec } from '../../styles';
import TransportIcon from '../TransportIcon';

type Props = {
  type: string;
  info: string;
  style?: any;
};

const Row = styled.div`
  display: flex;
  height: 35px;
  flex-direction: row;
  border-radius: 17px;
  padding: 8px;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 5px;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  background: ${({ theme }) => theme.colors[colorsSpec.secondary]};
`;

const InfoText = styled.p`
  font-size: 9px;
  letter-spacing: 0.8px;
  line-height: 12px;
  text-align: center;
  //max-width: 77px;
  white-space: break-spaces;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const IconContainer = styled.div`
  margin-right: 5px;
  img {
    padding: 2px;
  }
`;

const TransportPill: FunctionalComponent<Props> = ({
  type = '',
  info = '',
  style = {},
}) => {
  return (
    <Row style={style}>
      <IconContainer>
        <TransportIcon type={TransportMappings[type] ?? type} />
      </IconContainer>
      <InfoText>{info}</InfoText>
    </Row>
  );
};

export default TransportPill;
