import { useQuery, useReactiveVar } from '@apollo/client';
import {
  GET_ALL_ACTIVITY_TYPES,
} from 'apollo/myDesti/queries';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import NumberInput from 'components/NumberInput';
import { IsPageVisited } from 'components/PageVisited';
import Tag from 'components/Tag';
import { Paths } from 'constants/paths';
import useIntl from 'hooks/useIntl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { transparency } from 'styles/defaultTheme';
import { stayDetailsVar } from '../Stay/cache';
import { useStayDetails } from '../Stay/useStayDetails';
import { activitiesDetailsVar } from './cache';
import Slider from './Slider';
import useActivitiesDetails from './useActivitiesDetails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  padding: 15px 25px 30px 25px;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  min-height: 40px;
  align-items: center;

  :first-child {
    margin-top: 40px;
  }
`;

const TagContainer = styled.div`
  margin: 10px 20px 0 0px;

  :first-child {
    margin-left: 10px;
  }
`;

const Title = styled.h3`
  font-size: 21px;
  font-weight: 700;
`;

const StyledSlider = styled(Slider)`
  margin-top: 50px;
`;

const GalleryTile = styled.div`
  width: 220px;
  position: relative;
  padding: 0 ${({ theme }) => theme.spacing.small1};
  cursor: pointer;
`;

const ItemImage = styled.img.attrs(({ src }) => ({
  src,
}))`
  max-width: 100%;
  display: block;
`;

const ItemTitle = styled.h4`
  position: absolute;
  width: calc(100% - 10px);
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: ${transparency.white70};
  bottom: 0;
  left: ${({ theme }) => theme.spacing.small1};
  right: ${({ theme }) => theme.spacing.small1};
  font-size: ${({ theme }) => theme.fontSizes.default} !important;
  padding: ${({ theme }) => theme.spacing.small1};
`;

const Message = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const ActivitiesDetails = () => {
  const history = useHistory();
  const isThisPageVisited = IsPageVisited('ActivitiesDetails');
  const { t } = useIntl('app.ActivitiesDetails');
  const { t: controls } = useIntl('app.Controls');
  const { t: types } = useIntl('app.ActivitiesDetails.activityTypes');

  const { setNumValues } = useStayDetails(stayDetailsVar);
  const { people } = useReactiveVar(stayDetailsVar);

  const {
    toggleActivityCategorySelected,
    setActivitiesCategories,
  } = useActivitiesDetails(activitiesDetailsVar);

  const {
    selectedActivitiesCategories,
    activitiesCategories,
  } = useReactiveVar(activitiesDetailsVar);

  const {
    loading: activityTypesDataLoading,
    error: activityTypesDataError,
  } = useQuery(GET_ALL_ACTIVITY_TYPES, {
    onCompleted: data => setActivitiesCategories(data.activity_type),
  });

  const renderActivityTypes = () => {
    if (activityTypesDataLoading) {
      return <Message>{controls('loadingMessage')}</Message>;
    }

    if (activityTypesDataError) {
      return <Message>{t('errorMessage')}</Message>;
    }

    return (
      <StyledSlider title={t('inFocusTagsTitle')}>
        {activitiesCategories.map((type, idx) => (
          <TagContainer>
            <Tag
              id={`activity-type-${idx}`}
              title={types(`${type.value}`)}
              input={{
                checked: selectedActivitiesCategories.includes(type.value),
                onClick: () => toggleActivityCategorySelected(type.value),
              }}
            />
          </TagContainer>
        ))}
      </StyledSlider>
    );
  };

  return (
    <>
      <ModalHeader title={t('title')} />
      <Container>
        <Row>
          <p>{t('adultsTitle')}</p>
          <NumberInput
            id="people"
            min={1}
            input={{
              value: parseInt(people.toString(), 10),
              onChange: e => setNumValues(e.target.value, 'people'),
            }}
          />
        </Row>
        {renderActivityTypes()}
      </Container>
      <ModalFooter
        mapIconProps={{ disabled: true }}
        buttonTitle={t('buttonTitle')}
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        onButtonClick={() =>
          history.push({
            pathname: Paths.ActivitiesList,
            state: { activeTab: 'app.Controls.activitiesTab' },
          })
        }
        doOpen={!isThisPageVisited}
      />
    </>
  );
};

export default ActivitiesDetails;
