import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { Fragment, FunctionalComponent } from 'preact';
import { Text } from 'preact-i18n';
import { ITravelItineraryActivity } from 'types/cache/TravelItinerary';
import { formatPrice } from 'utils/price/priceOperations';
import {
  CartItem,
  CartItemInfo,
  CartItemTime,
  CartItemTimeTitle,
  CartItemTimeValue,
  MediumSmall,
  Row,
  SmallCard,
  Title,
  Value,
} from '../components';

type Props = {
  item: ITravelItineraryActivity;
};

const ActivityItineraryItem: FunctionalComponent<Props> = ({ item }) => {
  const { t } = useIntl('app.TravelItinerary');
  const { t: types } = useIntl('app.TravelItinerary.activityTypes');

  return (
    <Fragment>
      <CartItem>
        <Fragment>
          <CartItemTime>
            <CartItemTimeTitle>{t('activityDate')}</CartItemTimeTitle>
            <CartItemTimeValue>
              {dayjs.utc(item.date_time_activity).format('DD.MM.YYYY')}
            </CartItemTimeValue>
            <CartItemTimeTitle>{t('activityTime')}</CartItemTimeTitle>
            <CartItemTimeValue>
              {dayjs.utc(item.date_time_activity).format('HH:mm')}
            </CartItemTimeValue>
          </CartItemTime>
          <CartItemInfo>
            <Row>
              <SmallCard>
                <Value>{`${item.number_of_adults} ${t('adults')}`}</Value>
                <Value>{`${item.number_of_children || 0} ${t('children')}`}</Value>
              </SmallCard>
              <MediumSmall>
                <Title>{t('activityCity')}</Title>
                <Value>{item.city || 'Stockholm'}</Value>
              </MediumSmall>
              <MediumSmall>
                <Title>{t('activityType')}</Title>
                <Value>{types(item.type_of_activity)}</Value>
              </MediumSmall>
            </Row>
            <Row>
              <MediumSmall>
                <Title>{t('price')}</Title>
                <Value>
                  <Text
                    id="app.TravelItinerary.pricePerPerson"
                    fields={{
                      price: parseFloat(
                        (item.price.amount / (item.number_of_adults + item.number_of_children)).toFixed(2)
                      ),
                      currency: item.price.currency
                    }}
                  />
                </Value>
              </MediumSmall>
              <MediumSmall>
                <Title>{t('summaryPrice')}</Title>
                <Value>{formatPrice(item.price)}</Value>
              </MediumSmall>
            </Row>
          </CartItemInfo>
        </Fragment>
      </CartItem>
    </Fragment>
  )
}

export default ActivityItineraryItem;
