import { Attributes, FunctionalComponent } from 'preact';
import styled from 'styled-components';

const StyledIcon = styled.img`
  display: inline-block;
`;

const Container = styled.button.attrs(
  ({ round, theme: { colors, borderRadius } }) => ({
    bgColor: round ? colors.white : colors.secondaryLight,
    borderRadius: round ? borderRadius.round : borderRadius.small,
    hoverColor: round ? colors.primaryDark : colors.secondaryDark,
    activeColor: round ? colors.primary : colors.secondaryLight,
    focusColor: round ? colors.primaryDark : colors.secondaryDark,
    height: round ? '30px' : '50px',
    width: round ? '30px' : '50px',
  })
)`
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius};
  cursor: pointer;
  display: flex;
  height: ${({ height }) => height};
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.none};
  text-align: center;
  width: ${({ width }) => width};

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
  }

  &:focus {
    background-color: ${({ focusColor }) => focusColor};
  }

  &:active {
    background-color: ${({ activeColor }) => activeColor};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.accent};
    cursor: default;
  }

  ${({ small }) =>
    small &&
    `
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  height: 25px;
  width: 25px;
`}
`;

type Props = {
  disabled?: boolean;
  icon: string;
  iconProps?: any; // TODO: Add type
  onClick(): void;
  round?: boolean;
  small?: boolean;
  attributes?: Attributes;
  id?: string;
  style?: any; // TODO remove this
};

const Icon: FunctionalComponent<Props> = ({
  disabled = false,
  icon = null,
  iconProps = {},
  onClick = () => 0,
  round = false,
  small = false,
  ...attributes
}) => (
  <Container
    disabled={disabled}
    onClick={onClick}
    round={round}
    small={small}
    {...attributes}
  >
    <StyledIcon src={icon} alt="icon" {...iconProps} round={round} />
  </Container>
);

export default Icon;
