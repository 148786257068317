import { IBasePrice } from 'types/cache/Cart';

export const formatPrice = (price: IBasePrice) => `${price?.amount || 0} ${price?.currency || ''}`;

export const sumPrices = (...prices: IBasePrice[]): IBasePrice => {
  // it is assumed currencies match
  const amount = prices.reduce((total, price) => total + (price?.amount || 0), 0);
  const currency = prices.find(x => x?.currency)?.currency;

  return { amount, currency };
}
