import CloseIcon from 'assets/icons/x-button.svg';
import Icon from 'components/Icon';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryLight};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  color: ${({ theme }) => theme.colors.primaryDark};
  display: flex;
  flex-direction: column;
  min-height: 100px;
  min-width: fit-content;
  width: 100%;

  @media (max-width: 576px) {
    min-width: auto;
  }

  ::after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid ${({ theme }) => theme.colors.white};
    content: ' ';
    height: 0;
    position: absolute;
    left: 25px;
    bottom: -15px;
    width: 0;
  }
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 800;
  justify-content: space-between;
  line-height: 24px;
  padding: ${({ theme: { spacing } }) =>
    `${spacing.medium1} ${spacing.small4} ${spacing.medium1} ${spacing.large2}`};
  position: relative;
  white-space: nowrap;
  width: 100%;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  margin-left: 5px;
`;

const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryLight};
  flex-shrink: 0;
  height: 1px;
  width: 100%;
`;

const Body = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  font-size: ${({ theme }) => theme.fontSizes.default};
  line-height: 24px;
  padding: ${({ theme: { spacing } }) => `${spacing.large4} ${spacing.large3}`};
  white-space: pre-line;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  font-size: ${({ theme }) => theme.fontSizes.small2};
  line-height: 24px;
  opacity: 0.6;
  padding: ${({ theme: { spacing } }) => `${spacing.small2} ${spacing.large2}`};
`;

const StyledTooltip = styled.div`
  position: absolute;
  min-width: 343px;
  bottom: 56px;
  left: -15px;
  z-index: 1000;

  @media (max-width: 576px) {
    min-width: auto;
    max-width: 300px;
  }
`;

const Title = styled.span`
  white-space: normal;
  width: 343px;
  overflow: hidden;
`;

type Props = {
  footerText: string;
  onDismiss: () => void;
  text: any;
  title: string;
  style?: any;
};

const Tooltip: FunctionalComponent<Props> = ({
  footerText = null,
  onDismiss = () => {},
  text = null,
  title = null,
  style = null,
}) => (
  <StyledTooltip style={{ ...style }} id="tooltip">
    <Container>
      <Header style={{ boxSizing: 'border-box' }}>
        <Title>{title}</Title>
        <StyledIcon
          icon={CloseIcon}
          small
          onClick={onDismiss}
          id="tooltipCloseIcon"
        />
      </Header>
      <Line />
      <Body>
        <span>{text}</span>
      </Body>
      <Footer>
        <span>{footerText}</span>
      </Footer>
    </Container>
  </StyledTooltip>
);

export default Tooltip;
