import { makeVar } from '@apollo/client';
import { ActivityDetails } from 'types/cache/ActivityDetails';

const activitiesDetails: ActivityDetails = {
  selectedActivitiesCategories: [],
  inFocusItems: [],
  activitiesCategories: [],
};

export const activitiesDetailsVar = makeVar<ActivityDetails>(activitiesDetails);
