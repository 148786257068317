type Config = {
  activityBookingTestingEnabled: boolean;
  enabledTransportTypes: {
    airplane: boolean;
    trainbus: boolean;
  };
};

const developmentConfig: Config = {
  activityBookingTestingEnabled: true,
  enabledTransportTypes: {
    airplane: true,
    trainbus: true,
  },
};

const testConfig: Config = {
  activityBookingTestingEnabled: true,
  enabledTransportTypes: {
    airplane: true,
    trainbus: true,
  },
};

const uatConfig: Config = {
  activityBookingTestingEnabled: true,
  enabledTransportTypes: {
    airplane: true,
    trainbus: true,
  },
};

const productionConfig: Config = {
  activityBookingTestingEnabled: false,
  enabledTransportTypes: {
    airplane: true,
    trainbus: false,
  },
};

const configMap: { [key: string]: Config } = {
  debug: developmentConfig,
  development: developmentConfig,
  test: testConfig,
  uat: uatConfig,
  prod: productionConfig,
};

export default configMap[process.env.PREACT_APP_ENV];
