import { gql } from '@apollo/client';

export const SIGNUP = gql`
  mutation(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $confirmPassword: String!
    $countryId: Int!
    $dateOfBirth: date!
    $gender: String!
  ) {
    signup(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      confirmPassword: $confirmPassword
      countryId: $countryId
      dateOfBirth: $dateOfBirth
      gender: $gender
    ) {
      message
    }
  }
`;

export const SIGNIN = gql`
  mutation($password: String!, $username: String!) {
    usernamePasswordLogin(password: $password, username: $username) {
      accessToken
      expiresIn
      idToken
      refreshToken
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation($email: String!) {
    resetPassword(email: $email) {
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation($oldPassword: String!, $newPassword: String!, $username: String!) {
    changePassword(
      newPassword: $newPassword
      oldPassword: $oldPassword
      username: $username
    ) {
      message
    }
  }
`;

export const CHANGE_EMAIL = gql`
  mutation($newEmail: String!, $oldEmail: String!, $password: String!) {
    changeEmail(newEmail: $newEmail, oldEmail: $oldEmail, password: $password) {
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation(
    $id: String!
    $firstName: String!
    $lastName: String!
    $countryId: Int!
    $gender: String!
    $dateOfBirth: date!
  ) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        first_name: $firstName
        last_name: $lastName
        country_id: $countryId
        gender: $gender
        date_of_birth: $dateOfBirth
      }
    ) {
      id
      first_name
      last_name
      country_id
      gender
      date_of_birth
    }
  }
`;

export const REFRESH_TOKEN_LOGIN = gql`
  mutation($refreshToken: String!) {
    refreshTokenLogin(refreshToken: $refreshToken) {
      accessToken
      expiresIn
      idToken
      refreshToken
    }
  }
`;

export const SAVE_TRAVEL_PLAN = gql`
  mutation($travelPlan: json!) {
    saveTravelPlan(travelPlan: $travelPlan) {
      id
    }
  }
`;

export const DELETE_TRAVEL_PLAN = gql`
  mutation($travelPlanId: String!) {
    deleteTravelPlan(travelPlanId: $travelPlanId) {
      message
    }
  }
`;

export const UPDATE_TRAVEL_PLAN_ITEM = gql`
  mutation($travelPlanItem: json!, $travelPlanId: String!) {
    updateTravelPlanItem(
      travelPlanItem: $travelPlanItem
      travelPlanId: $travelPlanId
    ) {
      message
    }
  }
`;

export const DELETE_TRAVEL_PLAN_ITEM = gql`
  mutation($travelPlanId: String!, $travelPlanItemId: uuid!) {
    deleteTravelPlanItem(
      travelPlanId: $travelPlanId
      travelPlanItemId: $travelPlanItemId
    ) {
      message
    }
  }
`;

export const GET_TRAVEL_PLANS = gql`
  mutation($id: String!) {
    getTravelPlans(id: $id) {
      travelPlans
    }
  }
`;