import { InMemoryCache } from '@apollo/client';
import { cartVar } from 'screens/Cart/cache';
import { stayDetailsVar } from 'screens/Details/Stay/cache';
import { transportDetailsVar } from 'screens/Details/Transport/cache';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        city_activity: {
          keyArgs: false,
          merge(existing = [], incoming, { args }) {
            if (args.offset === 0) {
              return [...incoming];
            }
            return [...existing, ...incoming];
          },
        },
        accommodation: {
          keyArgs: false,
          merge(existing = [], incoming, { args }) {
            if (args.offset === 0) {
              return [...incoming];
            }
            return [...existing, ...incoming];
          },
        },
        transportation_suggestion_view: {
          keyArgs: false,
          merge(existing = [], incoming, { args }) {
            if (args.offset === 0) {
              return [...incoming];
            }
            return [...existing, ...incoming];
          },
        },
        stayDetails: {
          read() {
            return stayDetailsVar();
          },
        },
        transportDetails: {
          read() {
            return transportDetailsVar();
          },
        },
        cart: {
          read() {
            return cartVar();
          },
        },
      },
    },
  },
});
