import {
  CalendarContainer,
  CalendarLabel,
  Container,
  Description,
  GalleryImage,
  GalleryRow,
  GalleryTitle,
  HeaderLeftColumn,
  HeaderRightColumn,
  HeaderRow,
  ImageOverlay,
  IndividualGallery,
  ItemImage,
  LeftColumn,
  Line,
  RightColumn,
  Row,
  ScrollRow,
  Title,
} from '../components';
import { ActivityModel, PriceOption, Session } from './activityModel';
import { individualActivityVar } from './cache';
import { useIndividualActivity } from './useIndividualActivity';
import { useMutation, useReactiveVar } from '@apollo/client';
import { myDestiClient } from 'apollo/client';
import { GET_INTEGRATIONS_ACTIVITY } from 'apollo/myDesti/mutations';
import { STAY_DETAILS } from 'apollo/myDesti/queries';
import ActivityIcon from 'assets/icons/item-activity.svg';
import Calendar from 'components/Calendar';
import Loading from 'components/Loading/';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import Tabs, { mapActiveTab } from 'components/Tabs/';
import TimePeriod from 'components/TimePeriod/';
import { Paths } from 'constants/paths';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import useIntl from 'hooks/useIntl';
import { Fragment } from 'preact';
import { Text } from 'preact-i18n';
import { useRef, useState } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import { cartVar } from 'screens/Cart/cache';
import { CartActionTypes, useCart } from 'screens/Cart/useCart';
import { locationAndPeriodVar } from 'screens/LocationAndPeriod/cache';
import {
  ADD_TO_CART_TIMEOUT,
  NotificationScreen,
} from 'screens/NotificationScreen';
import useNotification from 'screens/NotificationScreen/useNotification';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import { IBasePrice } from 'types/cache/Cart';
import { CartTypes } from 'types/shared/Cart';
import { NOTIFICATION_TYPES } from 'types/shared/Notification';
import { formatPrice } from 'utils/price/priceOperations';
import { v4 as uuidv4 } from 'uuid';

const StyledRow = styled(Row)`
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
`;

const ActivityTicketDetailsColumnLeft = styled.div`
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

const ActivityTicketDetailsColumnRight = styled.div`
  align-items: flex-start;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const VerticalLine = styled.div`
  border-left: 1px solid;
  border-left-color: ${({ theme }) => theme.colors[colorsSpec.white]};
  margin: 0px 20px;
`;

const TotalPrice = styled.span`
  line-height: 49px;
  font-size: 36px;
  font-weight: bold;
  white-space: nowrap;
`;

const TitleOverlay = styled.div`
  padding: 4px 12px;
  background: ${({ theme }) => theme.colors[colorsSpec.white]};
  border-radius: 16px;
  position: absolute;
  left: 5px;
  top: 110px;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 11px;
    color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  }
`;

const ActivityDescription = styled(Description)`
  color: ${({ theme }) => theme.colors[colorsSpec.white]};
`;

const PriceCategory = styled.div`
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  border-radius: 7px;
  color: ${({ theme }) => theme.colors[colorsSpec.white]};
  padding: 2px;
  align-items: center;
  margin: 10px 0px;

  div {
    background: ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
    border-left: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
  }
`;

const PriceCategoryButton = styled.button`
  background: ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors[colorsSpec.white]};
  font-size: 20px;
  font-weight: bold;

  &:disabled {
    cursor: default;
    background: ${({ theme }) => theme.colors[colorsSpec.accent]};
    color: grey;
    opacity: 0.5;
  }
`;

const DecrementButton = styled(PriceCategoryButton)`
  border-radius: 7px 0px 0px 7px;
  padding-right: 10px;
`;

const IncrementButton = styled(PriceCategoryButton)`
  border-radius: 0px 7px 7px 0px;
  padding-left: 10px;
`;

const ControlsRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0px 40px 10px 0px;

  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  }
`;

const ProductOwnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  img {
    margin-right: 5px;
  }

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
`;

enum PriceCategoryActions {
  Increment,
  Decrement,
}

const IndividualActivity = ({ tabs = [], computedMatch, location }) => {
  const galleryRef = useRef(null);
  const notificationRef = useRef(null);

  const { t } = useIntl('app.IndividualActivity');

  const history = useHistory();
  const isThisPageVisited = IsPageVisited('IndividualActivity');
  const { id } = computedMatch.params;
  const { activeTab } = location.state;
  const { stayDetails } = myDestiClient.readQuery({ query: STAY_DETAILS });
  const { notification, setNotification } = useNotification();

  const [activityResult, setActivity] = useState<ActivityModel>(null);
  const [sessions, setSessions] = useState<Session[]>([]);

  const { setCartItem } = useCart(cartVar);

  const { setTravelPeriod, setTimePeriod, clearTimePeriod, setParticipants } =
    useIndividualActivity(individualActivityVar);

  const {
    people = stayDetails.people,
    travelPeriod,
    timePeriod,
    priceCategories,
  } = useReactiveVar(individualActivityVar);

  const { travelPeriod: initialTravelPeriod } =
    useReactiveVar(locationAndPeriodVar);

  const [
    getActivity,
    { called: isActivityQueryCalled, loading: isActivityLoading },
  ] = useMutation(GET_INTEGRATIONS_ACTIVITY, {
    variables: {
      id,
      people,
      startTime: initialTravelPeriod[0],
      endTime: initialTravelPeriod[1],
    },
    onCompleted: data => {
      setActivity(data.getActivityDetails);

      if (travelPeriod) {
        const sessions = data.getActivityDetails.sessions.map(x =>
          new Date(x.startTime).toDateString()
        );

        clearTimePeriod();
        if (!sessions.includes(new Date(travelPeriod).toDateString())) {
          setTravelPeriod(null);
        } else {
          const timePeriods = setSessionsForDate(
            data.getActivityDetails,
            travelPeriod
          );
          timePeriods.length === 1 &&
            setTimePeriod(timePeriods[0], people, true);
        }
      }
    },
  });

  const addToCart = () => {
    const totalPrice = calculateTotalAmount();

    setCartItem(
      {
        id: uuidv4(),
        type: CartTypes.ACTIVITY,
        item: {
          quantity: priceCategories.map(pc => ({
            optionId: pc.optionId,
            label: pc.label,
            quantity: pc.quantity,
          })),
          people: priceCategories.reduce((total, pc) => pc.quantity + total, 0),
          id: activityResult.id,
          title: activityResult.name,
          subtitle: activityResult.supplierName,
          price: totalPrice,
          city: activityResult.productLocation.address,
          date: timePeriod.startTimeLocal,
          activity_type: activityResult.productType,
          product_owner: activityResult.productOwner,
          supplierName: activityResult.supplierName,
          fields: activityResult.bookingFields,
          booking_time_required: activityResult.bookingTimeRequired,
          startTimeLocal: timePeriod.startTimeLocal,
          endTimeLocal: timePeriod.endTimeLocal,
          startTime: timePeriod.startTime,
          endTime: timePeriod.endTime,
          terms: activityResult.terms,
          product_location: activityResult.productLocation,
          duration: activityResult.duration,
          integration: activityResult.integration,
        },
      },
      CartActionTypes.Add
    );
    setNotification(NOTIFICATION_TYPES.ADD_TO_CART);

    setTimeout(() => {
      history.replace({
        pathname: Paths.ActivitiesList,
        state: { activeTab: 'app.Controls.activitiesTab' },
      });
    }, ADD_TO_CART_TIMEOUT);
  };

  if (!isActivityQueryCalled && !isActivityLoading) {
    getActivity();
  }

  const setSessionsForDate = (activity: ActivityModel, date: string) => {
    const sessions = activity.sessions.filter(
      x =>
        dayjs(x.startTime).format('YYYY-MM-DD') ===
        dayjs(date).format('YYYY-MM-DD')
    );
    setSessions(sessions);
    return sessions;
  };

  const getSessionDateString = () => {
    return activityResult.sessions.map(x =>
      new Date(x.startTime).toDateString()
    );
  };

  const numOfSelectedOptions = () =>
    priceCategories.reduce((total, pc) => total + pc.quantity, 0);

  const getNumOfParticipants = () =>
    priceCategories.reduce(
      (total, pc) => total + pc.quantity * pc.seatsUsed,
      0
    );

  const getMaxNumOfParticipants = (priceCategory: PriceOption) => {
    const seatsAvailable = Math.min(
      timePeriod.seatsAvailable,
      // priceCategory.maxQuantity || Infinity,
      activityResult.quantityRequiredMax || Infinity
    );

    const numOfParticipants = getNumOfParticipants();
    const max = Math.floor(
      (seatsAvailable - numOfParticipants) / priceCategory.seatsUsed
    );
    return (
      max + priceCategories.find(x => x.optionId === priceCategory.id).quantity
    );
  };

  const calculateTotalAmount = (): IBasePrice => {
    return {
      amount: priceCategories.reduce(
        (total, pc) => total + pc.price * pc.quantity,
        0
      ),
      currency: activityResult.displayPrice.currency,
    };
  };

  const sanitizeHTML = (activityDescription: string) => {
    return DOMPurify.sanitize(activityDescription, {
      FORBID_TAGS: [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'style',
        'a',
        'link',
        'iframe',
        'script',
      ],
      FORBID_ATTR: ['style'],
    });
  };

  const handleOnClick = (
    action: PriceCategoryActions,
    value: number,
    label: string
  ): void => {
    const participantValue: number =
      action === PriceCategoryActions.Increment ? value + 1 : value - 1;
    setParticipants(label, parseInt(participantValue.toString(), 10));
  };

  return (
    <Fragment>
      <ModalHeader title={activityResult?.name} />
      {tabs.length && <Tabs config={mapActiveTab(tabs, activeTab)} />}

      {isActivityLoading && <Loading />}
      {!isActivityLoading && activityResult && (
        <Container>
          <NotificationScreen type={notification} ref={notificationRef} />
          <HeaderRow onClick={() => galleryRef.current.scrollIntoView()}>
            <HeaderLeftColumn>
              <ItemImage src={activityResult.images[0]?.largeUrl} />
              <TitleOverlay>
                <h3>
                  <Text
                    id="app.IndividualActivity.fromPrice"
                    fields={{
                      price: activityResult.displayPrice.amount,
                      currency: activityResult.displayPrice.currency,
                    }}
                  >
                    {`${activityResult.displayPrice.amount} ${activityResult.displayPrice.currency}`}
                  </Text>
                </h3>
              </TitleOverlay>
            </HeaderLeftColumn>
            <HeaderRightColumn>
              <ItemImage src={activityResult.images[1]?.mediumUrl} />
              <ImageOverlay>
                <h3>
                  <Text
                    id="app.IndividualActivity.photos"
                    plural={activityResult.images?.length}
                    fields={{ count: activityResult.images?.length }}
                  />
                </h3>
              </ImageOverlay>
            </HeaderRightColumn>
          </HeaderRow>

          <ScrollRow>
            <StyledRow>
              <LeftColumn>
                <Title>{activityResult.name}</Title>
                <ProductOwnerContainer>
                  <img src={ActivityIcon} />
                  <h3>{activityResult.supplierName}</h3>
                </ProductOwnerContainer>
                <ActivityDescription
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(activityResult.description),
                  }}
                />
              </LeftColumn>
              <RightColumn>
                <CalendarLabel>{t('dateTime')}</CalendarLabel>
                <CalendarContainer>
                  <Calendar
                    minDate={initialTravelPeriod[0]}
                    maxDate={initialTravelPeriod[1]}
                    tileDisabled={({ date, view }) => {
                      return (
                        view === 'month' &&
                        !getSessionDateString().some(
                          x => x === date.toDateString()
                        )
                      );
                    }}
                    input={{
                      onChange: value => {
                        setTravelPeriod(value);
                        const timePeriods = setSessionsForDate(
                          activityResult,
                          value
                        );
                        timePeriods.length === 1 &&
                          setTimePeriod(timePeriods[0], people);
                      },
                      value: travelPeriod,
                    }}
                  />
                </CalendarContainer>
                {activityResult.bookingTimeRequired &&
                  travelPeriod &&
                  sessions.every(x => !x.allDay) && (
                    <TimePeriod
                      periods={sessions}
                      value={timePeriod}
                      onClick={value => setTimePeriod(value, people)}
                      valueExtractor={period =>
                        dayjs(period.startTimeLocal, { utc: true }).format(
                          'HH:mm'
                        )
                      }
                    />
                  )}
              </RightColumn>
            </StyledRow>
            <Line />

            <GalleryTitle>
              <h4>{t('ticketDetails')}</h4>
            </GalleryTitle>
            <Row>
              <ActivityTicketDetailsColumnLeft>
                <b>{t('totalPrice')}</b>
                <TotalPrice>{formatPrice(calculateTotalAmount())}</TotalPrice>
              </ActivityTicketDetailsColumnLeft>
              <VerticalLine />
              <ActivityTicketDetailsColumnRight>
                {timePeriod?.prices.map(priceCategory => {
                  const value: number =
                    priceCategories.find(x => x.label === priceCategory.label)
                      ?.quantity || 0;
                  const min: number = numOfSelectedOptions() === 1 ? 1 : 0;
                  const max: number = getMaxNumOfParticipants(priceCategory);

                  return (
                    <ControlsRow>
                      <h5>{priceCategory.label}</h5>
                      <p>
                        <Text
                          id="app.IndividualActivity.pricePerPerson"
                          fields={{
                            price: priceCategory.price,
                            currency: activityResult.displayPrice.currency,
                          }}
                        />
                      </p>
                      <PriceCategory>
                        <DecrementButton
                          disabled={value <= min}
                          onClick={() =>
                            handleOnClick(
                              PriceCategoryActions.Decrement,
                              value,
                              priceCategory.label
                            )
                          }
                        >
                          -
                        </DecrementButton>
                        <div>{value}</div>
                        <IncrementButton
                          disabled={value >= max}
                          onClick={() =>
                            handleOnClick(
                              PriceCategoryActions.Increment,
                              value,
                              priceCategory.label
                            )
                          }
                        >
                          +
                        </IncrementButton>
                      </PriceCategory>
                    </ControlsRow>
                  );
                })}
              </ActivityTicketDetailsColumnRight>
            </Row>

            <Line />

            <GalleryTitle>
              <h4>{t('photosTitle')}</h4>
            </GalleryTitle>
            <IndividualGallery ref={galleryRef}>
              {activityResult.images?.map(image => (
                <GalleryRow>
                  <GalleryImage src={image.largeUrl} />
                </GalleryRow>
              ))}
            </IndividualGallery>
          </ScrollRow>
        </Container>
      )}
      <ModalFooter
        buttonTitle={t('buttonTitle')}
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        onButtonClick={addToCart}
        buttonProps={{
          disabled:
            !travelPeriod ||
            (activityResult?.bookingTimeRequired && !timePeriod),
        }}
        mapIconProps={{
          onClick: () =>
            history.push({
              pathname: '/map',
              state: {
                markers: [
                  {
                    ...{
                      lng: activityResult.productLocation.longitude,
                      lat: activityResult.productLocation.latitude,
                    },
                    onClick: () =>
                      history.push({
                        pathname: `/activity/${id}`,
                        state: {
                          activeTab: 'app.Controls.activitiesTab',
                        },
                      }),
                  },
                ],
                activeTab: 'app.Controls.activitiesTab',
                isIndividual: true,
              },
            }),
        }}
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default IndividualActivity;
