import {
  CartItemType,
  IActivityCartItem,
  IStayCartItem,
  ITransportCartItem,
} from 'types/cache/Cart';
import { CartTypes } from 'types/shared/Cart';
import ActivityTravelPlanItemDetails from './Activity';
import StayTravelPlanItemDetails from './Stay';
import TransportTravelPlanItemDetails from './Transport';

const TravelPlanItemDetails = (id: number, item: CartItemType) => {
  return {
    [CartTypes.TRANSPORT]: (
      <TransportTravelPlanItemDetails item={item as ITransportCartItem} />
    ),
    [CartTypes.STAY]: (
      <StayTravelPlanItemDetails item={item as IStayCartItem} />
    ),
    [CartTypes.ACTIVITY]: (
      <ActivityTravelPlanItemDetails item={item as IActivityCartItem} />
    ),
  };
};

export default TravelPlanItemDetails;
