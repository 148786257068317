import { ReactiveVar } from '@apollo/client';
import { IProductLocation } from 'types/cache/ProductLocation';
import { StayIndividual } from 'types/cache/StayIndividual';

export function useIndividualStay (
  individualStayVar: ReactiveVar<StayIndividual>
) {
  const setNumValues = (data: any, key: any) => {
    const oldValue = individualStayVar();
    const updated = { ...oldValue, [key]: data };
    individualStayVar(updated);
  };

  const setYearsForChild = (index: number, years: string) => {
    const oldValue = individualStayVar();
    const { childrenYears } = oldValue;

    childrenYears[index] = { idx: index, value: years };

    const updated = { ...oldValue, childrenYears };
    individualStayVar(updated);
  };

  const updateChildren = children => {
    const oldValue = individualStayVar();
    const childrenCount = parseInt(children);

    const {
      childrenYears: childrenYearsOld,
      children: childrenOld = 0,
    } = oldValue;

    if (childrenCount < childrenOld) {
      childrenYearsOld.splice(-(childrenOld - childrenCount));
    } else if (childrenCount > childrenOld) {
      for (let i = 0; i < childrenCount - childrenOld; i++) {
        childrenYearsOld.push({
          value: '1',
          idx: (childrenYearsOld?.length || 0) + i,
        });
      }
    }

    const updated = {
      ...oldValue,
      childrenYears: childrenYearsOld,
      children,
    };
    individualStayVar(updated);
  };

  const setTravelPeriod = (travelPeriod: any) => {
    const travelLocationAndPeriod = individualStayVar();
    const updatedTravelLocationAndPeriod = {
      ...travelLocationAndPeriod,
      travelPeriod,
    };

    individualStayVar(updatedTravelLocationAndPeriod);
  };

  const setAccommodationName = (name: string) => {
    const oldValue = individualStayVar();
    const updated = { ...oldValue, accommodationName: name };
    individualStayVar(updated);
  };

  const setAccommodationCode = (code: string) => {
    const oldValue = individualStayVar();

    const updated = {
      ...oldValue,
      accommodationCode: {
        establishmentId: code
      },
    };
    individualStayVar(updated);
  };

  const setProductOwner = (productOwner: string) => {
    const oldValue = individualStayVar();
    const updated = {
      ...oldValue,
      productOwner,
    };
    individualStayVar(updated);
  };

  const setProductLocation = (productLocation: IProductLocation) => {
    const oldValue = individualStayVar();
    const updated = {
      ...oldValue,
      productLocation
    };
    individualStayVar(updated);
  }

  const setCheckInTime = checkInTime => {
    const individualStay = individualStayVar();
    const updatedTravelLocationAndPeriod = {
      ...individualStay,
      checkInTime,
    };

    individualStayVar(updatedTravelLocationAndPeriod);
  };

  return {
    setNumValues,
    setYearsForChild,
    updateChildren,
    setTravelPeriod,
    setAccommodationName,
    setAccommodationCode,
    setProductOwner,
    setProductLocation,
    setCheckInTime
  };
}
