import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import { ITransportCartItem } from 'types/cache/Cart';
import {
  PlanItemInfoContainer,
  PlanItemInfoRow,
  PlanItemInfoTitle,
  PlanItemInfoValue,
} from '../components';

interface IProps {
  item: ITransportCartItem;
}

const TransportMarketplaceItemMainInfo: FunctionalComponent<IProps> = ({
  item,
}) => {
  const { t } = useIntl('app.Marketplace');

  const departureDate: string = dayjs(item.date).format('DD.MM.YYYY');

  return (
    <PlanItemInfoContainer>
      <PlanItemInfoRow>
        <PlanItemInfoTitle>{t('departureDateTitle')}</PlanItemInfoTitle>
        <PlanItemInfoValue>{departureDate}</PlanItemInfoValue>
      </PlanItemInfoRow>
      <PlanItemInfoRow>
        <PlanItemInfoTitle>{t('numberOfPeople')}</PlanItemInfoTitle>
        <PlanItemInfoValue>{item.people || 0}</PlanItemInfoValue>
      </PlanItemInfoRow>
      {item.children > 0 && (
        <PlanItemInfoRow>
          <PlanItemInfoTitle>{t('numberOfChildren')}</PlanItemInfoTitle>
          <PlanItemInfoValue>{item.children}</PlanItemInfoValue>
        </PlanItemInfoRow>
      )}
      {item.infants > 0 && (
        <PlanItemInfoRow>
          <PlanItemInfoTitle>{t('numberOfInfants')}</PlanItemInfoTitle>
          <PlanItemInfoValue>{item.infants}</PlanItemInfoValue>
        </PlanItemInfoRow>
      )}
    </PlanItemInfoContainer>
  );
};

export default TransportMarketplaceItemMainInfo;
