import { IntlContext, translate } from 'preact-i18n';
import { useContext } from 'preact/hooks';

const useIntl = (scope: any) => {
  const {
    intl: { dictionary },
  } = useContext(IntlContext);
  const t = (id, fields = undefined, plural = undefined) =>
    translate(id, scope, dictionary, fields, plural);

  return { t };
};

export default useIntl;
