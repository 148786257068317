import { useMutation } from '@apollo/client';
import { CHECK_AVAILABILTY_INTEGRATIONS_TRANSPORT } from 'apollo/myDesti/mutations';
import { useRef, useState } from 'preact/hooks';
import { ITransportCartItem } from 'types/cache/Cart';

const useRefreshFlightsAvailabilityAndPrice = (
  travelPlanItems,
  setTravelPlanItems
) => {
  const flightsInvalid = useRef<{
    [id: number]: boolean;
  }>({});
  const flightsPriceChanged = useRef<{
    [id: number]: any;
  }>({});
  const [update, forceUpdate] = useState(0);

  const [checkAvailability] = useMutation(
    CHECK_AVAILABILTY_INTEGRATIONS_TRANSPORT,
    {}
  );

  const callRefreshFlightAvailabilityAndPrice = () => {
    travelPlanItems.forEach((travelPlanItem, index) => {
      if (travelPlanItem.paid || flightsInvalid.current[travelPlanItem.id]) return;

      const item = travelPlanItem.item as ITransportCartItem;
      if (!item.booking_token) return;

      checkAvailability({
        variables: {
          id: item.booking_token,
          bagCount: item.bags,
          passengers: item.passengers,
        },
      })
        .then(({ data }) => {
          const check = data.checkTransportationAvailability;

          flightsInvalid.current[travelPlanItem.id] = check.flightsInvalid;

          if (check.priceChange && item.price.amount !== check.price.amount) {
            flightsPriceChanged.current[travelPlanItem.id] = {
              priceChange: check.priceChange,
              oldPrice: item.price,
              newPrice: check.price,
            };

            const updated = [...travelPlanItems];
            updated[index].price = check.price;
            setTravelPlanItems([...updated, ]);
          }
        })
        .catch(() => {
          setInvalidFlight(travelPlanItem.id);
        })
        .finally(() => {
          forceUpdate(Date.now());
        });
    });
  };

  const setInvalidFlight = (itemId) => {
    flightsInvalid.current[itemId] = true;
    forceUpdate(Date.now());
  }

  return {
    flightsInvalid,
    setInvalidFlight,
    flightsPriceChanged,
    callRefreshFlightAvailabilityAndPrice,
  };
};

export default useRefreshFlightsAvailabilityAndPrice;
