import { useReactiveVar } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { IsPageVisited } from 'components/PageVisited';
import useIntl from 'hooks/useIntl';
import { Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { toast } from 'react-toastify';
import { cartVar } from 'screens/Cart/cache';
import CartItems from 'screens/Cart/components/CartItems';
import { CartActionTypes, useCart } from 'screens/Cart/useCart';
import { NotificationScreen } from 'screens/NotificationScreen/';
import useNotification from 'screens/NotificationScreen/useNotification';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import { NOTIFICATION_TYPES } from 'types/shared/Notification';
import { formatPrice } from 'utils/price/priceOperations';
import { Container, Title } from '../components';
import CheckoutForm from './CheckoutForm';

export const Row = styled.div`
  margin-bottom: 37px;
`;

const Details = styled.div`
  position: relative;
  padding-left: 24px;

  &:before {
    position: absolute;
    top: 12px;
    left: 4px;
    content: '';
    height: 95%;
    width: 1px;
    background: ${({ theme }) => theme.colors[colorsSpec.accent]};
  }
`;

export const Rect = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
  width: 10px;
  height: 10px;
  background: ${({ theme }) => theme.colors[colorsSpec.secondary]};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BillingTitle = styled(Title)`
  text-align: left;
`;

const CartItemsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  flex-wrap: wrap;
`;

const CartItem = styled.div`
  margin-right: 20px;
`;

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap',
    },
  ],
};

const Billing = () => {
  const { cartItems = [], price } = useReactiveVar(cartVar);
  const { t } = useIntl('app.Billing');
  const isThisPageVisited = IsPageVisited('Billing');

  const { notification, setNotification } = useNotification();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isCardValid, setIsCardValid] = useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const { setCartItem } = useCart(cartVar);
  const [stripeLoader, setStripeLoader] = useState<Promise<Stripe>>(null);

  const successPayment = (): void => {
    setCartItem(null, CartActionTypes.CleanCart);
    setNotification(NOTIFICATION_TYPES.PAYMENT_COMPLETED);
  };

  const errorPayment = message => {
    setIsNextDisabled(false);

    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    const stripeLoader = loadStripe(process.env.PREACT_APP_STRIPE_PUBLIC_KEY, {
      stripeAccount: 'acct_1Jf4R1AcDSqHkRwz',
    });
    setStripeLoader(stripeLoader);
  }, []);

  useEffect(() => setIsNextDisabled(!isFormValid || !isCardValid), [
    isFormValid,
    isCardValid,
  ]);

  return (
    stripeLoader && (
      <Fragment>
        <ModalHeader title={t('title')} />

        <Container>
          <NotificationScreen type={notification} />

          <Elements stripe={stripeLoader} options={ELEMENTS_OPTIONS}>
            <CheckoutForm
              onSuccessPayment={successPayment}
              onErrorPayment={errorPayment}
              onCardValidation={value => setIsCardValid(value)}
              onFormValidation={value => setIsFormValid(value)}
              onFormSubmit={() => setIsNextDisabled(true)}
            />
          </Elements>

          {Boolean(cartItems.length) && (
            <Row>
              <Details>
                <Rect />
                <TitleContainer>
                  <BillingTitle>{`${t('travelPlan')} (${
                    cartItems.length
                  })`}</BillingTitle>
                  <BillingTitle>
                    {t('totalPrice')}:{' '}
                    {formatPrice(price)}
                  </BillingTitle>
                </TitleContainer>
                <CartItemsRow>
                  {cartItems.map(({ id, item, type }) => (
                    <Fragment>
                      <CartItem>
                        {CartItems(id, item, type, true)[type]}
                      </CartItem>
                    </Fragment>
                  ))}
                </CartItemsRow>
              </Details>
            </Row>
          )}
        </Container>

        <ModalFooter
          buttonProps={{
            disabled: !cartItems.length || isNextDisabled,
            onClick: () => {
              document
                .getElementById('checkoutForm')
                .dispatchEvent(new Event('submit', { cancelable: true }));
            },
          }}
          buttonTitle={t('buttonTitle')}
          mapIconProps={{ disabled: true }}
          chatIconProps={{ disabled: true }}
          tooltipTitle={t('tooltipTitle')}
          tooltipText={t('tooltipText')}
          tooltipPoweredBy={t('tooltipPoweredBy')}
          doOpen={!isThisPageVisited}
        />
      </Fragment>
    )
  );
};

export default Billing;
