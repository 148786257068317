import { useMutation, useReactiveVar } from '@apollo/client';
import { myDestiClient } from 'apollo/client';
import { GET_INTEGRATIONS_AVAILABLE_ROOMS } from 'apollo/myDesti/mutations';
import { STAY_DETAILS } from 'apollo/myDesti/queries';
import DoubleBedIcon from 'assets/icons/double-bed-white.svg';
import SingleBedIcon from 'assets/icons/single-bed-white.svg';
import Button from 'components/Button/';
import InfoDisclaimer, { MessageType } from 'components/InfoDisclaimer';
import Loading from 'components/Loading/';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import NumberInput from 'components/NumberInput/';
import { IsPageVisited } from 'components/PageVisited';
import Tabs, { mapActiveTab } from 'components/Tabs/';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { Fragment } from 'preact';
import { useReducer, useState } from 'preact/hooks';
import { useHistory } from 'react-router-dom';
import { cartVar } from 'screens/Cart/cache';
import { CartActionTypes, useCart } from 'screens/Cart/useCart';
import { MARKERS_MOCK } from 'screens/Lists/config';
import { locationAndPeriodVar } from 'screens/LocationAndPeriod/cache';
import { NotificationScreen } from 'screens/NotificationScreen';
import useNotification from 'screens/NotificationScreen/useNotification';
import styled from 'styled-components';
import { colorsSpec } from 'styles/defaultTheme';
import { CartTypes } from 'types/shared/Cart';
import { NOTIFICATION_TYPES } from 'types/shared/Notification';
import { formatPrice } from 'utils/price/priceOperations';
import {
  Container,
  HeaderLeftColumn,
  HeaderRightColumn,
  HeaderRow,
  ItemImage,
  ScrollRow,
} from '../components';
import { individualStayVar } from '../Stay/cache';
import { roomsVar } from './cache';
import { useRooms } from './useRooms';
import { v4 as uuidv4 } from 'uuid';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  min-height: 425px;
`;

const RoomInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border: 3px solid ${({ theme }) => theme.colors[colorsSpec.accent]};
  padding: 15px;
  border-radius: 30px;
`;

const RoomInfoTopContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 567px) {
    flex-direction: column;
  }
`;

const RoomInfoBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

const RoomInfoLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;

  // Vertical line
  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors[colorsSpec.accent]};
    right: 0;
  }

  @media (max-width: 767px) {
    width: 50%;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  @media (max-width: 567px) {
    width: 100%;
    &:after {
      width: 90%;
      height: 1px;
      top: auto;
      right: 5%;
      bottom: 0;
    }
  }
`;

const RoomInfoRight = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    width: 50%;
  }

  @media (max-width: 567px) {
    width: 100%;
  }
`;

const RoomInfoRightDetails = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const RoomInfoList = styled.div`
  padding: 0.5rem 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  h3 {
    font-size: 34px;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 25px;
      margin-bottom: 5px;
    }
  }
`;

const RoomCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  padding: 1rem 0;
`;

const AddToPlanButton = styled(Button)`
  width: 100%;
`;

const ROOM_TYPE_IDS = {
  single: 1,
  double: 2,
  triple: 3,
};

const RoomTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const RoomTypeIcon = styled.img.attrs(({ src }) => ({
  src,
}))`
  width: 100%;
  height: auto;
  min-height: 60px;
  max-height: 60px;
  margin: 10px 5px;
`;

const NoOffersTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
`;

const Rooms = ({ tabs = [], computedMatch, location }) => {
  const { t } = useIntl('app.Rooms');
  const { integration, id } = computedMatch.params;
  const { activeTab } = location.state;
  const history = useHistory();
  const isThisPageVisited = IsPageVisited('Rooms');

  const { setRoomsQuantity } = useRooms(roomsVar);
  const { quantities } = useReactiveVar(roomsVar);
  const { checkInTime } = useReactiveVar(individualStayVar);
  const { setCartItem } = useCart(cartVar);
  const { stayDetails } = myDestiClient.readQuery({ query: STAY_DETAILS });
  const { notification, setNotification } = useNotification();
  const [resultRooms, setResultRooms] = useState<any>([]);
  const [searching, setSearching] = useState(false);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const { travelPeriod: defaultTravelPeriod, cityName } = useReactiveVar(
    locationAndPeriodVar
  );

  const {
    people,
    children,
    childrenYears,
    travelPeriod,
    accommodationCode,
    rooms,
    productOwner,
    productLocation
  } = useReactiveVar(individualStayVar);

  const [
    loadAvailableRooms,
    { called: calledAr, loading: loadingAr },
  ] = useMutation(GET_INTEGRATIONS_AVAILABLE_ROOMS, {
    variables: {
      city: cityName,
      arrivalDate: dayjs(defaultTravelPeriod[0]).format(
        'YYYY-MM-DD'
      ),
      departureDate: dayjs(defaultTravelPeriod[1]).format(
        'YYYY-MM-DD'
      ),
      currency: '',
      numOfAdults: people || stayDetails.people,
      childrenAges: childrenYears?.length
        ? childrenYears.map(c => Number(c.value))
        : [],
      numOfRooms: rooms || stayDetails.rooms,
      integration,
      stayEstablishmentId: accommodationCode.establishmentId
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setSearching(false);
      setResultRooms(data?.getAccommodationAvailableRooms);
      forceUpdate(data);
    },
    onError: () => {
      setSearching(false);
    },
  });

  const addToCart = (item, idx) => {
    const roomQuantity = quantities.get(id)[idx];
    const cartItem = {
      id: uuidv4(),
      type: CartTypes.STAY,
      item: {
        people: people || stayDetails.people,
        children: children || stayDetails.children,
        travelPeriod: travelPeriod.length
          ? travelPeriod
          : defaultTravelPeriod,
        title: `${item.room_type || item.roomType} ${item.roomDescription}`,
        subtitle: item.accommodation_name || item.accommodationName,
        accommodationName: item.accommodation_name || item.accommodationName,
        price: { ...item.rate, amount: item.rate.amount * roomQuantity },
        room_type: item.room_type || item.roomType,
        roomDescription: item.roomDescription,
        priceRateCode: item.priceRateCode,
        city: item.city_name || item.cityName,
        number_of_rooms: Number(roomQuantity),
        product_owner: productOwner,
        integration,
        stayEstablishmentId: id,
        childrenAges: childrenYears?.map(c => Number(c.value)),
        roomCapacity: item.roomCapacity,
        rooms: [],
        product_location: productLocation,
        check_in_time: checkInTime
      },
    };

    if (roomQuantity > 1) {
      const rooms = [];
      for (let i = 0; i < roomQuantity; i++) {
        rooms.push({
          people: people || stayDetails.people,
          children: children || stayDetails.children,
          room_type: item.room_type || item.roomType,
        })
      }
      cartItem.item.rooms = rooms;
    }

    setCartItem(cartItem, CartActionTypes.Add);
    setNotification(NOTIFICATION_TYPES.ADD_TO_CART);
  };

  const renderBedIcon = roomTypeId => {
    switch (roomTypeId) {
      case ROOM_TYPE_IDS.triple:
        return (
          <RoomTypeContainer>
            <RoomTypeIcon src={SingleBedIcon} />
            <RoomTypeIcon src={SingleBedIcon} />
            <RoomTypeIcon src={SingleBedIcon} />
          </RoomTypeContainer>
        );
      case ROOM_TYPE_IDS.double:
        return (
          <RoomTypeContainer>
            <RoomTypeIcon src={DoubleBedIcon} />
          </RoomTypeContainer>
        );
      default:
        return (
          <RoomTypeContainer>
            <RoomTypeIcon src={SingleBedIcon} />
          </RoomTypeContainer>
        );
    }
  };

  if (!calledAr && !loadingAr) {
    loadAvailableRooms();
    setSearching(true);
  }

  return (
    <Fragment>
      <ModalHeader title={t('title')} />
      {tabs?.length && <Tabs config={mapActiveTab(tabs, activeTab)} />}
      {resultRooms.length > 0 ? (
        <Container>
          <NotificationScreen type={notification} />

          <HeaderRow>
            <HeaderLeftColumn>
              <ItemImage
                src={`assets/images/hotel-cover-${
                  accommodationCode?.establishmentId || ''
                }.png`}
              />
            </HeaderLeftColumn>
            <HeaderRightColumn>
              <ItemImage
                src={`assets/images/hotel-cover-small-${
                  accommodationCode?.establishmentId || ''
                }.png`}
              />
            </HeaderRightColumn>
          </HeaderRow>
          <ScrollRow>
            <InfoDisclaimer
              text={t('additionalBedForChildrenInfo')}
              icon="assets/icons/info-bubble.svg"
              type={MessageType.Neutral}
            />
            <Column>
              {resultRooms.map((item: any, idx: number) => (
                <RoomInfo>
                  <RoomInfoTopContainer>
                    <RoomInfoLeft>
                      <h3>
                        {item.room_type || item.roomType} {item.roomDescription}
                      </h3>
                      <RoomCountContainer>
                        <div>
                          {renderBedIcon(item.room_type_id || item.roomTypeId)}
                        </div>

                        <NumberInput
                          min={0}
                          max={item.count}
                          input={{
                            value: parseInt(
                              quantities.has(id) ? quantities.get(id)[idx] : 0,
                              10
                            ),
                            onChange: e =>
                              setRoomsQuantity(id, idx, e.target.value),
                          }}
                        />
                      </RoomCountContainer>
                    </RoomInfoLeft>
                    <RoomInfoRight>
                      <RoomInfoRightDetails>
                        <RoomInfoList>
                          <h3>{formatPrice(item.rate)}</h3>
                          <AddToPlanButton
                            secondary
                            disabled={
                              !quantities.has(id) ||
                              parseInt(quantities.get(id)[idx] || 0, 10) === 0
                            }
                            onClick={() => addToCart(item, idx)}
                            title={t('addToTravelPlan')}
                          />
                        </RoomInfoList>
                      </RoomInfoRightDetails>
                    </RoomInfoRight>
                  </RoomInfoTopContainer>
                  <RoomInfoBottomContainer>
                    <RoomInfoList>
                      {item.roomCapacity
                        ? t(
                            'suitableForPersonsInfo',
                            {
                              roomCapacity: item.roomCapacity,
                            },
                            item.roomCapacity
                          )
                        : ''}
                    </RoomInfoList>
                    <RoomInfoList>
                      {item.rooms &&
                        item.rooms[0].room_facilities.map(facility => (
                          <p>{facility.room_facility_type.value}</p>
                        ))}
                      {item.facilities?.map(facility => (
                        <p>{facility}</p>
                      ))}
                    </RoomInfoList>
                  </RoomInfoBottomContainer>
                </RoomInfo>
              ))}
            </Column>
          </ScrollRow>
        </Container>
      ) : (
        <Container>
          <Column>
            {searching ? (
              <Loading />
            ) : (
              <NoOffersTitle>{t('noOffers')}</NoOffersTitle>
            )}
          </Column>
        </Container>
      )}

      <ModalFooter
        buttonTitle={t('buttonTitle')}
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        onButtonClick={() =>
          history.push({
            pathname: '/choosing-travel-arrangements',
          })
        }
        mapIconProps={{
          onClick: () =>
            history.push({
              pathname: '/map',
              state: {
                markers: [
                  {
                    ...MARKERS_MOCK[0],
                    onClick: () => {
                      history.push({
                        pathname: `/stay/${integration}/${id}/rooms`,
                        state: {
                          activeTab: 'app.Controls.stayTab',
                        },
                      });
                    },
                  },
                ],
                activeTab: 'app.Controls.stayTab',
                isIndividual: true,
              },
            }),
        }}
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default Rooms;
