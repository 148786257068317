import { INPUT_HEIGHT } from 'config/inputs';
import { FunctionalComponent } from 'preact';
import { useEffect, useRef, useState } from 'preact/compat';
import styled from 'styled-components';

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.secondaryLight};
  border: none;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  flex-shrink: 0;
  height: ${INPUT_HEIGHT};
  outline: none;
  text-align: center;
  width: 36px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryDark};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.secondaryDark};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.secondaryLight};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.accent};
    cursor: default;
  }

  &:first-child {
    border-radius: ${({
      theme: {
        borderRadius: { medium },
      },
    }) => `${medium} 0 0 ${medium}`};
  }

  &:last-child {
    border-radius: ${({
      theme: {
        borderRadius: { medium },
      },
    }) => `0 ${medium} ${medium} 0`};
  }
`;

const Text = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 600;
`;

const LeftButtonText = styled(Text)`
  position: relative;
  top: -1px;
`;

const Input = styled.input`
  border: none;
  color: ${({ theme }) => theme.colors.primaryDark};
  flex-shrink: 0;
  font-size: ${({ theme }) => theme.fontSizes.large};
  height: ${INPUT_HEIGHT};
  margin: ${({ theme }) => theme.spacing.none};
  outline: none;
  padding: ${({ theme }) => theme.spacing.none};
  pointer-events: none;
  text-align: center;
  width: 38px;
  // Remove buttons on the right side of input for increment/decrement
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: ${({ theme }) => theme.spacing.none};
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const StyledNumberInput = styled.div`
  display: flex;
  height: ${INPUT_HEIGHT};
  width: 110px;
`;

type Props = {
  disabled?: boolean;
  input?: any; // TODO FieldInputProps<any>;
  min?: number;
  max?: number;
  id?: string;
  canDisable?: boolean;
  onInit?: (value, index) => void;
};

const NumberInput: FunctionalComponent<Props> = ({
  disabled = false,
  input = null,
  min = 0,
  max = Infinity,
  canDisable = true,
  onInit = () => null
}) => {
  const defaultValue = input.value || 0;
  const [number, setNumber] = useState(defaultValue);

  useEffect(() => {
    for (let i = 0; i < number; ++i) {
      onInit(number, i);
    }
  }, []);

  useEffect(() => {
    if (number > max) {
      setNumber(max);
    }
    if (number < min) {
      setNumber(min);
    }
  }, [max, min]);

  const inputRef = useRef(null);
  const decrement = async () => {
    if (number > min) {
      await setNumber(number - 1);
      inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
    }
  };

  const increment = async () => {
    if (number < max) {
      await setNumber(number + 1);
      inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
    }
  };

  return (
    <StyledNumberInput disabled={disabled}>
      <Button disabled={canDisable && (number <= min || disabled)} onClick={decrement}>
        <LeftButtonText>-</LeftButtonText>
      </Button>
      <Input
        defaultValue={defaultValue}
        disabled={disabled}
        {...input}
        min={min}
        max={max}
        ref={inputRef}
        tabIndex="-1"
        type="number"
        value={number}
      />
      <Button disabled={canDisable && (number >= max || disabled)} onClick={increment}>
        <Text>+</Text>
      </Button>
    </StyledNumberInput>
  );
};

export default NumberInput;
