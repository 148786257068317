import ActivityImage from 'assets/images/activity-image.png';
import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import {
  ActivityAndTransportTotalPriceContainer,
  AdditionalInformationContainer,
  DetailsContainer,
  Image,
  ImageContainer,
  InformationContainer,
  InformationItem,
  InformationList,
  InformationTitle,
  InformationValue,
  TotalPriceTitle,
  TotalPriceValue,
} from 'screens/Cart/components';
import { IActivityCartItem } from 'types/cache/Cart';
import { formatPrice } from 'utils/price/priceOperations';

interface IProps {
  item: IActivityCartItem;
}

const ActivityCartItemDetails: FunctionalComponent<IProps> = ({ item }) => {
  const { t } = useIntl('app.Cart');
  const { t: types } = useIntl('app.TravelItinerary.activityTypes');

  const {
    people,
    children,
    supplierName,
    activity_type,
    city,
    price,
    date,
  } = item;

  const startDate = dayjs(date).format('DD.MM.YYYY');
  const startTime = `${dayjs.utc(item.date).format('HH:mm')}`;
  const numberOfAdults = people || 0;
  const numberOfChildren = children || 0;
  const priceValue = formatPrice(price);

  return (
    <DetailsContainer>
      <InformationContainer>
        <InformationList>
          <InformationItem>
            <InformationTitle>{t('date')}</InformationTitle>
            <InformationValue>{startDate}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('startTime')}</InformationTitle>
            <InformationValue>{startTime}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('numberOfAdults')}</InformationTitle>
            <InformationValue>{`${numberOfAdults} ${t(
              'people'
            )}`}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('numberOfChildren')}</InformationTitle>
            <InformationValue>{`${numberOfChildren} ${t(
              'children'
            )}`}</InformationValue>
          </InformationItem>
        </InformationList>
        <InformationList>
          <InformationItem>
            <InformationTitle>{t('serviceProvider')}</InformationTitle>
            <InformationValue>{supplierName}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('activityType')}</InformationTitle>
            <InformationValue>{types(activity_type)}</InformationValue>
          </InformationItem>
          <InformationItem>
            <InformationTitle>{t('city')}</InformationTitle>
            <InformationValue>{city}</InformationValue>
          </InformationItem>
        </InformationList>
      </InformationContainer>
      <AdditionalInformationContainer>
        <ImageContainer>
          <Image src={ActivityImage} />
        </ImageContainer>
        <ActivityAndTransportTotalPriceContainer>
          <TotalPriceTitle>{t('totalPrice')}</TotalPriceTitle>
          <TotalPriceValue>{priceValue}</TotalPriceValue>
        </ActivityAndTransportTotalPriceContainer>
      </AdditionalInformationContainer>
    </DetailsContainer>
  );
};

export default ActivityCartItemDetails;
