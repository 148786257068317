import {
  CartItemType,
  IActivityCartItem,
  IStayCartItem,
  ITransportCartItem,
} from 'types/cache/Cart';
import { CartTypes } from 'types/shared/Cart';
import ActivityCartItemDetails from './Activity';
import StayCartItemDetails from './Stay';
import TransportCartItemDetails from './Transport';

const CartItemDetails = (id: number, item: CartItemType) => {
  return {
    [CartTypes.TRANSPORT]: (
      <TransportCartItemDetails item={item as ITransportCartItem} />
    ),
    [CartTypes.ACTIVITY]: (
      <ActivityCartItemDetails item={item as IActivityCartItem} />
    ),
    [CartTypes.STAY]: <StayCartItemDetails item={item as IStayCartItem} />,
  };
};

export default CartItemDetails;
