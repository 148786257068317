import useIntl from 'hooks/useIntl';
import { useState } from 'preact/hooks';
import { LeftColumn, RightColumn, Row } from 'screens/Individual/components';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import dayjs from 'dayjs';
import { FunctionalComponent } from 'preact';
import { IBasePrice } from 'types/cache/Cart';
import { formatPrice } from 'utils/price/priceOperations';

const IndividualRow = styled(Row)`
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
`;

const TotalLabel = styled.p`
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
`;

const InfoPill = styled.div`
  height: 37px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors[colorsSpec.primaryLight]};
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const InfoPillExpanded = styled(InfoPill)`
  height: fit-content;
  z-index: 102;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: absolute;
  left: 0;
  top: 37px;
  right: 0;
  bottom: 0;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 8%;
    bottom: 0;
    height: 1px;
    width: 80%;
    border-bottom: 1px solid  ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  }
`;

const InfoLabel = styled.div`
  height: 24px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding-top: 2px;
  padding-left: 15px;
  padding-right: 20px;
`;

const TravelOptionsArrow = styled.div`
  top: -2px;
  left: 5px;
  padding-left: 1px;
  position: relative;
  opacity: 0.9;
  transform: scale(1.7, 1);
  cursor: pointer;
`;

const InfoPillExpandedStyle = {
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
};

type Props = {
  price: IBasePrice;
  travelDate: Date;
  passengers: any;
};

const PassengersInfo: FunctionalComponent<Props> = ({
  price, travelDate, passengers
}) => {
  const { t } = useIntl('app.IndividualTransport');

  const [infoExpanded, setInfoExpanded] = useState(false);

  return (
    <IndividualRow>
      {
        price &&
        <LeftColumn>
          <TotalLabel>
            <strong>{`${t('total')}: `}</strong>{formatPrice(price)}
          </TotalLabel>
        </LeftColumn>
      }
      <RightColumn style={{ width: '48%' }}>
        <InfoPill
          style={infoExpanded ? InfoPillExpandedStyle : null}
          onClick={() => setInfoExpanded(!infoExpanded)}
        >
          <InfoLabel>
            {dayjs(travelDate).format('DD. MMM YYYY.')}
          </InfoLabel>
          <InfoLabel>
            {`${passengers.VU +
              passengers.BO +
              passengers.SU +
              passengers.SE +
              passengers.PS
              } ${t('passengers')}`}
          </InfoLabel>
          <TravelOptionsArrow>&or;</TravelOptionsArrow>
          {infoExpanded ? (
            <InfoPillExpanded>
              <RightColumn style={{ width: '49%' }}>
                {passengers.VU > 0 && (
                  <p>
                    {passengers.VU} {t('passengerType.adult')}
                  </p>
                )}
                {passengers.BO > 0 && (
                  <p>
                    {passengers.BO}{' '}
                    {t('passengerType.childYouth')}
                  </p>
                )}
                {passengers.SU > 0 && (
                  <p>
                    {passengers.SU}{' '}
                    {t('passengerType.student')}
                  </p>
                )}
                {passengers.SE > 0 && (
                  <p>
                    {passengers.SE}{' '}
                    {t('passengerType.senior')}
                  </p>
                )}
                {passengers.SE > 0 && (
                  <p>
                    {passengers.SE}{' '}
                    {t('passengerType.retired')}
                  </p>
                )}
              </RightColumn>
            </InfoPillExpanded>
          ) : null}
        </InfoPill>
      </RightColumn>
    </IndividualRow>
  );
}

export default PassengersInfo;
