import { useMutation } from '@apollo/client';
import {
  BOOK_ACCOMMODATION,
  BOOK_GROUP_ACCOMMODATION,
} from 'apollo/myDesti/mutations';
import dayjs from 'dayjs';
import { useCallback } from 'preact/hooks';
import { IStayCartItem } from 'types/cache/Cart';
import { Gender } from 'types/enums';

const useBookAccommodation = item => {
  const [bookAccommodation] = useMutation(BOOK_ACCOMMODATION);
  const [bookGroupAccommodation] = useMutation(BOOK_GROUP_ACCOMMODATION);

  const createAccommodationBooking = useCallback(
    async (
      formValues: any,
      productOwnerGlobalId: string,
      paymentIntentId: string
    ) => {
      const stayItem = item.item as IStayCartItem;

      try {
        const genderValue =
          typeof formValues.gender === 'object'
            ? formValues.gender.value
            : formValues.gender; // the lead traveler form is broken - the select components return objects, while the initialization and autofill fill the form with strings - needs to be fixed asap

        const countryIsoCode2Value =
          typeof formValues.country === 'object'
            ? formValues.country.extra.isoCode2
            : formValues.countryIsoCode2; // the lead traveler form is broken - the select components return objects, while the initialization and autofill fill the form with strings - needs to be fixed asap

        if (stayItem.number_of_rooms > 1) {
          return await bookGroupAccommodation({
            variables: {
              arrivalDate: dayjs(stayItem.travelPeriod[0]).format('YYYY-MM-DD'),
              departureDate: dayjs(stayItem.travelPeriod[1]).format(
                'YYYY-MM-DD'
              ),
              roomType: stayItem.room_type,
              priceRateCode: stayItem.priceRateCode,
              integration: stayItem.integration,
              stayEstablishmentId: stayItem.stayEstablishmentId,
              customer: {
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                email: formValues.email,
                title: formValues.gender === Gender.Male ? 'Mr' : 'Mrs',
                gender: genderValue.toLowerCase(),
                address: {
                  countryCode: countryIsoCode2Value,
                },
              },
              rooms: Array.from({ length: stayItem.number_of_rooms }).map(
                room => {
                  return {
                    numOfAdults: stayItem.roomCapacity,
                    childrenAges: [],
                  };
                }
              ),
              globalId: productOwnerGlobalId,
              paymentIntentId,
            },
          });
        } else {
          return await bookAccommodation({
            variables: {
              arrivalDate: dayjs(stayItem.travelPeriod[0]).format('YYYY-MM-DD'),
              departureDate: dayjs(stayItem.travelPeriod[1]).format(
                'YYYY-MM-DD'
              ),
              roomType: stayItem.room_type,
              priceRateCode: stayItem.priceRateCode,
              integration: stayItem.integration,
              stayEstablishmentId: stayItem.stayEstablishmentId,
              customer: {
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                email: formValues.email,
                title: formValues.gender === Gender.Male ? 'Mr' : 'Mrs',
                gender: genderValue.toLowerCase(),
                address: {
                  countryCode: countryIsoCode2Value,
                },
              },
              numOfAdults: stayItem.people,
              childrenAges: stayItem.childrenAges || [],
              globalId: productOwnerGlobalId,
              paymentIntentId,
            },
          });
        }
      } catch (error) {
        return error;
      }
    },
    [item]
  );

  return { createAccommodationBooking };
};

export default useBookAccommodation;
