import { setContext } from '@apollo/client/link/context';
import { CLIENTS } from 'config/network';
import storage from 'local-storage-fallback';

export const authLink = setContext((_, { _headers, clientAwareness }) => {
  const { name } = clientAwareness;
  const token = storage.getItem('DESTI1:TOKEN');
  let headers = {
    ..._headers,
    cookie: document.cookie,
  };

  if (token) {
    headers = { ...headers, authorization: `Bearer ${token}` };
  }

  switch (name) {
    case CLIENTS.MY_BOOKINGS:
      return {
        headers: {
          ...headers,
          'widget-language': window.DEST1_CONFIG.lang,
        },
      };
    case CLIENTS.MY_PAGES:
      return {
        headers: {
          ...headers,
          'widget-language': window.DEST1_CONFIG.lang,
        },
      };
    default:
      return {
        headers: {
          ...headers,
          'widget-language': window.DEST1_CONFIG.lang,
        },
      };
  }
});
