import CollapseIcon from 'assets/icons/details-collapse-icon.svg';
import ExpandIcon from 'assets/icons/details-expand-icon.svg';
import Checkbox from 'components/Checkbox';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';
import { marketplaceVar } from 'screens/Marketplace/cache';
import {
  MarketplaceActionTypes,
  useMarketplace,
} from 'screens/Marketplace/useMarketplace';
import styled from 'styled-components';
import { colorsSpec } from 'styles/defaultTheme';
import { CartItem } from 'types/cache/Cart';
import { ITravelPlan } from 'types/cache/TravelPlan';
import { CartTypes } from 'types/shared/Cart';
import TravelPlanItem from '../TravelPlanItem';

const TravelPlanInnerContainer = styled.div`
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.secondaryDark]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px;
  background: ${({ theme }) => theme.colors[colorsSpec.white]};
`;

const ItemHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: ${({ theme }) => theme.colors[colorsSpec.black]};
  }
`;

const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  text-align: center;
  color: ${({ theme }) => theme.colors[colorsSpec.black]};

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    margin-right: 10px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 50px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors[colorsSpec.black]};
  }
`;

const ExpandCollapseIcon = styled.img`
  cursor: pointer;
  margin-left: 10px;
`;

interface IProps {
  travelPlan: ITravelPlan;
  handleItemRemove: (
    travelPlanId: string,
    itemId: number,
    itemType: CartTypes
  ) => void;
}

const TravelPlan: FunctionalComponent<IProps> = ({
  travelPlan,
  handleItemRemove,
}) => {
  const { t } = useIntl('app.TravelPlan');

  const { setMarketplaceItem } = useMarketplace(marketplaceVar);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { travelPlanItems } = travelPlan;

  const handleItemCheck = (isChecked: boolean): void => {
    setMarketplaceItem(
      travelPlan,
      isChecked ? MarketplaceActionTypes.Add : MarketplaceActionTypes.Remove
    );
  };

  return (
    <TravelPlanInnerContainer isOpen={isOpen}>
      <ItemHeaderContainer>
        <StyledCheckbox
          input={{
            onChange: e => handleItemCheck(e.target.checked),
          }}
        />
        <TitleContainer>
          <h4>{travelPlan.travelPlanName}</h4>
        </TitleContainer>
        <ActionContainer>
          <p>{t('details')}</p>
          <ExpandCollapseIcon
            src={isOpen ? CollapseIcon : ExpandIcon}
            onClick={() => setIsOpen(!isOpen)}
          />
        </ActionContainer>
      </ItemHeaderContainer>
      {isOpen &&
        travelPlanItems.map((item: CartItem) => (
          <TravelPlanItem
            travelPlanName={travelPlan.travelPlanName}
            item={item}
            travelPlanId={travelPlan.id}
            handleItemRemove={handleItemRemove}
          />
        ))}
    </TravelPlanInnerContainer>
  );
};

export default TravelPlan;
