import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import Tab, { TabProps } from './Tab';

export const mapActiveTab = (tabs: TabProps[], activeTab: string): TabProps[] =>
  tabs.map(tab =>
    tab.id === activeTab ? { ...tab, isSelected: true } : tab
  );

const StyledTabs = styled.div`
  display: flex;
  flex-direction: row;
  height: ${({ height }) => height};
  align-items: center;
  gap: 4px;
  ${({ count }) =>
    count > 3 &&
    `
    @media (max-width: 576px) {
      height: 100px;
      flex-wrap: wrap;
    }
  `}

  ${({ theme, isSecondary }) =>
    isSecondary
      ? `border: 1px solid ${theme.colors[colorsSpec.primary]};`
      : `border-bottom: 1px solid ${theme.colors[colorsSpec.secondaryDark]};`};
`;

type Props = {
  config: TabProps[];
  height?: string;
  isSecondary?: boolean;
};

const Tabs: FunctionalComponent<Props> = ({
  config,
  height = '40px',
  isSecondary = false,
}) => (
  <StyledTabs height={height} isSecondary={isSecondary} count={config.length}>
    {config.map(tab => (
      <Tab {...tab} count={config.length} isSecondary={isSecondary} />
    ))}
  </StyledTabs>
);

export default Tabs;
