import { useEffect, useState } from 'preact/hooks';
import { ADD_TO_CART_TIMEOUT } from 'screens/NotificationScreen';
import { NOTIFICATION_TYPES } from 'types/shared/Notification';

const useNotification = (timeout = ADD_TO_CART_TIMEOUT) => {
  const [notification, setNotification] = useState<any>(null);

  useEffect(() => {
    if (!notification) {
      return;
    }

    switch (notification) {
      // Show EXPLORE_MORE notification after the PAYMENT_COMPLETED
      case NOTIFICATION_TYPES.PAYMENT_COMPLETED:
        setTimeout(
          () => setNotification(NOTIFICATION_TYPES.EXPLORE_MORE),
          ADD_TO_CART_TIMEOUT
        );
        break;

      case NOTIFICATION_TYPES.USER_REGISTERED:
      setTimeout(
        () => setNotification(NOTIFICATION_TYPES.USER_REGISTERED),
        ADD_TO_CART_TIMEOUT
      );
      break;
      // Don't hide EXPLORE_MORE notification
      case NOTIFICATION_TYPES.EXPLORE_MORE:
        break;

      // Hide notification after timeout
      default:
        setTimeout(() => setNotification(null), timeout);
    }
  }, [notification, timeout]);

  return { notification, setNotification };
};

export default useNotification;
