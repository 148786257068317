import { useCallback, useEffect, useState } from 'preact/hooks';
import { CartItem, IActivityCartItem } from 'types/cache/Cart';

export enum CheckoutSections {
  AdditionalDetails = 'additionalDetails',
  Payment = 'payment',
}

const useCheckoutFormSetup = (item: CartItem) => {
  const [sections, setSections] = useState([CheckoutSections.Payment]);

  useEffect(() => {
    if ((item.item as IActivityCartItem).fields?.length) {
      setSections([
        CheckoutSections.AdditionalDetails,
        CheckoutSections.Payment,
      ]);
    }
  }, []);

  const hasSection = useCallback(
    (section: CheckoutSections): boolean =>
      sections.findIndex((s: CheckoutSections) => s === section) !== -1,
    [sections]
  );

  return {
    sections,
    hasSection,
  };
};

export default useCheckoutFormSetup;
