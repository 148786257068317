import { ReactiveVar } from '@apollo/client';
import { ActivityList } from 'types/cache/ActivityList';
import { IRating } from 'types/cache/Rating';

export function useActivitiesList (
  activitiesListVar: ReactiveVar<ActivityList>
) {
  const setOrderBy = (orderBy: any) => {
    const oldValue = activitiesListVar();
    const updated = { ...oldValue, orderBy };
    activitiesListVar(updated);
  };

  const setCity = (city: string) => {
    const oldValue = activitiesListVar();
    const updated = { ...oldValue, city };
    activitiesListVar(updated);
  };

  const setRatings = (activityRatings: { [id: string]: IRating }) => {
    const oldValue = activitiesListVar();
    const updated = { ...oldValue, activityRatings };
    activitiesListVar(updated);
  };

  const setActivityType = (activityType: string) => {
    const oldValue = activitiesListVar();
    const updated = { ...oldValue, activityType };
    activitiesListVar(updated);
  }

  return {
    setOrderBy,
    setCity,
    setRatings,
    setActivityType
  };
}
