import Button from 'components/Button/';
import Tabs from 'components/Tabs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colorsSpec } from 'styles';

export const Container = styled.div`
  min-height: 569px;
  padding: 25px;
  margin: 50px 25px;
  position: relative;
`;

export const SupportMailInfoContainer = styled.div`
  padding: 7px 25px 10px 25px;
  margin: 0px 25px 0px 25px;
  position: relative;
`;

export const Title = styled.h3`
  text-align: center;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  margin-top: 24px !important;
  color: ${({ theme }) => theme.colors[colorsSpec.primary]};
`;

export const Description = styled.span`
  margin-top: 6px;
  line-height: 14px;
  font-weight: 400;
  font-size: 10px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryLight]};
`;

export const ForgotPassword = styled(Link)`
  margin-top: 10px;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryLight]};
`;

export const TermsAndPolicy = styled.div`
  margin-top: 10px;
`;

export const TabsContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

export const ProfileTabsContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 500px;
  margin: 5px auto;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const ProfileInputRow = styled(InputRow)`
  max-width: 100%;
`;

export const InputColumnLeft = styled.div`
  @media (min-width: 568px) {
    width: 48%;
  }
`;

export const InputColumnRight = styled.div`
  @media (min-width: 568px) {
    width: 48%;
  }
`;

export const RegisterButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

export const CancelButton = styled(RegisterButton)`
  margin-top: 10px;
  width: 100%;

  img {
    width: 16px;
  }
`;

export const RecoverLabel = styled.span`
  padding: 12px 0 10px 0;
  color: ${({ theme }) => theme.colors[colorsSpec.primary]};
  line-height: 19px;
  font-size: ${({ theme }) => theme.fontSizes.default};
`;

export const LogoutRow = styled.div`
  position: relative;
  width: 100%;
  min-height: ${({ isPasswordEdit }) => isPasswordEdit && `80px;`};
`;

export const LogoutContainer = styled.div`
  position: absolute;
  width: 48%;
  top: -55px;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  top: ${({ isPasswordEdit }) => isPasswordEdit && `20px;`};

  @media (max-width: 576px) {
    position: static;
    width: 100%;
  }
`;

export const LogoutButton = styled(Button)`
  width: 100%;
`;

export const StyledTabs = styled(Tabs)``;
