import { IItinerary, IPriceGroup, ISegmentPlacement } from 'types/cache/Cart';
import styled from 'styled-components';
import { HeaderRow, Row } from 'screens/Individual/components';
import { colorsSpec } from 'styles';
import { FunctionalComponent } from 'preact';
import dayjs from 'dayjs';
import TransportPill from 'components/TransportPill';
import useIntl from 'hooks/useIntl';
import TravelClassTile from './TravelClassTile';
import { useEffect, useState } from 'preact/hooks';
import Placements from './Placements';

const DetailRow = styled(HeaderRow)`
  height: 54px;
  border: 1px solid ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  border-radius: 14.5px;
  background-color: ${({ theme }) => theme.colors[colorsSpec.primaryLight]};
  margin-top: 10px;
  padding: 10px;
`;

const DetailSegment = styled.div`
  display: inherit;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const DetailTimeStation = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 40%;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
`;

const DetailTransportInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DetailTransportText = styled.p`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
`;

const TicketOptionRow = styled(Row)`
  justify-content: space-between;
  padding: 20px 5px 20px 5px;
  flex-flow: wrap;
  row-gap: 7px;
`;

type Props = {
  itinerary: IItinerary;
  isCurrent: boolean;
  setItineraryOptions: (options: any) => void;
};

const TransportItinerary: FunctionalComponent<Props> = ({
  itinerary, isCurrent, setItineraryOptions
}) => {
  const { t } = useIntl('app.IndividualTransport');

  const [classFlexibility, setClassFlexibility] = useState(null);
  const [segmentPlacements, setSegmentPlacements] = useState<ISegmentPlacement[]>([]);
  const [selectedPlacements, setSelectedPlacements] = useState([]);
  const [priceGroups] = useState<IPriceGroup[]>(
    [...itinerary.priceGroups.filter(pg => pg.passengersPrices.length)].sort((a, b) => a.totalBasePrice.amount - b.totalBasePrice.amount)
  );

  const renderDetailsTimeStation = (time: string, locationName: string) =>
    <DetailTimeStation>
      <p>
        <strong>
          {dayjs(time).format('HH:mm')}
        </strong>
        &nbsp;{locationName}
      </p>
    </DetailTimeStation>

  useEffect(() => {
    if (!classFlexibility) {
      return;
    }
    setItineraryOptions(classFlexibility);

    const sp = itinerary.segments.map(s => s.segmentsPlacements.find(x => x.priceGroup === classFlexibility.priceGroup.value));
    setSegmentPlacements(sp);

    setSelectedPlacements(sp.map(x => {
      return {
        feature: x.placementCharacteristics[0],
        orientation: x.placementOrientation[0],
        compartment: x.placementCompartments[0]
      }
    }));
  }, [classFlexibility]);

  useEffect(() => {
    if (!selectedPlacements.length) {
      return;
    }

    setItineraryOptions({ ...classFlexibility, segmentPlacements: selectedPlacements });
  }, [selectedPlacements]);

  const arePlacementsVisibleForSegment = (index: number) =>
    isCurrent && (!!segmentPlacements[index]?.placementCharacteristics.length || !!segmentPlacements[index]?.placementCompartments.length);

  const renderPlacements = (index: number) => {
    return (
      <Placements
        segmentPlacement={segmentPlacements[index]}
        setOptions={(placement) => {
          const segmentPlacements = [...selectedPlacements];
          segmentPlacements[index] = placement;
          setSelectedPlacements(segmentPlacements);
        }}
      />
    )
  }

  return (
    <>
      {
        itinerary.segments.map((segment, index) =>
          <>
            <DetailRow>
              <DetailSegment>
                {renderDetailsTimeStation(segment.departureDateTime, segment.departureLocation.name)}
                <DetailTransportInfo>
                  <TransportPill
                    type={segment.travelMethodCode.value}
                    info={`${segment.segmentProducerCode.title} ${segment.productCode.value} ${segment.transportId}`}
                  />
                  <DetailTransportText>
                    {t(segment.travelMethodCode.title.toLowerCase())}{' '}
                    {t('with')} {segment.segmentProducerCode.title}
                    &nbsp;
                    {segment.productCode.value || ''}
                  </DetailTransportText>
                </DetailTransportInfo>
                {renderDetailsTimeStation(segment.arrivalDateTime, segment.arrivalLocation.name)}
              </DetailSegment>
            </DetailRow>
            {arePlacementsVisibleForSegment(index) && renderPlacements(index)}
          </>
        )
      }
      {
        isCurrent &&
        <>
          <TicketOptionRow>
            {
              priceGroups.map(pg =>
                <TravelClassTile
                  priceGroup={pg}
                  setClassOptions={setClassFlexibility}
                  checkedFlexibility={classFlexibility}
                />
              )
            }
          </TicketOptionRow>
        </>
      }
    </>
  );
}

export default TransportItinerary;
