import { PASSWORD_REGEX } from 'config/inputs';
import useIntl from 'hooks/useIntl';

export default function () {
  const { t: messages } = useIntl('app.ValidationMessages');

  const constraints = {
    oldEmail: {
      presence: {
        message: messages('emailRequired'),
      },
      email: {
        message: messages('emailInvalid'),
      },
    },
    newEmail: {
      presence: {
        message: messages('emailRequired'),
      },
      email: {
        message: messages('emailInvalid'),
      },
      equality: {
        attribute: 'oldEmail',
        message: messages('emailEqualError'),
        comparator: (newMail, oldMail) => newMail !== oldMail,
      },
    },
    password: {
      presence: {
        message: messages('passwordRequired'),
      },
      format: {
        pattern: PASSWORD_REGEX,
        message: messages('passwordInvalid'),
      },
    },
  };

  return { constraints };
}
