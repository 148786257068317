import styled from 'styled-components';
import { colorsSpec } from 'styles';

export const CartItem = styled.div`
  background: ${({ theme }) => theme.colors[colorsSpec.secondary]};
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
  display: flex;
  flex-direction: row;
  padding: 15px 24px 15px 10px;

  @media (max-width: 767.98px) {
    flex-wrap: wrap;
  }
`;

export const CartItemImage = styled.div`
  width: ${({ isMini }) => (isMini ? '140px' : '20%')};
  padding-right: ${({ isMini }) => (isMini ? '0' : '5px')};
  position: relative;
  border-top-left-radius: 3px;

  @media (max-width: 767.98px) {
    width: 40%;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    border-top-left-radius: 3px;

    @media (max-width: 767.98px) {
      width: 100%;
    }
  }

  p {
    color: ${({ theme }) => theme.colors[colorsSpec.white]};
    font-size: 10px;
    font-weight: 700;
    margin-bottom: 0;
    background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
    height: 26px;
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 10px;
  }

  span {
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 0;
    background-color: ${({ theme }) => theme.colors[colorsSpec.primary]};
    color: ${({ theme }) => theme.colors[colorsSpec.white]};
    font-size: 10px;
    padding: 5px;
    text-align: center;
    font-weight: 600;
  }
`;

export const CartItemTime = styled.div`
  padding: 12px 0;
  width: calc(25% - 10px);
  background: ${({ theme }) => theme.colors[colorsSpec.white]};
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;

  @media (max-width: 767.98px) {
    width: calc(60% - 10px);
  }
`;

export const CartItemTimeHeading = styled.p`
  border-bottom: 1px solid
    ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CartItemTimeTitle = styled.p`
  margin-bottom: 5px !important;
  font-size: 12px;
  font-weight: 700;
`;

export const CartItemTimeValue = styled.h3`
  &:first-of-type {
    margin-bottom: 10px;
  }
`;

export const CartItemInfo = styled.div`
  width: 80%;
  padding: 0 3px;

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

export const Title = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors[colorsSpec.white]};
  border-radius: 4px;
  height: 26px;
  text-align: center;
  margin: 2px 0;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Value = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors[colorsSpec.white]};
  border-radius: 4px;
  height: 26px;
  text-align: center;
  margin: 2px 0;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px 0;
`;

export const MediumSmall = styled.div`
  width: 33.33%;
  padding: 0 5px;

  @media (max-width: 767.98px) {
    width: 50%;
  }
`;

export const SmallCard = styled.div`
  width: 20%;
  padding: 0 5px;

  @media (max-width: 767.98px) {
    width: 50%;
  }
`;

export const MediumCard = styled.div`
  width: 40%;
  padding: 0 5px;

  @media (max-width: 767.98px) {
    width: 50%;
  }
`;

export const LargeCard = styled.div`
  width: 60%;
  padding: 0 5px;

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

export const ExtraLargeCard = styled.div`
  width: 85%;
  padding: 0 5px;

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

export const StyledIcon = styled.i`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors[colorsSpec.secondaryLight]};
  width: 25px;
  height: 25px;
  border-radius: 3px;
  padding: 5px;
  display: inline-block;
  text-align: center;

  img {
    margin: auto;
  }
`;

export const ItineraryTitle = styled.h4`
  margin-left: 18px !important;
`;

export const FullSizeCard = styled.div`
  width: 100%;
  padding: 0 5px;

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;
