import {
  useLazyQuery,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  GET_ACCOMMODATION_CATEGORY_AND_TYPE,
  GET_INTEGRATIONS_ACCOMMODATION_BY_TYPE,
} from 'apollo/myDesti/queries';
import ImageSlider from 'components/ImageSlider';
import Loading from 'components/Loading';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import NumberInput from 'components/NumberInput';
import { IsPageVisited } from 'components/PageVisited';
import { Paths } from 'constants/paths';
import useIntl from 'hooks/useIntl';
import { Fragment } from 'preact';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { stayListVar } from 'screens/Lists/Stay/cache';
import { useStayList } from 'screens/Lists/Stay/useStayList';
import styled from 'styled-components';
import { getRandomInt } from 'utils/number/getRandomInt';
import { locationAndPeriodVar } from '../../LocationAndPeriod/cache';
import { stayDetailsVar } from './cache';
import { useStayDetails } from './useStayDetails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  padding: 15px 25px 20px 25px;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const StayDetails = () => {
  const history = useHistory();
  const isThisPageVisited = IsPageVisited('StayDetails');
  const { t } = useIntl('app.StayDetails');

  // * Booking type functionality
  // const bookingTypes = [
  //   {
  //     value: controls('bookingTypePrivate'),
  //     label: controls('bookingTypePrivate'),
  //   },
  //   {
  //     value: controls('bookingTypeBusiness'),
  //     label: controls('bookingTypeBusiness'),
  //   },
  //   { value: controls('bookingTypeBoth'), label: controls('bookingTypeBoth') },
  // ];

  const {
    // setBookingType, // * Booking type functionality
    setInFocusItems,
    setAccommodationList,
    setNumValues,
  } = useStayDetails(stayDetailsVar);

  const {
    people,
    children,
    rooms,
    accommodationList,
    accommodationType,
    // bookingType, // * Booking type functionality
    inFocusItems,
  } = useReactiveVar(stayDetailsVar);

  const { stayRatings } = useReactiveVar(stayListVar);
  const { setRatings } = useStayList(stayListVar);

  const { cityName } = useReactiveVar(locationAndPeriodVar);

  const { data: accommodationData, loading: isAccommodationLoading } = useQuery(
    GET_ACCOMMODATION_CATEGORY_AND_TYPE
  );

  const [
    loadAccommodationsByType,
    { called: calledIAcType, loading: loadingIAcType },
  ] = useLazyQuery(GET_INTEGRATIONS_ACCOMMODATION_BY_TYPE, {
    variables: {
      city: cityName,
      type: 'Hotel',
      categoryId: 2,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setInFocusItems(
        {
          accommodation: [
            ...data?.getIntegrationsAccommodationByType,
            // ...inFocusItems,
          ],
        },
        'app.Controls.stayTab'
      );
    },
  });

  useEffect(() => {
    if (!accommodationData) {
      return;
    }

    setAccommodationList(accommodationData);
  }, [accommodationData]);

  useEffect(() => {
    const newRatings = { ...stayRatings };

    inFocusItems.forEach(({ id: stayEstablishmentId }) => {
      if (!newRatings[stayEstablishmentId]) {
        newRatings[stayEstablishmentId] = {
          rating: getRandomInt(4, 5),
          reviewCount: getRandomInt(1, 1000),
        };
      }
    });

    setRatings({ ...newRatings });
  }, [inFocusItems]);

  const renderComponents = () => {
    return (
      <Fragment>
        <Row>
          <p>{t('peopleTitle')}</p>
          <NumberInput
            id="people"
            min={1}
            input={{
              value: parseInt(people.toString(), 10),
              onChange: e => setNumValues(e.target.value, 'people'),
            }}
          />
        </Row>
        <Row>
          <p>{t('childrenTitle')}</p>
          <NumberInput
            id="children"
            input={{
              value: parseInt(children.toString(), 10),
              onChange: e => setNumValues(e.target.value, 'children'),
            }}
          />
        </Row>
        <Row>
          <p>{t('roomsTitle')}</p>
          <NumberInput
            id="rooms"
            min={1}
            input={{
              value: parseInt(rooms.toString(), 10),
              onChange: e => setNumValues(e.target.value, 'rooms'),
            }}
          />
        </Row>
        {/* // * Booking type functionality */}
        {/* <Row>
          <p>{t('privateBusiness')}</p>
          <SelectContainer>
            <SelectComponent
              id="stay-type"
              input={{
                value: bookingType,
                onChange: ({ value }: Option) => setBookingType(value),
              }}
              options={bookingTypes}
              styleType="type_3"
            />
          </SelectContainer>
        </Row> */}

        {accommodationList.length && (
          <></>
          // <DualSelector
          //   title={t('accommodationType')}
          //   list={accommodationList}
          //   selectedItems={accommodationType}
          //   onItemChange={({ item, subItem }) =>
          //     setAccommodationType(item, subItem)
          //   }
          //   emptyStateConfig={emptyStateConfig}
          // />
        )}

        <ImageSlider
          title={t('inFocusTitle')}
          galleryItems={inFocusItems}
          stateConfig={{ title: loadingIAcType ? 'Loading' : 'No items' }}
        />
      </Fragment>
    );
  };

  if (!calledIAcType && !loadingIAcType) {
    loadAccommodationsByType();
  }

  return (
    <Fragment>
      <ModalHeader title={t('title')} />

      {isAccommodationLoading ? (
        <Loading />
      ) : (
        <Container>{renderComponents()}</Container>
      )}
      <ModalFooter
        mapIconProps={{ disabled: true }}
        buttonTitle={t('buttonTitle')}
        tooltipTitle={t('tooltipTitle')}
        tooltipText={t('tooltipText')}
        tooltipPoweredBy={t('tooltipPoweredBy')}
        buttonProps={{ disabled: !accommodationType }}
        onButtonClick={() =>
          history.push({
            pathname: Paths.StayList,
            state: { activeTab: 'app.Controls.stayTab' },
          })
        }
        doOpen={!isThisPageVisited}
      />
    </Fragment>
  );
};

export default StayDetails;
