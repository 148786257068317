import { makeVar } from '@apollo/client';
import dayjs from 'dayjs';

export type OptionType = {
  value: string;
  label: string;
};

export type RegisterFormValuesType = {
  firstName: string;
  lastName: string;
  email: string;
  country: OptionType | string;
  gender: OptionType | string;
  dateOfBirth: Date;
  password: string;
  confirmPassword: string;
  terms: boolean;
};

export const maxDateOfBirth = dayjs()
  .subtract(1, 'day')
  .subtract(18, 'year')
  .toDate();

export const initialRegisterFormValues: RegisterFormValuesType = {
  firstName: '',
  lastName: '',
  email: '',
  country: '',
  gender: '',
  dateOfBirth: undefined,
  password: '',
  confirmPassword: '',
  terms: false,
};

export const registerFormVar = makeVar(initialRegisterFormValues);
