import { useMutation } from '@apollo/client';
import { GET_ACCOMMODATION_RESTRICTIONS } from 'apollo/myDesti/mutations';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useCallback, useEffect, useState } from 'preact/hooks';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

type RestrictedDate = {
  date: Date;
};
const useIndividualAccommodationRestrictions = (id: string, arrivalDate: Date, departureDate: Date) => {
  const [restrictedDates, setRestrictedDates] = useState<Date[]>([]);

  useEffect(() => {
    getRestrictedDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [getRestrictedDates] = useMutation<{
    getRestrictions: RestrictedDate[];
  }>(GET_ACCOMMODATION_RESTRICTIONS, {
    variables: {
      arrivalDate: arrivalDate,
      departureDate: new Date(new Date(departureDate).valueOf() + 1000 * 60 * 60 * 24 * 365), // one year into the future
      stayEstablishmentId: id,
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setRestrictedDates(
        data.getRestrictions.map(dateWrapper =>
          dayjs(dateWrapper.date).toDate()
        )
      );
    },
  });

  const isDateDisabled = useCallback(
    ({ date }) => {
      return restrictedDates.some(restrictedDate => {
        return dayjs(date).isSame(restrictedDate, 'day');
      });
    },
    [restrictedDates]
  );

  const isTravelPeriodValid = useCallback(
    (startDate: Date, endDate: Date) => {
      // restricted dates don't have a timezone - will get converted to local times which is OK for comparison with user dates
      const isInvalid =
        restrictedDates.length === 0
          ? false
          : restrictedDates.some(restrictedDate => {
              return (
                dayjs(startDate).isSameOrBefore(dayjs(restrictedDate)) &&
                dayjs(endDate).isSameOrAfter(dayjs(restrictedDate))
              );
            });

      return !isInvalid;
    },
    [restrictedDates]
  );

  return {
    restrictedDates,
    isDateDisabled,
    isTravelPeriodValid,
  };
};

export default useIndividualAccommodationRestrictions;
