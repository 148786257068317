import dayjs from 'dayjs';
import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import { IActivityCartItem } from 'types/cache/Cart';
import {
  PlanItemInfoContainer,
  PlanItemInfoRow,
  PlanItemInfoTitle,
  PlanItemInfoValue,
} from '../components';

interface IProps {
  item: IActivityCartItem;
}

const ActivityMarketplaceItemMainInfo: FunctionalComponent<IProps> = ({
  item,
}) => {
  const { t } = useIntl('app.Marketplace');

  const startDate = dayjs(item.date).format('DD.MM.YYYY');
  const startTime = `@ ${dayjs.utc(item.date).format('HH:mm')}`;

  return (
    <PlanItemInfoContainer>
      <PlanItemInfoRow>
        <PlanItemInfoTitle>{t('dateTitle')}</PlanItemInfoTitle>
        <PlanItemInfoValue>{startDate}</PlanItemInfoValue>
      </PlanItemInfoRow>
      <PlanItemInfoRow>
        <PlanItemInfoTitle>{t('timeTitle')}</PlanItemInfoTitle>
        <PlanItemInfoValue>{startTime}</PlanItemInfoValue>
      </PlanItemInfoRow>
      <PlanItemInfoRow>
        <PlanItemInfoTitle>{t('numberOfPeople')}</PlanItemInfoTitle>
        <PlanItemInfoValue>{item.people || 0}</PlanItemInfoValue>
      </PlanItemInfoRow>
    </PlanItemInfoContainer>
  );
};

export default ActivityMarketplaceItemMainInfo;
