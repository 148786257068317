import { useState } from 'preact/hooks';
import { useEffect } from 'react';
import { ICountry } from 'types/cache/Country';
import { IUser } from 'types/cache/User';

export interface IUserInfo {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  dateOfBirth: number;
  country: string;
}

export default function (
  user: IUser,
  countries: ICountry[]
): { userInfo: IUserInfo; saveUserInfo: (user: IUser) => void } {
  const [userInfo, setUserInfo] = useState<IUserInfo>(null);

  useEffect(() => saveUserInfo(user), [user, countries]);

  const saveUserInfo = (user: IUser) => {
    setUserInfo({
      firstName: user?.first_name,
      lastName: user?.last_name,
      email: user?.email || userInfo?.email,
      gender: user?.gender,
      dateOfBirth: Date.parse(user?.date_of_birth),
      country:
        user && countries.length
          ? countries.find(c => c.id === user.country_id).name
          : '',
    });
  };

  return { userInfo, saveUserInfo };
}
