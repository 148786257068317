import useIntl from 'hooks/useIntl';
import { useRef, useState } from 'preact/hooks';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

enum FlightPaymentStatusEnum {
  Pending = 'PENDING',
  Received = 'RECEIVED',
  Confirmed = 'CONFIRMED',
}

export type TravelItineraryTransport = {
  travel_itinerary_transport: Array<{
    id: string;
    external_id?: string;
    transaction_id?: string;
  }>;
};

const useFlightsItineraryPaymentStatus = (travelPlanItems, handlePaidItem) => {
  const { t } = useIntl('app.Marketplace');
  const timerRefs = useRef(null);
  const [
    savedFlightItemAndItineraryById,
    setSavedFlightItemAndItineraryById,
  ] = useState<{
    [itemId: string]: { itemId: string; travelItineraryId: string } | undefined;
  }>({});
  const [flightPaymentStatusById, setFlightPaymentStatusById] = useState<{
    [itemId: string]: FlightPaymentStatusEnum | undefined;
  }>({});

  useEffect(() => {
    return () => {
      if (timerRefs.current && Object.keys(timerRefs.current)) {
        Object.keys(timerRefs.current).forEach(timerKey =>
          clearTimeout(timerRefs.current[timerKey])
        );
      }
    };
  }, []);

  const saveFlightItemAndItineraryById = useCallback(
    (flightItem: { itemId: string; travelItineraryId: string }) => {
      setSavedFlightItemAndItineraryById({
        ...savedFlightItemAndItineraryById,
        [flightItem.itemId]: flightItem,
      });
      if (timerRefs.current && timerRefs.current[flightItem.itemId]) {
        clearTimeout(timerRefs.current[flightItem.itemId]);
      }
      timerRefs.current = {
        ...timerRefs.current,
        [flightItem.itemId]: setTimeout(() => {
          toast.warning(t('flightStatusCouldNotBeDetermined'), {
            position: 'top-right',
            autoClose: 0,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setSavedFlightItemAndItineraryById({
            ...savedFlightItemAndItineraryById,
            [flightItem.itemId]: undefined,
          });
        }, 300000),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [savedFlightItemAndItineraryById]
  );

  const saveFlightPaymentStatusById = useCallback(
    (itemId: string, data: TravelItineraryTransport) => {
      if (savedFlightItemAndItineraryById[itemId]) {
        setFlightPaymentStatusById({
          ...flightPaymentStatusById,
          [itemId]: FlightPaymentStatusEnum.Pending,
        });

        // order of the if cases matters
        if (data?.travel_itinerary_transport[0]?.transaction_id) {
          setFlightPaymentStatusById({
            ...flightPaymentStatusById,
            [itemId]: FlightPaymentStatusEnum.Confirmed,
          });
          const travelPlanItem = travelPlanItems.find(
            i => i.item.booking_token === itemId
          );
          travelPlanItem.paid = true;
          if (timerRefs.current && timerRefs.current[itemId]) {
            clearTimeout(timerRefs.current[itemId]);
          }

          handlePaidItem(travelPlanItem);
          setFlightPaymentStatusById({
            ...flightPaymentStatusById,
            [itemId]: undefined,
          });
        } else if (data?.travel_itinerary_transport[0]?.external_id) {
          setFlightPaymentStatusById({
            ...flightPaymentStatusById,
            [itemId]: FlightPaymentStatusEnum.Received,
          });
        }
      } else if (!savedFlightItemAndItineraryById[itemId]) {
        setFlightPaymentStatusById({
          ...flightPaymentStatusById,
          [itemId]: undefined,
        });
      }
    },
    [
      flightPaymentStatusById,
      savedFlightItemAndItineraryById,
      handlePaidItem,
      travelPlanItems,
    ]
  );

  return {
    flightPaymentStatusById,
    savedFlightItemAndItineraryById,
    saveFlightItemAndItineraryId: saveFlightItemAndItineraryById,
    saveFlightPaymentStatusById,
  };
};

export default useFlightsItineraryPaymentStatus;
