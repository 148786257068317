import { onError } from '@apollo/client/link/error';
import { HTTP_ERROR_MESSAGES } from 'config/network';
import storage from 'local-storage-fallback';
import { toast } from 'react-toastify';

export const errorLink = onError(({ graphQLErrors /*, networkError*/ }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, extensions }) => {
      /* const isServerError = Object.values(HTTP_STATUS_CODES).includes(
          parseInt(extensions.code, 10)
        );
        */

      if (extensions && extensions.code === 'validation-error') {
        return;
      }

      switch (message) {
        case HTTP_ERROR_MESSAGES.JWT_EXPIRED:
          storage.removeItem('DESTI1:TOKEN');
          storage.removeItem('DESTI1:REFRESH:TOKEN');

          // TODO: Add better redirect strategy
          window.location.reload();
          break;

        default:
          toast.error(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      }
    });
  }
});
