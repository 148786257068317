import styled from 'styled-components';

export const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 10px 28px;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    width: 100%;
  }

  :first-child {
    margin-right: 5px;
    width: 100%;

    @media (max-width: 576px) {
      margin-right: 0;
    }
  }

  :last-child {
    margin-left: 5px;
    width: 100%;

    @media (max-width: 576px) {
      margin-left: 0;
    }
  }
`;
