import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

const dateDiffInDMH = (startDate, endDate) => {
  const diffMilliseconds = dayjs.duration(
    dayjs.utc(endDate).diff(dayjs.utc(startDate).valueOf())
  );

  return diffMilliseconds.days() > 0
    ? diffMilliseconds.format('DD[d] HH[h] mm[min]')
    : diffMilliseconds.format('HH[h] mm[min]');
};

export default dateDiffInDMH;
