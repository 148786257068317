import { gql } from '@apollo/client';

export const TRAVEL_ITINERARY_TRANSPORT_SUBSCRIPTION = gql`
  subscription TravelItineraryTransport($itemId: String) {
    travel_itinerary_transport(where: { booking_token: { _eq: $itemId } }) {
      id
      external_id
      transaction_id
    }
  }
`;
