import SeeMore from 'components/SeeMore';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { colorsSpec } from 'styles';
import { IBasePrice } from 'types/cache/Cart';
import { formatPrice } from 'utils/price/priceOperations';

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 8px 10px;
  height: 100%;
`;

const LeftColumn = styled.div`
  width: 70%;
`;

const RightColumn = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const Title = styled.h5`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
`;

const Description = styled.div`
  margin-top: 16px;
  font-size: 10px;
  letter-spacing: 0.6px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
`;

const Price = styled.div`
  letter-spacing: 1.3px;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  color: ${({ theme }) => theme.colors[colorsSpec.primaryDark]};
`;

const ItemImage = styled.img.attrs(({ src }) => ({
  src,
}))`
  width: auto;
  margin: 0 auto;
  display: block;
  height: 18px;
`;

const Icons = styled.div`
  margin: auto 0;
  text-align: center;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  max-width: 90px;
`;

const IconContainer = styled.div`
  width: 33.33%;
  margin-top: 15px;
`;

type Props = {
  title: string;
  description: string;
  price?: IBasePrice;
  amenities: { image: string; label: string }[];
};

const Tile: FunctionalComponent<Props> = ({
  title = '',
  description = '',
  price = null,
  amenities = [],
}) => (
  <Container>
    <LeftColumn>
      <Title>{title}</Title>
      <Description>
        <SeeMore text={description} />
      </Description>
    </LeftColumn>
    <RightColumn>
      {
        price &&
        <Price>
          {formatPrice(price)}
        </Price>
      }
      <Icons>
        {amenities.map(
          ({ image, label = '' }) =>
            image && (
              <IconContainer>
                <ItemImage src={image} alt={label} />
              </IconContainer>
            )
        )}
      </Icons>
    </RightColumn>
  </Container>
);

export default Tile;
