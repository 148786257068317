import { useReactiveVar } from '@apollo/client';
import CartIcon from 'assets/icons/cart_2.svg';
import ChatIcon from 'assets/icons/chat-yellow.svg';
import InfoIcon from 'assets/icons/info-yellow.svg';
import LocationIcon from 'assets/icons/location.svg';
import UserIcon from 'assets/icons/user.svg';
import HomeIcon from 'assets/icons/home.svg';
import Button from 'components/Button';
import StyledIcon from 'components/StyledIcon';
import Tooltip from 'components/Tooltip';
import TooltipContainer from 'components/TooltipContainer';
import { Paths } from 'constants/paths';
import useIntl from 'hooks/useIntl';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useToken from 'hooks/useToken';
import { FunctionalComponent } from 'preact';
import { useRef, useState } from 'preact/hooks';
import { useHistory } from 'react-router';
import { cartVar } from 'screens/Cart/cache';
import styled from 'styled-components';
import { Cart } from 'types/cache/Cart';

const StyledModalFooter = styled.div`
  align-items: center;
  border-top: 2px solid ${({ theme }) => theme.colors.secondaryDark};
  display: flex;
  flex-shrink: 0;
  height: 90px;
  justify-content: space-between;
  padding: 0 25px;

  @media (max-width: 576px) {
    flex-direction: column;
    flex-direction: column-reverse;
    height: 120px;
    justify-content: space-evenly;
  }
`;

const IconGroup = styled.div`
  display: flex;
  width: 300px;
  justify-content: space-between;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
`;

const PoweredBy = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: large;
  position: relative;
  bottom: 10px;
  font-weight: 600;
`;

const SecondaryButton = styled(Button)`
  margin-left: 15px;
`;

const StyledCartIcon = styled(StyledIcon)`
  img {
    width: 25px;
    height: 25px;
  }
`;

const UserIconWrapper = props => {
  const { token } = useToken();
  const history = useHistory();

  return (
    <StyledIcon
      icon={UserIcon}
      round
      id="userIcon"
      {...props}
      onClick={() =>
        token
          ? history.push({
              pathname: Paths.Profile,
              state: { activeTab: 'app.Profile.personalTab' },
            })
          : history.push({
              pathname: Paths.Login,
              state: { activeTab: 'app.Login.login' },
            })
      }
    />
  );
};

const CartIconWrapper = props => {
  const { cartItems = [] } = useReactiveVar<Cart>(cartVar);
  const history = useHistory();

  return (
    <StyledCartIcon
      icon={CartIcon}
      round
      id="cartIcon"
      {...props}
      itemCount={cartItems.length}
      disabled={cartItems.length === 0}
      onClick={() => history.push(Paths.Cart)}
    />
  );
};

type Props = {
  buttonTitle?: string;
  buttonIcon?: string;
  buttonProps?: any; // TODO
  onButtonClick?: () => void;
  showButton?: boolean;
  mapIconProps?: any; // TODO
  tooltipIconProps?: any; // TODO
  chatIconProps?: any; // TODO
  cartIconProps?: any; // TODO
  userIconProps?: any; // TODO
  tooltipTitle?: string;
  tooltipText?: string;
  tooltipPoweredBy?: string;
  showSecondaryButton?: boolean;
  secondaryButtonTitle?: string;
  secondaryButtonIcon?: string;
  onSecondaryButtonClick?: () => void;
  secondaryButtonProps?: any; // TODO
  doOpen?: boolean;
  doOnDismiss?: () => void;
};

const ModalFooter: FunctionalComponent<Props> = ({
  buttonTitle = null,
  buttonIcon = null,
  buttonProps = null,
  onButtonClick = () => 0,
  showButton = true,
  mapIconProps = null,
  tooltipIconProps = null,
  chatIconProps = null,
  cartIconProps = null,
  userIconProps = null,
  tooltipTitle = null,
  tooltipText = null,
  tooltipPoweredBy = null,
  showSecondaryButton = false,
  secondaryButtonTitle = '',
  secondaryButtonIcon = '',
  onSecondaryButtonClick = () => 0,
  secondaryButtonProps = null,
  doOpen = false,
  doOnDismiss = () => {},
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(doOpen);
  const tooltipRef = useRef(null);
  useOnClickOutside(tooltipRef, () => {
    setIsTooltipOpen(false);
    doOnDismiss();
  });

  const { t } = useIntl('app.LocationAndPeriod');
  const history = useHistory();
  
  return (
    <>
      <StyledModalFooter showSecondaryButton={showSecondaryButton}>
        <IconGroup>
          <StyledIcon
            icon={HomeIcon}
            round
            onClick={() => history.push(Paths.TravelLocationAndPeriod)}
            id="homeIcon"
          />
          <StyledIcon
            icon={LocationIcon}
            round
            id="mapIcon"
            {...mapIconProps}
          />
          <TooltipContainer ref={tooltipRef}>
            <StyledIcon
              icon={InfoIcon}
              round
              onClick={() => setIsTooltipOpen(!isTooltipOpen)}
              id="tooltipIcon"
              {...tooltipIconProps}
            />
            {(isTooltipOpen || doOpen) && tooltipText ? (
              <Tooltip
                onDismiss={() => {
                  setIsTooltipOpen(false);
                  doOnDismiss();
                }}
                title={tooltipTitle}
                text={tooltipText}
                footerText={tooltipPoweredBy}
              />
            ) : null}
          </TooltipContainer>
          <CartIconWrapper {...cartIconProps} />
          <UserIconWrapper {...userIconProps} />
        </IconGroup>

        <ButtonGroup>
          {showButton ? (
            <Button
              title={buttonTitle}
              icon={buttonIcon}
              onClick={onButtonClick}
              id="nextButton"
              secondary
              {...buttonProps}
            />
          ) : null}
          {showSecondaryButton ? (
            <SecondaryButton
              title={secondaryButtonTitle}
              icon={secondaryButtonIcon}
              onClick={onSecondaryButtonClick}
              id="secondaryButton"
              {...secondaryButtonProps}
            />
          ) : null}
        </ButtonGroup>
      </StyledModalFooter>
      <PoweredBy>{t('widgetFooterPoweredBy')}</PoweredBy>
    </>
  );
};

export default ModalFooter;
