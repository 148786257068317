import { ApolloProvider } from '@apollo/client';
import 'assets/fonts/stylesheet.css';
import { render } from 'preact';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { myDestiClient } from './apollo/client';
import App from './App';

render(
  <ApolloProvider client={myDestiClient}>
      <App />
  </ApolloProvider>,
  document.querySelector("#app")
);
