import { ReactiveVar } from '@apollo/client';
import { ActivityIndividual } from 'types/cache/ActivityIndividual';
import { Session } from './activityModel';

export function useIndividualActivity(
  individualActivityVar: ReactiveVar<ActivityIndividual>
) {
  const setNumValues = (data: any, key: string) => {
    const oldValue = individualActivityVar();
    const updated = { ...oldValue, [key]: data };
    individualActivityVar(updated);
  };

  const setChildrenYears = (year: any) => {
    const oldValue = individualActivityVar();
    const { childrenYears } = oldValue;

    childrenYears.indexOf(year.idx) === -1
      ? (childrenYears[year.idx] = year)
      : childrenYears.splice(childrenYears.indexOf(year.idx), 1);

    const updated = { ...oldValue, childrenYears };
    individualActivityVar(updated);
  };

  const setTravelPeriod = (travelPeriod: any) => {
    const travelLocationAndPeriod = individualActivityVar();
    const updatedTravelLocationAndPeriod = {
      ...travelLocationAndPeriod,
      timePeriod: null,
      travelPeriod,
    };

    individualActivityVar(updatedTravelLocationAndPeriod);
  };

  const setTimePeriod = (
    timePeriod: Session,
    defaultQuantity: number,
    resetPriceCategories: boolean = false
  ) => {
    const oldValue = individualActivityVar();
    const priceCategories =
      resetPriceCategories || oldValue.priceCategories.length === 0
        ? timePeriod?.prices.map((p, idx) => ({
            label: p.label,
            quantity: idx === 0 ? defaultQuantity : 0,
            optionId: p.id,
            price: p.price,
            seatsUsed: p.seatsUsed,
          }))
        : oldValue.priceCategories;

    const updated = {
      ...oldValue,
      priceCategories,
      timePeriod,
    };

    individualActivityVar(updated);
  };

  const clearTimePeriod = () => {
    const oldValue = individualActivityVar();

    const updated = {
      ...oldValue,
      priceCategories: [],
      timePeriod: null,
    };

    individualActivityVar(updated);
  };

  const setParticipants = (label: string, value: number) => {
    const oldValue = individualActivityVar();
    const { priceCategories } = oldValue;
    priceCategories.find(x => x.label === label).quantity = value;

    const updated = {
      ...oldValue,
      priceCategories,
    };

    individualActivityVar(updated);
  };

  return {
    setNumValues,
    setChildrenYears,
    setTravelPeriod,
    setTimePeriod,
    setParticipants,
    clearTimePeriod,
  };
}
