import useIntl from 'hooks/useIntl';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';

const translateTitle = () => {
  const { t } = useIntl('app.Controls');
  return t('loadingMessage');
};

const Container = styled.div`
  max-width: 100%;
  height: 151px;
  margin-top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.default};
`;

type Props = {
  title?: string;
  className?: string;
};

const Loading: FunctionalComponent<Props> = ({
  title = translateTitle(),
  className,
}) => (
  <Container className={className}>
    <Title>{title}</Title>
  </Container>
);

export default Loading;
