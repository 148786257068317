import { makeVar } from '@apollo/client';
import { ActivityList } from 'types/cache/ActivityList';
import { GenericConfig } from 'types/shared/GenericConfig';

// TODO: Update keys when we add popular and rating options to backend
export const ActivitiesSort: GenericConfig = {
  popular: 'created_at:asc',
  priceAsc: 'price:asc',
  priceDesc: 'price:desc',
  ratingAsc: 'name:asc',
  ratingDesc: 'name:desc',
};

const activitiesListInitial: ActivityList = {
  orderBy: { key: ActivitiesSort.popular, value: '' },
  city: null,
  activityRatings: {},
  activityType: null
};
export const activitiesListVar = makeVar<ActivityList>(activitiesListInitial);
