export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
}

export enum TransportTypes {
  Air = 'Air',
  Train = 'Train',
  Bus = 'Bus',
  Boat = 'Water',
  Taxi = 'Taxi',
}

export enum TranportTypeKey {
  Airplane = 'airplane',
  Trainbus = 'trainbus',
}
